import { useLocation } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { loadTrack, useAudioController } from '../../audio/AudioController';
import { togglePlayPause } from '../../audio/AudioEngineHTML5';
import { useAudioPosition } from '../../audio/AudioPosition';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useQuery } from '../../graphql/client';
import {
  getFragment,
  VaultContentByIdDocument,
  VaultContentLandingPageFragmentDoc,
} from '../../graphql/generated';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { PlayButtonView } from '../audioPlayer/PlayButtonView';
import { Button, type OnClickType } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { PlayIndicatorBars } from '../track/PlayIndicatorBars';

const MinimizedMessageAttachment = ({
  trackId,
  onClose,
}: {
  trackId: string;
  onClose: OnClickType;
}) => {
  const { pathname } = useLocation();
  const { playing, activeTrackId, track: activeTrack } = useAudioController();
  const { percentComplete } = useAudioPosition();
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { data: vaultContent } = useQuery(VaultContentByIdDocument, {
    variables: { vaultContentId: trackId },
    staleTime: 0,
  });

  if (vaultContent?.data.vaultContentById?.__typename !== 'VaultTrack') {
    return null;
  }

  const track = getFragment(VaultContentLandingPageFragmentDoc, vaultContent.data.vaultContentById);

  const { id, title, vault, parentVaultContentId } = track;
  const isPlayDisabled = false;

  const isPlaying = playing && activeTrackId === id;

  const onPlayClick = () => {
    if (activeTrackId !== id) {
      loadTrack({
        trackId: id,
        vaultId: vault.id,
        component: 'minimized_message_attachment',
        folderId: parentVaultContentId,
      });
    } else {
      activeTrack != null &&
        trackEvent({
          type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
          properties: {
            trackId: activeTrack.id,
            vaultId: activeTrack.vault.id,
            artistId: activeTrack.vault.artist?.id,
            percentComplete,
            isPreview: !activeTrack.isFullVersionAvailable,
            component: 'minimized_message_attachment',
          },
          pathname,
        });

      togglePlayPause();
    }
  };

  return (
    <View
      className={twMerge(
        'mb-2 box-border flex w-full flex-row items-center justify-between rounded-lg px-4 py-3',
        isThemeEnabled
          ? 'bg-vault_text/10'
          : isNewMenuEnabled
            ? 'bg-base800 md2:bg-black'
            : 'bg-base800',
      )}
    >
      <View className="flex flex-row items-center gap-[10px]">
        <View>
          <PlayButtonView
            isPlaying={isPlaying}
            isDisabled={isPlayDisabled}
            onClick={onPlayClick}
            size={20}
          />
        </View>

        <View className="flex flex-row">
          {isPlaying && <PlayIndicatorBars className="mr-1" isAuthor={false} />}
          <Text className="mr-1 line-clamp-1 font-base !text-base-m font-semibold">{title}</Text>
        </View>
      </View>
      <Button
        iconOnly
        label=""
        leadingIcon={faClose}
        className={twMerge(
          'text-[14px]/[18px]',
          isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
        )}
        onClick={onClose}
      />
    </View>
  );
};

export { MinimizedMessageAttachment };
