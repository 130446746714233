import { type ReactNode, useCallback, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { current, produce } from 'immer';
import { remove } from 'lodash-es';
import { proxy, useSnapshot } from 'valtio';
import { proxySet } from 'valtio/utils';
import { useAuthContext } from '../contexts/AuthContext';
import {
  type ExecutionResultWithData,
  invalidateOperations,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '../graphql/client';

import type {
  FragmentType,
  MessageChannelUpdatesDocument,
  MessageChannelUpdatesSubscription,
} from '../graphql/generated';
import {
  ArtistReactionsMessageReactionRowFragmentDoc,
  getFragment,
  GetMessageChannelByArtistHandleDocument,
  type GetMessageChannelByArtistHandleQuery,
  makeFragmentData,
  MessageBubbleFragmentDoc,
  type MessageChannelViewFragment,
  MessageChannelViewFragmentDoc,
  MessageReactionRowFragmentDoc,
  MessageReactionTypeInput,
  MessageSubscriptionBubbleFragmentDoc,
  MyReactionsMessageReactionRowFragmentDoc,
  PinnedMessageChannelDocument,
  ReplyToMessageFragmentDoc,
  UpdateUserLastViewedMessageTimeOnVaultDocument,
} from '../graphql/generated';
import type { OnData } from '../graphql/wsClient';
import { LoginStatus } from '../types/authTypes';
import type { ReverseFragmentType, TypeFromGraphQLUnion } from '../types/gql';
import { getFromList, getManyFromList } from '../utils/arrayUtils';
import { createContainer } from '../utils/unstated';
import { useArtistHandle } from './useArtistHandle';
import { useStableCallback } from './useStableCallback';

const customPagesStore = proxy<
  Record<string, Record<string, ExecutionResultWithData<GetMessageChannelByArtistHandleQuery>>>
>({});

const removedMessagesStore = proxy<Record<string, Set<string>>>({});

const replyToMessageStore = proxy<{ message: FragmentType<ReplyToMessageFragmentDoc> | null }>({
  message: null,
});

export const setReplyToMessage = (message: FragmentType<ReplyToMessageFragmentDoc> | null) => {
  replyToMessageStore.message = message;
};

export const useRemovedMessagesList = ({ artistHandle }: { artistHandle: string | undefined }) =>
  useSnapshot(removedMessagesStore)[artistHandle?.toLowerCase() || '_'];

export const useReplyToMessage = () => useSnapshot(replyToMessageStore).message;

const REFETCH_INTERVAL_NO_WEBSOCKET_CONNECTION = 2_000; // 2 seconds

export function useVaultMessagesCustomPages({
  artistHandle,
}: {
  artistHandle: string | undefined;
}) {
  const customPagesSnapshot = useSnapshot(customPagesStore)[artistHandle?.toLowerCase() || '_'];
  return useMemo(() => Object.values(customPagesSnapshot ?? {}), [customPagesSnapshot]);
}

export const ActiveArtistMessageChannel = proxy<{
  artistHandle: string | undefined;
  onNewMessage: ((args: { isOptimistic: boolean }) => void) | null;
  websocketsConnectionReady: boolean;
}>({
  artistHandle: undefined,
  onNewMessage: null,
  websocketsConnectionReady: false,
});

export function useSetActiveArtistChatHandle() {
  const artistHandle = useArtistHandle().artistHandle?.toLowerCase();

  useEffect(() => {
    if (!artistHandle) return;

    ActiveArtistMessageChannel.artistHandle = artistHandle;

    return () => {
      ActiveArtistMessageChannel.artistHandle = undefined;
      ActiveArtistMessageChannel.websocketsConnectionReady = false;
    };
  }, [artistHandle]);
}

const VaultMessageChannelContainer = createContainer(() => {
  const { loginStatus, loggedInUser } = useAuthContext();
  const { artistHandle, websocketsConnectionReady } = useSnapshot(ActiveArtistMessageChannel);
  const { mutate: setLastReadtime } = useMutation(UpdateUserLastViewedMessageTimeOnVaultDocument, {
    retry: 3,
    onError: error => {
      Sentry.captureException(error, {
        tags: {
          type: 'updateUserLastViewedMessageTimeOnVault',
        },
      });
    },
  });

  const {
    data: messageChannelLayoutInfo,
    setQueryData: setPinnedMessagesData,
    isInitialLoading: pinnedMessagesInitialLoading,
  } = useQuery(PinnedMessageChannelDocument, {
    variables: !!artistHandle && { input: { artistHandle }, asArtistId: loggedInUser?.artist?.id },
    staleTime: 0,
    select(data) {
      return data.data.messageChannelByArtistHandle;
    },
    enabled: loginStatus === 'LOGGED_IN',
  });

  const pinnedMessages = messageChannelLayoutInfo?.pinnedMessages;

  if (artistHandle) customPagesStore[artistHandle] ||= {};

  const customPages = useVaultMessagesCustomPages({ artistHandle });

  const removedMessagesList = useRemovedMessagesList({ artistHandle });

  const filter = useCallback(
    (message: MessageChannelViewFragment['messages']['edges'][number]['node']) => {
      return !removedMessagesList?.has(message.id);
    },
    [removedMessagesList],
  );

  const query = useInfiniteQuery(GetMessageChannelByArtistHandleDocument, {
    staleTime: 0,
    filterQueryKey: {
      artistHandle,
    },
    customPages,
    refetchInterval: websocketsConnectionReady ? false : REFETCH_INTERVAL_NO_WEBSOCKET_CONNECTION,
    getNextPageParam: ({ data }) => {
      const messageChannel = getFragment(
        MessageChannelViewFragmentDoc,
        data.messageChannelByArtistHandle,
      );
      return (
        messageChannel?.messages.pageInfo.hasNextPage && {
          after: messageChannel.messages.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          input: { artistHandle },
          after: pageParam?.after ?? null,
          first: 40,
          asArtistId: loggedInUser?.artist?.id,
        };
      }),
    filter,
    list: ({ messageChannelByArtistHandle }) => {
      const messageChannel = getFragment(
        MessageChannelViewFragmentDoc,
        messageChannelByArtistHandle,
      );

      return getManyFromList(messageChannel?.messages.edges, edge => {
        const info = getFragment(MessageBubbleFragmentDoc, edge.node);

        return {
          ...edge.node,
          ...makeFragmentData(
            {
              ...info,
              reactionsSummary: info.reactionsSummary.filter(
                v => getFragment(MessageReactionRowFragmentDoc, v).type === 'EMOJI',
              ),
            },
            MessageBubbleFragmentDoc,
          ),
        };
      });
    },
    uniq: ({ id }) => id,
    enabled: artistHandle != null && loginStatus === LoginStatus.LOGGED_IN,
    order: [e => e.createdAt, 'desc'],
  });

  const { setInfiniteQueryData, entityStore } = query;

  const newMessage = useStableCallback(
    (
      message: ReverseFragmentType<
        TypeFromGraphQLUnion<
          TypeFromGraphQLUnion<
            MessageChannelUpdatesSubscription['messageChannelUpdates'],
            'SubscriptionMessageChannelUpdatesSuccess'
          >['data'],
          'CreateMessageSubscription'
        >
      > & { isOptimistic?: true },
    ) => {
      if (!artistHandle) {
        return {
          revert() {},
        };
      }
      const customPagesVaultMessages = customPagesStore[artistHandle]!;
      const messageChannel = query.firstPage?.data.messageChannelByArtistHandle;

      if (messageChannel == null) {
        return {
          revert() {},
        };
      }

      const messageFrag = (entityStore.nodes[message.id] = {
        ...makeFragmentData(
          {
            ...message,
            source: message.source,
            pinnedPriority: null,
            artistReactions: [],
            myReactions: [],
            reactionsSummary: [],
            vaultContent: message.vaultContent,
            ' $fragmentName': 'MessageBubbleFragment',
            messageAttachments: message.messageAttachments,
            replyTo: message.replyTo && {
              ...makeFragmentData(
                {
                  ...message.replyTo,
                  messageAttachments: message.replyTo.messageAttachments,
                  source: message.replyTo.source,
                  activeSubscriptionTier: message.replyTo.activeSubscriptionTier,
                },
                ReplyToMessageFragmentDoc,
              ),
              id: message.replyTo.id,
              source: message.replyTo.source,
            },
            replyToWasDeleted: false,
            activeSubscriptionTier: message.activeSubscriptionTier,
          },
          MessageBubbleFragmentDoc,
        ),
        ...message,
      });

      const messageChannelView = getFragment(MessageChannelViewFragmentDoc, messageChannel);

      const messages = {
        edges: [
          {
            cursor: message.id,
            node: messageFrag,
          },
        ],
        pageInfo: { endCursor: null, hasNextPage: true },
      } satisfies MessageChannelViewFragment['messages'];

      const messageChannelViewFragment = makeFragmentData(
        { ...messageChannelView, messages },
        MessageChannelViewFragmentDoc,
      );

      const page = {
        data: {
          messageChannelByArtistHandle: {
            ...messageChannel,
            ...messageChannelViewFragment,
          },
        },
      } satisfies ExecutionResultWithData<GetMessageChannelByArtistHandleQuery>;

      customPagesVaultMessages[message.id] = page;

      ActiveArtistMessageChannel.onNewMessage?.({ isOptimistic: message.isOptimistic ?? false });
      return {
        revert() {
          delete customPagesVaultMessages[message.id];
        },
        onMutationSuccess() {
          if (websocketsConnectionReady) return;

          Sentry.captureMessage('Message sent while websocket connection was not ready', {
            extra: {
              message,
              loggedInUser,
            },
            level: 'warning',
          });

          /**
           * If message was sent while the websocket connection was not ready,
           * We want to refetch the query to get the message confirmed back
           */
          invalidateOperations({
            operations: [GetMessageChannelByArtistHandleDocument],
          });
        },
      };
    },
  );

  const removeMessage = useStableCallback(({ messageId }: { messageId: string }) => {
    if (!artistHandle) return { revert() {} };

    removedMessagesStore[artistHandle] ||= proxySet();

    const channelRemovedMessages = removedMessagesStore[artistHandle]!;

    channelRemovedMessages.add(messageId);

    return {
      revert() {
        channelRemovedMessages.delete(messageId);
      },
    };
  });

  const reactionUpdate = useStableCallback(
    ({
      id,
      created,
      reactionType,
      userId,
      reactionTotalCount,
      isArtistReaction,
      asArtistId,
      emojiKeyword,
    }: Pick<
      TypeFromGraphQLUnion<
        TypeFromGraphQLUnion<
          MessageChannelUpdatesSubscription['messageChannelUpdates'],
          'SubscriptionMessageChannelUpdatesSuccess'
        >['data'],
        'ReactionMessageSubscription'
      >,
      | 'id'
      | 'created'
      | 'reactionType'
      | 'userId'
      | 'isArtistReaction'
      | 'asArtistId'
      | 'emojiKeyword'
    > & { reactionTotalCount: number | null }) => {
      if (reactionType !== MessageReactionTypeInput.Emoji) {
        return {
          revert() {},
        };
      }

      const messageFrag = getFragment(MessageBubbleFragmentDoc, entityStore.nodes[id]);

      if (messageFrag) {
        const isActor = asArtistId
          ? asArtistId === loggedInUser?.artist?.id
          : userId === loggedInUser?.id;
        if (created) {
          const createdId = `${id}_${userId}_${reactionType}`;

          if (isActor) {
            const createdReaction: (typeof messageFrag)['myReactions'][number] = {
              id: createdId,
              type: reactionType,
              emojiKeyword,
              ...makeFragmentData(
                { id: createdId, type: reactionType, emojiKeyword },
                MyReactionsMessageReactionRowFragmentDoc,
              ),
            };
            messageFrag.myReactions.push(createdReaction);
          }

          if (isArtistReaction) {
            const createdReaction: (typeof messageFrag)['artistReactions'][number] = {
              id: createdId,
              type: reactionType,
              emojiKeyword,
              ...makeFragmentData(
                { id: createdId, type: reactionType, emojiKeyword },
                ArtistReactionsMessageReactionRowFragmentDoc,
              ),
            };
            messageFrag.artistReactions.push(createdReaction);
          }

          let reactionSummary = getFromList(messageFrag.reactionsSummary, value => {
            const info = getFragment(MessageReactionRowFragmentDoc, value);

            return info.type === reactionType && info.emojiKeyword === emojiKeyword ? info : null;
          });

          if (!reactionSummary) {
            reactionSummary = {
              count: 0,
              type: reactionType,
              emojiKeyword,
            };
            messageFrag.reactionsSummary.push(
              makeFragmentData(reactionSummary, MessageReactionRowFragmentDoc),
            );
            reactionSummary = getFromList(messageFrag.reactionsSummary, value => {
              const info = getFragment(MessageReactionRowFragmentDoc, value);

              return info.type === reactionType && info.emojiKeyword === emojiKeyword ? info : null;
            })!;
          }

          reactionSummary.count = reactionTotalCount ?? (reactionSummary.count ?? 0) + 1;

          return {
            revert() {
              if (reactionSummary) reactionSummary.count -= 1;

              if (isActor) {
                remove(messageFrag.myReactions, ({ id }) => id === createdId);
              }

              if (isArtistReaction) {
                remove(messageFrag.artistReactions, ({ id }) => id === createdId);
              }
            },
          };
        } else {
          const prevMyReaction = messageFrag.myReactions.find(
            reaction => reaction.type === reactionType && reaction.emojiKeyword === emojiKeyword,
          );
          const prevArtistReaction = messageFrag.artistReactions.find(
            reaction => reaction.type === reactionType && reaction.emojiKeyword === emojiKeyword,
          );
          if (isActor) {
            remove(
              messageFrag.myReactions,
              reaction => reaction.type === reactionType && reaction.emojiKeyword === emojiKeyword,
            );
          }

          if (isArtistReaction) {
            remove(
              messageFrag.artistReactions,
              reaction => reaction.type === reactionType && reaction.emojiKeyword === emojiKeyword,
            );
          }

          let reactionSummary = getFromList(messageFrag.reactionsSummary, value => {
            const info = getFragment(MessageReactionRowFragmentDoc, value);

            return info.type === reactionType && info.emojiKeyword === emojiKeyword ? info : null;
          });

          if (!reactionSummary) {
            reactionSummary = {
              count: 0,
              type: reactionType,
              emojiKeyword,
            };
            messageFrag.reactionsSummary.push(
              makeFragmentData(reactionSummary, MessageReactionRowFragmentDoc),
            );
            reactionSummary = getFromList(messageFrag.reactionsSummary, value => {
              const info = getFragment(MessageReactionRowFragmentDoc, value);

              return info.type === reactionType && info.emojiKeyword === emojiKeyword ? info : null;
            })!;
          }

          reactionSummary.count = reactionTotalCount ?? (reactionSummary.count ?? 0) - 1;
          return {
            revert() {
              if (isActor && prevMyReaction) {
                messageFrag.myReactions.push(prevMyReaction);
              }
              if (isArtistReaction && prevArtistReaction) {
                messageFrag.artistReactions.push(prevArtistReaction);
              }
              if (reactionSummary) ++reactionSummary.count;
            },
          };
        }
      }

      return {
        revert() {},
      };
    },
  );

  const vaultId = messageChannelLayoutInfo?.vault?.id;

  const onMessageChannelUpdate: OnData<MessageChannelUpdatesDocument> = useStableCallback(
    ({ data: { messageChannelUpdates } }) => {
      if (messageChannelUpdates.__typename === 'SubscriptionMessageChannelUpdatesSuccess') {
        if (messageChannelUpdates.data.__typename === 'ReadyMessageSubscription') {
          ActiveArtistMessageChannel.websocketsConnectionReady = true;
        } else if (messageChannelUpdates.data.__typename === 'CreateMessageSubscription') {
          newMessage(getFragment(MessageSubscriptionBubbleFragmentDoc, messageChannelUpdates.data));

          if (vaultId) {
            setLastReadtime({ vaultId });
          }
        } else if (messageChannelUpdates.data.__typename === 'DeleteMessageSubscription') {
          const { id } = messageChannelUpdates.data;

          const page = customPagesStore[artistHandle || '_'];

          if (page?.[messageChannelUpdates.data.id]) {
            delete page[messageChannelUpdates.data.id];
          }

          setPinnedMessagesData(previous => {
            if (previous?.data.messageChannelByArtistHandle?.pinnedMessages == null) {
              return undefined;
            }

            remove(
              previous.data.messageChannelByArtistHandle.pinnedMessages,
              message => message.id === id,
            );
          });

          setInfiniteQueryData(previous => {
            if (previous == null) {
              return undefined;
            }

            return produce(previous, data => {
              for (const page of data.pages) {
                const messageChannel = getFragment(
                  MessageChannelViewFragmentDoc,
                  page.data.messageChannelByArtistHandle,
                );
                if (messageChannel != null) {
                  const removed = remove(
                    messageChannel.messages.edges,
                    edge => edge.node.id === id,
                  );

                  if (removed.length > 0) {
                    if (messageChannel.messages.pageInfo.endCursor === id) {
                      messageChannel.messages.pageInfo.endCursor =
                        messageChannel.messages.edges[messageChannel.messages.edges.length - 1]
                          ?.cursor ?? null;
                    }

                    break;
                  }
                }
              }
            });
          });
        } else if (messageChannelUpdates.data.__typename === 'PinMessageSubscription') {
          const { id, pinnedPriority } = messageChannelUpdates.data;

          setPinnedMessagesData(previous => {
            if (previous?.data.messageChannelByArtistHandle?.pinnedMessages == null) {
              return undefined;
            }

            remove(
              previous.data.messageChannelByArtistHandle.pinnedMessages,
              ({ id: messageId }) => messageId === id,
            );
          });

          invalidateOperations({
            operations: [PinnedMessageChannelDocument],
          });

          setInfiniteQueryData(previous => {
            if (previous == null) {
              return undefined;
            }

            return produce(previous, data => {
              for (const page of data.pages) {
                const messageChannel = getFragment(
                  MessageChannelViewFragmentDoc,
                  page.data.messageChannelByArtistHandle,
                );
                if (messageChannel != null) {
                  const message = messageChannel.messages.edges.find(edge => edge.node.id === id);
                  const messageFrag =
                    message &&
                    getFragment(MessageBubbleFragmentDoc, entityStore.nodes[message.node.id]);

                  if (messageFrag != null) {
                    messageFrag.pinnedPriority = pinnedPriority;

                    if (pinnedPriority != null) {
                      setPinnedMessagesData(previousPinnedMessageData => {
                        if (previousPinnedMessageData?.data.messageChannelByArtistHandle == null) {
                          return undefined;
                        }

                        previousPinnedMessageData.data.messageChannelByArtistHandle.pinnedMessages.splice(
                          pinnedPriority,
                          0,
                          current(messageFrag),
                        );
                      });
                    }

                    break;
                  }
                }
              }
            });
          });
        } else if (messageChannelUpdates.data.__typename === 'UnpinAllMessageSubscription') {
          const { count } = messageChannelUpdates.data;

          setPinnedMessagesData(previous => {
            if (previous?.data.messageChannelByArtistHandle == null) {
              return undefined;
            }

            previous.data.messageChannelByArtistHandle.pinnedMessages = [];
          });

          invalidateOperations({
            operations: [PinnedMessageChannelDocument],
          });

          setInfiniteQueryData(previous => {
            if (previous == null) {
              return undefined;
            }

            return produce(previous, data => {
              let remainingCount = count;

              for (const page of data.pages) {
                const messageChannel = getFragment(
                  MessageChannelViewFragmentDoc,
                  page.data.messageChannelByArtistHandle,
                );
                if (messageChannel != null) {
                  for (const edge of messageChannel.messages.edges) {
                    const messageFrag = getFragment(
                      MessageBubbleFragmentDoc,
                      entityStore.nodes[edge.node.id],
                    );
                    if (messageFrag?.pinnedPriority != null) {
                      messageFrag.pinnedPriority = null;
                      remainingCount--;
                      if (remainingCount <= 0) {
                        break;
                      }
                    }
                  }

                  if (remainingCount <= 0) {
                    break;
                  }
                }
              }
            });
          });
        } else if (messageChannelUpdates.data.__typename === 'ReactionMessageSubscription') {
          reactionUpdate(messageChannelUpdates.data);
        }
      }
    },
  );

  const isInitialLoading = !artistHandle || query.isInitialLoading || pinnedMessagesInitialLoading;

  const clearCustomPages = useCallback(() => {
    if (artistHandle && artistHandle in customPagesStore) {
      customPagesStore[artistHandle] = {};
    }
  }, [artistHandle]);

  return {
    onMessageChannelUpdate,
    query,
    pinnedMessages,
    newMessage,
    reactionUpdate,
    removeMessage,
    messageChannel: messageChannelLayoutInfo,
    isInitialLoading,
    clearCustomPages,
  };
});

export const useVaultMessageChannel = () => VaultMessageChannelContainer.useContainer();

export const VaultMessageChannelProvider = ({ children }: { children: ReactNode }) => (
  <VaultMessageChannelContainer.Provider>{children}</VaultMessageChannelContainer.Provider>
);
