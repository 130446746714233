import type { FC } from 'react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { faBars } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { MenuContext } from '../../contexts/MenuContext';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { EVENTS } from '../../types/eventTypes';
import { Button } from './Button';

type Props = { className?: string };

const MenuButton: FC<Props> = ({ className }) => {
  const { artistHandle } = useArtistHandle();
  const { openMenu, closeMenu, isOpen } = MenuContext.useContainer();
  const onClick = () => {
    if (isOpen) {
      closeMenu();
    } else {
      openMenu(artistHandle?.toLowerCase());
    }
  };

  return (
    <Button
      className={twMerge('text-[24px] text-white', className)}
      iconOnly
      label=""
      leadingIcon={faBars}
      onClick={onClick}
      event={
        isOpen
          ? { type: EVENTS.CLOSE_MENU, properties: { type: 'button' } }
          : { type: EVENTS.OPEN_MENU, properties: null }
      }
    />
  );
};

export { MenuButton };
