import { useMemo } from 'react';
import { isUUID4 } from '@soundxyz/utils/src/validation';
import { loadTrack } from '../../audio/AudioController';
import { ModalType } from '../../constants/modalConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useModal } from '../../contexts/ModalContext';
import { useQuery } from '../../graphql/client';
import { TrackContentByIdDocument } from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useWindow } from '../../hooks/useWindow';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { openFullscreenPlayer } from '../main/AudioPlayer';
import { StandardModal } from './StandardModal';

type ListenModalProps = { trackId: string; vaultId: string };

export function useListenNowModal() {
  const { openModal } = useModal();

  const openListenNowModal = useStableCallback(({ trackId, vaultId }: ListenModalProps) => {
    openModal(ModalType.LISTEN_NOW, {
      body: <ListenNowModal trackId={trackId} vaultId={vaultId} />,
    });
  });

  return useMemo(() => ({ openListenNowModal }), [openListenNowModal]);
}

function ListenNowModal({ trackId, vaultId }: ListenModalProps) {
  const { isDesktop } = useWindow();
  const { loggedInUser } = useAuthContext();
  const { closeModal } = useModal();

  const { data: track, isLoading } = useQuery(TrackContentByIdDocument, {
    variables: isUUID4(trackId) && {
      vaultContentId: trackId,
      asArtistId: loggedInUser?.artist?.id,
    },
    staleTime: 5000,
    select(data) {
      if (data.data.vaultContentById?.__typename !== 'VaultTrack') return null;

      return data.data.vaultContentById;
    },
  });

  const onListenNowClick = useStableCallback(() => {
    loadTrack({
      trackId,
      vaultId,
      component: 'track_landing_page',
      folderId: track?.parentVaultContentId ?? null,
    }).then(() => {
      !isDesktop && openFullscreenPlayer();
      closeModal();
    });
  });

  const vault = track?.vault;
  const artistName = vault?.artist?.name ?? 'Unknown';
  const trackTitle = track?.title || 'Untitled';
  const artistProfileImage = vault?.artist?.profileImage?.url;

  if (isLoading) {
    return null;
  }

  return (
    <StandardModal
      closeModal={() => {
        closeModal();
      }}
      body={
        <View className="flex flex-col items-center justify-center gap-2">
          <ArtistProfileImage
            profileImageUrl={artistProfileImage}
            className="h-[100px] w-[100px] rounded-full"
          />
          <View className="flex flex-col items-center">
            <Text className="font-title text-title-xl font-medium text-white">{trackTitle}</Text>
            <Text className="font-base text-base-l font-medium text-white">{artistName}</Text>
          </View>
        </View>
      }
      size="small"
      isCentered
      preventClose
      callToAction={
        <View className="flex w-full justify-center">
          <Button
            label="Listen now"
            type="primary"
            className="flex w-full text-base-l font-medium text-black md:w-auto"
            onClick={onListenNowClick}
            disabled={isLoading}
          />
        </View>
      }
    />
  );
}
