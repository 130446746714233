import { gql } from '@soundxyz/gql-string';
import type { Falsy } from '@soundxyz/utils';
import { useInfiniteQuery } from '../../graphql/client';
import { GetPaginatedVaultAnnouncementsDocument } from '../../graphql/generated';
import { getManyFromList } from '../../utils/arrayUtils';

gql(/* GraphQL */ `
  query GetPaginatedVaultAnnouncements($artistHandle: String!, $after: String, $first: Int!) {
    announcementsByArtistHandle(artistHandle: $artistHandle, after: $after, first: $first) {
      edges {
        node {
          id
          ... on Announcement {
            content
            ...AnnouncementRow
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const LIMIT = 10;

export function usePaginatedVaultAnnouncements({
  enabled,
  artistHandle,
  limit = LIMIT,
}: {
  enabled: boolean;
  artistHandle: string | Falsy;
  limit?: number;
}) {
  return useInfiniteQuery(GetPaginatedVaultAnnouncementsDocument, {
    filterQueryKey: { artistHandle },
    staleTime: 0,
    enabled,
    getNextPageParam: ({ data }) => {
      return (
        data.announcementsByArtistHandle.pageInfo.hasNextPage && {
          after: data.announcementsByArtistHandle.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: limit,
        };
      }),
    list: ({ announcementsByArtistHandle }) => {
      return getManyFromList(
        announcementsByArtistHandle.edges,
        ({ node }) => node.content != null && node,
      );
    },
    uniq: ({ id }) => id,
  });
}
