import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { type FragmentType, getFragment, TrackRowInfoFragmentDoc } from '../../graphql/generated';
import { getDurationAsTime } from '../../utils/dateUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';

const PinnedMessageAttachment = ({ track }: { track: FragmentType<TrackRowInfoFragmentDoc> }) => {
  const trackInfo = getFragment(TrackRowInfoFragmentDoc, track);

  const { title, duration } = trackInfo;

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View className="box-border flex w-full flex-row items-center justify-start gap-1 rounded-lg bg-transparent">
      <Text
        className={twMerge(
          'line-clamp-1 font-base text-[14px] font-medium',
          isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
        )}
      >
        {title}
      </Text>
      <Text
        className={twMerge(
          'font-base !text-base-m text-[14px] font-medium',
          isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
        )}
      >
        {getDurationAsTime(duration)}
      </Text>
    </View>
  );
};

export { PinnedMessageAttachment };
