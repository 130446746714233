import linkifyit, { type FullRule } from 'linkify-it';
import { IS_FINAL_DEPLOYMENT } from '../constants/urlConstants';

export const createNewLinkifyIt = () => {
  return new linkifyit();
};

export const addLinkifyItSchema = (linkifyIt: linkifyit, schema: string, rule: FullRule) => {
  linkifyIt.add(schema, rule);
};

export function generateShareLink({
  artistLinkValue,
  inviteCode = '',
  path = '',
}: {
  artistLinkValue: string | undefined | null;
  inviteCode: string | undefined | null;
  path: string | undefined | null;
}) {
  if (!artistLinkValue) return window.location.href;

  const url = new URL(window.location.href);
  const { host } = url;
  const isSubdomainPresent = host.startsWith(`${artistLinkValue}.`);

  if (IS_FINAL_DEPLOYMENT) {
    if (!isSubdomainPresent) {
      url.host = `${artistLinkValue}.${host}`;
    }
    url.pathname = path || '';
  } else {
    if (isSubdomainPresent) {
      url.host = host.replace(`${artistLinkValue}.`, '');
    }
    url.pathname = `/${artistLinkValue}${path || ''}`;
  }

  url.search = '';

  if (inviteCode) {
    url.searchParams.set('invite', inviteCode);
  }

  return url.href;
}
