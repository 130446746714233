import React, { useRef, useState } from 'react';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useModal } from '../../contexts/ModalContext';
import { useQuery } from '../../graphql/client';
import { VaultCustomizationDocument } from '../../graphql/generated';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { CustomizeVaultForm } from '../menu/customize-vault/CustomizeVaultForm';
import { StandardModal } from './StandardModal';

export function CustomizeVaultModal({ vaultId }: { vaultId: string }) {
  const { closeModal } = useModal();
  const { closeVaultCustomization } = useMenuContainer();

  const formRef = useRef<{ submit: () => void }>(null);
  const [isActionPending, setActionPending] = useState(false);

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  const { data, isLoading } = useQuery(VaultCustomizationDocument, {
    staleTime: 0,
    variables: !!vaultId && { vaultId },
    enabled: !!vaultId,
  });

  if (isLoading || data?.data.vaultFromId == null || !vaultId) {
    return null;
  }

  return (
    <StandardModal
      closeModal={closeModal}
      isFullScreen
      preventClose
      hideHeader
      bodyWithoutTextStyle
      containerClassName="bg-black md:w-full"
      overlayClassName="!z-mobileNav" // Custom z-index to ensure the modal is above the close bottomsheet
      body={
        <View className="relative">
          <View className="z-10 sticky -top-2 box-border flex w-full flex-row items-center justify-between bg-black pb-7 pt-10">
            <div className="flex-1">
              <Button
                leadingIcon={faXmark}
                iconOnly
                onClick={() => closeVaultCustomization({ showConfirm: true })}
                disabled={isActionPending}
                label="Close"
                className="text-[24px] text-white"
                disabledClassName="opacity-50 cursor-not-allowed"
              />
            </div>

            <div className="flex-1 text-center">
              <Text className="font-title text-title-m">Customize</Text>
            </div>

            <div className="flex flex-1 justify-end">
              <Button
                buttonType="submit"
                label=""
                labelComponent={
                  <Text className="font-base text-base-l font-medium text-[#E3F41D]">Save</Text>
                }
                onClick={handleSubmit}
                disabled={isActionPending}
                disabledClassName="opacity-50"
              />
            </div>
          </View>
          <CustomizeVaultForm vaultId={vaultId} setActionPending={setActionPending} ref={formRef} />
        </View>
      }
    />
  );
}
