import { type LegacyRef } from 'react';
import { useNavigate } from 'react-router';
import { FolderItemFragmentDoc } from '../../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../../graphql/generated';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { FolderItem } from '../Folder';

export const UserFolder = ({
  folderInfo,
  isOwner,
  containerRef,
  artistHandle,
}: {
  folderInfo: FragmentType<FolderItemFragmentDoc> & { id: string };
  isOwner: boolean;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const navigate = useNavigate();
  const { linkValue } = getFragment(FolderItemFragmentDoc, folderInfo);

  return (
    <FolderItem
      folderInfo={folderInfo}
      onClick={() =>
        navigate(
          artistNavigationPath(
            artistHandle,
            linkValue != null ? `/f/${linkValue}` : `/folder/${folderInfo.id}`,
          ),
        )
      }
      containerRef={containerRef}
      isOwner={isOwner}
      artistHandle={artistHandle}
      editingFolder={null}
    />
  );
};
