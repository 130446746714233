import type { ReactNode } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import type { LongPressPointerHandlers } from 'use-long-press';
import { faEllipsisH, type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { FEATURE_GATES } from '../../../../constants/flagConstants';
import { useMediaQuery } from '../../../../contexts/mediaQuery';
import { SelectButton } from '../../../common/SelectButton';
import { View } from '../../../common/View';

export type ContentOption = {
  title: string;
  icon: IconDefinition;
  onClick: () => void;
};

export function ContentOptions({
  isOpen,
  setIsOpen,
  onLongPress,
  isSelecting,
  onClick,
  selectedFile,
  menuHovered,
  setMenuHovered,
  buttons,
  triggerItem,
  disabled,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setMenuHovered: (value: boolean) => void;
  onLongPress: LongPressPointerHandlers<Element>;
  isSelecting: boolean;
  onClick: () => void;
  selectedFile: boolean;
  menuHovered: boolean;
  buttons: ContentOption[];
  triggerItem: ReactNode;
  disabled: boolean;
}) {
  const { md2 } = useMediaQuery();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.MENU);

  return (
    <Popover.Root open={isOpen}>
      {isOpen && (
        <View
          className={twMerge(
            'absolute left-0 top-0 z-overlay h-full w-full animate-fadeIn md2:hidden',
            isThemeEnabled
              ? 'bg-vault_background/50'
              : isNewMenuEnabled
                ? 'bg-black/50'
                : 'bg-base800_alpha50',
          )}
          onTouchMove={e => {
            e?.preventDefault();
            setIsOpen(false);
          }}
        />
      )}
      <View className="relative h-full w-full flex-grow">
        <Popover.Trigger
          {...onLongPress}
          onContextMenu={e => {
            e.preventDefault();
            if (isSelecting || md2) return;
            setIsOpen(true);
          }}
          className="relative w-full select-none appearance-none border-none bg-transparent outline-none"
          onClick={onClick}
          disabled={disabled}
        >
          {triggerItem}
          {isSelecting && (
            <SelectButton
              isSelected={selectedFile}
              hideCheck
              className={twMerge(
                'absolute right-3 top-2 z-above2 h-2 w-2 cursor-pointer',
                isThemeEnabled
                  ? 'border-vault_text/40'
                  : isNewMenuEnabled
                    ? 'border-white/40'
                    : 'border-base500',
              )}
            />
          )}
        </Popover.Trigger>
        {md2 && !isSelecting && (
          <div
            className={twMerge(
              'absolute right-0 top-[120px] z-above2 flex aspect-square h-5 w-5 flex-shrink-0 appearance-none items-center justify-center rounded-full border-none bg-transparent outline-none',
              isThemeEnabled ? 'hover:bg-vault_text/10' : 'hover:bg-base700',
            )}
            onMouseEnter={() => {
              setIsOpen(true);
            }}
            onMouseLeave={() => {
              if (menuHovered) return;
              setTimeout(() => {
                setIsOpen(false);
              }, 2000);
            }}
          >
            <FontAwesomeIcon
              icon={faEllipsisH}
              className={twMerge(
                'cursor-pointer !text-base-m',
                isThemeEnabled ? 'text-vault_text' : 'text-base50',
              )}
            />
          </div>
        )}
      </View>

      <Popover.Portal>
        <Popover.Content
          side={md2 ? 'right' : 'bottom'}
          avoidCollisions
          sideOffset={4}
          onClick={() => {
            setIsOpen(false);
          }}
          onInteractOutside={() => {
            if (md2) return;
            setIsOpen(false);
          }}
          align="center"
          className={twMerge(
            'z-bottomsheet flex w-56 flex-col rounded-lg p-1 will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade md2:p-0',
            isThemeEnabled ? 'bg-vault_text/10 backdrop-blur-2xl' : 'bg-transparent md2:pb-2',
          )}
          onMouseLeave={() => {
            if (!md2) return;
            setIsOpen(false);
            setMenuHovered(false);
          }}
          onMouseEnter={() => {
            if (!md2) return;
            setMenuHovered(true);
          }}
        >
          {buttons.map((button, index) => {
            const isLast = index === buttons.length - 1;
            return (
              <React.Fragment key={`${button.title}-${index}`}>
                <button
                  onClick={button.onClick}
                  className={twMerge(
                    'flex h-11 w-full appearance-none items-center justify-between border-x-0 border-b-[0.5px] border-t-0 px-4 outline-none first:rounded-t-lg md2:border-b-[1px]',
                    'cursor-pointer font-base text-[14px]/[18px]',
                    isThemeEnabled
                      ? 'border-b-vault_text/5 bg-transparent hover:bg-vault_text/20'
                      : 'border-b-base500 bg-base700 hover:bg-base600',
                    isThemeEnabled ? 'text-vault_text' : 'text-white',
                    isLast ? 'rounded-b-lg border-b-0' : '',
                  )}
                >
                  {button.title}
                  <FontAwesomeIcon icon={button.icon} className="text-[16px]" />
                </button>
              </React.Fragment>
            );
          })}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
