export const BOTTOMSHEET_TYPES = {
  ACTION: 'ACTION',
  ADD_TRACK_ATTACHMENT: 'ADD_TRACK_ATTACHMENT',
  CONFIRMATION: 'CONFIRMATION',
  EXIT_FLOW: 'EXIT_FLOW',
  SHARE: 'SHARE',
  SHARE_SNIPPET: 'SHARE_SNIPPET',
  SHARE_SNIPPET_INTERSTITIAL: 'SHARE_SNIPPET_INTERSTITIAL',
  SUBSCRIBE_WELCOME_MESSAGE: 'SUBSCRIBE_WELCOME_MESSAGE',
  PROFILE_PICTURE: 'PROFILE_PICTURE',
  USER_PROFILE: 'USER_PROFILE',
  MESSAGE_REACTION: 'MESSAGE_REACTION',
  ARTIST_TRACK_NOTE: 'ARTIST_TRACK_NOTE',
  ARTIST_MEDIA_ATTACHMENT: 'ARTIST_MEDIA_ATTACHMENT',
  ABOUT_ARTIST: 'ABOUT_ARTIST',
  ACCEPT_COLLABORATION: 'ACCEPT_COLLABORATION',
  GET_APP: 'GET_APP',
  TRACK_COMMENTS: 'TRACK_COMMENTS',
  SUBSCRIBERS_FILTERS: 'SUBSCRIBERS_FILTERS',
  USER_REFERRAL_FEATURE: 'USER_REFERRAL_FEATURE',
  CUSTOM: 'CUSTOM',
  JOIN_VAULT: 'JOIN_VAULT',
  MEMBERSHIP_BADGE: 'MEMBERSHIP_BADGE',
} as const;
