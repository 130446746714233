import type { FC } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';
import {
  ArtistLayoutFragmentDoc,
  type FragmentType,
  getFragment,
  GetPaymentMethodsDocument,
  PaymentMethodRowFragmentDoc,
} from '../../graphql/generated';
import { useSubscribeToVault } from '../../hooks/useSubscribeToVault';
import { LoginStatus } from '../../types/authTypes';
import { getDaysUntilDate } from '../../utils/dateUtils';
import { formatDateString, pluralizeText } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

type Props = {
  artist: FragmentType<ArtistLayoutFragmentDoc>;
};

export const RenewSubscriptionBanner: FC<Props> = ({ artist }) => {
  const { loginStatus } = useAuthContext();

  const { subscribe } = useSubscribeToVault();
  const { openBottomsheet } = useBottomsheetContainer();

  const { data: paymentMethodData, isLoading } = useQuery(GetPaymentMethodsDocument, {
    staleTime: 0,
    enabled: loginStatus === LoginStatus.LOGGED_IN,
  });

  if (isLoading) {
    return null;
  }

  if (paymentMethodData == null || paymentMethodData?.data.paymentMethods[0] == null) {
    return null;
  }

  const { last4 } = getFragment(
    PaymentMethodRowFragmentDoc,
    paymentMethodData?.data.paymentMethods[0],
  );

  const {
    name,
    mainVault: { id: vaultId, activeSubscription },
    linkValue,
  } = getFragment(ArtistLayoutFragmentDoc, artist);

  const onResubscribe = () => {
    openBottomsheet({
      type: 'CONFIRMATION',
      confirmationBottomsheetProps: {
        onConfirm: () => {
          activeSubscription?.stripeSubscriptionId != null &&
            subscribe({
              subscriptionId: activeSubscription?.stripeSubscriptionId,
              vaultId,
              linkValue,
              shouldRedirect: false,
            });
        },
        subText: `You will be subscribed to ${name} and will be charged on card ending in ${last4}${
          activeSubscription?.currentPeriodEnd != null
            ? ` starting ${formatDateString({
                date: activeSubscription?.currentPeriodEnd,
                format: 'numerical_month_day_year',
              })}.`
            : '.'
        }`,
      },
    });
  };

  const daysUntilExpiration =
    activeSubscription?.currentPeriodEnd != null
      ? getDaysUntilDate(activeSubscription?.currentPeriodEnd)
      : 0;

  const daysUntilExpirationText =
    daysUntilExpiration === 1
      ? 'today'
      : `in ${daysUntilExpiration} ${pluralizeText({ count: daysUntilExpiration, text: 'day' })}`;

  if (
    activeSubscription?.currentPeriodEnd === null ||
    activeSubscription?.status !== 'INACTIVE' ||
    // could be less than 0 if the subscription is already expired
    daysUntilExpiration <= 0
  ) {
    return null;
  }

  return (
    <View className="z-above1 flex justify-center max-md2:w-full">
      <View className="flex w-full justify-center bg-yellow100 text-center text-base600 md2:ml-[2px] md2:w-[602px] md2:max-lt:ml-[312px]">
        <Text className="py-[6px] text-[14px]">{`Your subscription expires ${daysUntilExpirationText}.`}</Text>
        <Button
          className="ml-[4px] cursor-pointer py-[6px] text-[14px] font-bold underline"
          onClick={onResubscribe}
          label="Renew now"
        />
      </View>
    </View>
  );
};
