import React from 'react';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faCheck } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import type { EventObject, EventType } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { View } from './View';

type Props<Event extends EventType> = {
  isSelected: boolean;
  className?: string;
  onClick?: () => void;
  event?: EventObject<Event>;
  hideCheck?: boolean;
};

const SelectButton = <Event extends EventType>({
  isSelected,
  className,
  onClick,
  event,
  hideCheck = false,
}: Props<Event>) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View
      className={twMerge(
        'h-[12px] w-[12px] items-center justify-center rounded-full border-[1px] border-solid p-[1px] text-[10px] font-black',
        isThemeEnabled ? 'border-vault_background' : 'border-black',
        className,
      )}
    >
      <Button
        iconOnly
        iconSize="xs"
        leadingIcon={isSelected && !hideCheck ? faCheck : undefined}
        label=""
        onClick={onClick}
        className={twMerge(
          'h-full w-full rounded-full text-base900',
          isSelected ? (isThemeEnabled ? 'bg-vault_accent' : 'bg-yellow100') : 'bg-transparent',
        )}
        event={event}
      />
    </View>
  );
};

export { SelectButton };
