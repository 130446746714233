import React from 'react';
import { GraphQLReactQueryProvider } from './graphql/client';
import { MainRouter } from './routers/MainRouter';

export function App() {
  return (
    <div className="h-full w-[100vw]">
      <GraphQLReactQueryProvider>
        <MainRouter />
      </GraphQLReactQueryProvider>
    </div>
  );
}
