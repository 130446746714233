const INVALID_SUBDOMAINS = ['www', 'vault', 'dev', 'privy'];

export function getSubdomain() {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts[0];
  }
  return null;
}

export function isValidSubdomain() {
  const subdomain = getSubdomain();
  const hostname = window.location.hostname;
  const isCloudflarePreview = hostname.endsWith('pages.dev');
  return subdomain && !INVALID_SUBDOMAINS.includes(subdomain) && !isCloudflarePreview;
}
