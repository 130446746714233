import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../constants/routeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { LoginStatus } from '../types/authTypes';
import { artistHandleFormat } from '../utils/analyticsUtils';
import { useSearchParams } from './params';

const ALLOWED_TO_REDIRECT_ROUTES = [ROUTES.EXPLORE, ROUTES.VAULTS];

export function useRedirectUserOnboarding() {
  const navigate = useNavigate();
  const { psid } = useSearchParams();
  const { loggedInUser, loginStatus } = useAuthContext();
  const location = useLocation();
  const currPath = location.pathname;

  const hasPsid = psid != null;

  const isAllowedToRedirectRoute = useMemo(() => {
    return ALLOWED_TO_REDIRECT_ROUTES.some(
      path => currPath === path || currPath.match(`^/${artistHandleFormat}/chat`),
    );
  }, [currPath]);

  useEffect(() => {
    if (
      loginStatus !== LoginStatus.LOGGED_IN ||
      !loggedInUser ||
      hasPsid ||
      !isAllowedToRedirectRoute
    )
      return;

    if (loggedInUser?.username == null) {
      setTimeout(() => navigate(ROUTES.ONBOARDING_USERNAME), 500);
    }
  }, [loggedInUser, loginStatus, navigate, hasPsid, isAllowedToRedirectRoute]);
}
