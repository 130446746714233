import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useGate } from 'statsig-react';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../constants/flagConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useBottomsheetContainer } from '../contexts/BottomsheetContext';
import { useQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  FeatureTypename,
  HighlightedFeatureDocument,
  SeenFeatureDocument,
} from '../graphql/generated';
import { LoginStatus } from '../types/authTypes';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';

gql(/* GraphQL */ `
  query HighlightedFeature($vaultId: UUID) {
    highlightedFeature(vaultId: $vaultId) {
      __typename
    }
  }
  mutation SeenFeature($featureKey: FeatureTypename!) {
    seenFeature(input: { featureKey: $featureKey }) {
      ... on MutationSeenFeatureSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [SeenFeatureDocument],
  refetch: [HighlightedFeatureDocument],
});

export function useShowHighlightedFeature({
  artistHandle,
  vaultId,
}: {
  artistHandle: string | null | undefined;
  vaultId: string | undefined;
}) {
  const { value: membershipEnabled } = useGate(FEATURE_GATES.MEMBERSHIP);
  const { loginStatus } = useAuthContext();

  const { pathname } = useLocation();

  const { openBottomsheet } = useBottomsheetContainer();

  const { data } = useQuery(HighlightedFeatureDocument, {
    staleTime: 5000,
    enabled: loginStatus === LoginStatus.LOGGED_IN,
    variables: !!vaultId && {
      vaultId,
    },
  });

  const highlightedFeature = data?.data.highlightedFeature?.__typename;

  // Open the highlighted bottomsheet after a delay
  useEffect(() => {
    if (highlightedFeature === FeatureTypename.UserReferrals) {
      if (!artistHandle || membershipEnabled) return;

      trackEvent({
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: {
          bottomsheetType: BOTTOMSHEET_TYPES.USER_REFERRAL_FEATURE,
          artistHandle,
        },
        pathname,
      });

      setTimeout(() => {
        openBottomsheet({
          type: BOTTOMSHEET_TYPES.USER_REFERRAL_FEATURE,
          userReferralFeatureBottomsheetProps: {
            artistHandle,
          },
        });
      }, 500);
    }
  }, [artistHandle, highlightedFeature, membershipEnabled, openBottomsheet, pathname]);
}
