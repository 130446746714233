import * as z from 'zod';
import { PersistenceStorage } from '../../utils/storeUtils';

export const SignInStore = PersistenceStorage({
  schema: z.object({
    codeRenabled: z.record(z.number()),
    lastActivePhoneNumber: z.string().nullable(),
  }),
  key: 'SignInTimestamps',
  eager: true,
});

export const ONE_MINUTE_IN_MS = 60 * 1000;
