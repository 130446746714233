export const VAULT_LOGO_SVG = 'https://d3jznoddta6qri.cloudfront.net/public/vault_logo.svg';

export const VAULT_LOGO_PNG = 'https://d3jznoddta6qri.cloudfront.net/public/vault_logo.png';

export const VAULT_LOGO_WHITE_PNG =
  'https://d3jznoddta6qri.cloudfront.net/public/vault_white_logo.png';

export const ABOUT_IMAGE = 'https://d3jznoddta6qri.cloudfront.net/public/about_image.png';

export const DEFAULT_AVATAR = 'https://d3jznoddta6qri.cloudfront.net/public/default_avatar.svg';

export const LANDING_MOBILE_PHONE =
  'https://d3jznoddta6qri.cloudfront.net/public/landing_phone_mobile.png';

export const INSTAGRAM_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/instagram.svg';

export const SPOTIFY_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/spotify.svg';

export const TIKTOK_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/tiktok.svg';

export const SOLID_YELLOW_THUMBTACK =
  'https://d3jznoddta6qri.cloudfront.net/public/solid_yellow_thumbtack.svg';

export const THUMBTACK = 'https://d3jznoddta6qri.cloudfront.net/public/thumbtack.svg';

export const GRAY_THUMBTACK = 'https://d3jznoddta6qri.cloudfront.net/public/gray_thumbtack.svg';

export const SOLID_THUMBTACK = 'https://d3jznoddta6qri.cloudfront.net/public/solid_thumbtack.svg';

export const FILE_IMAGE_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/file_image.svg';

export const LANDING_PAGE_LOGO =
  'https://d3jznoddta6qri.cloudfront.net/public/landing_page_logo.svg';

export const CD = 'https://d3jznoddta6qri.cloudfront.net/public/cd.png';

export const APP_ICON = 'https://d3jznoddta6qri.cloudfront.net/public/app_icon.png';

export const GIPHY_LOGO = 'https://d3jznoddta6qri.cloudfront.net/public/giphy.png';

export const BADGE_MATERIAL = 'https://d3jznoddta6qri.cloudfront.net/public/badge_material.png';

export const LANYARD_MATERIAL = 'https://d3jznoddta6qri.cloudfront.net/public/lanyard_texture.png';
