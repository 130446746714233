import { twMerge } from 'tailwind-merge';
import { faInstagram, faTiktok, faXTwitter } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faArrowRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import landingPageBg from '/images/landing_page_bg.png';
import landingPagePhoneArtist from '/images/landing_page_phone_artist.png';
import landingPagePhoneChat from '/images/landing_page_phone_chat.png';
import landingPagePhoneTrack from '/images/landing_page_phone_track.png';
import { Button } from '../components/buttons/Button';
import { Image } from '../components/common/Image';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { TitleLogo } from '../components/svg/Logo';
import { ROUTES } from '../constants/routeConstants';
import {
  PRIVACY_POLICY_URL,
  TOS_URL,
  VAULT_FM_INSTAGRAM_URL,
  VAULT_FM_TIKTOK_URL,
  VAULT_FM_TWITTER_URL,
} from '../constants/urlConstants';

export const LandingPage = () => {
  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      hasChatReadAccess={false}
      messageChannelId={undefined}
      showBorder
      vaultId={undefined}
      withBottomNavigator={false}
      shouldShowFullWidthOnDesktop
      headerLeft={
        <View className="h-[48px] md2:ml-2">
          <TitleLogo />
        </View>
      }
      headerRight={
        <Button
          href={`${ROUTES.SIGN_IN}`}
          type="secondary"
          label="Sign in"
          className="w-max rounded-full px-[20px] py-[16px]"
          linkClassName="w-max"
        />
      }
      shouldSkipMargin
    >
      <View
        className="mb-10 flex bg-bottom bg-no-repeat max-lg:mb-10 max-lg:bg-[length:0px_0px] lg:mt-10 lg:min-h-[800px] lg:w-full"
        style={{
          backgroundImage: `url(${landingPageBg})`,
        }}
      >
        <View
          className={twMerge(
            'flex max-lg:flex-col max-lg:items-center max-lg:bg-none lg:mx-[auto]  lg:flex-row lg:justify-between',
            'lg:w-[910px]',
          )}
        >
          <View className="flex max-w-[350px] flex-col max-lg:items-center lg:mt-24 lg:max-w-[380px]">
            <Text className="mt-5 box-border w-full font-title font-medium text-white max-lg:px-4 max-lg:text-center max-lg:text-title-xl lg:text-title-xxl">
              Drop music on your own terms
            </Text>
            <Text className="mt-5 box-border w-full max-w-[350px] font-base text-[14px]/[18px] font-light text-neutral400 max-lg:px-1 max-lg:text-center md2:max-w-none">
              Let fans subscribe to unlock unreleased music straight from your vault
            </Text>
            <View className="flex flex-col items-center lg:gap-5">
              <Button
                label="Apply as an artist"
                type="primary"
                linkClassName="w-full"
                className="mt-12 w-full"
                href="https://vaultfm.typeform.com/to/XWlHQ2Mn"
              />
              <Button
                label="Explore vaults"
                className="relative z-above1 font-base text-[16px]/[19.2px] font-light text-white max-lg:mt-7 lg:mt-1"
                trailingIcon={faArrowRight}
                href={`${ROUTES.VAULTS}`}
                isExternal
              />
            </View>
          </View>

          <View className="relative w-full max-w-[340px] max-lg:my-10">
            <View className="flex w-full flex-col">
              <Image
                src={landingPagePhoneChat}
                alt="phone"
                className="animate-fadeCycleLandingPage object-contain animation-delay-[6000ms]"
              />
              <Image
                src={landingPagePhoneTrack}
                alt="phone"
                className="absolute left-0 top-0 w-full animate-fadeCycleLandingPage object-contain animation-delay-[3000ms]"
              />
              <Image
                src={landingPagePhoneArtist}
                alt="phone"
                className="absolute left-0 top-0 w-full animate-fadeCycleLandingPage object-contain"
              />
            </View>
          </View>
        </View>
      </View>

      <Footer />
    </DefaultLayout>
  );
};

export function Footer() {
  return (
    <View className="mb-5 flex flex-col items-center gap-5">
      <View className="flex flex-row gap-5">
        <Button
          label=""
          iconOnly
          className="text-[20px] text-base400"
          leadingIcon={faInstagram}
          href={VAULT_FM_INSTAGRAM_URL}
          isExternal
        />
        <Button
          label=""
          iconOnly
          className="text-[20px] text-base400"
          leadingIcon={faTiktok}
          href={VAULT_FM_TIKTOK_URL}
          isExternal
        />
        <Button
          label=""
          iconOnly
          className="text-[20px] text-base400"
          leadingIcon={faXTwitter}
          href={VAULT_FM_TWITTER_URL}
          isExternal
        />
      </View>
      <View className="flex flex-row items-center gap-4">
        <a
          className="font-base text-title-xs text-base400 no-underline"
          href={PRIVACY_POLICY_URL}
          target="_blank"
        >
          Privacy Policy
        </a>
        <Text className="font-base text-title-xs text-base400">|</Text>
        <a
          className="font-base text-title-xs text-base400 no-underline"
          href={TOS_URL}
          target="_blank"
        >
          Terms of Service
        </a>
      </View>
      <Text className="mx-4 text-center font-base text-title-xs text-base400">
        Copyright © 2024 Vault.
        <br />
        All rights reserved.
      </Text>
    </View>
  );
}
