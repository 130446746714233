import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextareaAutosize from 'react-textarea-autosize';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faInfoCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { TrackUpload, useTrackUpload } from '../../contexts/TrackUploadContext';
import type { VaultContentAccessFeatureInput } from '../../graphql/generated';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Toggle } from '../forms/Toggle';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { TrackFreeMemberAccessForm } from './EditTrackView';

const FinalizeTrackUploadView = ({
  onShareClick,
  isLoadingArtist,
}: {
  onShareClick: () => void;
  isLoadingArtist: boolean;
}) => {
  const [showBanner, setShowBanner] = useState(true);

  const { value: isVaultMediaEnabled } = useGate(FEATURE_GATES.VAULT_MEDIA);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const {
    isUploadingTrack,
    isPromptOpen,
    notificationMessage,
    featureAccessType,
    shouldSendSms,
    fileRef,
  } = useTrackUpload();
  const [messageLength, setMessageLength] = useState(notificationMessage.length);

  const handleFreeMemberAccessChange = (accessType: VaultContentAccessFeatureInput) => {
    TrackUpload.featureAccessType = accessType;
  };

  const handleNotificationMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value;
    if (newMessage.length <= 120) {
      TrackUpload.notificationMessage = newMessage;
      setMessageLength(newMessage.length);
    }
  };

  useEffect(() => {
    setMessageLength(notificationMessage.length);
  }, [notificationMessage]);

  if (!fileRef.current) return null;

  const { contentType } = fileRef.current;

  return (
    <View className="no-scrollbar flex w-full flex-1 flex-col justify-between overflow-y-scroll pb-4">
      <View className="mx-4 my-3 flex flex-col gap-6">
        <TrackFreeMemberAccessForm
          featureAccess={featureAccessType}
          handleFreeMemberAccessChange={handleFreeMemberAccessChange}
          contentType={contentType}
        />

        <View className="flex flex-col gap-4 pb-6">
          {isVaultMediaEnabled && (
            <View className="flex w-full flex-row">
              <Toggle
                label="Notify fans with SMS"
                onChange={e => (TrackUpload.shouldSendSms = e.target.checked)}
                checked={shouldSendSms}
                className={isThemeEnabled ? 'text-vault_text' : undefined}
                componentClassName={
                  isThemeEnabled
                    ? 'bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent'
                    : undefined
                }
              />
            </View>
          )}
          <Text
            className={twMerge(
              '!text-title-s font-medium',
              !shouldSendSms && 'opacity-20',
              isThemeEnabled && 'text-vault_text',
            )}
          >
            What should the text notification say?
          </Text>
          {showBanner && (
            <View
              className={twMerge(
                'flex flex-row items-center justify-between rounded-full bg-opacity-30 px-1.5 py-3 md:px-2',
                isThemeEnabled ? 'bg-vault_text/20' : 'bg-education200',
              )}
            >
              <View className="ml-1 flex w-full flex-row items-center justify-start gap-2 ">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className={isThemeEnabled ? 'text-vault_text/50' : 'text-[#7572FF]'}
                />
                <Text
                  className={twMerge(
                    'line-clamp-1 !text-base-s md:!text-base-m',
                    isThemeEnabled ? 'text-vault_text/50' : 'text-[#9390FF]',
                  )}
                >
                  A custom message improves fan engagement
                </Text>
              </View>
              <Button
                label=""
                leadingIcon={faClose}
                leadingIconClassName={twMerge(
                  'h-4 w-4',
                  isThemeEnabled ? 'text-vault_text/50' : 'text-[#7572FF]',
                )}
                onClick={() => setShowBanner(false)}
              />
            </View>
          )}
          {isLoadingArtist ? (
            <>
              <LoadingSkeleton
                className={twMerge(
                  'flex min-h-[100px] w-full flex-row rounded-lg border-x-0 border-t-0 border-solid pb-3 pt-6',
                  isThemeEnabled ? 'border-vault_text/10' : 'border-base700',
                )}
              />
              <View className="flex flex-row items-center justify-between gap-2 pt-3">
                <LoadingSkeleton
                  className={twMerge('h-4 w-16', isThemeEnabled && 'bg-vault_text/10')}
                />
                <LoadingSkeleton
                  className={twMerge('h-4 w-40', isThemeEnabled && 'bg-vault_text/10')}
                />
              </View>
            </>
          ) : (
            <>
              <TextareaAutosize
                className={twMerge(
                  'mt-1 min-h-[100px] w-[inherit] resize-none rounded-md  border p-3 font-base !text-base-l focus:font-normal focus:outline-none disabled:opacity-20',
                  isThemeEnabled
                    ? 'border-vault_text/5 bg-vault_background text-vault_text placeholder:text-vault_text/50 disabled:text-vault_text/50'
                    : 'border-base700 bg-black text-white disabled:text-base500',
                )}
                placeholder="Add a text notification message when you upload this track."
                value={notificationMessage}
                onChange={handleNotificationMessageChange}
                disabled={isUploadingTrack || !shouldSendSms}
                autoFocus
              />
              <View
                className={twMerge(
                  'flex flex-row items-center justify-between pt-3',
                  !shouldSendSms && 'opacity-20',
                  isThemeEnabled ? 'text-vault_text/50' : 'text-base500',
                )}
              >
                <Text className="!text-base-s">{messageLength}/120</Text>
                <View className="flex flex-row items-center justify-center gap-1">
                  <FontAwesomeIcon icon={faLink} size="xs" />
                  <Text className="!text-base-s ">Track link will be included in the message</Text>
                </View>
              </View>
            </>
          )}
        </View>
      </View>
      <Button
        label={contentType === 'TRACK' ? 'Upload track' : 'Upload media'}
        type="primary"
        buttonType="submit"
        disabledClassName="opacity-30"
        disabled={isPromptOpen || isUploadingTrack}
        className={twMerge('w-full', isThemeEnabled && 'bg-vault_accent text-vault_accent_text')}
        onClick={onShareClick}
      />
    </View>
  );
};

export { FinalizeTrackUploadView };
