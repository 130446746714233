import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useGate } from 'statsig-react';
import { proxy, useSnapshot } from 'valtio';
import { isUUID4 } from '@soundxyz/utils/validation';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { VAULT_LOGO_SVG } from '../../constants/imageConstants';
import { VaultThemeStore } from '../../hooks/useVaultTheme';

const MetaHeaders = proxy<{
  title: string | undefined;
  description: string | undefined;
  imageUrl: string | undefined;

  status: 'initial' | 'loading' | 'ready';
}>({
  title: undefined,
  description: undefined,
  imageUrl: undefined,

  status: 'initial',
});

export function useSetMetaHeaders({
  title,
  description,
  imageUrl,
}: {
  title: string | null;
  description?: string;
  imageUrl?: string;
}) {
  useEffect(() => {
    if (title) {
      MetaHeaders.status = 'ready';
    } else {
      MetaHeaders.status = 'loading';

      return;
    }

    MetaHeaders.title = title;
    MetaHeaders.description = description;
    MetaHeaders.imageUrl = imageUrl;

    return () => {
      MetaHeaders.title = undefined;
      MetaHeaders.description = undefined;
      MetaHeaders.imageUrl = undefined;
    };
  }, [title, description, imageUrl]);
}

export const MetatagsHeader = () => {
  const meta = useSnapshot(MetaHeaders);
  const title =
    meta.title ||
    getVaultTitle(prettyPathnameSegment(getLastPathnameSegment(window.location.pathname)));
  const description = meta.description || title;
  const imageUrl = meta.imageUrl || VAULT_LOGO_SVG;

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);
  const vaultTheme = useSnapshot(VaultThemeStore);

  useEffect(() => {
    setTimeout(() => {
      if (MetaHeaders.status === 'initial') MetaHeaders.status = 'ready';
    }, 1000);
  }, []);

  if (meta.status !== 'ready') return null;

  return (
    <Helmet key={title}>
      <title>{title}</title>

      <meta name="description" content={description} />

      {/* Theme meta tag */}
      {isThemeEnabled && <meta name="theme-color" content={vaultTheme.backgroundColor} />}

      {/* OG meta tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={window.location.href} />

      {/* Twitter card meta tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export function getLastPathnameSegment(pathname: string): string {
  const lastSlashIndex = pathname.lastIndexOf('/');

  return lastSlashIndex >= 0 ? pathname.substring(lastSlashIndex + 1) : '';
}

export function prettyPathnameSegment(pathnameSegment: string): string {
  if (!pathnameSegment) return '';

  if (isUUID4(pathnameSegment)) return '';

  const clean = pathnameSegment.replaceAll('-', ' ').replaceAll('_', ' ');

  return clean[0]!.toUpperCase() + clean.slice(1);
}

export function getVaultTitle(prettyPath: string) {
  return prettyPath ? `Vault - ${prettyPath}` : 'Vault';
}
