import { type FC, type RefObject, useEffect, useRef } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { faInstagram, faSpotify, faTiktok } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faChevronRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faGlobe } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';

import type { TierTypename } from '../../graphql/generated';
import {
  ArtistMainVaultViewFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../graphql/generated';
import { useFreeTier } from '../../hooks/useFreeTier';
import { useIsVisible } from '../../hooks/useIsVisible';

import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { AllAccessPassWithForm } from '../vault/AllAccessPass';
import { ArtistLogo } from './items/artist/ArtistLogo';

export const VaultHeader: FC<{
  artist: FragmentType<ArtistMainVaultViewFragmentDoc>;
  setShowProfileImage: (header: boolean) => void;
  scrollRef: RefObject<HTMLDivElement>;
  isOwner: boolean;
  chatAvailableForFreeUsers: boolean;
  activeSubscriptionTier: TierTypename | undefined;
  onJoinFreeClick: () => void;
}> = ({
  artist,
  setShowProfileImage,
  scrollRef,
  onJoinFreeClick,
  activeSubscriptionTier,
  chatAvailableForFreeUsers,
  isOwner,
}) => {
  const { loginStatus } = useAuthContext();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const vaultTheme = useSnapshot(VaultThemeStore);

  const visibleRef = useRef<HTMLDivElement | null>(null);

  const { openBottomsheet } = useBottomsheetContainer();

  const isVisible = useIsVisible({ scrollRef, visibleRef });

  const {
    id: artistId,
    name,
    mainVault: { id: vaultId, contentCount, price, memberCount },
    instagramHandle,
    customWebsiteUrl,
    spotifyUrl,
    tiktokHandle,
    linkValue,
    createdAt,
    description,
  } = getFragment(ArtistMainVaultViewFragmentDoc, artist);

  const { isSubscribingFreeTier } = useFreeTier();

  const onAboutClick = () => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ABOUT_ARTIST,
      aboutArtistBottomsheetProps: {
        vaultId,
        contentCount,
        memberCount,
        artist: {
          id: artistId,
          name,
          instagramHandle,
          customWebsiteUrl,
          spotifyUrl,
          tiktokHandle,
          profileImageUri: vaultTheme.profileImageUrl,
          createdAt,
          description,
        },
      },
    });
  };

  useEffect(() => {
    setShowProfileImage(!isVisible);
  }, [isVisible, setShowProfileImage]);

  const monthlySubPrice = price ?? DEFAULT_PRICE;

  const showAllAccessPass = !isOwner && !activeSubscriptionTier;

  const hasUrl =
    instagramHandle != null ||
    spotifyUrl != null ||
    tiktokHandle != null ||
    customWebsiteUrl != null;

  return (
    <View className={twMerge('flex flex-col', isNewMenuEnabled && 'mb-4')}>
      <View
        className={twMerge(
          'flex flex-col',
          isNewMenuEnabled ? 'items-center gap-3' : 'items-start gap-1',
        )}
      >
        {isThemeEnabled && !!vaultTheme.logoMediaUrl ? (
          <ArtistLogo src={vaultTheme.logoMediaUrl} alt={name} />
        ) : (
          <Text
            className={twMerge(
              'font-title font-medium',
              isNewMenuEnabled ? 'text-[48px]' : 'text-[32px]',
              isThemeEnabled ? 'text-vault_text' : 'text-white',
            )}
            containerRef={visibleRef}
          >
            {name}
          </Text>
        )}
        {isNewMenuEnabled ? (
          hasUrl && (
            <View className="flex flex-row gap-4">
              {customWebsiteUrl && (
                <Button
                  label="globe"
                  leadingIcon={faGlobe}
                  href={customWebsiteUrl}
                  isExternal
                  className={twMerge(
                    'text-[24px]',
                    isThemeEnabled ? 'text-vault_text' : 'text-white',
                  )}
                  iconOnly
                  event={{
                    type: EVENTS.ARTIST_SOCIAL,
                    properties: {
                      vaultId,
                      artistId,
                      link: customWebsiteUrl,
                      type: 'other',
                    },
                  }}
                />
              )}
              {spotifyUrl && (
                <Button
                  label="spotify"
                  className={twMerge(
                    'text-[24px]',
                    isThemeEnabled ? 'text-vault_text' : 'text-white',
                  )}
                  iconOnly
                  leadingIcon={faSpotify}
                  isExternal
                  href={spotifyUrl}
                  event={{
                    type: EVENTS.ARTIST_SOCIAL,
                    properties: {
                      vaultId,
                      artistId,
                      link: spotifyUrl,
                      type: 'spotify',
                    },
                  }}
                />
              )}
              {instagramHandle != null && (
                <Button
                  label="instagram"
                  className={twMerge(
                    'text-[26px]',
                    isThemeEnabled ? 'text-vault_text' : 'text-white',
                  )}
                  iconOnly
                  leadingIcon={faInstagram}
                  isExternal
                  href={`https://www.instagram.com/${instagramHandle.replace('@', '')}`}
                  event={{
                    type: EVENTS.ARTIST_SOCIAL,
                    properties: {
                      vaultId,
                      artistId,
                      link: `https://www.instagram.com/${instagramHandle.replace('@', '')}`,
                      type: 'instagram',
                    },
                  }}
                />
              )}
              {tiktokHandle != null && (
                <Button
                  label="tiktok"
                  className={twMerge(
                    'text-[26px]',
                    isThemeEnabled ? 'text-vault_text' : 'text-white',
                  )}
                  iconOnly
                  leadingIcon={faTiktok}
                  isExternal
                  href={`https://www.tiktok.com/@${tiktokHandle.replace('@', '')}`}
                  event={{
                    type: EVENTS.ARTIST_SOCIAL,
                    properties: {
                      vaultId,
                      artistId,
                      link: `https://www.tiktok.com/@${tiktokHandle.replace('@', '')}`,
                      type: 'tiktok',
                    },
                  }}
                />
              )}
            </View>
          )
        ) : (
          <Text
            className="mb-4 flex cursor-pointer items-center gap-2 font-base text-base-l text-white/60"
            onClick={onAboutClick}
          >
            About <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
          </Text>
        )}
      </View>

      {showAllAccessPass && (
        <View
          className={twMerge(
            'mb-6 w-full',
            isNewMenuEnabled ? (loginStatus === LoginStatus.LOGGED_IN ? '-mb-2' : 'mb-0') : 'mb-6',
          )}
        >
          <AllAccessPassWithForm
            chatAvailableForFreeUsers={chatAvailableForFreeUsers}
            isSubscribingFreeTier={isSubscribingFreeTier}
            linkValue={linkValue}
            monthlySubPrice={monthlySubPrice}
            onJoinFreeClick={onJoinFreeClick}
          />
        </View>
      )}
    </View>
  );
};
