import clsx from 'clsx';
import { ErrorBoundary } from 'react-error-boundary';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { setBadgeVisibility, useMembership } from '../../hooks/useMembership';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { FallbackBadge, MembershipBadge } from '../vault/MembershipBadge';

export function MembershipView({
  aspectRatio = 0.8,
  className,
  isLoading,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  points,
  children,
}: {
  aspectRatio: number;
  className?: string;
  isLoading: boolean;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  points: number | undefined;
  children?: React.ReactNode;
}) {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <ErrorBoundary
      fallback={
        <View className="-mt-32 flex flex-col">
          <FallbackBadge
            isLoading={isLoading}
            artistName={artistName}
            serialNumber={serialNumber}
            imageUrl={imageUrl}
            displayName={displayName}
            createdAt={createdAt}
            points={points}
          />
          {children}
        </View>
      }
    >
      <View
        className={clsx(
          'relative -mb-2 -mt-36 flex min-h-full w-full flex-1 flex-col items-center md:-mt-32',
          isThemeEnabled ? 'text-vault_text' : 'text-white',
          className,
        )}
      >
        <View className="relative top-0 z-base w-full overflow-clip" style={{ aspectRatio }}>
          <MembershipBadge
            isLoadingData={isLoading}
            artistName={artistName}
            serialNumber={serialNumber}
            imageUrl={imageUrl}
            displayName={displayName}
            createdAt={createdAt}
            points={points}
          />
        </View>
      </View>
      {children}
    </ErrorBoundary>
  );
}

export function MembershipWelcomeView({
  className,
  childrenCotaninerClassName,
  title,
  vaultId,
  aspectRatio,
  isLoading,
  artistHandle,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  points,
  onComplete,
}: {
  className?: string;
  childrenCotaninerClassName?: string;
  title: string;
  vaultId: string;
  aspectRatio: number;
  isLoading: boolean;
  artistHandle: string;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  points: number | undefined;
  onComplete?: () => void;
}) {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { seenMembershipCard, isLoadingSeenMembershipCard } = useMembership({ artistHandle });
  async function setSeenMembershipCard() {
    await seenMembershipCard({ artistHandle });
    setBadgeVisibility(artistHandle, vaultId, false);
    onComplete?.();
  }

  return (
    <MembershipView
      aspectRatio={aspectRatio}
      className={className}
      isLoading={isLoading}
      artistName={artistName}
      serialNumber={serialNumber}
      imageUrl={imageUrl}
      displayName={displayName}
      createdAt={createdAt}
      points={points}
    >
      <View
        className={clsx(
          'flex w-full max-w-[330px] flex-col items-center gap-2 px-4',
          childrenCotaninerClassName,
        )}
      >
        <Text
          className={twMerge(
            'font-title !text-title-l font-semibold',
            isThemeEnabled ? 'text-vault_text' : 'text-white',
          )}
        >
          {title}
        </Text>
        <Text
          className={twMerge(
            'text-center !text-base-l',
            isThemeEnabled ? 'text-vault_text/400' : 'text-base400',
          )}
        >
          {`Your membership card unlocks exclusive rewards and access with ${artistName}`}
        </Text>
        <Button
          className={twMerge(
            'mt-4 w-full',
            isThemeEnabled && 'bg-vault_accent text-vault_accent_text',
          )}
          label="Let's Go"
          type="primary"
          loading={isLoadingSeenMembershipCard}
          onClick={setSeenMembershipCard}
        />
      </View>
    </MembershipView>
  );
}
