import React, { type LegacyRef, useEffect, useMemo, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { useLongPress } from 'use-long-press';

import { faClock } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFolder } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpArrowDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMusicNote } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faCoins } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../../../constants/flagConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useToast } from '../../../../contexts/ToastContext';
import { MediaQuery } from '../../../../contexts/mediaQuery';
import {
  type FragmentType,
  getFragment,
  TrackItemFragmentDoc,
} from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { useStableCallback } from '../../../../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../../../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../../../hooks/useUpsellInterstitials';
import { useSelectVaultContent } from '../../../../hooks/vault/useSelectVaultContent';
import { useVaultContentActions } from '../../../../hooks/vault/useVaultContentActions';
import { MoveToContents } from '../../../../screens/vault/MoveToPage';
import { LoginStatus } from '../../../../types/authTypes';
import { EVENTS } from '../../../../types/eventTypes';
import { trackEvent } from '../../../../utils/analyticsUtils';
import { generateShareLink } from '../../../../utils/linkUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { TrackItem } from '../Track';
import { useTrackFilePress } from '../useTrackFilePress';
import { type ContentOption, ContentOptions } from './ContentOptions';

export const ArtistTrack = ({
  track,
  containerRef,
  artistHandle,
}: {
  track: FragmentType<TrackItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const {
    id: trackId,
    linkValue,
    vault,
    parentVaultContentId,
  } = getFragment(TrackItemFragmentDoc, track);
  const { loginStatus, loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();
  const { md2 } = MediaQuery.useContainer();

  const { value: sortingEnabled } = useGate(FEATURE_GATES.VAULT_SORTING);

  const [menuHovered, setMenuHovered] = useState(false);

  const [selectedFile, setSelectedFile] = React.useState(false);
  const {
    isSelecting,
    removeContent: removeSelectedContent,
    addContent: addSelectedContent,
  } = useSelectVaultContent();

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = !isOwner && activeSubscriptionFeatures?.tier == null;

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const showUpsellInterstitial =
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const { onPlayClick } = useTrackFilePress({
    vaultId: vault.id,
    trackId,
    isLocked,
    showUpsellInterstitial,
    folderId: parentVaultContentId,
    artistHandle,
    vaultArtistProfileImage: vault.artist?.profileImage?.url ?? '',
  });

  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    if (isSelecting || !md2) return;
    setIsOpen(true);
  })();

  const link = useMemo(() => {
    const path = linkValue ? `/t/${linkValue}` : `/${trackId}`;
    return generateShareLink({
      artistLinkValue: artistHandle,
      path,
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [linkValue, trackId, artistHandle, loggedInUser?.inviteCode]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Track link copied to clipboard!',
  });

  const { removeContents: deleteFile } = useVaultContentActions();

  const rearrangeButton = {
    title: 'Rearrange',
    icon: faArrowUpArrowDown,
    onClick: () => {
      navigate(
        artistNavigationPath(
          artistHandle,
          `/rearrange${parentVaultContentId != null ? `/${parentVaultContentId}` : ''}`,
        ),
      );
    },
  };

  const deleteButton = {
    title: 'Delete',
    icon: faTrash,
    onClick: () => {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.CONFIRMATION,
        confirmationBottomsheetProps: {
          subText: 'Are you sure you want to delete this track?',
          confirmButtonText: 'Delete',
          confirmType: 'delete',
          onConfirm: () => {
            deleteFile({
              contentIds: [trackId],
              vaultId: vault.id,
              onSuccess: () => {
                openToast({
                  text: 'The track has been deleted',
                  variant: 'success',
                });
              },
            });
          },
        },
      });
    },
  } satisfies ContentOption;

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: copy,
    },
    {
      title: 'Move to',
      icon: faFolder,
      onClick: () => {
        MoveToContents.contentIds = [trackId];
        navigate(artistNavigationPath(artistHandle, '/move-to'));
      },
    },
  ];

  if (sortingEnabled) {
    buttons.push(rearrangeButton);
  }

  buttons.push(
    {
      title: 'Edit Snippet',
      icon: faClock,
      onClick: () => {
        navigate(artistNavigationPath(artistHandle, `/snippet/${trackId}`));
        trackEvent({
          type: EVENTS.EDIT_TRACK_SNIPPET,
          properties: { vaultContentId: trackId, artistHandle },
        });
      },
    },
    {
      title: 'Edit Track',
      icon: faMusicNote,
      onClick: () => {
        navigate(artistNavigationPath(artistHandle, `/edit/${trackId}`));
        trackEvent({
          type: EVENTS.EDIT_TRACK,
          properties: { vaultContentId: trackId, artistHandle },
        });
      },
    },
    {
      title: 'Splits',
      icon: faCoins,
      onClick: () => {
        navigate(artistNavigationPath(artistHandle, `/splits/${trackId}`));
      },
    },
  );

  buttons.push(deleteButton);

  const onSelectFile = useStableCallback(() => {
    return setSelectedFile(prev => {
      if (prev) {
        removeSelectedContent(trackId);
        return false;
      }
      addSelectedContent(trackId);
      return true;
    });
  });

  useEffect(() => {
    if (!isSelecting) {
      setSelectedFile(false);
    }
  }, [isSelecting]);

  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={isSelecting}
      onLongPress={onLongPress}
      onClick={() => {
        if (isSelecting) {
          return onSelectFile();
        }
        if (!isOpen) {
          return onPlayClick();
        }
      }}
      selectedFile={selectedFile}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={<TrackItem track={track} containerRef={containerRef} hasEllipsis={md2} />}
      disabled={false}
    />
  );
};
