import React from 'react';
import { twMerge } from 'tailwind-merge';

export const Toggle = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    componentClassName?: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(({ label, className, componentClassName, ...props }, forwardedRef) => {
  return (
    <label
      className={twMerge(
        'inline-flex w-full cursor-pointer items-center justify-between',
        className,
      )}
    >
      <span className="font-medium">{label}</span>
      <input type="checkbox" value="" className="peer sr-only" ref={forwardedRef} {...props} />
      <div
        className={twMerge(
          'relative h-6 w-11 rounded-full bg-[#787880]',

          // white toggle button
          "after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:bg-white after:transition-all after:content-['']",

          // style based on checked state
          'peer peer-checked:bg-yellow100 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full',
          'peer-checked:after:shadow-[0_0px_1px_0_rgba(0,0,0,0.25),0_4px_11px_0_rgba(0,0,0,0.08),-1px_3px_3px_0_rgba(0,0,0,0.14)]',
          'peer-checked:after:ring-0 peer-checked:after:ring-offset-0',
          componentClassName,
        )}
      />
    </label>
  );
});

Toggle.displayName = 'Toggle';
