import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router';
import { FullPageLoading } from '../components/views/FullPageLoading';
import { useMutation } from '../graphql/client';
import { SpotifyConnectDocument } from '../graphql/generated';
import {
  newSpotifyConnectState,
  SpotifyConnectState,
  useLinkSpotifyAccount,
} from '../hooks/useSpotifyAuth';

export function SpotifyConnectPage() {
  const navigate = useNavigate();

  const { mutateAsync: connectSpotify } = useMutation(SpotifyConnectDocument, {
    retry: 5,
  });

  const linkSpotify = useLinkSpotifyAccount();

  useEffect(() => {
    SpotifyConnectState.initialValue.then(initialValue => {
      if (!initialValue) return;

      const url = new URL(window.location.href);

      const storeState = initialValue.state;

      const state = url.searchParams.get('state');
      const code = url.searchParams.get('code');
      const error = url.searchParams.get('error');

      if (!state || !code || !storeState || state !== storeState) {
        navigate('/settings');

        return;
      }

      function finalErrorState() {
        newSpotifyConnectState({
          code: null,
        })
          .catch(error => {
            captureException(error, {});
          })
          .finally(() => {
            // TODO: Spotify connection error modal
          });
      }

      if (error) {
        return finalErrorState();
      }

      const redirectUri = new URL('/spotify-connect', window.location.origin).href;

      connectSpotify({
        input: {
          redirectUri,
          code,
        },
      })
        .then(connection => {
          // eslint-disable-next-line no-console
          console.log(connection.data);

          if (connection.data.connectSpotify.__typename !== 'MutationConnectSpotifySuccess') {
            return finalErrorState();
          }

          return newSpotifyConnectState({
            code,
          })
            .then(() => {
              return linkSpotify({
                authCode: code,
              });
            })
            .then(() => {
              try {
                window.close();
              } catch (error) {
                captureException(error, {
                  level: 'warning',
                });
              } finally {
                setTimeout(() => {
                  navigate('/');
                }, 2000);
              }
            })
            .catch(() => finalErrorState());
        })
        .catch(() => finalErrorState());
    });
  }, [navigate, connectSpotify, linkSpotify]);

  return <FullPageLoading withVaultTheme={false} />;
}
