import { type FC, useEffect } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSnapshot } from 'valtio';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowDownArrowUp } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Button } from '../../components/buttons/Button';
import { DropdownEllipsis } from '../../components/common/Dropdown';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { SkeletonUserRow, UserRow } from '../../components/user/UserRow';
import { EmptyStateView } from '../../components/views/EmptyStateView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { getFragment, TierTypename, UserRowFragmentDoc } from '../../graphql/generated';
import { filtersState, useMySubscribers } from '../../hooks/useMySubscribers';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { getMonthAndYear } from '../../utils/dateUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { formatDateString } from '../../utils/textUtils';

const MySubscribersPage: FC = () => {
  const { loggedInUser } = useAuthContext();
  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });
  const { openBottomsheet } = useBottomsheetContainer();

  const { sort, status, tierLevel } = useSnapshot(filtersState);
  const hasFilterChanges = sort !== 'DESC' || !!status || !!tierLevel;

  const {
    hasNextPage,
    fetchNextPage,
    exportCsv,
    isError,
    subscribers,
    refetch,
    isInitialLoading,
    isLoadingError,
    isFetchingNextPage,
    isLoadingSummary,
    subscribersSummary,
  } = useMySubscribers();

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isAtBottom, fetchNextPage]);

  const onShareClick = () => {
    if (!loggedInUser?.artist?.linkValue) return;

    const link = generateShareLink({
      artistLinkValue: loggedInUser.artist.linkValue,
      inviteCode: loggedInUser.inviteCode,
      path: null,
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SHARE,
      shared: {
        withVaultTheme: false,
      },
      shareBottomsheetProps: {
        link,
        artistName: loggedInUser.artist?.name ?? 'vault',
        withVaultTheme: false,
      },
    });
  };

  const onExportCsvClick = () => {
    if (!loggedInUser?.artist?.mainVaultId) return;

    exportCsv({
      vaultId: loggedInUser.artist.mainVaultId,
    });
  };

  const buttons: ActionBottomsheetProps['buttons'] = [
    {
      leadingIcon: faArrowUpFromBracket,
      label: 'Export CSV',
      type: 'secondary',
      onClick: onExportCsvClick,
      event:
        loggedInUser?.artist?.id != null
          ? { type: EVENTS.EXPORT_CSV, properties: { artistId: loggedInUser.artist.id } }
          : undefined,
    },
  ];

  const onEllipsisClick = () => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ACTION,
      actionBottomsheetProps: {
        buttons,
        withVaultTheme: false,
      },
    });
  };

  if (isError && subscribers.length === 0) {
    return (
      <SettingsLayout
        title="Members"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="my_subscribers_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (isInitialLoading) {
    return (
      <SettingsLayout
        titleComponent={
          <View className="flex flex-col items-center">
            <Text className="font-title text-title-m font-medium text-white">Members</Text>
            <LoadingSkeleton className="h-4 w-[100px]" />
          </View>
        }
      >
        <LoadingSkeleton className="h-[2px] w-full" />
        <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
        <LoadingSkeleton className="h-[2px] w-full" />
        <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
        <LoadingSkeleton className="h-[2px] w-full" />
        <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
        <LoadingSkeleton className="h-[2px] w-full" />
        <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
        <LoadingSkeleton className="h-[2px] w-full" />
        <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
        <LoadingSkeleton className="h-[2px] w-full" />
        <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout
      titleComponent={
        <View className="flex flex-col items-center">
          <Text className="font-title text-title-m font-medium text-white">Members</Text>
          {isLoadingSummary ? (
            <LoadingSkeleton className="h-4 w-[100px]" />
          ) : (
            <Text className="select-none overflow-x-hidden text-clip text-center font-base text-[12px]/[16px] font-normal text-base400">
              {subscribersSummary?.data.vaultFromId?.type === 'FREEMIUM' ? (
                <>
                  {subscribersSummary.data.mySubscribersSummary.activePaidSubscriptionsCount} paid
                  members •{' '}
                </>
              ) : null}
              {subscribersSummary?.data.mySubscribersSummary.activeSubscriptionsCount ?? '...'}{' '}
              total members
            </Text>
          )}
        </View>
      }
      nonScrollingChildren={
        !isInitialLoading &&
        subscribers.length === 0 && (
          <EmptyStateView
            title="You currently have no members"
            subtitle="Share your vault with fans to build up your members list"
            buttonText="Share"
            onButtonClick={onShareClick}
          />
        )
      }
      right={
        <DropdownEllipsis
          onClick={onEllipsisClick}
          buttons={buttons}
          dropdownType="Export Member CSV"
        />
      }
    >
      <Button
        label="Sort & view"
        className="mb-4 flex items-center gap-2 self-start font-base !text-base-m font-medium text-base500"
        leadingIcon={faArrowDownArrowUp}
        labelComponent={
          <View className="flex items-center gap-3">
            <Text>Sort & view</Text>

            {hasFilterChanges && (
              <View className="h-4 min-h-4 w-4 min-w-4 items-center justify-center rounded-full bg-yellow100 p-[1px]">
                <Text className="text-center font-base text-base-s text-black">1</Text>
              </View>
            )}
          </View>
        }
        onClick={() => {
          openBottomsheet({
            type: BOTTOMSHEET_TYPES.SUBSCRIBERS_FILTERS,
          });
        }}
      />

      {subscribers.map(
        (
          {
            user,
            tier,
            status,
            createdAt,
            phone,
            email,
            joinedViaReferralCode,
            isTrial,
            geoLocation,
          },
          i,
        ) => (
          <UserRow
            key={user.id}
            user={user}
            phone={phone}
            subText={`${status === 'ACTIVE' ? 'Since' : 'Joined'} ${formatDateString({
              date: createdAt,
              format: 'month_year',
            })}`}
            className="mb-0 items-center rounded-none border-solid border-x-transparent border-b-transparent border-t-base800 py-[20px]"
            rightComponent={
              isTrial ? (
                <Text className="font-base text-base-m font-medium text-blue400">Free Trial</Text>
              ) : status === 'INACTIVE' ? (
                <Text className="font-base text-base-m font-medium text-destructive300">
                  Cancelled
                </Text>
              ) : tier.__typename === TierTypename.FreeTier ? (
                <Text className="font-base text-base-m font-medium text-base500">Free Member</Text>
              ) : (
                <Text className="font-base text-base-m font-medium text-green300">Paid Member</Text>
              )
            }
            bottomRef={i === subscribers.length - 1 ? bottomRef : undefined}
            onClick={() => {
              const { id, createdAt, avatar, username, displayName } = getFragment(
                UserRowFragmentDoc,
                user,
              );

              openBottomsheet({
                type: 'USER_PROFILE',
                userProfileBottomsheetProps: {
                  vaultId: loggedInUser?.artist?.mainVaultId,
                  userId: id,
                  vaultArtistId: loggedInUser?.artist?.id,
                  avatarUrl: avatar?.url || '',
                  username: username || '',
                  displayName: displayName || '',
                  joinDate: getMonthAndYear(new Date(createdAt)),
                  phone,
                  email,
                  showAdminOptions: true,
                  referralCodeInfo: joinedViaReferralCode ?? undefined,
                  activeSubscriptionTier: tier.__typename,
                  geoLocation,
                  withVaultTheme: false,
                },
              });
            }}
            event={{
              type: EVENTS.OPEN_BOTTOMSHEET,
              properties: { bottomsheetType: BOTTOMSHEET_TYPES.USER_PROFILE, userId: user.id },
            }}
          />
        ),
      )}
      {isFetchingNextPage ? (
        <>
          <LoadingSkeleton className="h-[2px] w-full" />
          <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
          <LoadingSkeleton className="h-[2px] w-full" />
          <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
          <LoadingSkeleton className="h-[2px] w-full" />
          <SkeletonUserRow rightComponent className="mb-0 items-center rounded-none py-[20px]" />
        </>
      ) : isLoadingError ? (
        <ErrorView
          onRetryClick={fetchNextPage}
          loggingType="my_subscribers_page_next_page"
          withVaultTheme={false}
        />
      ) : null}
    </SettingsLayout>
  );
};

export { MySubscribersPage };
