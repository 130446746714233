import { type FC, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { Logo } from '../../components/svg/Logo';
import { SkeletonSubscribeView, SubscribeView } from '../../components/views/SubscribeView';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { useQuery } from '../../graphql/client';
import { GetSubscribePageArtistDocument } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useReferralLinks } from '../../hooks/useReferralLinks';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  query GetSubscribePageArtist($link: String!) {
    artistLink(link: $link) {
      artist {
        id
        ...artistSubscribeView
      }
    }
  }
`);

export const SubscribePage: FC = () => {
  const navigate = useNavigate();
  const { openToast } = useToast();
  const [searchParams] = useSearchParams();
  const { artistHandle } = useArtistHandle();

  const { loginStatus } = useAuthContext();

  const code = searchParams.get('code'); // referral link code

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  useVaultTheme();

  const {
    referralCodeData,
    isLoadingReferralCode,
    error: referralCodeError,
    refetch: refetchReferralCode,
  } = useReferralLinks(code);

  const { data, isLoading, isError, refetch } = useQuery(GetSubscribePageArtistDocument, {
    staleTime: 0,
    variables: !!artistHandle && { link: artistHandle.toLowerCase() },
    enabled: loginStatus === LoginStatus.LOGGED_IN,
  });

  useEffect(() => {
    if (code && referralCodeData === null) {
      navigate(artistNavigationPath(artistHandle, '/'));
      openToast({
        text: 'Referral code does not exist',
        variant: 'error',
      });
    }
  }, [code, referralCodeData, navigate, openToast, artistHandle]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  if (isError || referralCodeError) {
    return (
      <DefaultLayout
        withVaultTheme={isThemeEnabled}
        showRoundedTop={isThemeEnabled}
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        shouldShowFullScreenOnDesktop={!isThemeEnabled}
        headerLeft={
          <BackButton
            className={twMerge('md2:hidden', isThemeEnabled ? 'text-vault_text' : 'text-white')}
          />
        }
        contentClassName={isThemeEnabled ? 'md2:bg-vault_text/3' : undefined}
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={isError ? refetch : refetchReferralCode}
            loggingType="subscribe_page"
            withVaultTheme={isThemeEnabled}
          />
        }
      />
    );
  }

  if (isLoading || isLoadingReferralCode || data == null) {
    return (
      <DefaultLayout
        withVaultTheme={isThemeEnabled}
        showRoundedTop={isThemeEnabled}
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        shouldShowFullScreenOnDesktop={!isThemeEnabled}
        contentClassName={isThemeEnabled ? 'md2:bg-vault_text/3' : undefined}
        headerLeft={
          <BackButton
            className={twMerge('md2:hidden', isThemeEnabled ? 'text-vault_text' : 'text-white')}
          />
        }
      >
        <SkeletonSubscribeView />
      </DefaultLayout>
    );
  }

  if (data.data.artistLink?.artist == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <DefaultLayout
      withVaultTheme={isThemeEnabled}
      showRoundedTop={isThemeEnabled}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      shouldShowFullScreenOnDesktop={!isThemeEnabled}
      contentClassName={isThemeEnabled ? 'md2:bg-vault_text/3' : undefined}
      headerLeft={
        <BackButton
          className={twMerge('md2:hidden', isThemeEnabled ? 'text-vault_text' : 'text-white')}
        />
      }
      headerCenter={
        <Button
          label=""
          href={`/${artistHandle}`}
          event={{ type: EVENTS.BACK, properties: { type: 'logo' } }}
          labelComponent={
            <View className="flex h-[48px] w-[48px] flex-col items-center justify-center">
              <Logo variant={isThemeEnabled ? 'themed' : 'default'} />
            </View>
          }
        />
      }
    >
      <SubscribeView
        artist={data.data.artistLink?.artist}
        artistHandle={artistHandle}
        code={code}
        referralCodeInfo={referralCodeData}
      />
    </DefaultLayout>
  );
};
