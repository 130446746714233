import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { View } from '../common/View';

export function FullPageLoading({ withVaultTheme }: { withVaultTheme: boolean }) {
  return (
    <View
      className={twMerge(
        'flex h-full w-full items-center justify-center',
        withVaultTheme && 'bg-vault_background',
      )}
    >
      <FontAwesomeIcon
        icon={faSpinner}
        size="5x"
        className={twMerge(
          'inline-block animate-spin items-center self-center rounded-full font-medium',
          withVaultTheme ? 'text-vault_text/50' : 'text-base500',
        )}
      />
    </View>
  );
}
