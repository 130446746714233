import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Content, Overlay, Root } from '@radix-ui/react-dialog';
import { Link, type LinkProps, useLocation } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faCog } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faUserGroup } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faSquareDollar } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useAudioController } from '../../audio/AudioController';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { ArtistAdminRole } from '../../graphql/generated';
import { useMembership, VaultToShowBadge } from '../../hooks/useMembership';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { normalizePath } from '../../utils/navigationUtils';
import { getSubdomain, isValidSubdomain } from '../../utils/subdomainUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Logo } from '../svg/Logo';
import { MenuUser } from './MenuUser';
import { SecondaryMenu } from './SecondaryMenu';
import { SubscribedArtists } from './SubscribedArtists';

export function Menu() {
  const location = useLocation();
  const { isDesktop } = useWindow();
  const { hideAudioPlayer, activeTrackId } = useAudioController();
  const { loginStatus } = useAuthContext();
  const { isOpen, closeMenu, selectedHandle, isClosingMenu, setIsAccountOpen } = useMenuContainer();

  const vaultToShowBadge = useSnapshot(VaultToShowBadge);
  const { value: membershipEnabled } = useGate(FEATURE_GATES.MEMBERSHIP);
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const hasAudioPlayer = activeTrackId !== null && !hideAudioPlayer;

  const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const { pathname } = useLocation();

  const possibleArtistHandleFromPathname = pathname.split('/')[1];
  const possibleArtistHandle = subdomainArtistHandle || possibleArtistHandleFromPathname;

  const subscribedArtistsRef = React.useRef<HTMLDivElement>(null);

  const selectedHandleMemo = useMemo(() => {
    return (
      selectedHandle ??
      (Object.values(ROUTES).includes(`/${possibleArtistHandle}`) ? null : possibleArtistHandle)
    );
  }, [possibleArtistHandle, selectedHandle]);

  const withVaultTheme = !!selectedHandleMemo && isThemeEnabled;
  const { membership } = useMembership({ artistHandle: selectedHandleMemo });
  const { cardFirstTimeSeen } = membership || {};

  const closeAll = useCallback(() => {
    closeMenu();
    setIsAccountOpen(false);
  }, [closeMenu, setIsAccountOpen]);

  if (
    (location.pathname === ROUTES.LANDING_PAGE && !subdomainArtistHandle) ||
    location.pathname.includes('textme') ||
    (selectedHandleMemo &&
      vaultToShowBadge[selectedHandleMemo] &&
      cardFirstTimeSeen == false &&
      membershipEnabled)
  ) {
    return null;
  }

  return (
    <Root open={isDesktop || isOpen}>
      <MobileOverlay
        setIsAccountOpen={setIsAccountOpen}
        isClosingMenu={isClosingMenu}
        closeAll={closeAll}
        selectedHandleMemo={selectedHandleMemo}
      >
        <View
          className={twMerge(
            'mt-[18px] flex w-full flex-col overflow-hidden md2:mt-[10px]',
            isDesktop && hasAudioPlayer && 'mb-[90px]',
          )}
        >
          <View
            className={twMerge(
              '[&_svg]:w-8',
              isNewMenuEnabled ? 'mb-2 max-w-8 pl-3 md2:pl-2' : 'p-3 pt-0',
            )}
          >
            <Logo variant={withVaultTheme ? 'themed' : 'default'} />
          </View>

          <View
            containerRef={subscribedArtistsRef}
            className={twMerge(
              'overflow-auto pr-1 scrollbar-thin scrollbar-none scrollbar-track-transparent scrollbar-track-rounded-md scrollbar-thumb-rounded-md scrollbar-w-1',
              isNewMenuEnabled
                ? 'scrollbar-thumb-vault_text/50 md2:pr-0'
                : 'md2:scrollbar-dark scrollbar-thumb-neutral700 md2:pr-5',
            )}
          >
            {loginStatus === LoginStatus.LOGGED_IN ? (
              <>
                <SubscribedArtists
                  selectedHandleMemo={selectedHandleMemo}
                  closeAll={closeAll}
                  source="menu"
                />

                <Link
                  className={twMerge(
                    'flex min-h-14 flex-row items-center justify-start gap-3 rounded-xl text-[unset] no-underline transition-all duration-300 ease-in-out',
                    isNewMenuEnabled ? 'pl-3 md2:pl-2' : 'px-3',
                    withVaultTheme
                      ? 'hover:bg-vault_text/20'
                      : isNewMenuEnabled
                        ? 'hover:bg-white/20'
                        : 'hover:bg-base800',
                  )}
                  to={normalizePath(ROUTES.VAULTS)}
                  onClick={() => {
                    trackEvent({
                      type: EVENTS.MENU_NAVIGATE,
                      properties: { type: 'vaults' },
                      pathname: location.pathname,
                    });

                    closeAll();
                  }}
                >
                  <View
                    className={twMerge(
                      'flex aspect-square w-8 flex-shrink-0 flex-col items-center justify-center rounded-full',
                      withVaultTheme ? 'bg-vault_text/10' : 'bg-white/15',
                    )}
                  >
                    <FontAwesomeIcon
                      icon={faWaveformLines}
                      className={withVaultTheme ? 'text-vault_text' : 'text-white'}
                      fontSize={12}
                    />
                  </View>
                  <Text
                    className={twMerge(
                      'justify-start font-base text-[14px] font-medium',
                      withVaultTheme ? 'text-vault_text' : 'text-white',
                      isNewMenuEnabled
                        ? 'overflow-hidden transition-all ease-in-out md2:w-0 md2:opacity-0 md2:group-hover/menu:w-auto md2:group-hover/menu:opacity-100 md2:group-hover/menu:delay-75'
                        : 'flex w-full',
                    )}
                  >
                    All Vaults
                  </Text>
                </Link>
              </>
            ) : (
              loginStatus === LoginStatus.LOGGED_OUT && (
                <>
                  <Link
                    className={twMerge(
                      'group flex min-h-14 flex-row items-center justify-start gap-3 rounded-xl px-3 text-[unset] no-underline',
                      isNewMenuEnabled &&
                        'overflow-hidden transition-all ease-in-out md2:opacity-0 md2:group-hover/menu:opacity-100 md2:group-hover/menu:delay-75',
                    )}
                    to={normalizePath(ROUTES.VAULTS)}
                    onClick={() => {
                      trackEvent({
                        type: EVENTS.MENU_NAVIGATE,
                        properties: { type: 'vaults' },
                        pathname: location.pathname,
                      });

                      closeAll();
                    }}
                  >
                    <Text
                      className={twMerge(
                        'w-full justify-start font-title !text-title-m font-normal transition-all duration-300 ease-in-out',
                        withVaultTheme
                          ? 'text-vault_text group-hover:text-vault_text/50'
                          : 'text-white group-hover:text-base300',
                      )}
                    >
                      Explore Vaults
                    </Text>
                  </Link>

                  <Link
                    className={twMerge(
                      'group flex min-h-14 flex-row items-center justify-start gap-3 rounded-xl px-3 text-[unset] no-underline',
                      isNewMenuEnabled &&
                        'overflow-hidden transition-all ease-in-out md2:opacity-0 md2:group-hover/menu:opacity-100 md2:group-hover/menu:delay-75',
                    )}
                    to={normalizePath(ROUTES.ABOUT)}
                    onClick={() => {
                      trackEvent({
                        type: EVENTS.MENU_NAVIGATE,
                        properties: { type: 'about' },
                        pathname: location.pathname,
                      });

                      closeAll();
                    }}
                  >
                    <Text
                      className={twMerge(
                        'w-full justify-start font-title !text-title-m font-normal transition-all duration-300 ease-in-out',
                        withVaultTheme
                          ? 'text-vault_text group-hover:text-vault_text/50'
                          : 'text-white group-hover:text-base300',
                      )}
                    >
                      About
                    </Text>
                  </Link>
                </>
              )
            )}
          </View>
        </View>

        <View
          className={twMerge(
            'sticky bottom-0 mt-2 w-full',
            hasAudioPlayer && 'md2:bottom-[87px]',
            isNewMenuEnabled ? 'bg-transparent' : 'bg-black',
          )}
        >
          <View
            className={twMerge(
              'border-0 border-t border-solid pb-5 pt-4',
              withVaultTheme
                ? 'border-t-vault_text/5'
                : isNewMenuEnabled
                  ? 'border-t-white/7'
                  : 'border-t-base800',
            )}
          >
            <BottomMenuLinks closeAll={closeAll} withVaultTheme={withVaultTheme} />

            <MenuUser selectedHandle={selectedHandleMemo} closeAll={closeAll} />
          </View>
        </View>
      </MobileOverlay>
    </Root>
  );
}

function MobileOverlay({
  children,
  setIsAccountOpen,
  isClosingMenu,
  closeAll,
  selectedHandleMemo,
}: {
  setIsAccountOpen: (isOpen: boolean) => void;
  isClosingMenu: boolean;
  closeAll: () => void;
  children: React.ReactNode;
  selectedHandleMemo: string | null | undefined;
}) {
  const { isDesktop } = useWindow();
  const location = useLocation();
  const { value: isNewMenuEnabled, isLoading } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const withVaultTheme = !!selectedHandleMemo && isThemeEnabled;

  if (isDesktop) {
    if (location.pathname === ROUTES.VERIFY || location.pathname === ROUTES.SIGN_IN || isLoading) {
      return null;
    }

    return (
      <View className="fixed inset-0">
        <View
          className={twMerge(
            'no-scrollbar absolute bottom-0 left-0 top-0 box-border flex flex-col',
            'z-above3 items-start justify-between overflow-y-scroll pb-0',
            isNewMenuEnabled
              ? withVaultTheme
                ? 'group/menu border-0 border-r border-solid border-r-vault_text/5 px-3 pt-6 transition-all duration-200 ease-in-out md2:w-[72px] hover:md2:w-[272px]'
                : 'group/menu border-0 border-r border-solid border-r-white/10 px-3 pt-6 transition-all duration-200 ease-in-out md2:w-[72px] hover:md2:w-[272px]'
              : 'p-6 md2:w-[272px] md2:pr-0',
            withVaultTheme ? 'bg-vault_background' : 'bg-black',
          )}
        >
          {children}
        </View>

        {isNewMenuEnabled && isDesktop && <SecondaryMenu selectedHandleMemo={selectedHandleMemo} />}
      </View>
    );
  }

  return (
    <Overlay className="fixed inset-0 z-overlay grid h-full max-h-screen w-full overflow-x-hidden bg-[rgba(0,0,0,0.6)] sm:mx-[auto] sm:w-full md:w-full lg:w-[33%]">
      <Content
        onOpenAutoFocus={(e: Event) => e.preventDefault()}
        className={twMerge(
          'no-scrollbar absolute bottom-0 left-0 top-0 box-border flex flex-col items-start justify-between overflow-y-scroll pb-0',
          'z-menu shadow-[0px_0px_0px_1px_rgba(0,0,0,0.04),0px_-8px_28px_rgba(0,0,0,0.28)]',
          'w-[338px] max-w-[87%] animate-openMenu',
          isClosingMenu && 'animate-closeMenu',
          isNewMenuEnabled
            ? withVaultTheme
              ? 'border-0 border-r border-solid border-r-vault_text/5 px-3'
              : 'border-0 border-r border-solid border-r-white/10 px-3'
            : 'p-6 md2:pr-0',
          withVaultTheme ? 'bg-vault_background' : 'bg-black',
        )}
        onPointerDownOutside={() => {
          trackEvent({
            type: EVENTS.CLOSE_MENU,
            properties: { type: 'pointer' },
            pathname: location.pathname,
          });

          closeAll();
          setIsAccountOpen(false);
        }}
      >
        {children}
      </Content>
    </Overlay>
  );
}

function BottomMenuLinks({
  closeAll,
  withVaultTheme,
}: {
  closeAll: () => void;
  withVaultTheme: boolean;
}) {
  const { loginStatus, loggedInUser, isArtist } = useAuthContext();

  const isActualArtist = isArtist && loggedInUser?.artist?.role === ArtistAdminRole.Artist;
  if (loginStatus !== LoginStatus.LOGGED_IN) return null;

  return (
    <>
      {isActualArtist && (
        <>
          <MenuLink
            icon={faSquareDollar}
            label="Earnings"
            onClick={() => {
              trackEvent({
                type: EVENTS.SETTINGS_NAVIGATE,
                properties: { type: 'earnings' },
              });
              closeAll();
            }}
            to="/settings/artist-earnings"
            withVaultTheme={withVaultTheme}
          />
          <MenuLink
            icon={faUserGroup}
            label="Members"
            onClick={() => {
              trackEvent({
                type: EVENTS.SETTINGS_NAVIGATE,
                properties: { type: 'subscribers' },
              });
              closeAll();
            }}
            to="/settings/members"
            withVaultTheme={withVaultTheme}
          />
        </>
      )}
      <MenuLink
        icon={faCog}
        label="Settings"
        onClick={() => {
          trackEvent({
            type: EVENTS.MENU_NAVIGATE,
            properties: { type: 'explore' },
            pathname: location.pathname,
          });

          closeAll();
        }}
        to={normalizePath(ROUTES.SETTINGS)}
        withVaultTheme={withVaultTheme}
      />
    </>
  );
}

function MenuLink({
  icon,
  label,
  onClick,
  to,
  withVaultTheme,
}: {
  icon: FontAwesomeIconProps['icon'];
  label: string;
  onClick: () => void;
  to: LinkProps['to'];
  withVaultTheme: boolean;
}) {
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);

  return (
    <Link
      className={twMerge(
        'my-2 flex min-h-11 flex-row items-center justify-start gap-3 rounded-xl text-[unset] no-underline transition-all duration-300 ease-in-out ',
        withVaultTheme
          ? 'pl-3 hover:bg-vault_text/10 md2:pl-2'
          : isNewMenuEnabled
            ? 'pl-3 hover:bg-white/10 md2:pl-2'
            : 'px-3 hover:bg-base800',
      )}
      to={to}
      onClick={onClick}
    >
      <View className="flex aspect-square w-8 flex-shrink-0 flex-col items-center justify-center rounded-full">
        <FontAwesomeIcon
          icon={icon}
          fontSize={16}
          className={withVaultTheme ? 'text-vault_text' : 'text-white'}
        />
      </View>
      <Text
        className={twMerge(
          'w-full justify-start font-base text-[14px] font-medium',
          isNewMenuEnabled &&
            'overflow-hidden md2:w-0 md2:opacity-0 md2:group-hover/menu:w-auto md2:group-hover/menu:opacity-100',
          withVaultTheme ? 'text-vault_text' : 'text-white',
        )}
      >
        {label}
      </Text>
    </Link>
  );
}
