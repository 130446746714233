'use client';

import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { gql } from '@soundxyz/gql-string';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { EarningRow, SkeletonEarningRow } from '../../components/payment/EarningRow';
import { ActivateFreemiumPrompt } from '../../components/vault/ActivateFreemiumPrompt';
import { EmptyStateView } from '../../components/views/EmptyStateView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useInfiniteQuery, useQuery } from '../../graphql/client';
import {
  BubbleArtistFragmentDoc,
  getFragment,
  GetMyEarningsDocument,
  MyArtistVaultDocument,
  VaultType,
} from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import { generateShareLink } from '../../utils/linkUtils';

gql(/* GraphQL */ `
  query GetMyEarnings($after: String, $first: Int, $artistId: UUID) {
    myEarnings(after: $after, first: $first, artistId: $artistId) {
      __typename
      ... on QueryMyEarningsSuccess {
        data {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              month
              year
              ...earningsRow
            }
            cursor
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

export const EarningsPage = ({ isArtistPage }: { isArtistPage: boolean }) => {
  const { openBottomsheet } = useBottomsheetContainer();
  const { isArtist, loginStatus, loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const LIMIT = 10;

  const artistId = isArtistPage ? loggedInUser?.artist?.id ?? null : null;
  const hasUserPayeeOnboarded = loggedInUser?.payee?.hasCompletedOnboarding;

  const getMyVaultInformation = useQuery(MyArtistVaultDocument, {
    staleTime: 0,
    enabled: isArtist,
    variables: !!loggedInUser?.artist && { artistId: loggedInUser.artist.id },
  });

  const artistVault = getFragment(
    BubbleArtistFragmentDoc,
    getMyVaultInformation.data?.data.artistById,
  );

  const {
    orderedList: earnings,
    isError,
    hasNextPage,
    loadMoreNextPage,
    refetch,
    isFetchingNextPage,
    isLoadingError,
    fetchNextPage,
    isInitialLoading,
  } = useInfiniteQuery(GetMyEarningsDocument, {
    enabled: loginStatus === LoginStatus.LOGGED_IN && (isArtistPage ? artistId != null : true),
    staleTime: 0,
    filterQueryKey: {
      userId: loggedInUser?.id,
      artistId,
    },
    getNextPageParam: ({ data }) => {
      if (data.myEarnings.__typename !== 'QueryMyEarningsSuccess') {
        return false;
      }
      return (
        data.myEarnings.data.pageInfo.hasNextPage && {
          after: data.myEarnings.data.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
        artistId,
      };
    },
    list: ({ myEarnings }) => {
      if (myEarnings.__typename !== 'QueryMyEarningsSuccess') {
        return [];
      }
      return myEarnings.data.edges.map(({ node, cursor }) => {
        return { ...node, cursor };
      });
    },
    uniq: ({ id, month, year }) => id ?? `${month}_${year}`,
  });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      loadMoreNextPage();
    }
  }, [hasNextPage, isAtBottom, loadMoreNextPage]);

  if (isError && earnings.length === 0) {
    return (
      <SettingsLayout
        title="Earnings"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="earnings_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (loginStatus === LoginStatus.LOADING || isInitialLoading) {
    return (
      <SettingsLayout title="Earnings">
        <SkeletonEarningRow />
        <SkeletonEarningRow />
        <SkeletonEarningRow />
      </SettingsLayout>
    );
  }

  const onShareClick = () => {
    if (!loggedInUser?.artist?.linkValue) return;

    const link = generateShareLink({
      artistLinkValue: loggedInUser.artist.linkValue,
      inviteCode: loggedInUser.inviteCode,
      path: null,
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SHARE,
      shared: {
        withVaultTheme: false,
      },
      shareBottomsheetProps: {
        link,
        artistName: loggedInUser.artist?.name ?? 'vault',
        withVaultTheme: false,
      },
    });
  };

  const onConnectBankRedirectClick = () => {
    navigate(ROUTES.SETTINGS_COLLABORATOR_PAYOUTS);
  };

  const onEarningPress = ({
    month,
    year,
    cursor,
  }: {
    month: number;
    year: number;
    cursor?: string | null;
  }) => {
    if (isArtistPage) {
      navigate(
        `${ROUTES.SETTINGS}/artist-earnings-breakdown?month=${month}&year=${year}${cursor != null ? `&cursor=${cursor}` : ''}`,
      );
    } else {
      navigate(
        `${ROUTES.SETTINGS}/earnings-breakdown?month=${month}&year=${year}${cursor != null ? `&cursor=${cursor}` : ''}`,
      );
    }
  };

  return (
    <SettingsLayout
      title="Earnings"
      nonScrollingChildren={
        loginStatus === LoginStatus.LOGGED_IN &&
        !isInitialLoading &&
        earnings.length === 0 &&
        (artistVault?.mainVault.type === VaultType.FreeOnly ? (
          <ActivateFreemiumPrompt
            type="earnings"
            artistLinkValue={artistVault.linkValue}
            withVaultTheme={false}
          />
        ) : (
          <EmptyStateView
            title={
              isArtistPage
                ? 'You currently have no earnings'
                : hasUserPayeeOnboarded
                  ? 'Claim your earnings'
                  : 'You currently have no earnings'
            }
            subtitle={
              isArtistPage
                ? 'Share your vault with fans to gain members and to start earning'
                : hasUserPayeeOnboarded
                  ? 'You will have earnings once you are a collaborator on a track'
                  : 'Connect your bank account to claim your earnings.'
            }
            buttonText={
              isArtistPage ? 'Share vault' : hasUserPayeeOnboarded ? undefined : 'Connect Bank'
            }
            onButtonClick={
              isArtistPage
                ? onShareClick
                : hasUserPayeeOnboarded
                  ? undefined
                  : onConnectBankRedirectClick
            }
          />
        ))
      }
    >
      {earnings.map((earning, i) => (
        <EarningRow
          earning={earning}
          key={`${earning.month} ${earning.year}`}
          bottomRef={i === earnings.length - 1 ? bottomRef : undefined}
          onClick={() =>
            onEarningPress({
              month: earning.month,
              year: earning.year,
              cursor: i - 1 >= 0 ? earnings[i - 1]?.cursor : null,
            })
          }
        />
      ))}
      {isFetchingNextPage ? (
        <>
          <SkeletonEarningRow />
          <SkeletonEarningRow />
        </>
      ) : isLoadingError ? (
        <ErrorView
          onRetryClick={fetchNextPage}
          loggingType="earnings_page_next_page"
          withVaultTheme={false}
        />
      ) : null}
    </SettingsLayout>
  );
};
