import type { FC } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faCircleUser } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { DEFAULT_AVATAR } from '../../constants/imageConstants';
import { Image } from '../common/Image';

type Props = {
  className?: string;
  profileImageUrl?: string | null;
  borderColor?: string;
  withVaultTheme?: boolean;
};

const ArtistProfileImage: FC<Props> = ({
  className,
  profileImageUrl,
  borderColor,
  withVaultTheme,
}) => {
  const style = borderColor ? { border: `1px solid ${borderColor}` } : {};

  return withVaultTheme && !profileImageUrl ? (
    <FontAwesomeIcon
      icon={faCircleUser}
      className={twMerge('aspect-square rounded-[50%]', className)}
    />
  ) : (
    <Image
      src={profileImageUrl ?? DEFAULT_AVATAR}
      alt="Artist Profile Image"
      className={twMerge('aspect-square rounded-[50%]', className)}
      style={style}
    />
  );
};

export { ArtistProfileImage };
