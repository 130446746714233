import type { FC, MouseEventHandler, ReactNode, SyntheticEvent } from 'react';
import React from 'react';
import type { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import type { EventObject, EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { AccessibleLink } from '../common/AccessibleLink';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';

export type OnClickType = (() => void) | MouseEventHandler | (() => Promise<void>);

export type ButtonProps<Event extends EventType> = {
  onClick?: OnClickType;
  label: string | null;
  secondaryLabel?: string;
  labelComponent?: ReactNode;
  className?: string;
  iconOnly?: boolean;
  leadingIcon?: IconProp;
  trailingIcon?: IconProp;
  iconSize?: SizeProp;
  leadingImage?: string;
  trailingImage?: string;
  imageSize?: number;
  disabled?: boolean;
  type?: 'primary' | 'secondary' | 'default';
  position?: 'individual' | 'top' | 'middle' | 'bottom';
  loading?: boolean;
  href?: string;
  isExternal?: boolean;
  linkClassName?: string;
  disabledClassName?: string;
  buttonType?: 'submit' | 'reset' | 'button';
  loadingClassName?: string;
  hideIconWhenLoading?: boolean;
  leadingIconClassName?: string;
  trailingIconClassName?: string;
  event?: EventObject<Event>;
  onTouchDown?: (e?: SyntheticEvent) => void;
  fullWidthLabel?: boolean;
  labelClassName?: string;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
};

type IconProps = {
  icon: IconProp;
  size?: SizeProp;
  iconOnly: boolean;
  className?: string;
};

type ImageProps = {
  src: string;
  size?: number;
  iconOnly: boolean;
};

const LeadingIcon: FC<IconProps> = ({ icon, size, iconOnly, className }) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      size={size}
      className={twMerge('flex items-center', !iconOnly && 'mr-2', className)}
    />
  );
};

const TrailingIcon: FC<IconProps> = ({ icon, size, iconOnly, className }) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      size={size}
      className={twMerge('flex items-center', !iconOnly && 'ml-2', className)}
      fill="true"
    />
  );
};

const LeadingImage: FC<ImageProps> = ({ src, size, iconOnly }) => {
  return (
    <Image src={src} width={size} height={size} alt="" className={twMerge(!iconOnly && 'mr-2')} />
  );
};

const TrailingImage: FC<ImageProps> = ({ src, size, iconOnly }) => {
  return (
    <Image src={src} width={size} height={size} alt="" className={twMerge(!iconOnly && 'ml-2')} />
  );
};

export const Button = <Event extends EventType>({
  onClick,
  label,
  secondaryLabel,
  className,
  leadingIcon,
  trailingIcon,
  iconSize,
  iconOnly = false,
  leadingImage,
  trailingImage,
  imageSize = 10,
  disabled = false,
  type = 'default',
  position = 'individual',
  loading = false,
  href,
  isExternal = false,
  labelComponent,
  linkClassName,
  disabledClassName,
  buttonType,
  loadingClassName,
  hideIconWhenLoading = false,
  leadingIconClassName,
  trailingIconClassName,
  event,
  onTouchDown,
  fullWidthLabel,
  labelClassName,
  onMouseEnter,
  onMouseLeave,
}: ButtonProps<Event>) => {
  const { pathname } = useLocation();
  let typeClass: string;
  let linkTypeClass: string | undefined;
  let loadingClass: string | undefined;
  let positionClass: string | undefined;

  switch (type) {
    case 'primary':
      typeClass =
        'rounded-full bg-yellow100 px-[28px] py-[16px] font-title text-[16px]/[20px] font-medium text-black';
      loadingClass = twMerge('min-w-[10px]', label == null && 'py-[1.5px]');
      break;
    case 'secondary':
      typeClass =
        'w-full justify-start rounded-xl bg-base800 px-[28px] py-[20px] font-title text-[16px]/[20px] font-medium text-white';
      linkTypeClass = 'w-full';
      break;
    case 'default':
    default:
      typeClass = '';
  }

  switch (position) {
    case 'top':
      positionClass = 'rounded-b-none';
      break;
    case 'middle':
      positionClass = 'rounded-none';
      break;
    case 'bottom':
      positionClass = 'rounded-t-none';
      break;
    default:
      positionClass = '';
  }

  const loadingAnimation = (
    <FontAwesomeIcon
      icon={faSpinner}
      size={iconSize ?? 'lg'}
      className={twMerge(
        'ml-2 inline-block animate-spin rounded-full font-medium text-base500',
        iconOnly && 'ml-0',
        loadingClass,
        loadingClassName,
      )}
    />
  );

  const onButtonClick: OnClickType | undefined =
    event != null
      ? e => {
          trackEvent({
            ...event,
            pathname,
          });
          onClick?.(e);
        }
      : onClick;

  return (
    <LinkContainer
      href={href}
      isExternal={isExternal}
      linkClassName={twMerge(linkTypeClass, linkClassName)}
    >
      <button
        onTouchStart={onTouchDown}
        onMouseDown={onTouchDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        type={buttonType}
        disabled={disabled}
        className={twMerge(
          'flex flex-row items-center justify-center border-none bg-transparent hover:cursor-pointer focus:outline-none',
          typeClass,
          positionClass,
          className,
          disabled && twMerge('hover:cursor-not-allowed', disabledClassName),
        )}
        onClick={!disabled ? onButtonClick : undefined}
      >
        {leadingImage != null && (!hideIconWhenLoading || !loading) && (
          <LeadingImage src={leadingImage} size={imageSize} iconOnly={iconOnly} />
        )}
        {leadingIcon != null && (!hideIconWhenLoading || !loading) && (
          <LeadingIcon
            icon={leadingIcon}
            size={iconSize}
            iconOnly={iconOnly}
            className={leadingIconClassName}
          />
        )}
        <View
          className={twMerge('flex flex-col text-left', fullWidthLabel && 'w-full', labelClassName)}
        >
          {iconOnly ? null : labelComponent ?? label}
          {secondaryLabel != null && (
            <Text className="font-base-m mt-1 font-body text-base400">{secondaryLabel}</Text>
          )}
        </View>
        {trailingIcon != null && (!hideIconWhenLoading || !loading) && (
          <TrailingIcon
            icon={trailingIcon}
            size={iconSize}
            iconOnly={iconOnly}
            className={trailingIconClassName}
          />
        )}
        {trailingImage != null && (!hideIconWhenLoading || !loading) && (
          <TrailingImage src={trailingImage} size={imageSize} iconOnly={iconOnly} />
        )}
        {loading && loadingAnimation}
      </button>
    </LinkContainer>
  );
};

const LinkContainer: FC<{
  children: ReactNode;
  href?: string;
  isExternal: boolean;
  linkClassName?: string;
}> = ({ href, children, isExternal, linkClassName }) => {
  if (href == null) {
    return <>{children}</>;
  }
  return isExternal ? (
    <AccessibleLink
      type="external"
      absoluteUri={href}
      className={twMerge('text-[unset] no-underline', linkClassName)}
    >
      {children}
    </AccessibleLink>
  ) : (
    <Link to={href} className={twMerge('text-[unset] no-underline', linkClassName)}>
      {children}
    </Link>
  );
};
