import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEnvelope } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCircleUser } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { TopInvitersFragmentDoc } from '../../graphql/generated';
import { InvitedUserFragmentDoc } from '../../graphql/generated';
import { VaultInviteStatsFragmentDoc } from '../../graphql/generated';
import {
  ArtistInviteViewFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../graphql/generated';

import { useCopy } from '../../hooks/useCopy';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getMonthAndYear } from '../../utils/dateUtils';
import { pluralizeText } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { UserProfileImage } from '../user/UserProfileImage';

gql(/* GraphQL */ `
  fragment ArtistInviteView on Artist {
    id
    name
    profileImage {
      id
      url
    }
    linkValue
    mainVaultId
  }

  fragment InvitedUser on User {
    id
    displayName
    username
    createdAt
    avatar {
      id
      url
    }
    updatedAt
  }

  fragment VaultInviteStats on InviteStats {
    inviteCount
    previewInvitedUsers {
      ...InvitedUser
    }
  }

  fragment TopInviters on TopVaultInviter {
    inviteCount
    inviter {
      ...InvitedUser
    }
    rank
  }
`);

export function VaultInviteView({
  artist,
  stats,
  topInviters,
}: {
  artist: FragmentType<ArtistInviteViewFragmentDoc>;
  stats: FragmentType<VaultInviteStatsFragmentDoc>;
  topInviters: FragmentType<TopInvitersFragmentDoc>[];
}) {
  const { pathname } = useLocation();

  const { loggedInUser } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { isDesktop } = useWindow();

  const showRoundedTop = isDesktop && isNewMenuEnabled;

  const { id, name, profileImage, linkValue, mainVaultId } = getFragment(
    ArtistInviteViewFragmentDoc,
    artist,
  );
  const { inviteCount, previewInvitedUsers } = getFragment(VaultInviteStatsFragmentDoc, stats);

  const isStatsEmpty = inviteCount === 0;
  const isTopInvitersEmpty = topInviters.length === 0;

  const inviteLink = useMemo(() => {
    if (!loggedInUser?.inviteCode) {
      return null;
    }

    return `${window.location.origin}/${linkValue}?invite=${loggedInUser.inviteCode}`;
  }, [linkValue, loggedInUser?.inviteCode]);

  const { copy: copyInviteLink } = useCopy({
    text: inviteLink,
    successMessage: 'Invite link copied to clipboard!',
  });

  const mailtoLink = useMemo(() => {
    if (!inviteLink) {
      return null;
    }

    return `mailto:?subject=Join%20${encodeURIComponent(name)}'s%20vault!&body=Use%20my%20signup%20link%20to%20join%20${encodeURIComponent(name)}'s%20exclusive%20music%20and%20community%20on%20Vault%20${encodeURIComponent(inviteLink)}`;
  }, [inviteLink, name]);

  return (
    <View
      className={twMerge(
        'relative flex min-h-full w-full flex-1 flex-col items-center',
        showRoundedTop ? 'md2:-mt-[61px]' : '-mt-[84px]',
        !isNewMenuEnabled && '-mb-2',
      )}
    >
      <View className="relative top-0 z-base aspect-square w-full overflow-clip">
        {profileImage != null ? (
          <Image
            src={profileImage.url}
            alt={name}
            className={twMerge(
              'z-below aspect-square w-full object-cover object-left-top',
              showRoundedTop && 'md2:rounded-t-[20px]',
            )}
          />
        ) : (
          <UserPlaceholderImage
            id={id}
            className="z-below aspect-square w-full"
            svgClassName={showRoundedTop ? 'md2:rounded-t-[20px]' : undefined}
          />
        )}
        <View
          className={twMerge(
            'absolute top-0 z-base aspect-square w-full bg-gradient-to-b to-transparent to-40% md2:hidden',
            isThemeEnabled ? 'from-vault_background' : 'from-black',
            showRoundedTop && 'md2:rounded-t-[20px]',
          )}
        />
        <View
          className={twMerge(
            'absolute top-20 z-base aspect-square w-full bg-gradient-to-b from-transparent to-80% md2:to-60%',
            isThemeEnabled ? 'to-vault_background' : 'to-black',
          )}
        />
      </View>

      <View
        className={twMerge(
          'z-above1 box-border w-full',
          !isNewMenuEnabled && 'rounded-t-xl px-2 md2:px-6',
        )}
      >
        <View
          className={twMerge(
            '-mt-[100px] box-border flex w-full flex-col px-4 py-6 md2:px-6',
            isNewMenuEnabled
              ? isThemeEnabled
                ? 'min-h-screen bg-gradient-to-b from-transparent to-vault_text/3 md2:-mt-[290px]'
                : 'min-h-screen bg-gradient-to-b from-transparent to-white/3 md2:-mt-[290px]'
              : 'rounded-t-xl bg-gradient-to-b from-black/10 from-[10.99%] to-black to-[43.96%] backdrop-blur-3xl md2:-mt-[216px]',
          )}
        >
          <View className="mx-4 flex flex-col items-center">
            <View className="mb-[20px] flex items-center justify-center">
              <Text
                className={twMerge(
                  'line-clamp-2 text-center font-title text-[32px] font-medium',
                  isThemeEnabled ? 'text-vault_text' : 'text-white',
                )}
              >
                Help grow
                <br />
                this vault
              </Text>
            </View>
          </View>

          <View className="mx-4 flex flex-col items-center">
            <View className="mb-[20px] flex items-center justify-center">
              <Text
                className={twMerge(
                  'line-clamp-2 text-center font-base text-[16px] font-normal leading-[20px]',
                  isThemeEnabled ? 'text-vault_text' : 'text-base50',
                )}
              >
                Invite friends to help this artist grow and
                <br />
                see your ranking against other fans!
              </Text>
            </View>
          </View>

          {/* Do not display buttons if no inviteCode available */}
          {!!inviteLink && (
            <View className="mx-4 mt-4 flex items-center justify-center gap-3">
              <Button
                label="Copy invite link"
                type="primary"
                leadingIcon={faLink}
                className={isThemeEnabled ? 'bg-vault_accent text-vault_accent_text' : undefined}
                onClick={() => {
                  trackEvent({
                    type: EVENTS.COPY,
                    properties: { type: 'invite-link' },
                    pathname,
                  });

                  copyInviteLink();
                }}
              />

              {!!mailtoLink && (
                <Button
                  label=""
                  className={twMerge(
                    'h-[48px] w-[48px] rounded-full text-[20px] hover:bg-opacity-30',
                    isThemeEnabled ? 'bg-vault_text/20 text-vault_text' : 'bg-base700 text-white',
                  )}
                  leadingIcon={faEnvelope}
                  onClick={() => {
                    trackEvent({
                      type: EVENTS.SHARE_VAULT_INVITE,
                      properties: { type: 'email' },
                      pathname,
                    });

                    window.location.href = mailtoLink;
                  }}
                  iconOnly
                />
              )}
            </View>
          )}

          <View className="mt-[40px] box-border w-full px-4">
            <View
              className={twMerge(
                'box-border flex w-full flex-col items-center justify-center gap-3 rounded-xl p-6',
                isThemeEnabled ? 'bg-vault_text/20' : 'bg-base800',
              )}
            >
              {isStatsEmpty ? (
                <>
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    fontSize={24}
                    className={isThemeEnabled ? 'text-vault_text/50' : 'text-base500'}
                  />
                  <Text
                    className={twMerge(
                      'text-center font-title text-[18px] font-medium',
                      isThemeEnabled ? 'text-vault_text' : 'text-white',
                    )}
                  >
                    No friends referred
                  </Text>
                </>
              ) : (
                <>
                  <UserGroup
                    users={previewInvitedUsers}
                    inviteCount={inviteCount}
                    withVaultTheme={isThemeEnabled}
                  />
                  <Text
                    className={twMerge(
                      'text-center font-title text-[18px] font-medium',
                      isThemeEnabled ? 'text-vault_text' : 'text-white',
                    )}
                  >
                    {inviteCount} {pluralizeText({ count: inviteCount, text: 'friend' })} referred
                  </Text>
                </>
              )}
            </View>
          </View>

          <View className="mt-[40px] box-border w-full px-4 pb-6">
            <View
              className={twMerge(
                'mb-3 box-border flex w-full items-center justify-between',
                isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
              )}
            >
              <Text className="font-base text-[14px] font-normal leading-[20px]">
                Top referrers
              </Text>
              <Text className="font-base text-[14px] font-normal leading-[20px]">Referrals</Text>
            </View>

            {isTopInvitersEmpty ? (
              <View
                className={twMerge(
                  'box-border flex w-full flex-col items-center justify-center gap-3 rounded-xl p-6',
                  isThemeEnabled ? 'bg-vault_text/20' : 'bg-base800',
                )}
              >
                <View className="flex items-center gap-3">
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    fontSize={24}
                    className={isThemeEnabled ? 'text-vault_text/50' : 'text-base500'}
                  />
                  <Text
                    className={twMerge(
                      'text-center font-base text-[14px] font-medium',
                      isThemeEnabled ? 'text-vault_text' : 'text-white',
                    )}
                  >
                    Be the first to refer a friend to this vault
                  </Text>
                </View>
              </View>
            ) : (
              topInviters.map(user => {
                const { inviteCount, inviter, rank } = getFragment(TopInvitersFragmentDoc, user);
                const { id, avatar, displayName, username, createdAt } = getFragment(
                  InvitedUserFragmentDoc,
                  inviter,
                );

                const displayNameOrUsername = displayName || username || 'Unknown';

                const onAvatarClick = () => {
                  trackEvent({
                    type: EVENTS.OPEN_BOTTOMSHEET,
                    properties: {
                      bottomsheetType: BOTTOMSHEET_TYPES.USER_PROFILE,
                      userId: id,
                    },
                    pathname,
                  });

                  openBottomsheet({
                    type: BOTTOMSHEET_TYPES.USER_PROFILE,
                    shared: {
                      withVaultTheme: isThemeEnabled,
                    },
                    userProfileBottomsheetProps: {
                      vaultId: mainVaultId,
                      userId: id,
                      vaultArtistId: loggedInUser?.artist?.id,
                      avatarUrl: avatar?.url || '',
                      username: username || '',
                      displayName: displayNameOrUsername,
                      joinDate: getMonthAndYear(new Date(createdAt)),
                      showAdminOptions: false,
                      withVaultTheme: isThemeEnabled,
                    },
                  });
                };

                return (
                  <View
                    key={id}
                    className={twMerge(
                      'mb-1 box-border flex w-full items-center justify-between gap-3 rounded-xl px-4 py-3',
                      isThemeEnabled ? 'bg-vault_text/20' : 'bg-base800',
                    )}
                  >
                    <View className="flex items-center gap-4">
                      <Text
                        className={twMerge(
                          'w-4 text-center font-base text-[14px] font-medium tabular-nums',
                          isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
                        )}
                      >
                        {rank}
                      </Text>
                      {avatar?.url ? (
                        <UserProfileImage
                          profileImageUrl={avatar.url}
                          className="h-6 w-6 cursor-pointer rounded-full"
                          onClick={onAvatarClick}
                          withVaultTheme={isThemeEnabled}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircleUser}
                          fontSize={24}
                          className={twMerge(
                            'cursor-pointer',
                            isThemeEnabled ? 'text-vault_text/50' : 'text-base500',
                          )}
                          onClick={onAvatarClick}
                        />
                      )}
                      <Text
                        className={twMerge(
                          'text-center font-base text-[14px] font-medium',
                          isThemeEnabled ? 'text-vault_text' : 'text-white',
                        )}
                      >
                        {displayNameOrUsername}
                      </Text>
                    </View>

                    <Text
                      className={twMerge(
                        'text-center font-base text-[14px] font-medium',
                        isThemeEnabled ? 'text-vault_text' : 'text-white',
                      )}
                    >
                      {inviteCount}
                    </Text>
                  </View>
                );
              })
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

function UserGroup({
  users,
  inviteCount,
  withVaultTheme,
}: {
  users: FragmentType<InvitedUserFragmentDoc>[];
  inviteCount: number;
  withVaultTheme: boolean;
}) {
  const remainingInvites = inviteCount - users.length;

  return (
    <View className="flex items-center">
      <View className="mr-3 flex w-full items-center justify-center">
        {users.map(userFrag => {
          const { avatar, updatedAt } = getFragment(InvitedUserFragmentDoc, userFrag);

          return avatar?.url ? (
            <UserProfileImage
              key={updatedAt}
              profileImageUrl={avatar.url}
              className="-mr-2 h-6 w-6 rounded-full border-2 border-solid border-base800"
              withVaultTheme={withVaultTheme}
            />
          ) : (
            <FontAwesomeIcon
              key={updatedAt}
              icon={faCircleUser}
              fontSize={24}
              className="-mr-2 rounded-full border-2 border-solid border-base800 text-base500"
            />
          );
        })}
      </View>
      {remainingInvites > 0 && (
        <Text className="text-nowrap font-base text-[14px] font-normal text-base400">
          +{remainingInvites} more
        </Text>
      )}
    </View>
  );
}

export function SkeletonVaultInvitesView() {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View className="no-scrollbar -mb-4 mt-12 flex min-h-full w-full flex-1 flex-col items-center overflow-y-scroll">
      <View className="relative top-0 z-base aspect-square w-full overflow-clip" />
      <View className="z-above1 -mt-[270px] flex w-full flex-col md2:-mt-[400px]">
        <View className="flex flex-col items-center">
          <LoadingSkeleton
            className={twMerge(
              'mb-[12px] h-[32px] w-[250px]',
              isThemeEnabled && 'bg-vault_text/10',
            )}
          />
          <LoadingSkeleton
            className={twMerge(
              'mb-[20px] h-[32px] w-[200px]',
              isThemeEnabled && 'bg-vault_text/10',
            )}
          />

          <View className="mb-6 flex w-[60%] min-w-[300px] flex-col items-center justify-center gap-2 text-center font-base !text-base-l font-normal md2:mb-[28px]">
            <LoadingSkeleton
              className={twMerge('h-[16px] w-full', isThemeEnabled && 'bg-vault_text/10')}
            />
            <LoadingSkeleton
              className={twMerge('h-[16px] w-full', isThemeEnabled && 'bg-vault_text/10')}
            />
          </View>

          <View className="mb-10 flex w-[60%] min-w-[300px] flex-row items-center justify-center gap-2 text-center font-base !text-base-l font-normal md2:mb-[28px]">
            <LoadingSkeleton
              className={twMerge(
                'h-[48px] w-[200px] rounded-full',
                isThemeEnabled && 'bg-vault_text/10',
              )}
            />
            <LoadingSkeleton
              className={twMerge(
                'h-[48px] w-[48px] rounded-full',
                isThemeEnabled && 'bg-vault_text/10',
              )}
            />
          </View>

          <View className="mb-6 box-border flex w-[80%] flex-1 flex-col items-center">
            <LoadingSkeleton
              className={twMerge('h-[100px] w-full', isThemeEnabled && 'bg-vault_text/10')}
            />
          </View>

          <View className="mt-6 box-border flex w-[80%] flex-1 flex-col items-center gap-2">
            <View className="mb-2 flex w-full items-center justify-between">
              <LoadingSkeleton
                className={twMerge('h-[20px] w-[80px]', isThemeEnabled && 'bg-vault_text/10')}
              />
              <LoadingSkeleton
                className={twMerge('h-[20px] w-[80px]', isThemeEnabled && 'bg-vault_text/10')}
              />
            </View>

            <LoadingSkeleton
              className={twMerge('h-[56px] w-full', isThemeEnabled && 'bg-vault_text/10')}
            />
            <LoadingSkeleton
              className={twMerge('h-[56px] w-full', isThemeEnabled && 'bg-vault_text/10')}
            />
            <LoadingSkeleton
              className={twMerge('h-[56px] w-full', isThemeEnabled && 'bg-vault_text/10')}
            />
          </View>
        </View>
      </View>
    </View>
  );
}
