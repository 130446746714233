import type { FC } from 'react';
import React from 'react';
import { BackButton } from '../components/buttons/BackButton';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { Logo } from '../components/svg/Logo';

export const NotFoundPage: FC = () => {
  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      showBorder
      withBottomNavigator={false}
      headerLeft={<BackButton href="/" />}
      headerCenter={
        <View className="flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full text-black">
          <Logo variant="default" />
        </View>
      }
    >
      <Text className="text-title-xl text-white">Page not found</Text>
    </DefaultLayout>
  );
};
