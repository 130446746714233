import { type FC, useEffect, useMemo } from 'react';
import React from 'react';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faComment } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faComment as faCommentSolid } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPlay } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPause } from '@soundxyz/font-awesome/sharp-solid-svg-icons';
import { useAudioController } from '../../audio/AudioController';
import { togglePlayPause } from '../../audio/AudioEngineHTML5';
import { useAudioPosition } from '../../audio/AudioPosition';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { TrackContentByIdQuery } from '../../graphql/generated';
import { useWindow } from '../../hooks/useWindow';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { EVENTS } from '../../types/eventTypes';
import type { TypeFromGraphQLUnion } from '../../types/gql';
import { trackEvent } from '../../utils/analyticsUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import {
  closeBottomAudioPlayer,
  openBottomAudioPlayer,
  openFullscreenPlayer,
} from '../main/AudioPlayer';
import { ControlButtons, ShareButton } from './FullscreenAudioPlayer';
import { Timeline } from './Timeline';

type Props = {
  content: TypeFromGraphQLUnion<
    NonNullable<TrackContentByIdQuery['vaultContentById']>,
    'VaultTrack'
  >;
  withBottomNavigator: boolean;
  withVaultTheme: boolean;
};

export const BottomAudioPlayer: FC<Props> = ({ content, withBottomNavigator, withVaultTheme }) => {
  const { isDesktop } = useWindow();

  const { isSelecting } = useSelectVaultContent();

  const { playing, track } = useAudioController();
  const { percentComplete } = useAudioPosition();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);

  useEffect(() => {
    openBottomAudioPlayer();
    return () => {
      closeBottomAudioPlayer();
    };
  }, []);

  const { openBottomsheet } = useBottomsheetContainer();

  if (isDesktop) {
    return <DesktopBottomAudioPlayer content={content} withVaultTheme={withVaultTheme} />;
  }

  if (isSelecting) {
    return null;
  }

  const {
    title,
    vault: { artist },
    commentMessageCount,
    isFullVersionAvailable,
  } = content;

  return (
    <View
      className={twMerge(
        'sticky z-audioPlayerContainer box-border flex h-[60px] w-full cursor-pointer flex-row',
        withBottomNavigator && 'bottom-[68px]',
        isNewMenuEnabled
          ? withVaultTheme
            ? 'border-0 border-t border-solid border-t-vault_text/5'
            : 'border-0 border-t border-solid border-t-white/5'
          : 'shadow-[0px_0px_0px_1px_rgba(0,0,0,0.04),0px_-8px_28px_rgba(0,0,0,0.28)]',
      )}
      onClick={() => {
        openFullscreenPlayer();
      }}
    >
      <View
        className={twMerge(
          'm-0 box-border w-full items-center justify-center overflow-hidden py-1',
          isNewMenuEnabled
            ? withVaultTheme
              ? 'border-0 border-t border-solid border-vault_text/5 bg-vault_background/75 backdrop-blur-2xl'
              : 'border-0 border-t border-solid border-white/5 bg-black/75 backdrop-blur-2xl'
            : 'bg-base800',
        )}
      >
        <View className="box-border flex h-full w-full flex-row items-center justify-between gap-4 px-2">
          <View className="flex flex-row">
            <ArtistProfileImage
              className="mr-[8px] w-[36px] rounded-full"
              profileImageUrl={artist?.profileImage?.url}
            />
            <View className="flex flex-1 flex-shrink flex-col justify-around">
              <View className="flex w-full flex-row items-center gap-2">
                <Text
                  className={twMerge(
                    'line-clamp-1 font-title !text-title-xxs font-medium',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                >
                  {title}
                </Text>
                {!isFullVersionAvailable && (
                  <Text
                    className={twMerge(
                      'flex h-4 w-fit items-center justify-center rounded-full border-[1px] border-solid px-2 text-[8px]',
                      withVaultTheme
                        ? 'border-vault_text text-vault_text'
                        : 'border-white text-white',
                    )}
                  >
                    Snippet
                  </Text>
                )}
              </View>
              <Text
                className={twMerge(
                  'font-base !text-base-xs font-medium',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
              >
                {artist?.name ?? 'Unnamed'}
              </Text>
            </View>
          </View>
          <View className="flex flex-row items-center justify-center gap-1">
            <Button
              labelComponent={
                <span
                  className={twMerge(
                    'font-base text-[13px] font-semibold tabular-nums',
                    withVaultTheme ? 'text-vault_text/50' : 'text-base400',
                  )}
                >
                  {commentMessageCount.toString()}
                </span>
              }
              label={commentMessageCount.toString()}
              iconOnly={commentMessageCount === 0}
              leadingIcon={faCommentSolid}
              className={twMerge('text-[20px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();

                trackEvent({
                  type: EVENTS.OPEN_BOTTOMSHEET,
                  properties: {
                    bottomsheetType: BOTTOMSHEET_TYPES.TRACK_COMMENTS,
                    component: 'MiniPlayer',
                    event: 'Open Bottomsheet',
                    artistId: artist?.id,
                    trackId: content.id,
                  },
                });

                openBottomsheet({
                  type: 'TRACK_COMMENTS',
                  shared: {
                    withVaultTheme,
                  },
                  trackCommentsBottomsheetProps: {
                    trackId: content.id,
                    autoFocusInput: false,
                    withVaultTheme,
                  },
                });
              }}
            />
            <Button
              iconOnly
              label=""
              leadingIcon={!playing ? faPlay : faPause}
              className={twMerge(
                'px-5 text-[20px]',
                withVaultTheme ? 'text-vault_text' : 'text-white',
              )}
              onClick={e => {
                e.stopPropagation();
                togglePlayPause();
              }}
              event={
                track != null
                  ? {
                      type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
                      properties: {
                        trackId: track.id,
                        percentComplete,
                        artistId: track.vault.artist?.id,
                        vaultId: track.vault.id,
                        isPreview: !track.isFullVersionAvailable,
                        component: 'bottom_audio_player',
                      },
                    }
                  : undefined
              }
            />
          </View>
        </View>

        <Timeline type="bottom" withVaultTheme={withVaultTheme} />
      </View>
    </View>
  );
};

const DesktopBottomAudioPlayer: FC<Pick<Props, 'content' | 'withVaultTheme'>> = ({
  content,
  withVaultTheme,
}) => {
  const { loggedInUser } = useAuthContext();

  const { openBottomsheet } = useBottomsheetContainer();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);

  const {
    id,
    title,
    linkValue,
    vault: { artist },
    commentMessageCount,
    isFullVersionAvailable,
  } = content;

  const link = useMemo(() => {
    const path = linkValue ? `/t/${linkValue}` : `/${id}`;

    return generateShareLink({
      artistLinkValue: artist?.linkValue,
      path,
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [artist?.linkValue, id, linkValue, loggedInUser?.inviteCode]);

  return (
    <View
      className={twMerge(
        'sticky z-audioPlayerContainer box-border flex w-full flex-row',
        isNewMenuEnabled
          ? withVaultTheme
            ? 'border-0 border-t border-solid border-t-vault_text/5'
            : 'border-0 border-t border-solid border-t-white/5'
          : 'shadow-[0px_0px_0px_1px_rgba(0,0,0,0.04),0px_-8px_28px_rgba(0,0,0,0.28)]',
      )}
    >
      <View
        className={twMerge(
          'm-0 box-border w-full items-center justify-center overflow-hidden px-4',
          isNewMenuEnabled
            ? withVaultTheme
              ? 'border-0 border-t border-solid border-vault_text/5 bg-vault_background/75 backdrop-blur-2xl'
              : 'border-0 border-t border-solid border-white/5 bg-black/75 backdrop-blur-2xl'
            : 'bg-base800',
        )}
      >
        <View className="box-border flex h-full w-full flex-row items-center justify-between ">
          <View className="flex w-[20%] flex-row">
            <View className="flex flex-1 flex-shrink flex-col justify-around">
              <View className="flex w-full flex-row items-center gap-2">
                <Text
                  className={twMerge(
                    'line-clamp-1 font-title !text-title-xxs font-medium',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                >
                  {title}
                </Text>
                {!isFullVersionAvailable && (
                  <Text
                    className={twMerge(
                      'mb-1 flex h-4 w-fit items-center justify-center rounded-full border-[1px] border-solid px-2 text-[8px]',
                      withVaultTheme
                        ? 'border-vault_text text-vault_text'
                        : 'border-white text-white',
                    )}
                  >
                    Snippet
                  </Text>
                )}
              </View>
              <Text
                className={twMerge(
                  'font-base !text-base-xs font-medium',
                  withVaultTheme ? 'text-vault_text/50' : 'text-neutral500',
                )}
              >
                {artist?.name ?? 'Unnamed'}
              </Text>
            </View>
          </View>

          <View className="flex w-[50%] flex-col items-center justify-center">
            <ControlButtons className="pt-[14px]" withVaultTheme={withVaultTheme} />
            <Timeline
              type="normal"
              className="py-0 pb-[5px]"
              timestampPosition="top"
              timestampClassName={twMerge(
                withVaultTheme ? 'text-vault_text/50' : 'text-neutral500',
              )}
              withVaultTheme={withVaultTheme}
            />
          </View>

          <View className="flex min-w-[20%] flex-row justify-end ">
            <Button
              className={twMerge('text-[16px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
              label={commentMessageCount.toString()}
              leadingIcon={faComment}
              onClick={() => {
                openBottomsheet({
                  type: 'TRACK_COMMENTS',
                  shared: {
                    withVaultTheme,
                  },
                  trackCommentsBottomsheetProps: {
                    trackId: content.id,
                    autoFocusInput: false,
                    withVaultTheme,
                  },
                });
              }}
            />

            <ShareButton
              link={link}
              className={twMerge('ml-[20px]', withVaultTheme && 'text-vault_text')}
              artistName={artist?.name ?? 'vault'}
              withVaultTheme={withVaultTheme}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export const LoadingBottomAudioPlayer: FC = () => {
  useEffect(() => {
    openBottomAudioPlayer();
    return () => {
      closeBottomAudioPlayer();
    };
  }, []);

  return <LoadingSkeleton className="sticky h-[60px] w-full rounded-none md2:h-[96px]" />;
};
