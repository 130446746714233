import { type LegacyRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { heardVaults, useAudioController } from '../../../audio/AudioController';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { useMenuContainer } from '../../../contexts/MenuContext';
import {
  FeatureTypename,
  type FragmentType,
  getFragment,
  TierTypename,
  TrackItemFragmentDoc,
} from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { useActiveSubscriptionFeatures } from '../../../hooks/useTierFeatures';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { getDurationAsTime } from '../../../utils/dateUtils';
import { formatDateString } from '../../../utils/textUtils';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

import { LargePlayIndicatorBars } from '../../track/PlayIndicatorBars';

gql(/* GraphQL */ `
  fragment TrackItem on VaultTrack {
    id
    title
    linkValue
    createdAt

    vaultId
    duration
    commentMessageCount
    parentVaultContentId

    featureAccess {
      feature {
        __typename
      }
    }

    vault {
      id
      isUserArtistAdmin
      artist: artistProfile {
        id
        profileImage {
          id
          url
        }
      }
      activeSubscription {
        id
        createdAt
        ...ActiveSubscriptionFeatures
      }
    }
  }
`);
export const TrackItem = ({
  track,
  containerRef,
  onPlayClick,
  hasEllipsis,
}: {
  track: FragmentType<TrackItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  onPlayClick?: () => void;
  hasEllipsis?: boolean;
}) => {
  const {
    title,
    createdAt,
    duration,
    id: trackId,
    vault,
    featureAccess,
  } = getFragment(TrackItemFragmentDoc, track);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const vaultTheme = useSnapshot(VaultThemeStore);

  const { activeTrackId, playing } = useAudioController();

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: trackId });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = !isOwner && activeSubscriptionFeatures?.tier == null;
  const isActiveTrack = activeTrackId === trackId;
  const heardVaultIds = useSnapshot(heardVaults).vaultIds;
  const isPlaying = playing && isActiveTrack;

  const couldBeNew =
    !isOwner &&
    !heardVaultIds.has(trackId) &&
    vault.activeSubscription != null &&
    vault.activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || (isVaultCustomizeOpen && isThemeEnabled));
  }, [couldBeNew, isLoading, isThemeEnabled, isVaultCustomizeOpen, isViewed]);

  return (
    <View
      className="group flex w-full flex-grow cursor-pointer select-none flex-col gap-3 pb-3 text-left"
      containerRef={containerRef}
      onClick={onPlayClick}
    >
      <View
        className={twMerge(
          'relative flex min-h-[110px] w-full items-center justify-center rounded-md transition-all duration-300 ease-in-out',
          isThemeEnabled
            ? 'bg-vault_text/10 group-hover:bg-vault_text/20'
            : isNewMenuEnabled
              ? 'bg-white/10 group-hover:bg-white/20'
              : 'bg-base800 bg-base800/70 group-hover:bg-base800',
        )}
      >
        <AnimatePresence>
          {isNew && (
            <motion.div
              className={twMerge(
                'absolute right-3 top-2 rounded-full font-base !text-base-xs font-semibold',
                isThemeEnabled ? 'text-vault_accent' : 'text-yellow100',
              )}
              exit={{ opacity: 0 }}
            >
              New
            </motion.div>
          )}
        </AnimatePresence>
        <View className="relative flex h-[74px] items-start justify-center">
          <View
            className={twMerge(
              'h-[74px] w-[54px] bg-contain bg-no-repeat',
              isThemeEnabled
                ? vaultTheme.mode === 'light'
                  ? 'bg-new-file-background-black'
                  : 'bg-new-file-background-white'
                : 'bg-new-file-background-white',
            )}
          />
          <View className="absolute top-5 flex flex-col items-center justify-center gap-1.5">
            {isActiveTrack ? (
              <LargePlayIndicatorBars
                isPaused={!isPlaying}
                sharedBarClassName={isThemeEnabled ? 'bg-vault_accent' : undefined}
              />
            ) : (
              <FontAwesomeIcon
                icon={faWaveformLines}
                className={twMerge(
                  'text-[24px]',
                  isThemeEnabled ? 'text-vault_text' : 'text-white',
                )}
              />
            )}

            {isLocked ? (
              <FontAwesomeIcon
                icon={faLock}
                className={twMerge(
                  'text-[11px]',
                  isThemeEnabled
                    ? 'text-vault_text'
                    : isNewMenuEnabled
                      ? 'text-white'
                      : 'text-base300',
                )}
              />
            ) : !isOwner && activeSubscriptionFeatures?.tier !== TierTypename.PaidTier ? (
              featureAccess.some(
                access => access.feature.__typename === FeatureTypename.FreeVaultContent,
              ) ? null : (
                <Text
                  className={twMerge(
                    'text-[10px] font-medium',
                    isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
                  )}
                >
                  Snippet
                </Text>
              )
            ) : null}
          </View>
        </View>
      </View>
      <View className="flex flex-col gap-1">
        <View className="flex w-full justify-between gap-1">
          <Text
            className={twMerge(
              'line-clamp-2 text-ellipsis  break-words font-base !text-base-m font-medium',
              isThemeEnabled ? 'text-vault_text' : 'text-white',
            )}
          >
            {title ?? 'Untitled'}
          </Text>
          {!!hasEllipsis && <div className="h-5 w-5 flex-shrink-0" />}
        </View>
        <Text
          className={twMerge(
            'text-[12px] font-normal leading-[16px]',
            isThemeEnabled
              ? 'text-vault_text/50'
              : isNewMenuEnabled
                ? 'text-white/50'
                : 'text-base50&50',
          )}
        >
          {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
          &nbsp;&middot;&nbsp;
          {getDurationAsTime(duration)}
        </Text>
      </View>
    </View>
  );
};
