import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { VaultType } from '../../graphql/generated';
import { useFreeTier } from '../../hooks/useFreeTier';
import { useLockedMessages } from '../../hooks/useLockedMessages';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button } from '../buttons/Button';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { MockMessageBubble } from '../message/MockMessageBubble';

export const LockedChatView = ({
  vaultId,
  vaultType,
  linkValue,
  vaultArtistName,
  vaultArtistImage,
  monthlySubPrice = DEFAULT_PRICE,
  chatAvailableForFreeUsers,
}: {
  vaultId: string | undefined;
  vaultType: VaultType | undefined;
  linkValue: string | undefined;
  vaultArtistName: string | undefined;
  vaultArtistImage: string | undefined;
  monthlySubPrice: number;
  chatAvailableForFreeUsers: boolean;
}) => {
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { subscribeFreeTier } = useFreeTier();
  const messages = useLockedMessages({ vaultArtistName, vaultArtistImage });
  const messagesRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const smsCampaignResponseShortcode = searchParams.get('c');

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const showJoinFreeButton = vaultType === VaultType.FreeOnly || chatAvailableForFreeUsers;

  const onJoinFreeClick = useCallback(async () => {
    if (!vaultId || !linkValue) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      await subscribeFreeTier({ input: { vaultId, smsCampaignResponseShortcode } });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        c: smsCampaignResponseShortcode,
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [linkValue, loginStatus, navigate, subscribeFreeTier, vaultId, smsCampaignResponseShortcode]);

  return (
    <View className="relative flex h-full w-full flex-1 overflow-clip overscroll-none">
      <View containerRef={messagesRef} className="flex w-full flex-1 flex-col">
        {messages.map((message, i) => (
          <MockMessageBubble
            key={i}
            className={twMerge(i !== 0 && 'mt-4')}
            author={message.author}
            content={message.content}
            profileImageUrl={message.profileImageUrl}
            reactionSummary={message.reactionSummary}
            isVaultArtist={message.isVaultArtist}
            hasVaultContent={message.hasVaultContent}
          />
        ))}
      </View>
      <View
        className={twMerge(
          'pointer-events-none absolute bottom-0 left-0 right-0 h-[70vh] bg-gradient-to-t to-transparent md:h-[75vh]',
          isThemeEnabled ? 'from-vault_background via-vault_background' : 'from-black via-black',
        )}
      />
      <div
        className={twMerge(
          'absolute bottom-4 left-0 right-0 mx-auto flex max-w-[360px] flex-col gap-5 p-4 text-center',
          isThemeEnabled ? 'text-vault_text' : 'text-white',
        )}
      >
        <Text className="text-title-m font-semibold">Unlock private chat</Text>
        <Text className="text-base-m">
          Get behind the scenes, hear directly from the artist about their music and process.
        </Text>
        {linkValue &&
          vaultId &&
          (showJoinFreeButton ? (
            <Button
              label="Join for free"
              type="primary"
              buttonType="submit"
              disabledClassName="opacity-30"
              className={twMerge(
                'w-full',
                isThemeEnabled && 'bg-vault_accent text-vault_accent_text',
              )}
              onClick={onJoinFreeClick}
            />
          ) : (
            <SubscribeButton
              label={`Unlock for $${monthlySubPrice}/month`}
              className={twMerge(
                'w-full text-base-l',
                isThemeEnabled ? 'bg-vault_accent text-vault_accent_text' : 'text-base900',
              )}
              linkValue={linkValue}
              artistAvatarUrl={vaultArtistImage}
              price={monthlySubPrice}
              vaultId={vaultId}
              showBottomSheet={false}
              smsCampaignResponseShortcode={smsCampaignResponseShortcode}
              component="locked_chat"
            />
          ))}
      </div>
    </View>
  );
};
