import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';
import Linkify from 'react-linkify';
import { useGate } from 'statsig-react';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { addLinkifyItSchema, createNewLinkifyIt } from '../../utils/linkUtils';

const linkify = createNewLinkifyIt();

addLinkifyItSchema(linkify, 'http:', {
  validate: (text, pos) => {
    const url = text.slice(pos).split(/[\s]/)[0];

    if (!url || url.lastIndexOf('.') < 0) {
      return false;
    }

    const lastPeriod = url.lastIndexOf('.');
    const lastSlash = url.indexOf('/', lastPeriod);
    const end = url.slice(lastPeriod, lastSlash > 0 ? lastSlash + 1 : url.length + 1);

    return end.replace('.', '').replace('/', '').length < 4 && url.length;
  },
});

export const LinkifyText: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const componentDecorator = useCallback(
    (href: string, text: string, key: number) => (
      <a
        href={href}
        key={key}
        className={isThemeEnabled ? 'text-vault_accent' : 'text-yellow100'}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    ),
    [isThemeEnabled],
  );

  return (
    <Linkify
      componentDecorator={componentDecorator}
      matchDecorator={text => {
        if (!linkify.test(text)) return null;

        return linkify.match(text);
      }}
    >
      {children}
    </Linkify>
  );
};
