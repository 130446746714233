import { type LegacyRef } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { BOTTOMSHEET_TYPES } from '../../../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../../../constants/flagConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../../../contexts/OverlayContext';
import { useMutation } from '../../../../graphql/client';
import { ImageItemFragmentDoc, UpsertUserContentViewDocument } from '../../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../../graphql/generated';
import { useFreeTier } from '../../../../hooks/useFreeTier';
import { useActiveSubscriptionFeatures } from '../../../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../../../hooks/useUpsellInterstitials';
import { LoginStatus } from '../../../../types/authTypes';
import { EVENTS } from '../../../../types/eventTypes';
import { trackEvent } from '../../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { constructQueryParams } from '../../../../utils/stringUtils';
import { MediaViewer } from '../../../message/MediaViewer';
import { ImageItem } from '../ImageItem';

export const UserImage = ({
  image,
  containerRef,
  artistHandle,
}: {
  image: FragmentType<ImageItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const navigate = useNavigate();
  const { mutate: upsertUserContentView } = useMutation(UpsertUserContentViewDocument, {});
  const {
    id: imageId,
    vault,
    title,
    uploadedMedia,
    vaultId,
  } = getFragment(ImageItemFragmentDoc, image);
  const { loginStatus } = useAuthContext();
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('code'); // referral link code
  const smsCampaignResponseShortcode = searchParams.get('c');

  const hasSubscription = vault.activeSubscription != null;
  const { subscribeFreeTier } = useFreeTier();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { openBottomsheet } = useBottomsheetContainer();

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const showUpsellInterstitial =
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const onClick = () => {
    upsertUserContentView({ input: { vaultContentId: imageId } });
    if (isLocked || showUpsellInterstitial) {
      if (showUpsellInterstitial) {
        setTimeout(() => {
          openBottomsheet({
            type: 'GET_APP',
            getAppBottomsheetProps: {
              vaultId,
              interstitial: 'first_media',
              onContinue: null,
            },
          });

          trackEvent({
            type: EVENTS.OPEN_BOTTOMSHEET,
            properties: {
              bottomsheetType: 'GET_APP',
              vaultId,
              interstitial: 'first_media',
            },
          });
        }, 2000);
      } else {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'JOIN_VAULT',
            joinType: hasSubscription ? 'paid' : 'free',
            artistHandle,
          },
        });

        openBottomsheet({
          type: BOTTOMSHEET_TYPES.JOIN_VAULT,
          shared: {
            withVaultTheme: isThemeEnabled,
          },
          joinVaultBottomsheetProps: {
            artistHandle,
            vaultArtistProfileImage: vault.artist?.profileImage?.url ?? '',
            joinType: hasSubscription ? 'paid' : 'free',
            buttonTitle: hasSubscription ? 'Upgrade to All-Access' : 'Join for free',
            title: hasSubscription ? 'Upgrade to view and more' : 'Join this vault to get started',
            onConfirm() {
              if (hasSubscription) {
                const queryParams = constructQueryParams({
                  code: inviteCode,
                  c: smsCampaignResponseShortcode,
                });
                navigate(artistNavigationPath(artistHandle, '/subscribe', queryParams));
                return;
              }

              subscribeFreeTier({
                input: { vaultId, inviteCode, smsCampaignResponseShortcode },
              });
            },
          },
        });
      }
      return;
    }

    openOverlay(
      <MediaViewer
        title={title ?? ''}
        medias={[{ id: uploadedMedia.id, type: uploadedMedia.mediaType, url: uploadedMedia.url }]}
        onClose={closeOverlay}
      />,
    );
  };

  return <ImageItem image={image} containerRef={containerRef} onClick={onClick} />;
};
