import type { FC, ReactNode } from 'react';
import React from 'react';
import type { SwipeableHandlers } from 'react-swipeable';
import { twMerge } from 'tailwind-merge';
import { View } from '../common/View';

export const StyledInputContainer: FC<{
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  swipeableHandlers?: SwipeableHandlers;
}> = ({ children, className, disabled, swipeableHandlers }) => {
  return (
    <View
      className={twMerge(
        'mb-[12px] flex w-full max-w-[310px] flex-row items-center rounded-[16px] bg-[#1f1f1f] px-[20px] py-[12px]',
        disabled && 'opacity-50',
        className,
      )}
      swipeableHandlers={swipeableHandlers}
    >
      {children}
    </View>
  );
};
