import React, { Suspense, useMemo, useRef } from 'react';
import {
  Environment,
  Html,
  Lightformer,
  PresentationControls,
  useGLTF,
  useTexture,
} from '@react-three/drei';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import { MeshLineGeometry, MeshLineMaterial } from 'meshline';
import { useGate } from 'statsig-react';
import * as THREE from 'three';
import { useSnapshot } from 'valtio/react';
import { FEATURE_GATES } from '../../constants/flagConstants';
import {
  BADGE_MATERIAL,
  LANYARD_MATERIAL,
  VAULT_LOGO_WHITE_PNG,
} from '../../constants/imageConstants';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { getMonthAndYear } from '../../utils/dateUtils';
import { formatSerialNumber } from '../../utils/textUtils';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';

extend({ MeshLineGeometry, MeshLineMaterial });
useGLTF.preload('https://d3jznoddta6qri.cloudfront.net/public/wrap8.glb');
useTexture.preload('https://d3jznoddta6qri.cloudfront.net/public/ribbon_diff5.png');
useTexture.preload(BADGE_MATERIAL);
useTexture.preload('https://d3jznoddta6qri.cloudfront.net/public/ribbon_rough.png');

export const MembershipBadge = ({
  className,
  isLoadingData,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  points,
}: {
  className?: string;
  isLoadingData: boolean;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  points: number | undefined;
}) => {
  return (
    <Canvas className={className} camera={{ position: [0, 0, 6] }} dpr={2}>
      <Suspense fallback={null}>
        <Environment blur={0.5}>
          <Lightformer
            intensity={3}
            color="white"
            position={[0, -1, 5]}
            rotation={[0, 0, Math.PI / 3]}
            scale={[100, 0.1, 1]}
          />
          <Lightformer
            intensity={2}
            color="white"
            position={[-1, -1, 1]}
            rotation={[0, 0, Math.PI / 3]}
            scale={[100, 0.1, 1]}
          />
          <Lightformer
            intensity={3}
            color="white"
            position={[1, -10, 1]}
            rotation={[0, 0, Math.PI / 3]}
            scale={[100, 0.1, 1]}
          />
          <Lightformer
            intensity={2}
            color="white"
            position={[-10, 0, 14]}
            rotation={[0, Math.PI / 2, Math.PI / 3]}
            scale={[100, 10, 1]}
          />
        </Environment>
      </Suspense>
      <PresentationControls
        global
        config={{ mass: 2, tension: 100 }}
        snap={{ mass: 4, tension: 400 }}
        rotation={[0, 0, 0]}
        polar={[-Math.PI / 3, Math.PI / 3]}
        azimuth={[-Math.PI / 1.4, Math.PI / 2]}
      >
        <Card
          isLoadingData={isLoadingData}
          artistName={artistName}
          serialNumber={serialNumber}
          imageUrl={imageUrl}
          displayName={displayName}
          createdAt={createdAt}
          points={points}
        />
      </PresentationControls>
    </Canvas>
  );
};

function Card({
  isLoadingData,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  points,
}: {
  isLoadingData: boolean;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  points: number | undefined;
}) {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const vaultTheme = useSnapshot(VaultThemeStore);

  const joinedAt = useMemo(() => {
    if (!createdAt) return '';
    return getMonthAndYear(new Date(createdAt));
  }, [createdAt]);

  const { nodes } = useGLTF('https://d3jznoddta6qri.cloudfront.net/public/wrap8.glb');
  const texture = useTexture(
    imageUrl || 'https://d3jznoddta6qri.cloudfront.net/public/badge_material.png',
  );
  const ribbon = useTexture('https://d3jznoddta6qri.cloudfront.net/public/ribbon_diff5.png');
  const roughness = useTexture('https://d3jznoddta6qri.cloudfront.net/public/ribbon_rough.png');

  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(-1, 1); // Set texture repeat

  const cardRef = useRef<THREE.Group | null>(null);

  useFrame(state => {
    if (!cardRef.current) return;

    const t = state.clock.getElapsedTime();

    // Smoothly looping cosine wave for X rotation with very subtle tilt
    cardRef.current.rotation.x = THREE.MathUtils.lerp(
      cardRef.current.rotation.x,
      0.05 * Math.cos(t / 4), // Reduced rate and amplitude for minimal tilt
      0.1, // Interpolation factor, lower for smoother transitions
    );

    // Smoothly looping sine wave for Y rotation, gentle swaying without excessive side tilting
    cardRef.current.rotation.y = THREE.MathUtils.lerp(
      cardRef.current.rotation.y,
      0.05 * Math.sin(t / 4), // Matched amplitude and rate with X for uniform motion
      0.1, // Interpolation factor, lower for smoother transitions
    );

    // Matching Z rotation to Y for visual consistency with slight phase offset
    cardRef.current.rotation.z = THREE.MathUtils.lerp(
      cardRef.current.rotation.z,
      0.05 * Math.sin(t / 4 + Math.PI / 2), // Adding a phase offset for dynamic visual effect
      0.1, // Interpolation factor
    );

    // Subtle floating effect on Y position, reduced amplitude and slower for a soft bobbing effect
    cardRef.current.position.y = THREE.MathUtils.lerp(
      cardRef.current.position.y,
      -1.25 + 0.1 * Math.sin(t / 2), // Reduced amplitude and increased period for smoother bobbing
      0.1, // Smoother transition to new positions
    );
  });

  return (
    <group ref={cardRef}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <mesh geometry={nodes.Cube?.geometry}>
        <meshPhysicalMaterial
          map={texture}
          emissiveMap={texture}
          map-anisotropy={16}
          clearcoat={0}
          clearcoatRoughness={0.15}
          roughness={0.3}
          metalness={0.5}
        />
      </mesh>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <mesh geometry={nodes.Wrap?.geometry}>
        <meshPhysicalMaterial
          thickness={0}
          ior={1}
          clearcoat={0}
          roughness={0}
          reflectivity={0.67}
          transmission={1}
          iridescence={0}
          opacity={0.1}
          transparent
          side={THREE.DoubleSide}
          color={isThemeEnabled ? vaultTheme.textColor : undefined}
        />
      </mesh>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <mesh geometry={nodes.Plane?.geometry}>
        <meshPhysicalMaterial
          roughnessMap={roughness}
          roughness={0.8}
          clearcoat={0.025}
          clearcoatMap={roughness}
          map={ribbon}
          side={THREE.DoubleSide}
        />
      </mesh>
      <Html transform pointerEvents="none" scale={0.25}>
        <div
          className="flex select-none flex-col justify-between rounded-md text-[6px]"
          style={{
            width: '120px',
            aspectRatio: '310/550',
            borderRadius: '4px',
            transform: 'scale(4)', // Scale up for better readability
          }}
        >
          <View className="flex flex-row items-start justify-between px-2 py-4">
            <View className="flex flex-col gap-1">
              {isLoadingData ? (
                <LoadingSkeleton className="h-2 w-8" />
              ) : (
                <Text className="text-[8px] font-medium">{artistName}</Text>
              )}
              <Text>Member</Text>
            </View>
            <View className="flex translate-x-5 translate-y-1 -rotate-90 transform items-center gap-1">
              {isLoadingData || !serialNumber ? (
                <LoadingSkeleton className="h-2 w-8" />
              ) : (
                <p className="text-[6px] text-white">{formatSerialNumber(serialNumber)}</p>
              )}
              <Image
                width={20}
                height={32}
                src={VAULT_LOGO_WHITE_PNG}
                className="rotate-90 object-contain"
                alt="Logo"
              />
            </View>
          </View>
          <View className="flex flex-row items-end justify-between px-2 py-4">
            <View className="flex flex-col gap-1">
              {isLoadingData ? (
                <LoadingSkeleton className="h-2 w-10" />
              ) : (
                <Text className="font-medium">{`@${displayName}`}</Text>
              )}

              {isLoadingData ? (
                <LoadingSkeleton className="h-2 w-14" />
              ) : (
                <Text>{`Joined ${joinedAt}`}</Text>
              )}
            </View>
            {false && ( // TODO: Remove when ready to show points
              <View className="flex flex-col items-end">
                {isLoadingData ? (
                  <LoadingSkeleton className="mb-0.5 h-5 w-4 rounded-sm" />
                ) : (
                  <Text className="text-base-m font-medium">{points || 0}</Text>
                )}
                <Text>Points</Text>
              </View>
            )}
          </View>
        </div>
      </Html>
    </group>
  );
}

export function FallbackBadge({
  isLoading,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  points,
}: {
  isLoading: boolean;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  points: number | undefined;
}) {
  const joinedAt = useMemo(() => {
    if (!createdAt) return '';
    return getMonthAndYear(new Date(createdAt));
  }, [createdAt]);

  return (
    <View className="relative flex h-full w-full flex-col items-center overflow-hidden">
      <Image
        className="z-above4 rounded-sm"
        width={38}
        height={150}
        src={LANYARD_MATERIAL}
        alt="lanyard"
      />
      <Image
        className="absolute z-above1 -translate-x-[25px] rotate-[-16deg] opacity-75"
        width={38}
        height={150}
        src={LANYARD_MATERIAL}
        alt="lanyard"
      />
      <View className="relative z-above2 -translate-y-4 rounded-xl border border-solid border-base600 shadow-md">
        <View className="absolute left-[50%] top-2.5 z-above3 h-3 w-[60px] -translate-x-[30px] items-center justify-center rounded-full border-1.5 border-x-0 border-t-0 border-solid border-base500 bg-black" />
        <Image
          className="z-above2 aspect-[310/503] rounded-xl"
          src={imageUrl || BADGE_MATERIAL}
          alt="badge"
        />
        {/* Linear Gradient Overlay */}
        <div
          className="absolute bottom-1 left-0 right-0 top-0 rounded-xl"
          style={{
            background:
              'linear-gradient(to bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.9) 100%)',
          }}
        />
        {/* Overlay View */}
        <View className="absolute bottom-0 left-0 flex h-full w-full text-white">
          <View className="flex w-full flex-col justify-between px-4 pb-2 pt-8">
            <View className="flex flex-row items-start justify-between">
              <View className="flex flex-col gap-1">
                {isLoading ? (
                  <LoadingSkeleton className="h-2 w-8" />
                ) : (
                  <Text className="text-title-l font-bold">{artistName}</Text>
                )}
                <Text className="text-base-s text-base400">Member</Text>
              </View>
              <View className="flex translate-x-8 translate-y-4 -rotate-90 transform items-center gap-1">
                {isLoading || !serialNumber ? (
                  <LoadingSkeleton className="h-2 w-8" />
                ) : (
                  <p className="text-base-s text-white">{formatSerialNumber(serialNumber)}</p>
                )}
                <Image
                  width={40}
                  height={50}
                  src={VAULT_LOGO_WHITE_PNG}
                  className="rotate-90 object-contain"
                  alt="Logo"
                />
              </View>
            </View>
            <View className="flex flex-row items-end justify-between px-2 py-4">
              <View className="flex flex-col gap-1">
                {isLoading ? (
                  <LoadingSkeleton className="h-2 w-10" />
                ) : (
                  <Text className="text-base-m font-bold">{`@${displayName}`}</Text>
                )}

                {isLoading ? (
                  <LoadingSkeleton className="h-2 w-14" />
                ) : (
                  <Text className="text-base-s text-base400">{`Joined ${joinedAt}`}</Text>
                )}
              </View>
              {false && ( // TODO: Remove when ready to show points
                <View className="flex flex-col items-end">
                  {isLoading ? (
                    <LoadingSkeleton className="mb-0.5 h-5 w-4 rounded-sm" />
                  ) : (
                    <Text className="text-title-xl font-bold">{points || 0}</Text>
                  )}
                  <Text className="text-base-s text-base400">Points</Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
