import type { ComponentProps } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faLock } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons/index.mjs';

import { InputContext, useInputContext } from '../../contexts/InputContext';
/**
 * @usage for grouping an input with bottom info to keep the layout consistent i.e. error and description
 */
const Root = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  className?: ComponentProps<'div'>['className'];
} & React.ContextType<typeof InputContext>) => {
  return (
    <InputContext.Provider value={{ ...props }}>
      <div className={twMerge(!!className ? className : 'flex w-full min-w-0 flex-col gap-y-1')}>
        {children}
      </div>
    </InputContext.Provider>
  );
};

export const Input = React.forwardRef<
  HTMLInputElement,
  {
    /**
     * @usage to show controlled error state when using without InputWrapper
     */
    hasError?: boolean;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(({ hasError, className, ...props }, forwardedRef) => {
  const { error, disabled } = useInputContext();
  return (
    <input
      className={twMerge(
        'block w-full rounded-[6px] border-0 bg-base200 px-[11px] py-3 font-base text-base-m text-base800 transition-all',
        'outline-none ring-1 ring-inset ring-base200 placeholder:text-base500 focus:ring-2 focus:ring-inset focus:ring-base800',
        (props.disabled || disabled) &&
          'cursor-not-allowed !bg-base50 text-base400 !ring-base50 hover:!bg-base50 disabled:cursor-not-allowed disabled:text-base400',
        hasError || !!error
          ? 'bg-destructive50 ring-destructive50 hover:ring-1 hover:ring-destructive200 focus:ring-destructive300'
          : 'hover:border-base300 hover:bg-base50 hover:ring-1',
        className,
      )}
      ref={forwardedRef}
      disabled={props.disabled || disabled}
      {...props}
    />
  );
});

Input.displayName = 'Input';

const InputError = ({ errorMessage }: { errorMessage: string | null }) => {
  const { error } = useInputContext();

  if (!error) {
    return null;
  }

  return (
    <p className="error font-base text-base-s text-destructive700">
      <FontAwesomeIcon className="mr-1" icon={faExclamationCircle} fontSize={12} />
      {errorMessage}
    </p>
  );
};

const InputDescription = ({ description }: { description?: string }) => {
  const { error, disabled } = useInputContext();

  if (error) {
    return null;
  }
  return (
    <p className={twMerge('font-base text-base-s', disabled ? 'text-base500' : 'text-base800')}>
      {description}
    </p>
  );
};

const Label = ({
  children,
  name,
  isEditable = true,
}: {
  children: React.ReactNode;
  name: string;
  isEditable?: boolean;
}) => {
  return (
    <label htmlFor={name} className="flex items-center text-base-l font-semibold text-base800">
      {children}&nbsp;
      {!isEditable && <FontAwesomeIcon icon={faLock} size="sm" />}
    </label>
  );
};

Root.Error = InputError;
Root.Description = InputDescription;
Root.Label = Label;

export { Root, Root as InputWrapper };
