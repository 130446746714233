import type { ReactNode } from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import { isSafari } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { useAudioController } from '../../audio/AudioController';
import { togglePlayPause } from '../../audio/AudioEngineHTML5';
import { setHideAudioPlayer } from '../../audio/AudioMeta';
import { useAudioPosition } from '../../audio/AudioPosition';
import { FEATURE_GATES } from '../../constants/flagConstants';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { PlayButtonView } from '../audioPlayer/PlayButtonView';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { AudioPlayer } from '../main/AudioPlayer';
import { VaultNav } from '../vault/VaultNav';
import { DefaultLayout } from './DefaultLayout';

gql(/* GraphQL */ `
  fragment MessageChannelLayoutInfo on MessageChannel {
    id
    vault {
      id
      contentCount
      artist: artistProfile {
        id
        name
      }
    }
  }
`);

export const MessageChannelLayout = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    artistName?: string;
    artistProfileImage?: string;

    secondaryFooter?: ReactNode;
    nonScrollingChildren?: ReactNode;
    stretch?: boolean;
    activeSubscriptionFeatures: TierFeatures;

    // VaultNav related props
    messageChannelId: string | undefined;
    hasChatReadAccess: boolean;
    chatAvailableForFreeUsers: boolean;

    childrenContainerClassName?: string;
  }
>(
  (
    {
      children,
      artistName,
      secondaryFooter,
      nonScrollingChildren,
      stretch,
      artistProfileImage,
      activeSubscriptionFeatures,
      messageChannelId,
      hasChatReadAccess,
      chatAvailableForFreeUsers,
      childrenContainerClassName,
    },
    ref,
  ) => {
    const { pathname } = useLocation();
    const { isDesktop } = useWindow();
    const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
    const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

    const showVaultNav = !isDesktop || !isNewMenuEnabled;

    const { track, playing } = useAudioController();
    const { percentComplete } = useAudioPosition();

    useEffect(() => {
      if (!isDesktop) {
        setHideAudioPlayer(true);
      }

      return () => {
        if (!isDesktop) {
          setHideAudioPlayer(false);
        }
      };
    }, [isDesktop]);

    const onPlayClick = () => {
      track != null &&
        trackEvent({
          type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
          properties: {
            trackId: track.id,
            percentComplete,
            artistId: track.vault.artist?.id,
            vaultId: track.vault.id,
            isPreview: !track.isFullVersionAvailable,
            component: 'message_channel_layout',
          },
          pathname,
        });

      togglePlayPause();
    };

    const strokeDashoffset = isSafari
      ? ((100 - percentComplete) * 2 * 14 * Math.PI) / 100
      : -((100 - percentComplete) * 2 * 14 * Math.PI) / 100;

    return (
      <DefaultLayout
        withVaultTheme={isThemeEnabled}
        showRoundedTop={isNewMenuEnabled}
        showBorder
        ref={ref}
        headerCenter={
          <HeaderCenter
            artistName={artistName}
            artistProfileImage={artistProfileImage}
            activeSubscriptionFeatures={activeSubscriptionFeatures}
          />
        }
        headerRight={
          track != null ? (
            <View
              className="relative flex h-[30px] w-[30px] cursor-pointer items-center justify-center overflow-hidden rounded-full"
              onClick={onPlayClick}
            >
              <svg className="absolute" height={30} width={30}>
                <circle
                  r="14"
                  cx={15}
                  cy={15}
                  strokeWidth={3}
                  stroke="yellow"
                  fillOpacity={0}
                  strokeDasharray={2 * 14 * Math.PI}
                  transform="rotate(-90 15 15)"
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
              <PlayButtonView isPlaying={playing} isDisabled={false} size={24} />
            </View>
          ) : (
            <View className="w-[50px]" />
          )
        }
        isHeaderTransparent={false}
        shouldSkipMargin
        secondaryFooter={secondaryFooter}
        nonScrollingChildren={nonScrollingChildren}
        stretch={stretch}
        headerGridClassName="flex"
        headerLeftClassName="mr-4"
        headerCenterClassName="flex-1 justify-start"
        headerClassName={twMerge(
          'border-b border-t-0 border-solid border-b-base700 bg-base900_alpha90 pt-8 backdrop:blur-[50px] md2:border-b',
          isNewMenuEnabled &&
            'border-x-0 border-b border-white/5 md2:mt-1 md2:rounded-t-[20px] md2:border-y md2:bg-white/3',
          isThemeEnabled && 'bg-vault_background md2:bg-vault_text/3',
        )}
        withBottomNavigator={showVaultNav}
        messageChannelId={messageChannelId}
        hasChatReadAccess={hasChatReadAccess}
        chatAvailableForFreeUsers={chatAvailableForFreeUsers}
        vaultId={undefined}
        childrenContainerClassName={childrenContainerClassName}
        contentClassName={
          isThemeEnabled ? 'md2:bg-vault_text/3' : isNewMenuEnabled ? 'md2:bg-white/3' : undefined
        }
      >
        {children}
      </DefaultLayout>
    );
  },
);

type Props = {
  children: ReactNode;
  artistName?: string;
  contentCount?: number;
  artistProfileImage?: string;

  secondaryFooter?: ReactNode;
  nonScrollingChildren?: ReactNode;
  activeSubscriptionFeatures: TierFeatures;
  vaultId: string | undefined;
  messageChannelId: string | undefined;
  chatAvailableForFreeUsers: boolean;
};

export const NewMessageLayout = ({
  secondaryFooter,
  children,
  artistName,
  nonScrollingChildren,
  artistProfileImage,
  activeSubscriptionFeatures,
  vaultId,
  messageChannelId,
  chatAvailableForFreeUsers,
}: Props) => {
  const { pathname } = useLocation();
  const viewHeaderRef = useRef<HTMLDivElement>(null);

  const { isDesktop } = useWindow();
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);
  const showVaultNav = !isDesktop || !isNewMenuEnabled;

  const { track, playing } = useAudioController();
  const { percentComplete } = useAudioPosition();
  const strokeDashoffset = isSafari
    ? ((100 - percentComplete) * 2 * 14 * Math.PI) / 100
    : -((100 - percentComplete) * 2 * 14 * Math.PI) / 100;

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  useEffect(() => {
    setHideAudioPlayer(!isDesktop);

    return () => {
      setHideAudioPlayer(false);
    };
  }, [isDesktop]);

  const onPlayClick = () => {
    track != null &&
      trackEvent({
        type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
        properties: {
          trackId: track.id,
          percentComplete,
          artistId: track.vault.artist?.id,
          vaultId: track.vault.id,
          isPreview: !track.isFullVersionAvailable,
          component: 'message_channel_layout',
        },
        pathname,
      });

    togglePlayPause();
  };

  return (
    <View
      className={twMerge(
        'flex h-full w-full select-none flex-col items-center overflow-y-clip overscroll-none',
        isThemeEnabled ? 'bg-vault_background text-vault_text' : 'text-white',
      )}
    >
      <ViewHeader
        ref={viewHeaderRef}
        center={
          <HeaderCenter
            artistName={artistName}
            artistProfileImage={artistProfileImage}
            activeSubscriptionFeatures={activeSubscriptionFeatures}
          />
        }
        right={
          track != null ? (
            <View
              className="relative flex h-[30px] w-[30px] cursor-pointer select-none items-center justify-center overflow-hidden rounded-full"
              onClick={onPlayClick}
            >
              <svg className="absolute" height={30} width={30}>
                <circle
                  r="14"
                  cx={15}
                  cy={15}
                  strokeWidth={3}
                  className={isThemeEnabled ? 'stroke-vault_accent' : 'stroke-yellow100'}
                  fillOpacity={0}
                  strokeDasharray={2 * 14 * Math.PI}
                  transform="rotate(-90 15 15)"
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
              <PlayButtonView
                isPlaying={playing}
                isDisabled={false}
                size={24}
                className={isThemeEnabled ? 'text-vault_text' : undefined}
              />
            </View>
          ) : (
            <View className="w-[50px]" />
          )
        }
        className={twMerge(
          'select absolute top-0 z-above3 w-full pt-6',
          'border-b border-solid md2:w-[600px] md2:border-b md2:border-l md2:border-r',
          isNewMenuEnabled
            ? isThemeEnabled
              ? 'border-b border-vault_text/5 bg-vault_background md2:mt-1 md2:rounded-t-[20px] md2:border-y md2:bg-vault_text/3 md2:max-lt:ml-[312px]'
              : 'border-b border-white/5 md2:mt-1 md2:rounded-t-[20px] md2:border-y md2:bg-white/3 md2:max-lt:ml-[312px]'
            : 'border-b-base700 md2:border-[#404040]',
        )}
        gridClassName="flex"
        leftClassName="mr-4"
        centerClassName="flex-1 justify-start"
      />

      <View
        className={twMerge(
          'flex h-full w-full flex-col overflow-y-clip overscroll-y-none md2:w-[600px] md2:border-0 md2:border-l md2:border-r md2:border-solid',
          isNewMenuEnabled
            ? isThemeEnabled
              ? 'mt-[78px] border-t border-vault_text/5 bg-vault_background md2:mt-[90px] md2:bg-vault_text/3 md2:max-lt:ml-[312px]'
              : 'mt-[78px] border-t border-white/5 md2:mt-[90px] md2:bg-white/3 md2:max-lt:ml-[312px]'
            : 'mt-[82px] md2:border-[#404040]',
        )}
      >
        {children}
        {nonScrollingChildren}
        <View className="z-above4 w-full">{secondaryFooter}</View>
      </View>

      <AudioPlayer withBottomNavigator={showVaultNav} withVaultTheme={isThemeEnabled} />
      {showVaultNav && (
        <View className="w-full md2:hidden">
          <VaultNav
            vaultId={vaultId}
            messageChannelId={messageChannelId}
            hasChatReadAccess={hasChatReadAccess}
            chatAvailableForFreeUsers={chatAvailableForFreeUsers}
            variant="borderless"
            withVaultTheme={isThemeEnabled}
          />
        </View>
      )}
    </View>
  );
};

function HeaderCenter({
  artistName,
  artistProfileImage,
  activeSubscriptionFeatures,
}: {
  artistName?: string;
  artistProfileImage?: string;
  activeSubscriptionFeatures: TierFeatures | null;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View
      className={twMerge(
        'flex cursor-pointer select-none flex-row items-center justify-start gap-3',
        !activeSubscriptionFeatures?.enabledFeatures.ChatRead && 'cursor-default',
      )}
      onClick={
        activeSubscriptionFeatures?.enabledFeatures.ChatRead ? () => navigate('details') : undefined
      }
      containerRef={ref}
    >
      <ArtistProfileImage
        profileImageUrl={artistProfileImage}
        className="h-9 w-9 rounded-full md2:h-10 md2:w-10"
      />
      <View className="flex select-none flex-col items-start">
        {artistName != null ? (
          <>
            <Text
              className={twMerge(
                'line-clamp-1 font-title !text-title-s font-normal md2:!text-title-m',
                isThemeEnabled ? 'text-vault_text' : 'text-base50',
              )}
            >
              {artistName}
            </Text>
            {activeSubscriptionFeatures?.enabledFeatures.ChatRead && (
              <Text
                className={twMerge(
                  'font-base text-[12px]/[14px] font-normal md:text-[14px]/[16px]',
                  isThemeEnabled
                    ? 'text-vault_text/60 hover:text-vault_text/70'
                    : 'text-base400 hover:text-base500',
                  !activeSubscriptionFeatures?.enabledFeatures.ChatRead &&
                    (isThemeEnabled ? 'hover:text-vault_text/60' : 'hover:text-base400'),
                )}
              >
                See details
              </Text>
            )}
          </>
        ) : (
          <>
            <LoadingSkeleton
              className={twMerge('h-[22px] w-[100px]', isThemeEnabled && 'bg-vault_text/10')}
            />
            <LoadingSkeleton
              className={twMerge('mt-1 h-[14px] w-[60px]', isThemeEnabled && 'bg-vault_text/10')}
            />
          </>
        )}
      </View>
    </View>
  );
}
