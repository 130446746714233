import type { FC, ReactNode } from 'react';
import { TrackPlaySessionReporter } from '../audio/playSession';
import { TrackUploadingTracker } from '../components/views/TrackUploadTracker';
import { SoundStatsigProvider } from '../contexts/Statsig';
import { MediaQuery } from '../contexts/mediaQuery';
import { VaultMessageChannelProvider } from '../hooks/useVaultMessageChannel';
import { AuthProvider } from './AuthProvider';
import { BottomsheetProvider, ExtraBottomsheetProvider } from './BottomsheetProvider';
import { MenuProvider } from './MenuProvider';
import { ModalProvider } from './ModalProvider';
import { OverlayProvider } from './OverlayProvider';
import { PrivyLoginProvider } from './PrivyLoginProvider';
import { ToastProvider } from './ToastProvider';

export const MainProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <PrivyLoginProvider>
      <SoundStatsigProvider>
        <ToastProvider>
          <AuthProvider>
            <MediaQuery.Provider>
              <VaultMessageChannelProvider>
                <ModalProvider>
                  <ExtraBottomsheetProvider>
                    <OverlayProvider>
                      <BottomsheetProvider>
                        <MenuProvider>
                          <TrackPlaySessionReporter />
                          {children}
                          <TrackUploadingTracker />
                        </MenuProvider>
                      </BottomsheetProvider>
                    </OverlayProvider>
                  </ExtraBottomsheetProvider>
                </ModalProvider>
              </VaultMessageChannelProvider>
            </MediaQuery.Provider>
          </AuthProvider>
        </ToastProvider>
      </SoundStatsigProvider>
    </PrivyLoginProvider>
  );
};
