import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faMobile, faPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFolderPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Text } from '../../components/common/Text';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useWindow } from '../../hooks/useWindow';
import {
  FOLDER_TITLE_MAX_LENGTH,
  FOLDER_TITLE_MIN_LENGTH,
  useVaultFolder,
} from '../../hooks/vault/useVaultFolder';

import { artistNavigationPath } from '../../utils/navigationUtils';
import { TextInputModal } from '../bottomsheets/TextInputModal';
import { View } from '../common/View';
import { UploadButton } from '../layouts/ArtistLayout';

export const CreateButton = ({
  artistHandle,
  mainVaultId,
}: {
  artistHandle: string | undefined;
  mainVaultId: string;
}) => {
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();
  const { isDesktop } = useWindow();
  const { folderId } = useParams();
  const location = useLocation();
  const isInChat = location.pathname.includes('/chat');

  const { value: foldersEnabled } = useGate(FEATURE_GATES.FOLDERS);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { createFolder } = useVaultFolder({
    vaultId: mainVaultId,
  });

  const onCreateFolderClick = useCallback(() => {
    if (artistHandle == null || mainVaultId == null) return;
    closeBottomsheet();
    openOverlay(
      <TextInputModal
        title="Create New Folder"
        confirmText="Create"
        placeholder="Untitled"
        minLength={FOLDER_TITLE_MIN_LENGTH}
        maxLength={FOLDER_TITLE_MAX_LENGTH}
        onConfirm={async text => {
          await createFolder({
            title: text,
            folderId,
            onSuccess: () => {
              if (isInChat) {
                navigate(artistNavigationPath(artistHandle, ''));
              }
              closeOverlay();
            },
          });
        }}
      />,
    );
  }, [
    artistHandle,
    closeBottomsheet,
    closeOverlay,
    createFolder,
    folderId,
    isInChat,
    mainVaultId,
    navigate,
    openOverlay,
  ]);

  const uploadButton =
    !!artistHandle && !!mainVaultId ? (
      <UploadButton
        folderId={folderId ?? null}
        customLabel="Upload media"
        artistLinkValue={artistHandle}
        vaultId={mainVaultId}
        showIcon
        className={twMerge(
          'flex w-full cursor-pointer items-center justify-start gap-1.5 self-stretch rounded-b-none rounded-t-lg border-none p-4 font-normal outline-none md2:font-medium',
          isThemeEnabled
            ? 'hover:bg-vault_text/ bg-vault_text/10 text-vault_text'
            : 'bg-base800 hover:bg-white/10',
        )}
        onNavigate={() => {
          closeBottomsheet();
        }}
      />
    ) : null;

  const onPlusClick = useStableCallback(() => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CUSTOM,
      shared: {
        withVaultTheme: isThemeEnabled,
      },
      customBottomsheetProps: {
        body: (
          <View className="flex flex-col gap-0.5">
            {uploadButton}
            <button
              onClick={() => {
                if (!artistHandle) return;
                navigate(artistNavigationPath(artistHandle, '/announcements/create'));
                closeBottomsheet();
              }}
              className={twMerge(
                'flex w-full cursor-pointer items-center gap-1.5 self-stretch rounded-b-lg border-none p-4 outline-none',
                isThemeEnabled
                  ? 'hover:bg-vault_text/ bg-vault_text/10 text-vault_text'
                  : 'bg-base800 hover:bg-white/10',
                foldersEnabled && 'rounded-b-none',
              )}
            >
              <FontAwesomeIcon icon={faMobile} fontSize={16} className="mr-2 aspect-square" />
              <Text className="font-title !text-title-s">Text members</Text>
            </button>
            {foldersEnabled && (
              <button
                onClick={onCreateFolderClick}
                className={twMerge(
                  'flex w-full cursor-pointer items-center gap-1.5 self-stretch rounded-b-lg border-none p-4 outline-none',
                  isThemeEnabled
                    ? 'hover:bg-vault_text/ bg-vault_text/10 text-vault_text'
                    : 'bg-base800 hover:bg-white/10',
                )}
              >
                <FontAwesomeIcon icon={faFolderPlus} fontSize={16} className="mr-2 aspect-square" />
                <Text className="font-title !text-title-s">Create folder</Text>
              </button>
            )}
          </View>
        ),
      },
    });
  });

  return (
    <>
      {/* mobile */}
      <button
        onClick={onPlusClick}
        className={twMerge(
          'h-12 w-12 appearance-none rounded-full border-none outline-none hover:cursor-pointer hover:opacity-90 md2:hidden',
          isThemeEnabled ? 'bg-vault_accent text-vault_accent_text' : 'bg-yellow100 text-black',
        )}
      >
        <FontAwesomeIcon icon={faPlus} fontSize={18} />
      </button>

      {/* desktop */}
      {isDesktop && (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="h-12 w-12 appearance-none rounded-full border-none bg-yellow100 outline-none hover:cursor-pointer hover:opacity-90">
            <FontAwesomeIcon icon={faPlus} fontSize={18} className="text-black" />
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              align="end"
              sideOffset={8}
              className="z-stickyHeader flex w-fit flex-col gap-0.5 rounded-lg bg-base900 md2:min-w-[302px] "
            >
              <DropdownMenu.Item asChild>{uploadButton}</DropdownMenu.Item>

              <DropdownMenu.Item
                onClick={() => {
                  if (!artistHandle) return;
                  navigate(artistNavigationPath(artistHandle, '/announcements/create'));
                }}
                className={twMerge(
                  'flex cursor-pointer items-center gap-1.5 self-stretch  border-none bg-base800 p-4 outline-none hover:bg-base700',
                  foldersEnabled ? '' : 'rounded-b-lg ',
                )}
              >
                <FontAwesomeIcon icon={faMobile} fontSize={16} className="mr-2" />
                <Text className="font-title text-title-s text-white">Text members</Text>
              </DropdownMenu.Item>
              {foldersEnabled && (
                <DropdownMenu.Item
                  onClick={onCreateFolderClick}
                  className="flex cursor-pointer items-center gap-1.5 self-stretch rounded-b-lg border-none bg-base800 p-4 outline-none hover:bg-base700"
                >
                  <FontAwesomeIcon icon={faFolderPlus} fontSize={16} className="mr-2" />
                  <Text className="font-title text-title-s text-white">Create Folder</Text>
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      )}
    </>
  );
};
