import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { GIPHY_LOGO } from '../../constants/imageConstants';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const GifAttachment = ({
  isAuthor,
  gif,
  type,
}: {
  isAuthor: boolean | undefined;
  gif: { id: string; url: string; title: string; aspectRatio: number };
  type: 'default' | 'elevated';
}) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View className="pointer-events-none flex max-w-[200px] select-none flex-col gap-1.5 pt-1">
      {type === 'default' && (
        <View
          className={twMerge(
            'flex flex-row items-center gap-1',
            isAuthor ? 'justify-end' : 'justify-start',
          )}
        >
          <Image className="h-2.5 w-2" src={GIPHY_LOGO} alt="" />
          <Text
            className={twMerge(
              '!text-base-xs',
              isThemeEnabled ? 'text-vault_text/50' : 'text-base500',
            )}
          >
            Powered By GIPHY
          </Text>
        </View>
      )}
      <Image
        key={gif.id}
        className="pointer-events-none select-none rounded-lg"
        src={gif.url}
        alt={gif.title}
        style={{ aspectRatio: gif.aspectRatio }}
      />
    </View>
  );
};
