import type { FC } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faCircleUser } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { DEFAULT_AVATAR } from '../../constants/imageConstants';
import { Image } from '../common/Image';

type Props = {
  className?: string;
  profileImageUrl?: string | null;
  onClick?: () => void;
  withVaultTheme: boolean;
};

const UserProfileImage: FC<Props> = ({ className, profileImageUrl, onClick, withVaultTheme }) => {
  return withVaultTheme && !profileImageUrl ? (
    <FontAwesomeIcon
      icon={faCircleUser}
      className={twMerge('aspect-square rounded-[50%]', className)}
    />
  ) : (
    <Image
      src={profileImageUrl ?? DEFAULT_AVATAR}
      alt="User avatar"
      className={twMerge('aspect-square rounded-[50%]', className)}
      onClick={onClick}
    />
  );
};

export { UserProfileImage };
