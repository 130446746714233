import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ImagesAttachment } from '../../contexts/ImagesMessageAttachmentContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { VideoAttachment } from '../../contexts/VideoMessageAttachmentContext';
import { View } from '../common/View';
import { MediaViewer } from '../message/MediaViewer';
import { UploadedMedia } from './UploadedMedia';

export const HorizontalMessageMediaCarousel = ({ artistName }: { artistName: string }) => {
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { video } = useSnapshot(VideoAttachment);
  const { images } = useSnapshot(ImagesAttachment);

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const mediaItems = [...images, ...(video ? [video] : [])];

  if (mediaItems.length === 0) {
    return null;
  }

  const onViewMedia = (startAt: number) => {
    const medias = mediaItems.map(media => ({
      id: media.identifier,
      url: media.cdnUrl || media.objectUrl,
      type: media.type,
    }));
    openOverlay(
      <MediaViewer startAt={startAt} title={artistName} medias={medias} onClose={closeOverlay} />,
    );
  };

  return (
    <View
      className={twMerge(
        'no-scrollbar flex flex-row gap-4 border-0 border-t border-solid px-4 pt-3',
        isThemeEnabled ? 'border-vault_text/5' : 'border-base700',
      )}
    >
      {mediaItems.map((media, index) => (
        <View key={media.identifier} className="aspect-square shrink-0 rounded-md">
          <UploadedMedia
            identifier={media.identifier}
            type={media.type}
            cdnUrl={media.cdnUrl}
            objectUrl={media.objectUrl}
            progress={media.progress}
            uploaded={media.uploaded}
            onView={() => onViewMedia(index)}
          />
        </View>
      ))}
    </View>
  );
};
