import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { ModalType } from '../../../constants/modalConstants';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { useModal } from '../../../contexts/ModalContext';
import { useWindow } from '../../../hooks/useWindow';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { CustomizeVaultModal } from '../../modals/CustomizeVaultModal';
import { CustomizeVaultForm } from './CustomizeVaultForm';

export function CustomizeVaultMenu({ vaultId }: { vaultId: string }) {
  const { isVaultCustomizeOpen, closeVaultCustomization, isClosingCustomizeMenu } =
    useMenuContainer();
  const { isDesktop } = useWindow();
  const { openModal, closeModalByType } = useModal();

  const navigate = useNavigate();

  const formRef = useRef<{ submit: () => void }>(null);
  const [isActionPending, setActionPending] = useState(false);

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  useEffect(() => {
    if (!isDesktop) {
      openModal(ModalType.CUSTOMIZE_VAULT, {
        body: <CustomizeVaultModal vaultId={vaultId} />,
      });
    } else {
      closeModalByType(ModalType.CUSTOMIZE_VAULT);
    }
  }, [closeModalByType, closeVaultCustomization, isDesktop, navigate, openModal, vaultId]);

  if (!isVaultCustomizeOpen || !isDesktop) {
    return null;
  }

  return (
    <View
      className={twMerge(
        'no-scrollbar absolute bottom-0 left-0 top-0 box-border flex flex-col',
        'z-above4 items-start overflow-y-scroll bg-black',
        'animate-openMenu border-0 border-r border-solid border-r-white/5 p-10 md:w-[389px]',
        isClosingCustomizeMenu && 'animate-closeMenu',
      )}
    >
      <View className="mb-6 flex w-full flex-row items-center justify-between">
        <Text className="font-title text-title-l text-white">Customize vault</Text>
        <Button
          leadingIcon={faXmark}
          iconOnly
          onClick={() => closeVaultCustomization({ showConfirm: true })}
          disabled={isActionPending}
          label="Close"
          className="text-[24px] text-white"
          disabledClassName="opacity-50 cursor-not-allowed"
        />
      </View>
      <View className="flex h-full w-full flex-col justify-between">
        <CustomizeVaultForm vaultId={vaultId} setActionPending={setActionPending} ref={formRef} />
        <Button
          buttonType="submit"
          type="primary"
          label="Save"
          onClick={handleSubmit}
          disabled={isActionPending}
          disabledClassName="opacity-50"
          className="mt-6 w-full bg-white"
        />
      </View>
    </View>
  );
}
