import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { resetActiveTrack } from '../../audio/AudioController';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { TrackUploadView } from '../../components/views/TrackUploadView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { TrackUpload, useTrackUpload } from '../../contexts/TrackUploadContext';
import { VaultContentAccessFeatureInput } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';

export function TrackUploadPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openBottomsheet } = useBottomsheetContainer();

  const { artistHandle } = useArtistHandle();
  const { fileRef, isPromptOpen } = useTrackUpload();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);
  useVaultTheme();

  const media = fileRef.current;

  useEffect(() => {
    if (!artistHandle) {
      navigate(ROUTES.NOT_FOUND);
      return;
    }

    if (!media && !isPromptOpen) {
      if (
        (window.history.state.idx != null && window.history.state.idx > 0) ||
        (window.history?.length && window.history.length > 1)
      ) {
        navigate(-1);
      } else {
        navigate(artistNavigationPath(artistHandle, '/'));
      }
    }
  }, [artistHandle, media, navigate, isPromptOpen]);

  useEffect(() => {
    return () => {
      resetActiveTrack();
    };
  }, []);

  const onBackClick = useStableCallback(() => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: { bottomsheetType: BOTTOMSHEET_TYPES.EXIT_FLOW, event: EVENTS.UPLOAD_TRACK },
      pathname,
    });

    openBottomsheet({
      type: 'EXIT_FLOW',
      exitFlowBottomsheetProps: {
        onConfirm: () => {
          TrackUpload.isUploadingTrack = false;
          TrackUpload.isPromptOpen = false;
          TrackUpload.fileRef.current = null;
          TrackUpload.title = '';
          TrackUpload.shouldSendSms = false;
          TrackUpload.caption = '';
          TrackUpload.notificationMessage = '';
          TrackUpload.featureAccessType = VaultContentAccessFeatureInput.PaidVaultContent;
        },
        event: EVENTS.UPLOAD_TRACK,
      },
    });
  });

  return (
    <DefaultLayout
      withVaultTheme={isThemeEnabled}
      showRoundedTop={isThemeEnabled}
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName={twMerge(
        isThemeEnabled &&
          'bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5',
      )}
      headerLeft={
        <BackButton
          onClick={onBackClick}
          className={isThemeEnabled ? 'text-vault_text' : undefined}
        />
      }
      headerCenter={
        <Text
          className={twMerge(
            'font-title !text-title-m font-medium',
            isThemeEnabled ? 'text-vault_text' : 'text-white',
          )}
        >
          {media == null ? '' : `Add ${media.contentType.toLowerCase()}`}
        </Text>
      }
      contentClassName={isThemeEnabled ? 'md2:bg-vault_text/3' : undefined}
      showBorder
      stretch
    >
      <TrackUploadView />
    </DefaultLayout>
  );
}
