import { createContext, useContext } from 'react';

const InputContext = createContext<{
  error?: boolean;
  disabled?: boolean;
}>({
  error: false,
  disabled: false,
});

function useInputContext() {
  const context = useContext(InputContext);
  if (!context) {
    throw new Error(`Input compound components cannot be rendered outside the Input component`);
  }

  return context;
}

export { InputContext, useInputContext };
