import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faPauseCircle } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPlayCircle } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import type { OnClickType } from '../buttons/Button';

export function LockedPlayButton() {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <div
      className={twMerge(
        'flex h-[24px] w-[24px] items-center justify-center rounded-full p-2',
        isThemeEnabled ? 'bg-vault_text' : 'bg-neutral50',
      )}
    >
      <FontAwesomeIcon
        icon={faLock}
        className={twMerge(
          'flex h-[18px] w-[18px] items-center',
          isThemeEnabled ? 'text-vault_text_opposite' : 'text-black',
        )}
        fill="true"
      />
    </div>
  );
}

export function PlayButtonView({
  isPlaying,
  onClick,
  isDisabled,
  size = 40,
  className,
}: {
  isPlaying: boolean;
  onClick?: OnClickType;
  isDisabled: boolean;
  size?: number;
  className?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      className={twMerge(!isDisabled && 'cursor-pointer')}
      onClick={isDisabled ? undefined : onClick}
    >
      <FontAwesomeIcon
        icon={isPlaying ? faPauseCircle : faPlayCircle}
        className={twMerge('flex items-center', className)}
        fill="true"
      />
    </svg>
  );
}
