import { type FC, useMemo } from 'react';
import { isIOS, isMobile, isMobileSafari } from 'react-device-detect';
import { useLocation } from 'react-router';
import { useSwipeable } from 'react-swipeable';
import { twMerge } from 'tailwind-merge';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faShuffle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faRepeat } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faBackwardStep,
  faChevronDown,
  faForwardStep,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { cycleRepeatMode, useAudioController } from '../../audio/AudioController';
import { pause, togglePlayPause } from '../../audio/AudioEngineHTML5';
import { RepeatMode } from '../../audio/AudioMeta';
import { useAudioPosition } from '../../audio/AudioPosition';
import { goToNextTrack, goToPrevTrack, toggleShuffleEnabled } from '../../audio/AudioQueue';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { type TrackContentByIdQuery, VaultType } from '../../graphql/generated';
import { useUpsellInterstitials } from '../../hooks/useUpsellInterstitials';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import type { TypeFromGraphQLUnion } from '../../types/gql';
import { trackEvent } from '../../utils/analyticsUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { Button } from '../buttons/Button';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { Marquee } from '../common/Marquee';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { PlayButtonView } from './PlayButtonView';
import { SpinningCD } from './SpinningCD';
import { Timeline } from './Timeline';
import { TrackCommentPreviewRow } from './TrackCommentPreviewRow';

type Props = {
  closeFullScreen: () => void;
  content: TypeFromGraphQLUnion<
    NonNullable<TrackContentByIdQuery['vaultContentById']>,
    'VaultTrack'
  >;
  isClosingFullscreen: boolean;
  withVaultTheme: boolean;
};

const FullscreenAudioPlayer: FC<Props> = ({
  content,
  closeFullScreen,
  isClosingFullscreen,
  withVaultTheme,
}) => {
  const { pathname } = useLocation();
  const { playing } = useAudioController();

  const {
    id: trackId,
    vault: { id: vaultId, artist, price, type },
    title,
    linkValue,
    snippetVideo,
    isFullVersionAvailable,
  } = content;

  const vaultPrice = price ?? DEFAULT_PRICE;

  const { loginStatus, loggedInUser } = useAuthContext();
  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();
  const { openBottomsheet } = useBottomsheetContainer();

  const handlers = useSwipeable({
    onSwipedDown: () => {
      trackEvent({
        type: EVENTS.CLOSE_FULLSCREEN_AUDIO,
        properties: {
          trackId,
          trackTitle: title ?? undefined,
          vaultId: vaultId,
          artistId: artist?.id ?? null,
          onSwipe: true,
        },
        pathname,
      });

      closeFullScreen();
    },
    trackMouse: true,
    swipeDuration: 200,
    delta: 200,
  });

  const link = useMemo(() => {
    const trackLink = linkValue ? `/t/${linkValue}` : `/${trackId}`;

    return generateShareLink({
      artistLinkValue: artist?.linkValue,
      path: trackLink,
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [linkValue, trackId, artist?.linkValue, loggedInUser?.inviteCode]);

  const artistName = artist?.name ?? 'vault';

  const showUpsellInterstitial =
    upsellInterstitials?.firstSnippetShare === false &&
    isMobile &&
    isIOS &&
    !wasShowedInSession &&
    loginStatus === LoginStatus.LOGGED_IN;

  return (
    <View
      className={twMerge(
        'no-scrollbar fixed bottom-0 top-0 z-audioPlayerContainer flex h-full w-full origin-bottom flex-col overflow-x-clip overflow-y-scroll overscroll-none sm:w-full',
        withVaultTheme ? 'bg-vault_background' : 'bg-base900',
        isClosingFullscreen ? 'animate-closeFullscreenPlayer' : 'animate-openFullscreenPlayer',
      )}
      swipeableHandlers={handlers}
    >
      <ViewHeader
        left={
          <Button
            label=""
            leadingIcon={faChevronDown}
            className={twMerge('text-[24px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
            onClick={closeFullScreen}
            event={{
              type: EVENTS.CLOSE_FULLSCREEN_AUDIO,
              properties: {
                trackId: trackId,
                trackTitle: title ?? undefined,
                vaultId: vaultId,
                artistId: artist?.id ?? null,
              },
            }}
          />
        }
        right={
          <ShareButton
            large
            link={link}
            withVaultTheme={withVaultTheme}
            artistName={artistName}
            className={withVaultTheme ? 'text-vault_text' : undefined}
            onClick={() => {
              if (!!snippetVideo) {
                pause();

                if (showUpsellInterstitial) {
                  openBottomsheet({
                    type: 'SHARE_SNIPPET_INTERSTITIAL',
                    shareSnippetInterstitialBottomsheetProps: {
                      snippetVideo: snippetVideo.url,
                      trackId: trackId,
                      onContinue: () => {
                        openBottomsheet({
                          type: BOTTOMSHEET_TYPES.SHARE_SNIPPET,
                          shared: {
                            withVaultTheme,
                          },
                          shareSnippetBottomsheetProps: {
                            artistName,
                            link,
                            withVaultTheme,
                          },
                        });
                      },
                    },
                  });

                  trackEvent({
                    type: EVENTS.OPEN_BOTTOMSHEET,
                    properties: {
                      bottomsheetType: 'SHARE_SNIPPET_INTERSTITIAL',
                      entity: 'song',
                      trackId: trackId,
                    },
                    pathname,
                  });

                  return;
                }

                openBottomsheet({
                  type: BOTTOMSHEET_TYPES.SHARE_SNIPPET,
                  shared: {
                    withVaultTheme,
                  },
                  shareSnippetBottomsheetProps: {
                    artistName,
                    link,
                    withVaultTheme,
                  },
                });
              } else {
                openBottomsheet({
                  type: BOTTOMSHEET_TYPES.SHARE,
                  shared: {
                    withVaultTheme,
                  },
                  shareBottomsheetProps: {
                    link,
                    artistName,
                    withVaultTheme,
                  },
                });
              }
            }}
          />
        }
        className={twMerge(
          'sticky top-0 z-previewFrame w-full pt-5',
          withVaultTheme ? 'bg-vault_background' : 'bg-base900',
        )}
      />
      <View
        className={twMerge(
          'flex h-[calc(100vh-160px)] flex-col items-center justify-end overflow-clip',
          isMobileSafari && 'h-[calc(100vh-200px)]',
        )}
      >
        <SpinningCD isPlaying={playing} trackId={trackId} />
        <View
          className={twMerge(
            'z-above1 box-border w-full bg-gradient-to-b from-transparent to-50% px-4 pt-5',
            withVaultTheme ? 'to-vault_background' : 'to-base900',
          )}
        >
          <View className="mb-2 flex w-full flex-col">
            {!isFullVersionAvailable && (
              <Text
                className={twMerge(
                  'mb-1 flex h-5 w-fit items-center justify-center rounded-full border-[1px] border-solid px-2 !text-base-xs',
                  withVaultTheme ? 'border-vault_text text-vault_text' : 'border-white text-white',
                )}
              >
                Snippet
              </Text>
            )}
            <View
              className={twMerge(
                'flex w-full items-center font-title !text-title-xl font-medium',
                withVaultTheme ? 'text-vault_text' : 'text-white',
              )}
            >
              <Marquee className="z-above1 flex">{title}</Marquee>
            </View>
          </View>
          <Text
            className={twMerge(
              'z-above1 mb-[14px] w-full font-base !text-base-l font-medium',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          >
            {artist?.name ?? 'Unnamed'}
          </Text>
          <Timeline
            type="fullscreen"
            className="mb-[10px]"
            timestampPosition="bottom"
            timestampClassName={withVaultTheme ? 'text-vault_text' : undefined}
            withVaultTheme={withVaultTheme}
          />
          <ControlButtons withVaultTheme={withVaultTheme} large />
          {type !== VaultType.FreeOnly && !isFullVersionAvailable && artist && (
            <SubscribeButton
              label={`Unlock all songs for $${vaultPrice}/month`}
              className={twMerge(
                'mt-8 w-full !text-base-l',
                withVaultTheme ? 'bg-vault_accent text-vault_accent_text' : 'text-base900',
              )}
              linkValue={artist.linkValue}
              artistAvatarUrl={artist.profileImage?.url}
              price={vaultPrice}
              vaultId={vaultId}
              showBottomSheet={false}
              component="audio_player"
              onClick={closeFullScreen}
            />
          )}
        </View>
      </View>
      <View
        className={twMerge(
          'z-above1 min-h-[160px] w-full flex-1 border-0 border-t border-solid pt-[18px] outline-none',
          withVaultTheme
            ? 'border-vault_background bg-vault_background'
            : 'border-base900 bg-base900',
        )}
      >
        <TrackCommentPreviewRow track={content} withVaultTheme={withVaultTheme} />
      </View>
    </View>
  );
};

export const ControlButtons = ({
  large = false,
  className,
  withVaultTheme,
}: {
  large?: boolean;
  className?: string;
  withVaultTheme: boolean;
}) => {
  const { pathname } = useLocation();

  const {
    isNextTrackDisabled,
    playing,
    loading,
    loadingActiveTrack,
    disableNextPrev,
    playbackTrackIds,
    activeTrackId,
    track,
    shuffleEnabled,
    repeatMode,
  } = useAudioController();
  const { percentComplete } = useAudioPosition();

  const isNextDisabled =
    isNextTrackDisabled ||
    loading ||
    loadingActiveTrack ||
    disableNextPrev ||
    playbackTrackIds.length < 2;

  const isPreviousDisabled =
    loading ||
    loadingActiveTrack ||
    disableNextPrev ||
    playbackTrackIds.length < 2 ||
    activeTrackId === playbackTrackIds[0];

  return (
    <View className={twMerge('flex w-full flex-row justify-between md2:justify-center', className)}>
      <View className="flex flex-row items-center justify-start md2:pr-8">
        <Button
          label=""
          iconOnly
          leadingIcon={faShuffle}
          className={twMerge(
            large ? 'text-[24px]' : 'text-[20px]',
            withVaultTheme
              ? shuffleEnabled
                ? 'text-vault_accent'
                : 'text-vault_text'
              : shuffleEnabled
                ? 'text-yellow100'
                : 'text-white',
          )}
          onClick={toggleShuffleEnabled}
        />
      </View>

      {large ? (
        <>
          <View className="flex flex-row items-center justify-end">
            <Button
              label=""
              iconOnly
              leadingIcon={faBackwardStep}
              className={twMerge(
                'text-[32px]',
                withVaultTheme
                  ? isPreviousDisabled
                    ? 'text-vault_text/50'
                    : 'text-vault_text'
                  : isPreviousDisabled
                    ? 'text-base300'
                    : 'text-white',
                isPreviousDisabled && 'opacity-50',
              )}
              onClick={goToPrevTrack}
              disabled={isPreviousDisabled}
              event={
                activeTrackId == null
                  ? undefined
                  : { type: EVENTS.PREV_TRACK, properties: { trackId: activeTrackId } }
              }
            />
          </View>
          <View className="flex flex-row items-center justify-center">
            <PlayButtonView
              onClick={() => {
                track != null &&
                  trackEvent({
                    type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
                    properties: {
                      trackId: track.id,
                      percentComplete,
                      artistId: track.vault.artist?.id,
                      vaultId: track.vault.id,
                      component: 'fullscreen_audio_player',
                      isPreview: !track.isFullVersionAvailable,
                    },
                    pathname,
                  });
                togglePlayPause();
              }}
              isDisabled={loading || loadingActiveTrack}
              isPlaying={playing}
              className={twMerge(withVaultTheme && 'text-vault_text')}
              size={68}
            />
          </View>
          <View className="flex flex-row items-center justify-start">
            <Button
              label=""
              iconOnly
              leadingIcon={faForwardStep}
              className={twMerge(
                'text-[32px]',
                withVaultTheme
                  ? isNextDisabled
                    ? 'text-vault_text/50'
                    : 'text-vault_text'
                  : isNextDisabled
                    ? 'text-base300'
                    : 'text-white',
                isNextDisabled && 'opacity-50',
              )}
              onClick={goToNextTrack}
              disabled={isNextDisabled}
              event={
                activeTrackId == null
                  ? undefined
                  : { type: EVENTS.NEXT_TRACK, properties: { trackId: activeTrackId } }
              }
            />
          </View>
        </>
      ) : (
        <View className="flex flex-row justify-center">
          <View className="flex flex-row items-center justify-end">
            <Button
              label=""
              iconOnly
              leadingIcon={faBackwardStep}
              className={twMerge(
                'text-[20px]',
                withVaultTheme
                  ? isPreviousDisabled
                    ? 'text-vault_text/50'
                    : 'text-vault_text'
                  : isPreviousDisabled
                    ? 'text-base300'
                    : 'text-white',
                isPreviousDisabled && 'opacity-50',
              )}
              onClick={goToPrevTrack}
              disabled={isPreviousDisabled}
              event={
                activeTrackId == null
                  ? undefined
                  : { type: EVENTS.PREV_TRACK, properties: { trackId: activeTrackId } }
              }
            />
          </View>
          <View className="mx-[28px] flex flex-row items-center justify-center">
            <PlayButtonView
              onClick={() => {
                track != null &&
                  trackEvent({
                    type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
                    properties: {
                      trackId: track.id,
                      percentComplete,
                      artistId: track.vault.artist?.id,
                      vaultId: track.vault.id,
                      isPreview: !track.isFullVersionAvailable,
                      component: 'fullscreen_audio_player',
                    },
                    pathname,
                  });
                togglePlayPause();
              }}
              isDisabled={loading || loadingActiveTrack}
              isPlaying={playing}
              size={34}
              className={twMerge(withVaultTheme && 'text-vault_text')}
            />
          </View>
          <View className="flex flex-row items-center justify-start">
            <Button
              label=""
              iconOnly
              leadingIcon={faForwardStep}
              className={twMerge(
                'text-[20px]',
                withVaultTheme
                  ? isNextDisabled
                    ? 'text-vault_text/50'
                    : 'text-vault_text'
                  : isNextDisabled
                    ? 'text-base300'
                    : 'text-white',
                isNextDisabled && 'opacity-50',
              )}
              onClick={goToNextTrack}
              disabled={isNextDisabled}
              event={
                activeTrackId == null
                  ? undefined
                  : { type: EVENTS.NEXT_TRACK, properties: { trackId: activeTrackId } }
              }
            />
          </View>
        </View>
      )}

      <View className="relative flex flex-row items-center justify-start md2:pl-8">
        <Button
          label=""
          iconOnly
          leadingIcon={faRepeat}
          className={twMerge(
            'relative',
            large ? 'text-[24px]' : 'text-[20px]',
            withVaultTheme
              ? repeatMode === RepeatMode.NO_REPEAT
                ? 'text-vault_text'
                : 'text-vault_accent'
              : repeatMode === RepeatMode.NO_REPEAT
                ? 'text-white'
                : 'text-yellow100',
          )}
          onClick={cycleRepeatMode}
        />
        {repeatMode === RepeatMode.REPEAT_ONE && (
          <View
            className={twMerge(
              'absolute flex items-center justify-center rounded-full text-center',
              withVaultTheme ? 'bg-vault_text' : 'bg-white',
              large
                ? ' right-[-2px] top-5 h-[13px] w-[13px]'
                : ' right-[-3px] top-[5px] h-[11px] w-[11px]',
            )}
          >
            <Text
              className={twMerge(
                'font-base text-[9px] font-bold',
                withVaultTheme ? 'text-vault_text_opposite' : 'text-black',
              )}
            >
              1
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export const ShareButton: FC<{
  link: string;
  large?: boolean;
  className?: string;
  artistName: string;
  onClick?: () => void;
  withVaultTheme: boolean;
}> = ({ link, large = false, className, artistName, onClick, withVaultTheme }) => {
  const { openBottomsheet } = useBottomsheetContainer();

  const handleClick = useMemo(() => {
    if (onClick) {
      return onClick;
    }

    return () => {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.SHARE,
        shared: {
          withVaultTheme,
        },
        shareBottomsheetProps: {
          link,
          artistName,
          withVaultTheme,
        },
      });
    };
  }, [artistName, link, onClick, openBottomsheet, withVaultTheme]);

  return (
    <Button
      iconOnly
      className={twMerge('text-white', large ? 'text-[24px]' : 'text-[16px]', className)}
      label=""
      leadingIcon={faArrowUpFromBracket}
      onClick={handleClick}
    />
  );
};

export { FullscreenAudioPlayer };
