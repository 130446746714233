import { useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns-tz/format';
import millify from 'millify';
import { useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faUsers } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCalendar } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { formatSelectedDate, getZonedDate } from '../../../components/announcement/helpers';
import { useAnnouncementForm } from '../../../components/announcement/useAnnouncementForm';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ScheduledEventAccessFeatureInput } from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useMySubscribers } from '../../../hooks/useMySubscribers';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { artistNavigationPath } from '../../../utils/navigationUtils';

export function ReviewAnnouncement() {
  const navigate = useNavigate();
  const onBackClick = useStableCallback(() => {
    navigate(-1);
  });
  const { loggedInUser } = useAuthContext();
  const artistId = loggedInUser?.artist?.id;

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  useVaultTheme();

  //get param artistHandle
  const { artistHandle } = useArtistHandle();
  const { fields, enableSubmit, onSubmit, clearFields, isSubmitting } = useAnnouncementForm();
  const { subscribersSummary, isLoadingSummary } = useMySubscribers();

  const sharedStyles = {
    iconItem: 'flex-row gap-3 !items-center',
    item: twMerge(
      'flex items-start self-stretch rounded-md border border-solid p-4',
      isThemeEnabled ? 'border-vault_text/5' : 'border-base700',
    ),
    mainText: twMerge(
      'font-base !text-base-l font-normal',
      isThemeEnabled ? '!text-vault_text' : '!text-base50',
    ),
    subText: twMerge(
      'font-base !text-base-l font-normal',
      isThemeEnabled ? 'text-vault_text/50' : 'text-base500',
    ),
    textWrapper: 'flex flex-col gap-0.5',
  };

  const paidSubsCount =
    subscribersSummary?.data.mySubscribersSummary.activePaidSubscriptionsCount ?? 0;
  const activeSubsCount =
    subscribersSummary?.data.mySubscribersSummary.activeSubscriptionsCount ?? 0;

  const subscriberCount =
    fields.accessType === ScheduledEventAccessFeatureInput.FreeEvent
      ? activeSubsCount
      : paidSubsCount;

  const currDate = useMemo(() => new Date(), []);

  const timeZone = useMemo(() => {
    return format(getZonedDate(currDate), 'z');
  }, [currDate]);

  const formattedDate = formatSelectedDate(fields.date);

  const onClickSubmit = useStableCallback(async () => {
    if (!artistHandle || !artistId) return;
    await onSubmit({
      artistHandle,
      artistId,
    });
    clearFields();
  });

  const buttonLabel = `${formattedDate === 'Now' ? 'Send' : 'Schedule'} SMS`;

  useEffect(() => {
    //redirect to create page if announcement is not set
    if (!fields.announcement && !isSubmitting) {
      navigate(artistNavigationPath(artistHandle, '/announcements/create'));
    }
  }, [artistHandle, fields, isSubmitting, navigate]);

  return (
    <DefaultLayout
      withVaultTheme={isThemeEnabled}
      showRoundedTop={isThemeEnabled}
      showBorder
      headerLeft={
        <BackButton
          onClick={onBackClick}
          className={isThemeEnabled ? 'text-vault_text' : undefined}
        />
      }
      headerRight={
        <button
          className="hidden appearance-none border-none bg-transparent outline-none hover:cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 disabled:active:opacity-70 md2:block"
          disabled={!enableSubmit}
          onClick={onClickSubmit}
        >
          <Text
            className={twMerge(
              'font-title !text-base-m font-medium active:opacity-70',
              isThemeEnabled ? 'text-vault_accent' : 'text-yellow100',
            )}
          >
            {buttonLabel}
          </Text>
        </button>
      }
      stretch
      headerCenter={
        <Text
          className={twMerge(
            'font-title !text-title-m font-medium',
            isThemeEnabled ? 'text-vault_text' : 'text-white',
          )}
        >
          Review Announcement
        </Text>
      }
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName={twMerge(
        isThemeEnabled &&
          'bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5',
      )}
      contentClassName={isThemeEnabled ? 'md2:bg-vault_text/3' : undefined}
    >
      <View className="flex h-full w-full max-w-[600px] flex-col justify-between gap-4 py-6">
        <View className="flex flex-col gap-4">
          <View className={twMerge(sharedStyles.item, 'min-h-56')}>
            <Text className={twMerge(sharedStyles.mainText, 'w-full break-words')}>
              {fields.announcement}
            </Text>
          </View>
          <View className={twMerge(sharedStyles.item, sharedStyles.iconItem)}>
            <FontAwesomeIcon
              icon={faUsers}
              fontSize={20}
              className={isThemeEnabled ? 'text-vault_text/50' : 'text-base500'}
            />
            <View className={sharedStyles.textWrapper}>
              <Text className={sharedStyles.mainText}>
                {fields.accessType === ScheduledEventAccessFeatureInput.FreeEvent
                  ? 'Free tier and members'
                  : 'Paid members only'}
              </Text>
              {isLoadingSummary ? (
                <Text className={sharedStyles.subText}>...</Text>
              ) : (
                !!subscriberCount && (
                  <Text className={sharedStyles.subText}>
                    {millify(subscriberCount)} fan{subscriberCount > 1 && 's'}
                  </Text>
                )
              )}
            </View>
          </View>

          <View className={twMerge(sharedStyles.item, sharedStyles.iconItem)}>
            <FontAwesomeIcon
              icon={faCalendar}
              fontSize={20}
              className={isThemeEnabled ? 'text-vault_text/50' : 'text-base500'}
            />
            <View className={sharedStyles.textWrapper}>
              <Text className={sharedStyles.mainText}>{formattedDate}</Text>
              <Text className={sharedStyles.subText}>{timeZone}</Text>
            </View>
          </View>
        </View>

        <Button
          type="primary"
          label={buttonLabel}
          className={twMerge(
            '!text-base-3 w-fit self-center disabled:opacity-50 md2:hidden',
            isThemeEnabled
              ? 'bg-vault_accent text-vault_accent_text disabled:text-vault_accent_text'
              : 'disabled:text-black',
          )}
          disabled={!enableSubmit}
          onClick={onClickSubmit}
        />
      </View>
    </DefaultLayout>
  );
}
