export const ONE_DAY_IN_MS = 86400000;
const ONE_MINUTE_IN_MS = 60000;
const ONE_WEEK_IN_MS = 604800000;
const ONE_YEAR_IN_MS = 31557600000;

export function getMonthAndYear(date: Date) {
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  return `${month} ${year}`;
}

export function getMonthAndDate(date: Date) {
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.toLocaleString('default', { day: 'numeric' });

  return `${month} ${day}`;
}

export function getDurationAsTime(duration: number) {
  return `${Math.floor(duration / 60)}:${Math.round(duration % 60)
    .toString()
    .padStart(2, '0')}`;
}

function getDaySuffix(day: number): string {
  if (day > 3 && day < 21) return 'th'; // for 4th to 20th
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function compareDates(date1: Date, date2: Date): string {
  const dayInMillis = 1000 * 60 * 60 * 24;
  const startOfDay1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const startOfDay2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  const differenceInDays = (startOfDay2.getTime() - startOfDay1.getTime()) / dayInMillis;

  if (differenceInDays === 0) {
    return 'Today';
  } else if (differenceInDays === 1) {
    return 'Yesterday';
  } else if (differenceInDays < 7) {
    return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(startOfDay1);
  } else {
    const day = startOfDay1.getDate();
    const month = startOfDay1.toLocaleString('en-US', { month: 'long' });
    const year = date1.getFullYear() < date2.getFullYear() ? `, ${date1.getFullYear()}` : '';
    const daySuffix = getDaySuffix(day);

    return `${month} ${day}${daySuffix}${year}`;
  }
}

export function dateToTime(dateString: string) {
  const date = new Date(dateString);

  const hours = date.getHours();

  const minutes = date.getMinutes();

  const finalHours = hours % 12 || 12;

  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

  const ampm = hours >= 12 ? 'pm' : 'am';

  return `${finalHours}:${minutesFormatted}${ampm}`;
}

export function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

const ONE_HOUR_IN_MS = 3600000;

/**
 * Check if two dates are in the same period of time relative to midnight
 */
export function isSamePeriod(date1: Date, date2: Date, periodLengthInHours: number) {
  if (!isSameDay(date1, date2)) {
    return false;
  }

  const hoursInMs = periodLengthInHours * ONE_HOUR_IN_MS;

  const date1Time = date1.getTime();
  const date1TimeAtMidnight = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
  ).getTime();
  const date2Time = date2.getTime();
  const date2TimeAtMidnight = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
  ).getTime();

  const date1TimeFromMidnight = date1Time - date1TimeAtMidnight;
  const date2TimeFromMidnight = date2Time - date2TimeAtMidnight;

  const timeSegmentStartNumOfHours = Math.floor(date1TimeFromMidnight / hoursInMs);
  const timeSegmentStart = timeSegmentStartNumOfHours * hoursInMs;
  const timeSegmentEnd = timeSegmentStart + hoursInMs;

  return date2TimeFromMidnight >= timeSegmentStart && date2TimeFromMidnight < timeSegmentEnd;
}

export function getDaysUntilDate(targetDateString: string) {
  const targetDate = new Date(targetDateString);
  const currentDate = new Date();
  const differenceInMs: number = targetDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

  return daysDifference;
}

export function subDays(date: Date, days: number) {
  const ms = date.getTime();

  return new Date(ms - days * ONE_DAY_IN_MS);
}

export function isFileConsideredNew({
  createdAt,
  lastViewedTime,
}: {
  createdAt: string;
  lastViewedTime: Date | null;
}): boolean {
  return (
    new Date(createdAt).getTime() > Date.now() - 14 * ONE_DAY_IN_MS ||
    (lastViewedTime != null && new Date(createdAt) > lastViewedTime)
  );
}

export function millisecondsToSeconds(milliseconds: number) {
  return Math.floor(milliseconds / 1000);
}

export function secondsToMilliseconds(seconds: number) {
  return seconds * 1000;
}

export function pastDateInterval(date: Date) {
  const now = new Date();
  const diff = Math.abs(now.getTime() - date.getTime());

  if (diff < ONE_MINUTE_IN_MS) {
    return millisecondsToSeconds(diff) + 's';
  }

  if (diff < ONE_HOUR_IN_MS) {
    return Math.floor(diff / ONE_MINUTE_IN_MS) + 'm';
  }

  if (diff < ONE_DAY_IN_MS) {
    return Math.floor(diff / ONE_HOUR_IN_MS) + 'h';
  }

  if (diff < ONE_WEEK_IN_MS) {
    return Math.floor(diff / ONE_DAY_IN_MS) + 'd';
  }

  if (diff < ONE_YEAR_IN_MS) {
    return Math.floor(diff / ONE_WEEK_IN_MS) + 'w';
  }

  return Math.floor(diff / ONE_YEAR_IN_MS) + 'y';
}

export function futureDateInterval(date: Date) {
  const now = new Date();
  const absDiff = Math.abs(now.getTime() - date.getTime());

  if (absDiff < ONE_DAY_IN_MS) {
    const hours = Math.floor(absDiff / ONE_HOUR_IN_MS);
    const minutes = Math.floor((absDiff % ONE_HOUR_IN_MS) / ONE_MINUTE_IN_MS);
    const seconds = Math.floor((absDiff % ONE_MINUTE_IN_MS) / 1000);
    return `in ${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds > 0 ? `${seconds}s` : ''}`;
  }

  return `for ${getMonthAndDate(date)}, ${dateToTime(date.toString())}`;
}
