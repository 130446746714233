import React from 'react';
import { twMerge } from 'tailwind-merge';
import { View } from '../../common/View';
import { LoadingSkeleton } from '../../loading/LoadingSkeleton';

export function VaultItemSkeleton({
  className,
  itemClassName,
}: {
  className?: string;
  itemClassName?: string;
}) {
  return (
    <View className={twMerge('flex h-[170px] flex-grow flex-col gap-3 pb-4', className)}>
      <LoadingSkeleton className={twMerge('h-[110px] w-full rounded-md', itemClassName)} />

      <View className="flex w-full flex-col">
        <LoadingSkeleton className={twMerge('mb-1 h-[18px] w-[80%] rounded-lg', itemClassName)} />
        <LoadingSkeleton className={twMerge('h-3 w-[45px] rounded-lg', itemClassName)} />
      </View>
    </View>
  );
}

export function VaultItemsSkeleton({
  itemCount,
  itemClassName,
}: {
  itemCount: number;
  itemClassName?: string;
}) {
  return (
    <>
      {Array.from({ length: itemCount }).map((_, index) => {
        return (
          <VaultItemSkeleton key={`${index}-VAULT_ITEM_SKELETON`} itemClassName={itemClassName} />
        );
      })}
    </>
  );
}

export function RowItemsSkeleton({
  rows,
  itemClassName,
}: {
  rows: number;
  itemClassName?: string;
}) {
  return (
    <View className="-mt-1 w-full">
      {Array.from({ length: rows }).map((_, index) => {
        return (
          <View key={index} className="flex w-full flex-row items-center justify-between gap-4">
            <VaultItemSkeleton itemClassName={itemClassName} />
            <VaultItemSkeleton itemClassName={itemClassName} />
            <VaultItemSkeleton className="hidden md2:flex" itemClassName={itemClassName} />
          </View>
        );
      })}
    </View>
  );
}
