import React, { type FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { VaultContentType } from '../../graphql/generated';
import { VaultContentAccessFeatureInput } from '../../graphql/generated';
import { Text } from '../common/Text';
import { View } from '../common/View';

type Props = {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  caption: string;
  setCaption: React.Dispatch<React.SetStateAction<string>>;
  isDisabled: boolean;
  featureAccess: VaultContentAccessFeatureInput;
  setFeatureAccess: React.Dispatch<React.SetStateAction<VaultContentAccessFeatureInput>>;
};

const EditTrackView: FC<Props> = ({
  title,
  setTitle,
  caption,
  setCaption,
  isDisabled,
  featureAccess,
  setFeatureAccess,
}: Props) => {
  const handleFreeMemberAccessChange = (accessType: VaultContentAccessFeatureInput) => {
    setFeatureAccess(accessType);
  };

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View className="w-full">
      <View className="mx-4 my-3 flex flex-col gap-6">
        <View className="flex flex-col gap-2">
          <Text
            className={twMerge('!text-base-l font-semibold', isThemeEnabled && 'text-vault_text')}
          >
            Title
          </Text>
          <TitleInput title={title} setTitle={setTitle} isDisabled={isDisabled} />
        </View>
        <View className="flex flex-col gap-2">
          <Text
            className={twMerge('!text-base-l font-semibold', isThemeEnabled && 'text-vault_text')}
          >
            Description
          </Text>
          <CaptionInput caption={caption} setCaption={setCaption} isDisabled={isDisabled} />
        </View>

        <TrackFreeMemberAccessForm
          featureAccess={featureAccess}
          handleFreeMemberAccessChange={handleFreeMemberAccessChange}
          contentType={VaultContentType.Track}
        />
      </View>
    </View>
  );
};

const TitleInput = ({
  title,
  setTitle,
  isDisabled,
}: {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  isDisabled: boolean;
}) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View
      className={twMerge(
        'border-b-1 flex w-full flex-row items-center border-x-0 border-t-0 border-solid py-3',
        isThemeEnabled ? 'border-vault_text/5' : 'border-base700',
      )}
    >
      <input
        className={twMerge(
          'w-full border-0 !text-base-l focus:font-normal focus:outline-none',
          isThemeEnabled ? 'bg-transparent' : 'bg-black',
          isThemeEnabled
            ? isDisabled
              ? 'text-vault_text/50'
              : 'text-vault_text'
            : isDisabled
              ? 'text-base500'
              : 'text-white',
        )}
        placeholder="Add a title..."
        value={title}
        onChange={e => setTitle(e.target.value)}
        disabled={isDisabled}
      />
    </View>
  );
};

const CaptionInput = ({
  caption,
  setCaption,
  isDisabled,
}: {
  caption: string;
  setCaption: React.Dispatch<React.SetStateAction<string>>;
  isDisabled: boolean;
}) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <TextareaAutosize
      className={twMerge(
        'mt-2 flex max-h-72 min-h-[70px] w-[inherit] resize-none rounded-md border p-3 font-base !text-base-l focus:font-normal focus:outline-none',
        isThemeEnabled
          ? 'border-vault_text/5 bg-transparent text-vault_text placeholder:text-vault_text/50'
          : 'border-base700 bg-black text-white',
      )}
      placeholder="Share a note about your song...."
      value={caption}
      onChange={e => setCaption(e.target.value)}
      disabled={isDisabled}
    />
  );
};

export { EditTrackView };

export const TrackFreeMemberAccessForm = ({
  featureAccess,
  handleFreeMemberAccessChange,
  contentType,
}: {
  featureAccess: VaultContentAccessFeatureInput;
  handleFreeMemberAccessChange: (accessType: VaultContentAccessFeatureInput) => void;
  contentType: Exclude<VaultContentType, 'FOLDER'>;
}) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View
      className={twMerge(
        'border-b-1 flex flex-col border-x-0 border-t-0 border-solid pb-6',
        isThemeEnabled ? 'border-vault_text/5 placeholder:text-vault_text/50' : 'border-base700',
      )}
    >
      <Text
        className={twMerge('pb-10 !text-title-s font-medium', isThemeEnabled && 'text-vault_text')}
      >
        {contentType === VaultContentType.Track
          ? 'What should free members have access to?'
          : 'Who should have access?'}
      </Text>

      <View
        className="flex cursor-pointer flex-row justify-between pb-10"
        onClick={() =>
          handleFreeMemberAccessChange(VaultContentAccessFeatureInput.PaidVaultContent)
        }
      >
        <Text className={twMerge('!!text-base-l', isThemeEnabled && 'text-vault_text')}>
          {contentType === VaultContentType.Track ? 'Snippet only' : 'Paid members only'}
        </Text>
        <input
          type="radio"
          className="custom-radio cursor-pointer"
          checked={featureAccess === VaultContentAccessFeatureInput.PaidVaultContent}
          readOnly
        />
      </View>

      <View
        className="flex cursor-pointer flex-row justify-between pb-2"
        onClick={() =>
          handleFreeMemberAccessChange(VaultContentAccessFeatureInput.FreeVaultContent)
        }
      >
        <Text className={twMerge('!!text-base-l', isThemeEnabled && 'text-vault_text')}>
          {contentType === VaultContentType.Track ? 'Full song' : 'All members'}
        </Text>
        <input
          type="radio"
          className="custom-radio cursor-pointer"
          checked={featureAccess === VaultContentAccessFeatureInput.FreeVaultContent}
          readOnly
        />
      </View>
    </View>
  );
};
