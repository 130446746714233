import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faRightToBracket } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { ArtistAdminRole } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import { normalizePath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { maskPhoneNumber } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserProfileImage } from '../user/UserProfileImage';

export const MenuUser = ({
  selectedHandle,
  closeAll,
}: {
  selectedHandle?: string | null;
  closeAll: () => void;
}) => {
  const { loggedInUser, loginStatus, isArtist } = useAuthContext();
  const isMainArtistRole = isArtist && loggedInUser?.artist?.role === ArtistAdminRole.Artist;
  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const withVaultTheme = !!selectedHandle && isThemeEnabled;

  return loggedInUser != null ? (
    <UserRow
      avatarUrl={
        isMainArtistRole ? loggedInUser.artist?.profileImage?.url : loggedInUser.avatar?.url
      }
      username={
        isMainArtistRole
          ? loggedInUser.artist?.name
          : loggedInUser.displayName ?? loggedInUser.username
      }
      subText={
        isMainArtistRole
          ? `@${loggedInUser.artist?.linkValue}`
          : !!loggedInUser.displayName
            ? `@${loggedInUser?.username}` || maskPhoneNumber(loggedInUser?.phone)
            : null
      }
      isActualArtist={isMainArtistRole}
      artistLinkValue={loggedInUser.artist?.linkValue}
      withVaultTheme={withVaultTheme}
    />
  ) : loginStatus === LoginStatus.LOADING ? (
    <View
      className={twMerge(
        'mb-3 flex w-full flex-row items-center justify-between rounded-xl',
        !isNewMenuEnabled && 'py-3 pl-2',
      )}
    >
      <View className="flex flex-row">
        <LoadingSkeleton
          className={twMerge(
            'mr-4 aspect-square w-12 rounded-full',
            withVaultTheme && 'bg-vault_text/10',
          )}
        />
        <View
          className={twMerge(
            'flex flex-col justify-center',
            isNewMenuEnabled &&
              'overflow-hidden transition-all ease-in-out md2:w-0 md2:group-hover/menu:w-auto',
          )}
        >
          <LoadingSkeleton
            className={twMerge('h-4 w-[60px]', withVaultTheme && 'bg-vault_text/10')}
          />
        </View>
      </View>
      <LoadingSkeleton
        className={twMerge(
          'h-4 w-[60px] pl-0',
          isNewMenuEnabled &&
            'overflow-hidden transition-all ease-in-out md2:w-0 md2:group-hover/menu:w-[60px]',
          withVaultTheme && 'bg-vault_text/10',
        )}
      />
    </View>
  ) : (
    <>
      {!isNewMenuEnabled && (
        <Button
          href={`${ROUTES.SIGN_IN}?${constructQueryParams({ source: 'join', invite, c: smsCampaignResponseShortcode })}`}
          onClick={closeAll}
          className="mb-4 w-full justify-center rounded-full px-7 py-3 font-title  text-[16px]/[20px] font-medium"
          label="Join Vault"
          linkClassName="w-full"
          type="primary"
        />
      )}

      {isNewMenuEnabled ? (
        <View className="md2:group-hover/menu:pr-3">
          <Button
            href={`${ROUTES.SIGN_IN}?${constructQueryParams({ artistHandle: selectedHandle, invite, c: smsCampaignResponseShortcode })}`}
            onClick={closeAll}
            className={twMerge(
              'mb-4 w-full justify-center rounded-full px-7 py-3 font-title !text-[16px]/[20px] font-medium md2:ml-2 md2:h-8 md2:w-8 md2:items-center md2:p-3 md2:group-hover/menu:w-full',
              withVaultTheme ? 'bg-vault_text text-vault_text_opposite' : 'bg-white text-black',
            )}
            label="Log in"
            linkClassName="w-full"
            leadingIcon={faRightToBracket}
            labelClassName="w-full transition-all overflow-hidden ease-in-out md2:opacity-0 md2:group-hover/menu:opacity-100"
            leadingIconClassName="md2:mr-0 md2:group-hover/menu:mr-2"
          />
        </View>
      ) : (
        <Button
          href={`${ROUTES.SIGN_IN}?${constructQueryParams({ artistHandle: selectedHandle, invite, c: smsCampaignResponseShortcode })}`}
          onClick={closeAll}
          className="mb-4 w-full justify-center rounded-full bg-[rgb(51,51,51)] px-7 py-3 font-title text-[16px]/[20px] font-medium text-white"
          label="Log in"
          linkClassName="w-full"
        />
      )}
    </>
  );
};

const UserRow = ({
  avatarUrl,
  username,
  subText,
  isActualArtist,
  artistLinkValue,
  withVaultTheme,
}: {
  avatarUrl?: string;
  username?: string | null;
  subText?: string | null;
  isActualArtist?: boolean;
  artistLinkValue?: string;
  withVaultTheme: boolean;
}) => {
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);

  const row = (
    <View
      className={twMerge(
        'flex items-center gap-3',
        isNewMenuEnabled ? 'py-3 pl-3 md2:pl-2' : 'p-3',
      )}
    >
      <UserProfileImage
        profileImageUrl={avatarUrl}
        className={twMerge('h-8 w-8 rounded-full', withVaultTheme && 'text-vault_text/30')}
        withVaultTheme={withVaultTheme}
      />
      <View
        className={twMerge(
          'flex flex-col gap-0.5',
          isNewMenuEnabled &&
            'overflow-hidden md2:w-0 md2:opacity-0 md2:group-hover/menu:w-auto md2:group-hover/menu:opacity-100',
        )}
      >
        <View className="flex items-center gap-1">
          <Text
            className={twMerge(
              'max-w-[110px] overflow-hidden text-ellipsis whitespace-nowrap font-base text-[14px] font-medium',
              withVaultTheme ? 'text-vault_text' : 'text-white',
              isNewMenuEnabled ? 'max-w-[200px]' : 'max-w-[110px]',
            )}
          >
            {username}
          </Text>
          {isActualArtist && (
            <FontAwesomeIcon
              icon={faBadgeCheck}
              className={twMerge(
                'select-none text-[12px]',
                withVaultTheme ? 'text-vault_accent' : 'text-yellow100',
              )}
            />
          )}
        </View>
        <View className="flex gap-1">
          {!!subText && (
            <Text
              className={twMerge(
                'max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap font-base text-[12px] font-medium',
                withVaultTheme ? 'text-vault_text/50' : 'text-white/50',
                isNewMenuEnabled ? 'max-w-[200px]' : 'max-w-[100px]',
              )}
            >
              {subText}
            </Text>
          )}
          {isActualArtist && (
            <Text
              className={twMerge(
                'whitespace-nowrap text-base-s font-medium',
                withVaultTheme ? 'text-vault_text/50' : 'text-base400',
              )}
            >
              &middot; View vault
            </Text>
          )}
        </View>
      </View>
    </View>
  );

  if (isActualArtist && !!artistLinkValue) {
    return (
      <Link
        className="cursor-pointer no-underline hover:opacity-80"
        to={normalizePath(`/${artistLinkValue}`)}
      >
        {row}
      </Link>
    );
  }
  return row;
};
