import React from 'react';
import { faUserMusic } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ArtistBubble, SkeletonArtistBubble } from '../components/artist/ArtistBubble';
import { MenuButton } from '../components/buttons/MenuButton';
import { Text } from '../components/common/Text';
import { ErrorView } from '../components/error/ErrorView';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { EmptyStateView } from '../components/views/EmptyStateView';
import { ROUTES } from '../constants/routeConstants';
import { useInfiniteQuery } from '../graphql/client';
import { GetArtistsToExploreDocument } from '../graphql/generated';
import { useWindow } from '../hooks/useWindow';

export const ExplorePage = () => {
  const LIMIT = 20;
  const { isDesktop } = useWindow();

  const {
    orderedList: artists,
    isInitialLoading,
    isError,
    refetch,
    isFetchingNextPage,
    isLoadingError,
    fetchNextPage,
  } = useInfiniteQuery(GetArtistsToExploreDocument, {
    staleTime: 0,
    filterQueryKey: {},
    getNextPageParam: ({ data }) => {
      return (
        data.unsubscribedArtists.pageInfo.hasNextPage && {
          after: data.unsubscribedArtists.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
      };
    },
    list: ({ unsubscribedArtists }) => {
      return unsubscribedArtists.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  if (isError && artists.length === 0) {
    return (
      <DefaultLayout
        withVaultTheme={false}
        showRoundedTop={false}
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={isDesktop ? null : <MenuButton />}
        headerCenter={<Text className="font-title text-title-m font-medium">Explore artists</Text>}
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="explore_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (isInitialLoading) {
    return (
      <DefaultLayout
        withVaultTheme={false}
        showRoundedTop={false}
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={isDesktop ? null : <MenuButton />}
        headerCenter={<Text className="font-title text-title-m font-medium">Explore artists</Text>}
        childrenWrapperClassName="md2:grid md2:grid-cols-2 md2:gap-x-4 items-start"
      >
        <SkeletonArtistBubble className="mb-[20px]" />
        <SkeletonArtistBubble className="mb-[20px]" />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={isDesktop ? null : <MenuButton />}
      headerCenter={<Text className="font-title text-title-m font-medium">Explore artists</Text>}
      childrenWrapperClassName="sm:grid sm:grid-cols-2 sm:gap-x-4 items-start h-[unset]"
      nonScrollingChildren={
        !isInitialLoading &&
        artists.length === 0 && (
          <EmptyStateView
            title="No new artists"
            subtitle="You've subscribed to all the artists."
            buttonText="View your vaults"
            buttonHref={ROUTES.VAULTS}
            icon={faUserMusic}
          />
        )
      }
    >
      {artists.map(artist => (
        <ArtistBubble artist={artist} key={artist.id} className="mb-[20px]" />
      ))}
      {isFetchingNextPage ? (
        <>
          <SkeletonArtistBubble className="mb-[20px]" />
          <SkeletonArtistBubble className="mb-[20px]" />
        </>
      ) : isLoadingError ? (
        <ErrorView
          onRetryClick={fetchNextPage}
          loggingType="explore_page_next_page"
          withVaultTheme={false}
        />
      ) : null}
    </DefaultLayout>
  );
};
