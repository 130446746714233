import React, { type LegacyRef } from 'react';

import {
  type FragmentType,
  getFragment,
  VaultItemFragmentDoc,
} from '../../../../graphql/generated';
import { ArtistFolder } from './ArtistFolder';
import { ArtistImage } from './ArtistImage';
import { ArtistTrack } from './ArtistTrack';
import { ArtistVideo } from './ArtistVideo';

const VaultItemArtistComponent = React.memo(
  ({
    item,
    containerRef,
    vaultId,
    artistHandle,
  }: {
    item: FragmentType<VaultItemFragmentDoc>;
    containerRef?: LegacyRef<HTMLDivElement>;
    vaultId: string;
    artistHandle: string;
  }) => {
    const itemInfo = getFragment(VaultItemFragmentDoc, item);

    switch (itemInfo.__typename) {
      case 'VaultTrack':
        return (
          <ArtistTrack track={itemInfo} containerRef={containerRef} artistHandle={artistHandle} />
        );
      case 'VaultFolder':
        return (
          <ArtistFolder
            folderInfo={itemInfo}
            containerRef={containerRef}
            vaultId={vaultId}
            artistHandle={artistHandle}
          />
        );
      case 'VaultVideo':
        return (
          <ArtistVideo video={itemInfo} containerRef={containerRef} artistHandle={artistHandle} />
        );
      case 'VaultImage':
        return (
          <ArtistImage image={itemInfo} containerRef={containerRef} artistHandle={artistHandle} />
        );
      default:
        return <></>;
    }
  },
);

VaultItemArtistComponent.displayName = 'VaultItemArtist';

export const VaultItemArtist = React.memo(VaultItemArtistComponent);
