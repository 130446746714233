import { gql } from '@soundxyz/gql-string';
import { useMutation } from '../graphql/client';
import { ClickThroughSmsCampaignDocument } from '../graphql/generated';

gql(/* GraphQL */ `
  mutation ClickThroughSmsCampaign($shortcode: String!) {
    clickThroughSmsCampaign(shortcode: $shortcode) {
      __typename
      ... on MutationClickThroughSmsCampaignSuccess {
        data
      }
      ... on NotFoundError {
        message
      }
    }
  }
`);

export const useClickThroughSmsCampaign = () => {
  const { mutateAsync: clickThroughSmsCampaign } = useMutation(ClickThroughSmsCampaignDocument, {
    retry: 3,
  });

  return {
    clickThroughSmsCampaign,
  };
};
