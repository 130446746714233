import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faGift, faReceipt } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { MenuButton } from '../../components/buttons/MenuButton';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { MembershipView } from '../../components/views/MembershipView';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useMembership } from '../../hooks/useMembership';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';

export const MembershipPage = () => {
  const { isDesktop } = useWindow();
  const { loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { membership, isLoading } = useMembership({ artistHandle });

  const { artist, user, serialNumber, createdAt, points } = membership || {};
  const isOwner = !!artist?.mainVault.isUserArtistAdmin;

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  useVaultTheme();

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (loginStatus === LoginStatus.LOGGED_OUT || isOwner || membership === null) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  if (membership === undefined) {
    return <FullPageLoading withVaultTheme={isThemeEnabled} />;
  }

  return (
    <DefaultLayout
      messageChannelId={undefined}
      withBottomNavigator
      headerLeft={
        !isDesktop ? (
          <MenuButton className={twMerge('h-12', isThemeEnabled && 'text-vault_text')} />
        ) : null
      }
      vaultId={undefined}
      hasChatReadAccess={false}
      shouldSkipMargin={false}
      showBorder
      showRoundedTop
      withVaultTheme={isThemeEnabled}
      isHeaderTransparent
    >
      <MembershipView
        aspectRatio={0.8}
        isLoading={isLoading || !membership}
        artistName={artist?.name || artistHandle}
        serialNumber={serialNumber}
        imageUrl={artist?.membershipCardImage?.url}
        displayName={user?.displayName || user?.username}
        createdAt={createdAt}
        points={points}
      >
        <View className="my-8 flex w-full flex-col items-center gap-2">
          <Text
            className={twMerge(
              'font-title text-[22px]/[26px]',
              isThemeEnabled ? 'text-vault_text' : 'text-white',
            )}
          >
            Coming Soon
          </Text>
          <View
            className={twMerge(
              'mx-4 mt-4 flex flex-col gap-6 rounded-xl border p-10',
              isThemeEnabled ? 'border-vault_text/5 bg-vault_text/10' : 'border-base600 bg-base800',
            )}
          >
            <View className="flex flex-col gap-4">
              <FontAwesomeIcon
                icon={faReceipt}
                size="2xl"
                className={twMerge('flex items-center', isThemeEnabled && 'text-vault_text')}
              />
              <Text
                className={twMerge(
                  'max-w-[310px] text-center text-[16px]/[20px]',
                  isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
                )}
              >
                Collect receipts by pre-saving, streaming, and more.
              </Text>
            </View>

            <View
              className={twMerge(
                'h-[1px] w-full',
                isThemeEnabled ? 'bg-vault_text/5' : 'bg-base600',
              )}
            />

            <View className="flex flex-col gap-4">
              <FontAwesomeIcon
                icon={faGift}
                size="2xl"
                className={twMerge('flex items-center', isThemeEnabled && 'text-vault_text')}
              />
              <Text
                className={twMerge(
                  'max-w-[310px] text-center text-[16px]/[20px]',
                  isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
                )}
              >
                The more receipts you collect, the more rewards & access you get.
              </Text>
            </View>
          </View>
        </View>
      </MembershipView>
    </DefaultLayout>
  );
};
