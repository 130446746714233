import { type Maybe, uuidv4 } from '@soundxyz/utils';
import { useAuthContext } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import { GraphQLReactQuery, useMutation } from '../graphql/client';
import type { TierTypename } from '../graphql/generated';
import {
  GetBanStatusDocument,
  type GifAttachmentInput,
  type MediaType,
  MessageSource,
  type ReplyToMessageFragment,
  SendMessageDocument,
} from '../graphql/generated';
import { Sentry } from '../sentry';
import { AttachTrackContentTitles } from '../store/trackTitles';
import { useVaultMessageChannel } from './useVaultMessageChannel';

export function useSendMessage({
  artistId,
  messageChannelId,
  activeSubscriptionTier,
}: {
  artistId?: string;
  messageChannelId?: string;
  activeSubscriptionTier: TierTypename | null;
}) {
  const { openToast, closeToast } = useToast();
  const { loggedInUser } = useAuthContext();
  const { newMessage } = useVaultMessageChannel();

  const { mutateAsync, isLoading } = useMutation(SendMessageDocument, {
    onError: (_err, { input: { content, vaultContentId } }) => {
      openToast({
        variant: 'error',
        text: 'Your message could not be sent.',
        ctaText: 'Try again',
        ctaOnClick: () =>
          sendMessage({
            content,
            vaultContent: vaultContentId
              ? [
                  {
                    id: vaultContentId,
                    title: AttachTrackContentTitles.trackTitlesById[vaultContentId] || null,
                  },
                ]
              : null,
            activeSubscriptionTier,
          }),
        className: 'bg-destructive700',
      });
    },
    retry: 3,
  });

  const sendMessage = async ({
    content,
    activeSubscriptionTier,
    vaultContent,
    replyToMessage,
    messageAttachments,
    gifAttachment,
  }: {
    content: string;
    activeSubscriptionTier: TierTypename | null;
    vaultContent: Maybe<{ id: string; title: Maybe<string> }[]>;
    replyToMessage?: ReplyToMessageFragment | null | undefined;
    messageAttachments?: Maybe<{ media: { id: string; mediaType: MediaType; url: string } }[]>;
    gifAttachment?: Maybe<GifAttachmentInput>;
  }) => {
    if (!loggedInUser) return;
    closeToast();

    const id = uuidv4();
    const transformedReplyToMessage = replyToMessage
      ? {
          ...replyToMessage,
          vaultContent: replyToMessage.vaultContent.map(vaultContentItem => ({
            id: vaultContentItem.id,
            vaultContent: vaultContentItem
              ? {
                  id: vaultContentItem.id,
                }
              : null,
          })),
        }
      : null;
    const mappedAttachments = [
      ...(messageAttachments?.map(media => ({
        id: uuidv4(),
        media: {
          id: media.media.id,
          mediaType: media.media.mediaType,
          url: media.media.url,
        },
        gif: null,
      })) ?? []),
      ...(gifAttachment
        ? [
            {
              id: uuidv4(),
              media: null,
              gif: {
                id: gifAttachment.id,
                url: gifAttachment.url,
                title: gifAttachment.title,
                aspectRatio: gifAttachment.aspectRatio,
              },
            },
          ]
        : []),
    ];

    const { revert, onMutationSuccess } = newMessage({
      content: content.trim(),
      createdAt: new Date().toISOString(),
      id,
      user: loggedInUser,
      vaultContent: vaultContent
        ? vaultContent.map(content => {
            return {
              id: uuidv4(),
              vaultContent: {
                id: content.id,
                title: content.title ?? null,
              },
            };
          })
        : [],
      isOptimistic: true,
      replyTo: transformedReplyToMessage,
      messageAttachments: mappedAttachments,
      asArtist: loggedInUser.artist,
      source: MessageSource.VaultChat,
      activeSubscriptionTier,
    });

    const asArtistId = loggedInUser.artist?.id;

    const resp = await mutateAsync({
      input: {
        optimisticId: id,
        content: content.trim(),
        messageChannelId: messageChannelId ?? '',
        vaultContentId: vaultContent?.[0]?.id ?? null,
        replyToId: replyToMessage?.id,
        messageAttachments:
          messageAttachments && messageAttachments.length > 0
            ? messageAttachments.map(({ media }) => media.id)
            : null,
        gifAttachment: gifAttachment,
        asArtistId,
      },
      asArtistId,
    }).catch(error => {
      Sentry.captureException(error, {
        extra: {
          message: 'Error sending message',
          artistId,
          messageChannelId,
          content: content.trim(),
          vaultContent,
        },
        tags: {
          type: 'sendMessageMutation',
        },
      });

      return {
        data: {
          createMessage: {
            __typename: 'Error',
            message: 'Your message could not be sent.',
          },
        },
      };
    });

    if (resp.data.createMessage.__typename !== 'MutationCreateMessageSuccess') {
      revert();
      openToast({
        text: (
          <p>
            Your message could not be sent.{' '}
            <span
              className="cursor-pointer text-base-m font-semibold underline"
              onClick={() => sendMessage({ content, vaultContent, activeSubscriptionTier })}
            >
              Try again.
            </span>
          </p>
        ),
        variant: 'error',
      });
    } else {
      onMutationSuccess?.();
    }

    if (resp?.data?.createMessage?.__typename === 'NotAuthorizedError') {
      // User could have been banned
      GraphQLReactQuery.invalidateQueries([GetBanStatusDocument]);
    }
  };

  return { sendMessage, isLoading };
}
