import { type FC, type RefObject, useCallback, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  ArtistMainVaultViewFragmentDoc,
  FeatureTypename,
  type FragmentType,
  getFragment,
  TierTypename,
} from '../../graphql/generated';
import { useFreeTier } from '../../hooks/useFreeTier';

import { setBadgeVisibility } from '../../hooks/useMembership';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { Image } from '../common/Image';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { VaultContents } from '../vault/VaultContents';
import { TrackGrid, TrackGridSkeleton } from '../vault/VaultGrid';
import { VaultHeader } from '../vault/VaultHeader';

import { useVaultEngagement } from './hooks/useVaultEngagement';

gql(/* GraphQL */ `
  fragment artistMainVaultView on Artist {
    id
    name
    instagramHandle
    description
    customWebsiteUrl
    spotifyUrl
    tiktokHandle
    linkValue
    createdAt

    mainVault {
      id
      contentCount
      memberCount
      price
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      artist: artistProfile {
        id
        name
      }
      messageChannelId
      isUserArtistAdmin
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
  }

  query GetUserLastViewedTimeOnVault($vaultId: UUID!) {
    getUserLastViewedTimeOnVault(vaultId: $vaultId)
  }
`);

export function VaultView({
  artist,
  setShowProfileImage,
  scrollRef,
  showRightHeader,
}: {
  setShowProfileImage: (header: boolean) => void;
  artist: FragmentType<ArtistMainVaultViewFragmentDoc>;
  scrollRef: RefObject<HTMLDivElement>;
  showRightHeader: boolean;
}) {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const { isDesktop } = useWindow();
  const { value: foldersEnabled } = useGate(FEATURE_GATES.FOLDERS);
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { subscribeFreeTier } = useFreeTier();

  const showRoundedTop = isDesktop && isNewMenuEnabled;

  const { profileImageUrl } = useSnapshot(VaultThemeStore);

  const {
    id,
    name,
    mainVault: { id: vaultId, activeSubscription, price, isUserArtistAdmin, tiers },
    linkValue,
  } = getFragment(ArtistMainVaultViewFragmentDoc, artist);

  // Update the last viewed time after the initial get query finishes
  useVaultEngagement({
    vaultId,
    triggerQuery: loggedInUser != null,
  });

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: activeSubscription,
    isOwner: isUserArtistAdmin,
  });
  const hasActiveSubscription = !!activeSubscription?.id;

  const chatAvailableForFreeUsers = tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  const onJoinFreeClick = useCallback(async () => {
    if (loginStatus === LoginStatus.LOGGED_IN) {
      await subscribeFreeTier({
        input: { vaultId, inviteCode: invite, smsCampaignResponseShortcode },
      });
      if (!hasActiveSubscription) setBadgeVisibility(linkValue, vaultId, true);
      navigate(window.location.pathname, { replace: true });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        invite,
        c: smsCampaignResponseShortcode,
      });

      navigate(`/${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    hasActiveSubscription,
    invite,
    smsCampaignResponseShortcode,
    linkValue,
    loginStatus,
    navigate,
    subscribeFreeTier,
    vaultId,
  ]);

  useEffect(() => {
    if (redirect === 'onboarding') {
      navigate(
        `${ROUTES.ONBOARDING}/username?artistHandle=${linkValue}&openBottomSheet=${bottomSheetType}`,
      );
    } else if (
      bottomSheetType === 'freeTierModal' &&
      loginStatus === LoginStatus.LOGGED_IN &&
      !activeSubscription
    ) {
      onJoinFreeClick();
    }
  }, [
    activeSubscription,
    linkValue,
    loginStatus,
    navigate,
    onJoinFreeClick,
    bottomSheetType,
    redirect,
  ]);

  return (
    <View
      className={twMerge(
        'relative flex min-h-full w-full flex-1 flex-col items-center text-white',
        showRoundedTop ? '-mt-20' : '-mt-32',
        !isNewMenuEnabled && '-mb-2',
      )}
    >
      <View className="relative top-0 z-base aspect-square w-full overflow-clip">
        {profileImageUrl !== null ? (
          <Image
            src={profileImageUrl}
            alt={name}
            className={twMerge(
              'z-below aspect-square w-full object-cover object-left-top',
              showRoundedTop && 'md2:rounded-t-[20px]',
            )}
          />
        ) : (
          <UserPlaceholderImage
            id={id}
            className="z-below aspect-square w-full"
            svgClassName={showRoundedTop ? 'md2:rounded-t-[20px]' : undefined}
          />
        )}
        {showRightHeader && (
          <View
            className={twMerge(
              'absolute top-0 z-base aspect-square w-full bg-gradient-to-b to-transparent to-40%',
              showRoundedTop && 'md2:rounded-t-[20px]',
              isThemeEnabled ? 'from-vault_background' : 'from-black',
            )}
          />
        )}
        <View
          className={twMerge(
            'absolute top-20 z-base aspect-square w-full bg-gradient-to-b from-transparent to-80% md2:to-60%',
            isThemeEnabled ? 'to-vault_background' : 'to-black',
          )}
        />
      </View>

      <View
        className={twMerge(
          'z-above1 box-border w-full',
          !isNewMenuEnabled && 'rounded-t-xl px-2 md2:px-6',
        )}
      >
        <View
          className={twMerge(
            '-mt-[100px] box-border flex w-full flex-col px-4 py-6 md2:px-6',
            isNewMenuEnabled
              ? isThemeEnabled
                ? 'min-h-screen bg-gradient-to-b from-transparent to-vault_text/3 md2:-mt-[290px]'
                : 'min-h-screen bg-gradient-to-b from-transparent to-white/3 md2:-mt-[290px]'
              : 'rounded-t-xl bg-gradient-to-b from-black/10 from-[10.99%] to-black to-[43.96%] backdrop-blur-3xl md2:-mt-[216px]',
          )}
        >
          <VaultHeader
            artist={artist}
            setShowProfileImage={setShowProfileImage}
            scrollRef={scrollRef}
            isOwner={isUserArtistAdmin}
            chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
            activeSubscriptionTier={activeSubscriptionFeatures?.tier}
            onJoinFreeClick={onJoinFreeClick}
          />

          {foldersEnabled ? (
            <VaultContents
              vaultId={vaultId}
              artistLinkValue={linkValue}
              isOwner={isUserArtistAdmin}
              folderId={null}
            />
          ) : (
            <TrackGrid
              isOwner={isUserArtistAdmin}
              vaultId={vaultId}
              artistLinkValue={linkValue}
              activeSubscription={activeSubscription}
              price={price}
              profileImageUrl={profileImageUrl}
              invite={invite}
              smsCampaignResponseShortcode={smsCampaignResponseShortcode}
              artistName={name}
            />
          )}
        </View>
      </View>
    </View>
  );
}

export const SkeletonVaultView: FC = () => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View className="no-scrollbar mt-28 flex min-h-full w-full flex-1 flex-col items-center overflow-y-scroll text-white">
      <View className="relative top-0 z-base aspect-square w-full overflow-clip" />
      <View className="z-above1 -mt-[312px] flex w-full flex-col md2:-mt-[400px]">
        <View className="flex flex-col items-center">
          <View className="mb-[16px] ml-[28px] flex-col self-start md2:ml-[50px]">
            <LoadingSkeleton className="mb-2 h-[30px] w-[250px]" />
            <LoadingSkeleton className="h-[20px] w-[100px]" />
          </View>
          <View
            className={twMerge(
              'box-border flex w-full flex-1 flex-col items-center px-7 md2:px-9',
              isThemeEnabled ? 'bg-vault_background' : 'bg-black',
            )}
          >
            <TrackGridSkeleton />
          </View>
        </View>
      </View>
    </View>
  );
};
