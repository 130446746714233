import React from 'react';
import { useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { useVaultContentActions } from '../../hooks/vault/useVaultContentActions';
import { MoveToContents } from '../../screens/vault/MoveToPage';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';

export const SelectingVaultNav = ({ vaultId }: { vaultId: string }) => {
  const { artistHandle } = useArtistHandle();
  const navigate = useNavigate();
  const { selectedContentIds, setIsSelecting, clearSelection } = useSelectVaultContent();
  const hasSelectedContent = selectedContentIds.length > 0;
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { removeContents } = useVaultContentActions();

  const hasManySelected = selectedContentIds.length > 1;
  const onDeletePress = useStableCallback(() => {
    openBottomsheet({
      type: 'CONFIRMATION',
      confirmationBottomsheetProps: {
        subText: `Are you sure you want to delete ${hasManySelected ? 'these items' : 'this file'}?`,
        confirmButtonText: 'Delete',
        confirmType: 'delete',
        onConfirm: () => {
          if (!vaultId) return;
          removeContents({
            contentIds: selectedContentIds,
            vaultId: vaultId,
            onSuccess: () => {
              setIsSelecting(false);
              clearSelection();
              openToast({
                text: `Content successfully deleted.`,
                variant: 'success',
              });
            },
          });
        },
      },
    });
  });
  return (
    <View
      className={twMerge(
        'sticky z-mobileNav flex h-[68px] max-h-[68px] w-full items-center justify-center border-0 border-t border-solid py-2',
        isThemeEnabled
          ? 'border-t-vault_text/5 bg-vault_background/75 backdrop-blur-2xl'
          : 'border-t-base700 bg-black',
      )}
    >
      <View className="flex w-full items-center justify-between px-8 md2:px-16">
        <Button
          label="Delete"
          type="secondary"
          className="w-fit rounded-full bg-destructive400 px-4 py-3.5 text-[14px]/[18px] disabled:cursor-not-allowed disabled:opacity-50"
          disabled={!hasSelectedContent}
          onClick={onDeletePress}
        />
        <Button
          label="Move to"
          type="secondary"
          className={twMerge(
            'w-fit rounded-full px-4 py-3.5 text-[14px]/[18px] disabled:cursor-not-allowed disabled:opacity-50',
            isThemeEnabled && 'bg-vault_accent text-vault_accent_text',
          )}
          disabled={!hasSelectedContent || artistHandle == null}
          onClick={() => {
            MoveToContents.contentIds = [...selectedContentIds];
            navigate(artistNavigationPath(artistHandle, '/move-to'));
          }}
        />
      </View>
    </View>
  );
};
