import type { MutableRefObject } from 'react';

import { UAParser } from 'ua-parser-js';
import { isUUID4 } from '@soundxyz/utils';
import { mixpanelClient } from '../clients/mixpanelClient';
import { ROUTES } from '../constants/routeConstants';
import { LoggedInUserEventProperties } from '../providers/AuthProvider';
import { Sentry } from '../sentry';
import type { EventProperties, EventType } from '../types/eventTypes';
import { getSubdomain, isValidSubdomain } from './subdomainUtils';

const uaParser = new UAParser();

const regexStringFormat = "[a-zA-Z0-9!'@#$%&();,._-]+";
export const artistHandleFormat = '[a-zA-Z0-9_\\-]+';
const slugFormat = '[a-zA-Z0-9\\-]+';

const uuidPattern = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

//Make sure to add any new routes above artist handle so they are not erroneously logged
const getPageInfoFromRoute = ({
  subdomain,
  pathname,
  searchParams,
}: {
  subdomain: string | null | undefined;
  pathname: string;
  searchParams: URLSearchParams;
}) => {
  if (pathname.match(`^${ROUTES.SIGN_IN}$`)) {
    return { current_page_title: 'Sign In' };
  }

  if (pathname.match(`^${ROUTES.VERIFY}$`)) {
    return { current_page_title: 'Verify' };
  }

  if (pathname.match(`^${ROUTES.NOT_FOUND}$`)) {
    return { current_page_title: 'Not Found' };
  }

  if (pathname.startsWith(ROUTES.ONBOARDING)) {
    if (pathname.match(`^${ROUTES.ONBOARDING}/username$`)) {
      return { current_page_title: 'Onboarding - Username' };
    }
    if (pathname.startsWith(`${ROUTES.ONBOARDING}/complete-profile`)) {
      return { current_page_title: 'Onboarding - Complete Profile' };
    }
    return null;
  }

  if (pathname.startsWith(ROUTES.SETTINGS)) {
    if (pathname.match(`^${ROUTES.SETTINGS}/profile$`)) {
      return { current_page_title: 'Settings - Edit Profile' };
    }

    if (pathname.startsWith(`${ROUTES.SETTINGS}/subscriptions`)) {
      const paths = pathname.split('/');
      const subscriptionId = paths[paths.length - 1];
      if (subscriptionId !== 'subscriptions' && subscriptionId != null && isUUID4(subscriptionId)) {
        return {
          current_page_title: 'Settings - Manage Subscriptions',
          params: { subscriptionId },
        };
      }
      if (pathname.match(`^${ROUTES.SETTINGS}/subscriptions$`)) {
        return { current_page_title: 'Settings - My Subscriptions' };
      }

      return null;
    }

    if (pathname.startsWith(`${ROUTES.SETTINGS}/memberships`)) {
      const paths = pathname.split('/');
      const subscriptionId = paths[paths.length - 1];
      if (subscriptionId !== 'memberships' && subscriptionId != null && isUUID4(subscriptionId)) {
        return {
          current_page_title: 'Settings - Manage Subscriptions',
          params: { subscriptionId },
        };
      }
      if (pathname.match(`^${ROUTES.SETTINGS}/memberships$`)) {
        return { current_page_title: 'Settings - My Subscriptions' };
      }

      return null;
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/billing$`)) {
      return { current_page_title: 'Settings - Billings' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/notification-settings$`)) {
      return { current_page_title: 'Settings - Notification Settings' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/about-vault$`)) {
      return { current_page_title: 'Settings - About Vault' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/sms-notification-settings$`)) {
      return { current_page_title: 'Settings - SMS Notification Settings' };
    }

    if (pathname.startsWith(`${ROUTES.SETTINGS}/methods`)) {
      if (pathname.match(`^${ROUTES.SETTINGS}/methods/add$`)) {
        return { current_page_title: 'Settings - Add Payment Methods' };
      }

      if (pathname.match(`^${ROUTES.SETTINGS}/methods/update$`)) {
        return { current_page_title: 'Settings - Update Payment Methods' };
      }

      return { current_page_title: 'Settings - Payment Methods' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/artist-earnings$`)) {
      return { current_page_title: 'Settings - Artist Earnings' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/earnings$`)) {
      return { current_page_title: 'Settings - Earnings' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/subscribers$`)) {
      return { current_page_title: 'Settings - Subscriber' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/members$`)) {
      return { current_page_title: 'Settings - Subscriber' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/payouts$`)) {
      return { current_page_title: 'Settings - Payouts' };
    }

    if (pathname.match(`^${ROUTES.SETTINGS}/artist-notification`)) {
      const paths = pathname.split('/');
      const artistId = paths[paths.length - 1];
      if (artistId !== 'artist-notification' && artistId != null && isUUID4(artistId)) {
        return {
          current_page_title: 'Settings - Manage Artist Notification',
          params: { artistId },
        };
      }

      return { current_page_title: 'Settings - Artist Notification' };
    }

    return { current_page_title: 'Settings' };
  }

  if (!subdomain) {
    if (pathname.match(`^${ROUTES.VAULTS}$`)) {
      return { current_page_title: 'My Vaults' };
    }

    if (pathname.match(`^${ROUTES.EXPLORE}$`)) {
      return { current_page_title: 'Explore' };
    }

    if (pathname.match(`^${ROUTES.LANDING_PAGE}$`)) {
      return { current_page_title: 'Landing Page' };
    }
  }

  const artistHandle = subdomain || pathname.split('/')[1];

  if (artistHandle) {
    const baseRoute = subdomain ? '' : `/${artistHandle}`;

    if (searchParams.has('code')) {
      return { current_page_title: 'Vault - Referral Link', params: { artistHandle } };
    }

    if (pathname.match(`^${baseRoute}/upload$`)) {
      return { current_page_title: 'Vault - Upload Track', params: { artistHandle } };
    }

    if (pathname.match(`^${baseRoute}/finalize-upload$`)) {
      return { current_page_title: 'Vault - Finalize Upload Track', params: { artistHandle } };
    }

    if (pathname.match(`^${baseRoute}/edit/${regexStringFormat}$`)) {
      const paths = pathname.split('/');
      const vaultContentId = paths[paths.length - 1];
      return {
        current_page_title: 'Vault - Edit Track',
        params: {
          artistHandle,
          vaultContentId:
            vaultContentId != null && isUUID4(vaultContentId) ? vaultContentId : undefined,
        },
      };
    }

    if (pathname.match(`^${baseRoute}/snippet/${regexStringFormat}$`)) {
      const paths = pathname.split('/');
      const vaultContentId = paths[paths.length - 1];
      return {
        current_page_title: 'Edit Snippet Screen',
        params: {
          artistHandle,
          vaultContentId:
            vaultContentId != null && isUUID4(vaultContentId) ? vaultContentId : undefined,
        },
      };
    }

    if (pathname.match(`^${baseRoute}/subscribe$`)) {
      return { current_page_title: 'Vault - Subscribe', params: { artistHandle } };
    }

    if (pathname.match(`^${baseRoute}/success$`)) {
      return { current_page_title: 'Vault - Subscribe Success', params: { artistHandle } };
    }

    if (pathname.match(`^${baseRoute}/announcements$`)) {
      return { current_page_title: 'Announcements', params: { artistHandle } };
    }

    if (pathname.match(`^${baseRoute}/chat`)) {
      if (pathname.match(`^${baseRoute}/chat/pins$`)) {
        return { current_page_title: 'Chat - Pins', params: { artistHandle } };
      }

      if (pathname.match(`^${baseRoute}/chat/details$`)) {
        return { current_page_title: 'Chat - Details', params: { artistHandle } };
      }

      return { current_page_title: 'Chat', params: { artistHandle } };
    }

    if (
      pathname.match(`^${baseRoute}/${uuidPattern}$`) ||
      pathname.match(`^${baseRoute}/t/${slugFormat}$`)
    ) {
      const isSlug = pathname.match(`^${baseRoute}/t/${slugFormat}$`);

      return {
        current_page_title: 'Vault - Track Page',
        params: { artistHandle, vaultContentId: pathname.split('/').pop(), isSlug },
      };
    }

    if (
      pathname.match(`^${baseRoute}/folder/${uuidPattern}$`) ||
      pathname.match(`^${baseRoute}/f/${slugFormat}$`)
    ) {
      const isSlug = pathname.match(`^${baseRoute}/f/${slugFormat}$`);

      return {
        current_page_title: 'Vault - Folder',
        params: { artistHandle, folderId: pathname.split('/').pop(), isSlug },
      };
    }

    if (pathname.match(`^${baseRoute}/invites$`)) {
      return { current_page_title: 'Vault - Invites', params: { artistHandle } };
    }

    return { current_page_title: 'Vault - Main', params: { artistHandle } };
  }
  return null;
};

const sendPageAnalytics = async ({
  subdomain,
  pathname,
  previousAnalyticsPage,
  searchParams,
}: {
  subdomain: string | null | undefined;
  pathname: string;
  previousAnalyticsPage: MutableRefObject<{
    current_page_title: string;
    timestamp: string;
    pathname: string;
  } | null>;
  searchParams: { [key: string]: string };
}) => {
  if (previousAnalyticsPage.current?.pathname === pathname) {
    return;
  }

  const urlSearchParams = new URLSearchParams(searchParams);
  const analyticsPageCategory = getPageInfoFromRoute({
    subdomain,
    pathname,
    searchParams: urlSearchParams,
  });

  if (analyticsPageCategory != null) {
    const result = uaParser.getResult();
    const { isbot } = await import('isbot');

    if (isbot(result.ua)) {
      // Do not report page view analytics from bots
      return;
    }

    const referrer = document.referrer;

    const now = new Date();

    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log(
        `[Page Analytics] ${new Date().toUTCString()} page=${JSON.stringify(analyticsPageCategory)}`,
      );
    }

    mixpanelClient.track_pageview({
      ...analyticsPageCategory,
      height: window?.innerHeight || 0,
      width: window?.innerWidth || 0,
      version: import.meta.env.NEXT_PUBLIC_WEBAPP_VERSION,
      platform: 'WEB',
      browser: {
        name: result.browser.name,
        version: result.browser.version,
      },
      device: {
        vendor: result.device.vendor,
        name: result.os.name,
        version: result.os.version,
      },
      searchParams,
      referrer,
      performedByActiveUser: LoggedInUserEventProperties.highestSubscriptionLevel === 'PAID',
      subscriptionStatus: LoggedInUserEventProperties.highestSubscriptionLevel,
      previousPage:
        previousAnalyticsPage.current != null
          ? {
              ...previousAnalyticsPage.current,
              duration: now.getTime() - new Date(previousAnalyticsPage.current.timestamp).getTime(),
            }
          : undefined,
    });
    previousAnalyticsPage.current = {
      current_page_title: analyticsPageCategory.current_page_title,
      timestamp: now.toISOString(),
      pathname,
    };
  } else {
    previousAnalyticsPage.current = null;

    Sentry.captureMessage(
      `${pathname} format does not match any analytics page categories. Page Visit is not registered to MixPanel.`,
      {
        level: 'info',
      },
    );
  }
};

async function trackEvent<Event extends EventType>({
  type,
  properties,
  subdomain,
  pathname,
}: {
  type: Event;
  properties: EventProperties[Event];
  subdomain?: string | null | undefined;
  pathname?: string;
}) {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const page =
      getPageInfoFromRoute({
        subdomain: subdomain ?? isValidSubdomain() ? getSubdomain() : null,
        pathname: pathname ?? window.location.pathname,
        searchParams,
      }) ?? {};

    const userAgent = uaParser.getResult();

    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log(
        `[${type}] ${new Date().toUTCString()} page=${JSON.stringify(page)} properties=${JSON.stringify(properties)}`,
      );
    }

    mixpanelClient.track(type, {
      height: window?.innerHeight || 0,
      width: window?.innerWidth || 0,
      platform: 'WEB',
      browser: {
        name: userAgent.browser.name,
        version: userAgent.browser.version,
      },
      device: {
        vendor: userAgent.device.vendor,
        name: userAgent.os.name,
        version: userAgent.os.version,
      },
      ...page,
      ...properties,
      version: process.env.NEXT_PUBLIC_WEBAPP_VERSION,
      performedByActiveUser: LoggedInUserEventProperties.highestSubscriptionLevel === 'PAID',
      subscriptionStatus: LoggedInUserEventProperties.highestSubscriptionLevel,
    });
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        type: 'trackMixpanelEvent',
        mixpanelEvent: type,
      },
      level: 'warning',
    });
  }
}

export { uaParser, sendPageAnalytics, trackEvent };
