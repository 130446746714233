import { useMemo } from 'react';
import { captureException } from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';
import { createDeferredPromise } from '@soundxyz/utils/promise';
import { ROUTES } from '../constants/routeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useBottomsheetContainer } from '../contexts/BottomsheetContext';
import { useToast } from '../contexts/ToastContext';
import { useMutation, useQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  AuthUserDocument,
  SpotifyAuthConnectionDocument,
  SpotifyLinkDocument,
} from '../graphql/generated';
import { PersistenceStorage } from '../utils/storeUtils';
import { constructQueryParams } from '../utils/stringUtils';
import { useLatestRef } from './useLatestRef';
import { useStableCallback } from './useStableCallback';

gql(/* GraphQL */ `
  mutation SpotifyConnect($input: ConnectSpotifyInput!) {
    connectSpotify(input: $input) {
      __typename
      ... on MutationConnectSpotifySuccess {
        data {
          spotifyUserId
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation SpotifyLink($authCode: String!, $overrideAccount: Boolean!) {
    linkSpotify(authCode: $authCode, overrideAccount: $overrideAccount) {
      __typename
      ... on Error {
        message
      }
    }
  }

  query SpotifyAuthConnection($authCode: String!) {
    spotifyAuthConnection(authCode: $authCode) {
      spotifyUserId
    }
  }
`);

RefetchOnComplete({
  trigger: [SpotifyLinkDocument],
  refetch: [AuthUserDocument],
});

export const SpotifyConnectState = PersistenceStorage({
  schema: z.object({
    state: z.string(),

    code: z.string().nullable(),

    justConnected: z.boolean().default(false),
  }),
  key: 'spotifyConnectState',
  eager: true,
});

export function resetSpotifyConnectState() {
  if (!SpotifyConnectState.state.value) return;

  newSpotifyConnectState({ code: null });
}

export function getRandomSpotifyState(length = 16) {
  return Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
    .toString(36)
    .slice(1);
}

export function newSpotifyConnectState({ code }: { code: string | null }) {
  return SpotifyConnectState.set({
    state: getRandomSpotifyState(),

    justConnected: !!code,

    code: code ?? SpotifyConnectState.state.value?.code ?? null,
  });
}

export function useSpotifyAuth({ presaveEnabled }: { presaveEnabled: boolean }) {
  const { value, isLoading: isLoadingStore } = SpotifyConnectState.useStore();

  const state = value?.state;
  const authCode = value?.code;

  const { data, isLoading: isLoadingSpotifyAuth } = useQuery(SpotifyAuthConnectionDocument, {
    staleTime: 0,
    variables: !!authCode && { authCode },
  });

  return useMemo(() => {
    if (!presaveEnabled) {
      return {
        type: 'disabled',
      } as const;
    }

    if (isLoadingStore || isLoadingSpotifyAuth) {
      return { type: 'loading' } as const;
    }

    if (!state) {
      newSpotifyConnectState({ code: null });

      return {
        type: 'loading',
      } as const;
    }

    if (data?.data.spotifyAuthConnection?.spotifyUserId && authCode) {
      return {
        type: 'already-connected',
        authCode,
      } as const;
    }

    return {
      type: 'spotify-auth',
      link: spotifyAuthorizationLink({ state }),
    } as const;
  }, [data, isLoadingStore, isLoadingSpotifyAuth, state, authCode, presaveEnabled]);
}

export function spotifyAuthorizationLink({ state }: { state: string }) {
  /**
   * Reference:
   *
   * https://developer.spotify.com/documentation/web-api/tutorials/code-flow
   */

  const url = new URL('https://accounts.spotify.com/authorize');

  url.searchParams.append('client_id', import.meta.env.VITE_SPOTIFY_CLIENT_ID);
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('redirect_uri', new URL('/spotify-connect', window.location.href).href);
  url.searchParams.append('state', state);
  url.searchParams.append(
    'scope',
    'user-library-read user-library-modify user-read-recently-played user-top-read user-follow-read',
  );

  return url.href;
}

export function useLinkSpotifyAccount() {
  const { mutateAsync: linkSpotify } = useMutation(SpotifyLinkDocument, {
    retry: 5,
  });

  const { refetchAuthUser } = useAuthContext();

  const { openBottomsheet, onBottomsheetClose } = useBottomsheetContainer();

  const { openToast, toastProps } = useToast();

  const currentToastProps = useLatestRef(toastProps);

  const [searchParams] = useSearchParams();

  return useStableCallback(
    async ({
      authCode = SpotifyConnectState.state.value?.code,
    }: { authCode?: string | null } = {}) => {
      if (!authCode) {
        return {
          type: 'no-auth-code',
        } as const;
      }

      const authUser = await refetchAuthUser();

      if (authUser?.data?.data.currentUser.__typename !== 'QueryCurrentUserSuccess') {
        return {
          type: 'no-user',
          signInPath: `${ROUTES.SIGN_IN}?${constructQueryParams({
            linkSpotify: true,
            invite: searchParams.get('invite'),
            redirect: window.location.pathname,
            trackId: searchParams.get('trackId'),
            source: searchParams.get('source'),
            artistHandle: searchParams.get('artistHandle'),
          })}`,
        } as const;
      }

      const promise = createDeferredPromise<
        | { type: 'spotify-account-linked' }
        | {
            type: 'spotify-account-not-linked';
          }
      >();

      linkSpotify({
        authCode,
        overrideAccount: false,
      })
        .then(result => {
          if (result.data.linkSpotify.__typename !== 'MutationLinkSpotifySuccess') {
            if (result.data.linkSpotify.__typename === 'SpotifyAlreadyLinkedError') {
              const cleanupBottomsheetClose = onBottomsheetClose(() => {
                promise.resolve({ type: 'spotify-account-not-linked' });
              });
              promise.promise.finally(() => {
                cleanupBottomsheetClose();
              });
              return openBottomsheet({
                type: 'CONFIRMATION',
                confirmationBottomsheetProps: {
                  title: 'Spotify already linked',
                  subText:
                    'Your Spotify account is already linked to another Vault account. Would you like to link it to this account instead?',
                  onConfirm() {
                    linkSpotify({
                      authCode,
                      overrideAccount: true,
                    }).then(result => {
                      if (result.data.linkSpotify.__typename === 'MutationLinkSpotifySuccess') {
                        openToast({
                          text:
                            currentToastProps.current?.text === 'Presaved successfully'
                              ? 'Track presaved and spotify account linked!'
                              : 'Spotify account linked',
                          variant: 'success',
                        });
                        promise.resolve({
                          type: 'spotify-account-linked',
                        });
                      } else {
                        openToast({
                          text: result.data.linkSpotify.message,
                          variant: 'error',
                        });
                        promise.resolve({
                          type: 'spotify-account-not-linked',
                        });
                      }
                    });
                  },
                },
              });
            }

            openToast({
              text: result.data.linkSpotify.message,
              variant: 'error',
            });

            promise.resolve({ type: 'spotify-account-not-linked' });
          } else {
            openToast({
              text:
                currentToastProps.current?.text === 'Presaved successfully'
                  ? 'Track presaved and spotify account linked!'
                  : 'Spotify account linked',
              variant: 'success',
            });

            promise.resolve({
              type: 'spotify-account-linked',
            });
          }
        })
        .catch(error => {
          captureException(error, {
            extra: {
              spotifyAuth: SpotifyConnectState.state.value,
            },
          });
          openToast({
            text: "Couldn't link Spotify account. Please try again later.",
            variant: 'error',
          });
          promise.resolve({
            type: 'spotify-account-not-linked',
          });
        });

      return promise.promise;
    },
  );
}
