import { type FC, type SyntheticEvent, useEffect, useRef, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import TextareaAutosize from 'react-textarea-autosize';
import type { VirtuosoHandle } from 'react-virtuoso';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faPlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faGif } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrash } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faArrowUp } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { AudioAttachment } from '../../contexts/AudioRecordContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ImagesAttachment } from '../../contexts/ImagesMessageAttachmentContext';
import { useToast } from '../../contexts/ToastContext';
import { VideoAttachment } from '../../contexts/VideoMessageAttachmentContext';
import { useQuery } from '../../graphql/client';
import type { TierTypename } from '../../graphql/generated';
import {
  GetBanStatusDocument,
  getFragment,
  MediaType,
  ReplyToMessageFragmentDoc,
} from '../../graphql/generated';
import { useSendMessage } from '../../hooks/useSendMessage';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useStopwatch } from '../../hooks/useStopwatch';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import {
  ActiveArtistMessageChannel,
  setReplyToMessage,
  useReplyToMessage,
} from '../../hooks/useVaultMessageChannel';
import { WindowState } from '../../hooks/useWindow';
import { AttachTrackContentTitles } from '../../store/trackTitles';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { RecordButton } from '../audioRecorder/RecordButton';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { StyledInputContainer } from '../input/StyledInputContainer';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { RecordingWaveform } from '../waveform/RecordingWaveform';
import { MinimizedMessageAttachment } from './MinimizedMessageAttachment';

gql(/* GraphQL */ `
  mutation SendMessage($input: MutationCreateMessageInput!, $asArtistId: UUID) {
    createMessage(input: $input) {
      __typename
      ... on MutationCreateMessageSuccess {
        data {
          id
          source
          ...messageBubble
        }
      }
      ... on Error {
        message
      }
    }
  }

  query GetBanStatus($artistId: UUID!) {
    getBanStatus(input: { artistId: $artistId })
  }
`);

const MAX_MESSAGE_LENGTH = 2000;

type Props = {
  messageChannel: { id: string };
  activeSubscriptionTier: TierTypename | null;
  isOwner: boolean;
  gifButtonClick: () => void;
  vaultId?: string;
  artist: { id: string; name: string; linkValue: string };
  activeSubscriptionFeatures: TierFeatures;
  virtuosoRef: React.RefObject<VirtuosoHandle>;
};

const MessageTextInput: FC<Props> = ({
  vaultId,
  activeSubscriptionTier,
  messageChannel,
  isOwner,
  gifButtonClick,
  artist,
  activeSubscriptionFeatures,
  virtuosoRef,
}) => {
  const { pathname } = useLocation();
  const htmlElRef = useRef<HTMLTextAreaElement>(null);
  const replyToMessageFrag = useReplyToMessage();
  const [searchParams] = useSearchParams();
  const [content, setContent] = useState('');
  const [attachedTrack, setAttachedTrack] = useState<string | null>(searchParams.get('track'));
  const { openToast } = useToast();
  const { openBottomsheet } = useBottomsheetContainer();
  const [shouldFocus, setShouldFocus] = useState(false);

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const replyToMessage = getFragment(ReplyToMessageFragmentDoc, replyToMessageFrag);
  const {
    video: messageVideo,
    isUploading: isUploadingVideo,
    clearVideo,
  } = useSnapshot(VideoAttachment);
  const {
    images: messageImages,
    isUploading: isUploadingImages,
    clearImages,
  } = useSnapshot(ImagesAttachment);
  const {
    recording: messageRecording,
    isRecording: isRecordingAudio,
    isUploading: isUploadingAudio,
    isProcessingAudioFile,
    clearRecording,
  } = useSnapshot(AudioAttachment);

  useEffect(() => {
    // Focus the input if the user is replying to a message
    if (replyToMessage) setShouldFocus(true);
  }, [replyToMessage]);

  const swipeableHandlers = useSwipeable({
    onSwipedDown: () => {
      htmlElRef.current?.blur();
    },
    onSwipedUp: () => {
      htmlElRef.current?.blur();
    },
    onTouchStartOrOnMouseDown: ({ event }) => {
      event.stopPropagation();
    },
  });

  const { sendMessage, isLoading: isSendingMessage } = useSendMessage({
    artistId: artist.id,
    messageChannelId: messageChannel.id,
    activeSubscriptionTier,
  });

  useEffect(() => {
    if (!isSendingMessage && shouldFocus && htmlElRef.current != null && WindowState.isDesktop) {
      htmlElRef.current?.focus();
      setShouldFocus(false);
    }
  }, [isSendingMessage, shouldFocus]);

  useEffect(() => {
    ActiveArtistMessageChannel.onNewMessage = ({ isOptimistic }) => {
      if (isOptimistic) {
        virtuosoRef.current?.scrollToIndex({ index: 0 });
      }
    };

    return () => {
      ActiveArtistMessageChannel.onNewMessage = null;
    };
  }, [virtuosoRef]);

  const { data } = useQuery(GetBanStatusDocument, {
    variables: !!artist.id && { artistId: artist.id },
    staleTime: 0,
  });

  const { loggedInUser } = useAuthContext();

  const hasImageAttachmentAccess =
    isOwner ||
    loggedInUser?.isOfficialVaultUser ||
    activeSubscriptionFeatures?.enabledFeatures.ChatSendImageAttachments === true;
  const hasVideoAttachmentAccess =
    isOwner ||
    loggedInUser?.isOfficialVaultUser ||
    activeSubscriptionFeatures?.enabledFeatures.ChatSendVideoAttachments === true;
  const hasAudioAttachmentAccess =
    isOwner ||
    loggedInUser?.isOfficialVaultUser ||
    activeSubscriptionFeatures?.enabledFeatures.ChatSendAudioAttachments === true;

  const createMediaAttachment = (
    mediaId: string | null | undefined,
    cdnUrl: string | null | undefined,
    mediaType: MediaType,
  ) => {
    if (mediaId && cdnUrl) {
      return {
        media: {
          id: mediaId,
          mediaType: mediaType,
          url: cdnUrl,
        },
      };
    }
    return null;
  };

  const onClick = useStableCallback(async (e?: SyntheticEvent) => {
    if (content.trim().length > MAX_MESSAGE_LENGTH) {
      openToast({
        text: `Messages cannot be more than ${MAX_MESSAGE_LENGTH} characters.`,
        variant: 'error',
      });
      return;
    }
    if (!messageChannel || !loggedInUser || isSendingMessage) return;
    e?.preventDefault();
    e?.stopPropagation();

    const messageAttachments = [
      ...messageImages
        .map(image => createMediaAttachment(image.mediaId, image.cdnUrl, MediaType.Image))
        .filter(Boolean),
      createMediaAttachment(messageVideo?.mediaId, messageVideo?.cdnUrl, MediaType.Video),
      createMediaAttachment(
        messageRecording?.mediaId,
        messageRecording?.cdnUrl,
        MediaType.Recording,
      ),
    ].filter(Boolean);

    sendMessage({
      content: content.trim(),
      vaultContent:
        attachedTrack != null
          ? [
              {
                id: attachedTrack,
                title: AttachTrackContentTitles.trackTitlesById[attachedTrack] || null,
              },
            ]
          : undefined,
      replyToMessage: replyToMessage,
      messageAttachments: messageAttachments,
      activeSubscriptionTier,
    });
    clearVideo();
    clearImages();
    clearRecording();
    setShouldFocus(true);
    setReplyToMessage(null);
    setContent('');
    setAttachedTrack(null);
  });

  // Don't add loading state to the input if the user is banned because
  // it results in a janky experience for most users
  const isBanned = !!data?.data?.getBanStatus && !isOwner;
  const isAudioRecording = isRecordingAudio || messageRecording;
  const { value: KILLSWITCH_CHAT } = useGate(FEATURE_GATES.KILLSWITCH_CHAT);
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);

  const isButtonDisabled = () => {
    const hasContentOrTrack = content.trim().length > 0 || attachedTrack != null;
    const hasMediaAttachments = messageImages.length > 0 || messageVideo || messageRecording;
    return (
      isBanned ||
      isUploadingImages ||
      isUploadingVideo ||
      isUploadingAudio ||
      isRecordingAudio ||
      (!hasContentOrTrack && !hasMediaAttachments)
    );
  };

  const isRecordButtonAvailable =
    !KILLSWITCH_CHAT &&
    !isBanned &&
    vaultId != null &&
    !messageRecording &&
    content.length === 0 &&
    messageImages.length === 0 &&
    attachedTrack == null &&
    (isOwner || loggedInUser?.isOfficialVaultUser || hasAudioAttachmentAccess);

  const renderLeftButton = (vaultId: string) => {
    if (isRecordingAudio) return null;

    return messageRecording ? (
      <Button
        label=""
        iconOnly
        leadingIcon={faTrash}
        leadingIconClassName="!text-base-m"
        className={twMerge(
          'mr-[8px] h-[40px] w-[40px] items-center justify-center rounded-full font-black',
          isThemeEnabled
            ? 'bg-vault_text/10 text-vault_text'
            : isNewMenuEnabled
              ? 'bg-base700 text-white md2:bg-white/10'
              : 'bg-base700 text-white',
        )}
        onClick={clearRecording}
        event={{
          type: EVENTS.REMOVE_ATTACH_AUDIO,
          properties: null,
        }}
      />
    ) : (
      <Button
        label=""
        iconOnly
        leadingIcon={faPlus}
        leadingIconClassName="text-[16px]"
        className={twMerge(
          'mr-[8px] h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full font-black',
          isThemeEnabled
            ? 'bg-vault_text/10 text-vault_text'
            : isNewMenuEnabled
              ? 'bg-base700 text-white md2:bg-white/10'
              : 'bg-base700 text-white',
        )}
        onClick={() => {
          if (
            isOwner ||
            loggedInUser?.isOfficialVaultUser ||
            hasImageAttachmentAccess ||
            hasVideoAttachmentAccess
          ) {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.ARTIST_MEDIA_ATTACHMENT,
              shared: {
                withVaultTheme: isThemeEnabled,
              },
              artistMediaAttachmentBottomsheetProps: {
                onAttachTrack: track => setAttachedTrack(track),
                vaultId,
                artist,
                alreadyAttachedTrack: attachedTrack,
                hasImageAttachmentAccess,
                hasVideoAttachmentAccess,
              },
            });
          } else {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.ADD_TRACK_ATTACHMENT,
              addTrackAttachmentBottomsheetProps: {
                onAttachTrack: track => setAttachedTrack(track),
                vaultId,
                alreadyAttachedTrack: attachedTrack,
                artist,
              },
            });
          }
        }}
        event={{
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType:
              isOwner || loggedInUser?.isOfficialVaultUser
                ? BOTTOMSHEET_TYPES.ARTIST_MEDIA_ATTACHMENT
                : BOTTOMSHEET_TYPES.ADD_TRACK_ATTACHMENT,
            vaultId,
            artistId: artist.id,
          },
        }}
      />
    );
  };

  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-col items-center justify-center p-4',
        attachedTrack != null && 'border-0 border-t-[1px] border-solid',
        isNewMenuEnabled
          ? isThemeEnabled
            ? 'border-0 border-t border-solid border-vault_text/5 bg-transparent'
            : 'border-0 border-t border-solid border-white/5 bg-transparent'
          : 'border-base700 bg-base900_alpha90 backdrop:blur-[50px]',
      )}
      onTouchDown={() => htmlElRef.current?.blur()}
    >
      {isBanned && (
        <View
          className={twMerge(
            'p-2 !text-base-s',
            isThemeEnabled ? 'text-vault_text/50' : 'text-neutral400',
          )}
        >
          You are banned from chat. You are unable to participate until a moderator unbans you.
        </View>
      )}
      {KILLSWITCH_CHAT && (
        <View
          className={twMerge(
            'p-2 !text-base-s',
            isThemeEnabled ? 'text-vault_text/50' : 'text-neutral400',
          )}
        >
          Sorry, chat is currently unavailable. Please try again later.
        </View>
      )}
      {attachedTrack != null && (
        <MinimizedMessageAttachment
          trackId={attachedTrack}
          onClose={() => {
            attachedTrack != null &&
              trackEvent({
                type: EVENTS.REMOVE_ATTACH_TRACK,
                properties: { trackId: attachedTrack },
                pathname,
              });
            setAttachedTrack(null);
          }}
        />
      )}
      <View className="flex w-full flex-row items-end justify-center">
        {!KILLSWITCH_CHAT && !isBanned && vaultId != null && (
          <>
            {renderLeftButton(vaultId)}

            <StyledInputContainer
              className={twMerge(
                'mb-0 min-h-6 max-w-[unset] flex-1 items-end px-[16px] py-[8px]',
                isThemeEnabled
                  ? 'bg-vault_text/10'
                  : isNewMenuEnabled
                    ? 'bg-base700 md2:bg-white/10'
                    : 'bg-base700',
              )}
              swipeableHandlers={swipeableHandlers}
            >
              {isAudioRecording ? (
                <>
                  {messageRecording ? (
                    <RecordingWaveform
                      height={20}
                      audioUrl={messageRecording.cdnUrl || messageRecording.objectUrl}
                      peaks={messageRecording.peaks}
                      duration={messageRecording.duration}
                      isLoading={isUploadingAudio || isProcessingAudioFile}
                      variant="recording"
                    />
                  ) : (
                    <RecordingIndicator />
                  )}
                </>
              ) : (
                <>
                  <TextareaAutosize
                    className={twMerge(
                      'm-0 mb-[2px] h-5 flex-1 select-text resize-none border-none bg-transparent font-base !text-base-l font-normal focus:border-none focus:outline-none',
                      isThemeEnabled
                        ? 'text-vault_text placeholder:text-vault_text/50'
                        : 'text-white',
                    )}
                    value={content}
                    onChange={e => setContent(e.target.value)}
                    disabled={KILLSWITCH_CHAT || isBanned || isSendingMessage}
                    placeholder="Message"
                    maxRows={20}
                    ref={htmlElRef}
                    onKeyDown={e => {
                      if (isBrowser && e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        if (!isBanned && (content.trim().length > 0 || attachedTrack != null)) {
                          onClick();
                        }
                      }
                    }}
                  />
                  {content.length == 0 && (
                    <Button
                      label=""
                      iconOnly
                      leadingIcon={faGif}
                      leadingIconClassName="text-[20px]"
                      className={twMerge(
                        'h-6 w-6',
                        isThemeEnabled ? 'text-vault_text' : 'text-white',
                      )}
                      onClick={gifButtonClick}
                    />
                  )}
                </>
              )}
            </StyledInputContainer>
          </>
        )}

        {isRecordButtonAvailable && (
          <RecordButton
            className={twMerge(
              'ml-[8px] h-[40px] w-[40px] items-center justify-center rounded-full !text-base-l',
              isThemeEnabled
                ? 'bg-vault_text/10'
                : isNewMenuEnabled
                  ? 'bg-base700 bg-white/10'
                  : 'bg-base700',
            )}
            artistId={artist.id}
            onRecordComplete={() => {
              clearImages();
              clearVideo();
              setContent('');
              setAttachedTrack(null);
            }}
          />
        )}

        {!isButtonDisabled() && (
          <View className="flex h-[40px] w-[32px] items-center justify-end">
            <Button
              onTouchDown={e => e?.stopPropagation()}
              label=""
              iconOnly
              leadingIcon={faArrowUp}
              className={twMerge(
                'h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full p-0 text-[16px]/[20px] font-black',
                isThemeEnabled ? 'bg-vault_accent text-vault_accent_text' : 'text-black',
                (isBanned || isSendingMessage) && 'bg-transparent',
              )}
              type="primary"
              disabledClassName="hidden"
              onClick={onClick}
              disabled={isButtonDisabled()}
              loading={isSendingMessage}
              event={{
                type: EVENTS.SEND_MESSAGE,
                properties: {
                  channelId: messageChannel.id,
                  attachmentIds: attachedTrack != null ? [attachedTrack] : [],
                  contentLength: content.length,
                  isVaultArtist: loggedInUser?.artist?.id === artist.id,
                  containsMessageRecording: messageRecording != null,
                  isReply: replyToMessage != null,
                  imageAttachmentsCount: messageImages.length,
                },
              }}
              hideIconWhenLoading
            />
          </View>
        )}
      </View>
    </View>
  );
};

const RecordingIndicator = () => {
  const { minutes, seconds } = useStopwatch({ autoStart: true });
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View className="m-0 mb-0.5 flex h-5 flex-1 animate-pulse flex-row items-center justify-between gap-2">
      <View className="flex items-center gap-2">
        <View className="relative h-3 w-3 rounded-full bg-delete" />
        <Text
          className={twMerge(
            '!text-base-m',
            isThemeEnabled ? 'text-vault_text/50' : 'text-base300',
          )}
        >
          Recording...
        </Text>
      </View>

      <View>
        <Text
          className={twMerge(
            '!text-base-s tabular-nums',
            isThemeEnabled ? 'text-vault_text/50' : 'text-base300',
          )}
        >
          {minutes} : {seconds.toString().padStart(2, '0')}
        </Text>
      </View>
    </View>
  );
};

const SkeletonMessageTextInput = () => {
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-row p-4',
        isNewMenuEnabled
          ? isThemeEnabled
            ? 'border-0 border-t border-solid border-vault_text/5 bg-vault_background md2:bg-transparent'
            : 'border-0 border-solid border-white/5 bg-black md2:border-x md2:border-t md2:bg-white/3'
          : 'bg-black',
      )}
    >
      <LoadingSkeleton
        className={twMerge(
          'mr-2 h-10 w-10 rounded-full',
          isThemeEnabled
            ? 'bg-vault_text/10'
            : isNewMenuEnabled
              ? 'bg-base700 md2:bg-white/10'
              : 'bg-base700',
        )}
      />
      <LoadingSkeleton
        className={twMerge(
          'flex min-h-6 flex-1 flex-row rounded-xl py-2 pl-4',
          isThemeEnabled
            ? 'bg-vault_text/10'
            : isNewMenuEnabled
              ? 'bg-base700 md2:bg-white/10'
              : 'bg-base700',
        )}
      />
    </View>
  );
};

export { MessageTextInput, SkeletonMessageTextInput };
