import type { FC, LegacyRef, ReactNode, UIEventHandler } from 'react';
import type { SwipeableHandlers } from 'react-swipeable';
import type { LongPressPointerHandlers } from 'use-long-press';
import type { OnClickType } from '../buttons/Button';

export interface ViewProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  containerRef?: LegacyRef<HTMLDivElement>;
  onClick?: OnClickType;
  swipeableHandlers?: SwipeableHandlers;
  onLongPress?: LongPressPointerHandlers<Element>;
  onTouchDown?: (event?: React.MouseEvent | React.TouchEvent) => void;
  onScroll?: UIEventHandler<HTMLDivElement>;
  onTouchMove?: (event: React.TouchEvent) => void;
}

export const View: FC<ViewProps> = ({
  children,
  className,
  containerRef,
  onClick,
  style,
  swipeableHandlers,
  onLongPress,
  onTouchDown,
  onScroll,
  onTouchMove,
  ...rest
}) => {
  return (
    <div
      className={className}
      ref={containerRef}
      onClick={onClick}
      style={style}
      {...swipeableHandlers}
      {...onLongPress}
      onMouseDown={onTouchDown}
      onTouchStart={onTouchDown}
      onScroll={onScroll}
      onTouchMove={onTouchMove}
      {...rest}
    >
      {children}
    </div>
  );
};
