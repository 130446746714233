import type { ReactNode, SyntheticEvent } from 'react';
import React from 'react';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Provider, Root, Viewport } from '@radix-ui/react-toast';
import { twMerge } from 'tailwind-merge';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons/index.mjs';
import {
  faClock,
  faInfoCircle,
  faTriangleExclamation,
} from '@soundxyz/font-awesome/pro-solid-svg-icons/index.mjs';
import { faCircleCheck } from '@soundxyz/font-awesome/sharp-solid-svg-icons/index.mjs';

import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { AccessibleLink } from './AccessibleLink';

import { View } from './View';

const TOAST_DURATION = 3000;

export const ToastVariant = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  PENDING: 'pending',
} as const;

export interface ToastProps {
  alignment?: 'left' | 'right';
  ctaOnClick?: (event: SyntheticEvent) => void;
  ctaHref?: string;
  ctaText?: string;
  duration?: number;
  isToastOpen: boolean;
  setToastOpen: (isOpen: boolean) => void;
  text: ReactNode;
  variant?: (typeof ToastVariant)[keyof typeof ToastVariant];
  withAudioPlayer?: boolean;
  className?: string;
}

const CtaElement = ({
  ctaHref,
  ctaText,
  ctaOnClick,
}: {
  ctaHref?: string;
  ctaText: string;
  ctaOnClick: (e: SyntheticEvent<Element, Event>) => void;
}) => {
  return ctaHref ? (
    <AccessibleLink
      absoluteUri={ctaHref}
      type="external"
      className="font-base text-base-m font-normal text-yellow100"
    >
      {ctaText}
    </AccessibleLink>
  ) : (
    <button
      onClick={ctaOnClick}
      className="border-none bg-transparent font-base text-base-m font-normal text-yellow100 underline outline-none focus:border-none focus:outline-none"
    >
      {ctaText}
    </button>
  );
};

export const Toast = ({
  alignment,
  ctaOnClick = () => null,
  ctaHref,
  ctaText,
  duration = TOAST_DURATION,
  isToastOpen,
  setToastOpen,
  text,
  variant,
  className,
}: ToastProps) => {
  const { isDesktop } = useWindow();
  let variantIcon: FontAwesomeIconProps['icon'] | null = null;
  let viewPortClassName = '';
  let variantClassName = '';
  switch (alignment) {
    case 'left':
      viewPortClassName += 'left-4';
      break;
    case 'right':
      viewPortClassName += 'right-4';
      break;
  }

  switch (variant) {
    case ToastVariant.SUCCESS:
      variantIcon = faCircleCheck;
      break;
    case ToastVariant.WARNING:
      variantIcon = faTriangleExclamation;
      break;
    case ToastVariant.ERROR:
      variantIcon = faTriangleExclamation;
      variantClassName += 'bg-destructive400';
      break;
    case ToastVariant.PENDING:
      variantIcon = faClock;
      break;
    case ToastVariant.PRIMARY:
      variantIcon = faInfoCircle;
      break;
  }

  return (
    <Provider>
      <Root
        open={isToastOpen}
        duration={duration}
        onOpenChange={setToastOpen}
        className={twMerge(
          'mx-auto flex w-[calc(100vw-64px)] items-center justify-between gap-5 rounded-md bg-base600 p-4 font-medium shadow-[0px_0px_0px_1px_rgba(0,0,0,0.04),0px_6px_20px_rgba(0,0,0,0.2)] sm:w-[calc(66vw-64px)] md:w-[calc(50vw-64px)] lg:w-[calc(33vw-64px)]',
          isDesktop ? '!w-fit' : 'sm:w-[calc(100vw-64px)] md:w-[calc(100vw-64px)]',
          variantClassName,
          className,
        )}
      >
        {variantIcon != null && <FontAwesomeIcon icon={variantIcon} color="white" size="xl" />}
        <View className="w-full">
          <View className="inline overflow-hidden font-base text-base-m font-normal text-white">
            {text}
          </View>{' '}
          {ctaText && <CtaElement ctaHref={ctaHref} ctaText={ctaText} ctaOnClick={ctaOnClick} />}
        </View>
        <Button
          onClick={() => setToastOpen(false)}
          leadingIcon={faXmark}
          iconOnly
          label=""
          className="text-white"
          event={{ type: EVENTS.CLOSE_TOAST, properties: { toastType: variant } }}
        />
      </Root>
      <Viewport
        className={twMerge(
          'absolute bottom-[84px] z-toast w-full list-none outline-none',
          isDesktop && 'bottom-4 w-[unset]',
          viewPortClassName,
        )}
      />
    </Provider>
  );
};
