import { type FC, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import { AuthUserDocument, CreateArtistDocument, VaultType } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  mutation createArtist($input: MutationCreateArtistInput!) {
    createArtist(input: $input) {
      __typename
      id
      linkValue
    }
  }
`);

RefetchOnComplete({
  trigger: [CreateArtistDocument],
  refetch: [AuthUserDocument],
});

export const TestUserSettingsPage: FC = () => {
  const { openToast } = useToast();
  const navigate = useNavigate();
  const { loginStatus, loggedInUser } = useAuthContext();
  const [handle, setHandle] = useState('');
  const [name, setName] = useState('');
  const [vaultType, setVaultType] = useState<VaultType>(VaultType.FreeOnly);

  const { mutateAsync: createArtist, isLoading: creatingArtist } = useMutation(
    CreateArtistDocument,
    {
      onError: error => {
        openToast({ text: error.message, variant: 'error' });
      },
    },
  );

  const onCreateArtist = useStableCallback(async () => {
    if (!loggedInUser?.id || !handle || !name) return;

    const data = await createArtist({
      input: {
        handle,
        name,
        userId: loggedInUser.id,
        type: vaultType,
      },
    });
    navigate(`/${data.data.createArtist.linkValue}`);
  });

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <SettingsLayout title="Test User Settings">
      <View className="mt-4 flex w-full flex-col gap-8">
        <View className="flex flex-col gap-2">
          <Text className="text-base-l font-semibold">Handle</Text>
          <View className="border-b-1 flex w-full flex-row items-center border-x-0 border-t-0 border-solid border-base700 py-3">
            <input
              className="w-full border-0 bg-black text-base-l text-white focus:font-normal focus:outline-none"
              placeholder="Artist Handle"
              value={handle}
              onChange={e => setHandle(e.target.value)}
            />
          </View>
        </View>

        <View className="flex flex-col gap-2">
          <Text className="text-base-l font-semibold">Name</Text>
          <View className="border-b-1 flex w-full flex-row items-center border-x-0 border-t-0 border-solid border-base700 py-3">
            <input
              className="w-full border-0 bg-black text-base-l text-white focus:font-normal focus:outline-none"
              placeholder="Artist Name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </View>
        </View>

        <View
          className="flex cursor-pointer flex-row justify-between"
          onClick={() => setVaultType(VaultType.FreeOnly)}
        >
          <Text className="text-base-l">Free Only Vault</Text>
          <input
            type="radio"
            className="custom-radio cursor-pointer"
            checked={vaultType === VaultType.FreeOnly}
          />
        </View>

        <View
          className="flex cursor-pointer flex-row justify-between"
          onClick={() => setVaultType(VaultType.Freemium)}
        >
          <Text className="text-base-l">Freemium Vault</Text>
          <input
            type="radio"
            className="custom-radio cursor-pointer"
            checked={vaultType === VaultType.Freemium}
          />
        </View>

        <View className="flex w-full items-center justify-center">
          <Button
            label="Create Artist Vault"
            buttonType="button"
            type="primary"
            disabled={!handle || !name}
            loading={creatingArtist}
            onClick={onCreateArtist}
          />
        </View>
      </View>
    </SettingsLayout>
  );
};
