import type { LegacyRef } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { useAuthContext } from '../../../../contexts/AuthContext';
import {
  type FragmentType,
  getFragment,
  TrackItemFragmentDoc,
} from '../../../../graphql/generated';
import { useActiveSubscriptionFeatures } from '../../../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../../../hooks/useUpsellInterstitials';
import { LoginStatus } from '../../../../types/authTypes';
import { TrackItem } from '../Track';
import { useTrackFilePress } from '../useTrackFilePress';

export const UserTrack = ({
  track,
  containerRef,
  artistHandle,
}: {
  track: FragmentType<TrackItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const { id: trackId, vault, parentVaultContentId } = getFragment(TrackItemFragmentDoc, track);
  const { loginStatus } = useAuthContext();

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = !isOwner && activeSubscriptionFeatures?.tier == null;

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const showUpsellInterstitial =
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const { onPlayClick } = useTrackFilePress({
    vaultId: vault.id,
    trackId,
    isLocked,
    showUpsellInterstitial,
    folderId: parentVaultContentId,
    artistHandle,
    vaultArtistProfileImage: vault.artist?.profileImage?.url ?? '',
  });

  return <TrackItem track={track} containerRef={containerRef} onPlayClick={onPlayClick} />;
};
