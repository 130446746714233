import React, { type LegacyRef } from 'react';

import { gql } from '@soundxyz/gql-string';
import { RefetchOnComplete } from '../../../graphql/effects';
import {
  type FragmentType,
  GetBatchedTracksViewedDocument,
  UpsertUserContentViewDocument,
  type VaultItemFragmentDoc,
  VaultUpdateCountsDocument,
} from '../../../graphql/generated';
import { VaultItemArtist } from './artist/VaultItemArtist';
import { VaultItemUser } from './user/VaultItemUser';

//TODO: Support videos and images
gql(/* GraphQL */ `
  fragment VaultItem on VaultContent {
    id
    __typename
    parentVaultContentId
    title
    ... on VaultTrack {
      id
      vault {
        id
        artistProfile {
          id
          profileImage {
            id
            url
          }
        }
      }
      ...TrackItem
    }
    ... on VaultFolder {
      id
      ...FolderItem
    }

    ... on VaultVideo {
      id
      ...VideoItem
    }

    ... on VaultImage {
      id
      ...ImageItem
    }
  }

  mutation UpsertUserContentView($input: MutationUpsertUserViewContentInput!) {
    upsertUserViewContent(input: $input) {
      id
    }
  }
`);

RefetchOnComplete({
  trigger: [UpsertUserContentViewDocument],
  refetch: [GetBatchedTracksViewedDocument, VaultUpdateCountsDocument],
  throttled: '500ms',
});

const VaultItemComponent = React.memo(
  ({
    item,
    containerRef,
    vaultId,
    artistHandle,
    isOwner,
  }: {
    item: FragmentType<VaultItemFragmentDoc>;
    containerRef?: LegacyRef<HTMLDivElement>;
    vaultId: string;
    artistHandle: string;
    isOwner: boolean;
  }) => {
    if (isOwner) {
      return (
        <VaultItemArtist
          item={item}
          containerRef={containerRef}
          vaultId={vaultId}
          artistHandle={artistHandle}
        />
      );
    }

    return <VaultItemUser item={item} containerRef={containerRef} artistHandle={artistHandle} />;
  },
);

VaultItemComponent.displayName = 'VaultItem';

export const VaultItem = React.memo(VaultItemComponent);
