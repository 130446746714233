import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import {
  faComment,
  faFolderPlus,
  faHome,
  faIdCard,
  faMobile,
  faPlus,
  type IconDefinition,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faComment as faCommentSolid,
  faHome as faHomeSolid,
  faIdCard as faIdCardSolid,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useAudioController } from '../../audio/AudioController';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument, FeatureTypename, TierTypename } from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import {
  FOLDER_TITLE_MAX_LENGTH,
  FOLDER_TITLE_MIN_LENGTH,
  useVaultFolder,
} from '../../hooks/vault/useVaultFolder';
import { type EventObject, EVENTS, type EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { getSubdomain, isValidSubdomain } from '../../utils/subdomainUtils';
import { TextInputModal } from '../bottomsheets/TextInputModal';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { UploadButton } from '../layouts/ArtistLayout';
import { ArtistLogo } from '../vault/items/artist/ArtistLogo';
import { useBatchedVaultMessageUnreadCount } from '../views/hooks/useVaultMessageUnreadCount';

export function SecondaryMenu({
  selectedHandleMemo,
}: {
  selectedHandleMemo: string | null | undefined;
}) {
  const { value: membershipEnabled } = useGate(FEATURE_GATES.MEMBERSHIP);
  const location = useLocation();

  const { setIsSelecting } = useSelectVaultContent();

  const { loggedInUser } = useAuthContext();
  const isOwner = loggedInUser?.artist?.linkValue === selectedHandleMemo;

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const vaultTheme = useSnapshot(VaultThemeStore);

  const { hideAudioPlayer, activeTrackId } = useAudioController();
  const hasAudioPlayer = activeTrackId !== null && !hideAudioPlayer;

  const { data } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!selectedHandleMemo && {
      link: selectedHandleMemo.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: selectedHandleMemo != null,
  });

  const mainVault = data?.data.artistLink?.artist.mainVault;
  const messageChannelId = mainVault?.messageChannelId;
  const vaultId = mainVault?.id;

  const hasActiveSubscription = !!mainVault?.activeSubscription?.id;
  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: mainVault?.activeSubscription,
    isOwner,
  });

  const chatAvailableForFreeUsers = mainVault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const chatEnabled =
    (isOwner || hasChatReadAccess || !!chatAvailableForFreeUsers) && !!loggedInUser?.id;

  const { unreadCount: messageNotificationCount } = useBatchedVaultMessageUnreadCount({
    messageChannelId,
    vaultId,
    enabled: chatEnabled,
  });

  const showMembershipPageIcon = hasActiveSubscription && membershipEnabled && !isOwner;

  const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const isSubdomain = !!subdomainArtistHandle;

  const isHomeSelected = isSubdomain
    ? location.pathname === '/'
    : location.pathname.endsWith(`/${selectedHandleMemo}`) ||
      location.pathname.endsWith(`/${selectedHandleMemo}/`);
  const isChatSelected = location.pathname.includes('/chat');

  const isMembershipSelected = location.pathname.includes('/membership');

  useEffect(() => {
    if (!isOwner) {
      setIsSelecting(false);
    }
  }, [isOwner, setIsSelecting]);

  // This prevents the menu from showing on non vault pages
  if (selectedHandleMemo == null || !vaultId) {
    return null;
  }

  return (
    <View
      className={twMerge(
        'no-scrollbar absolute bottom-0 left-[72px] top-0 z-above2 box-border flex w-[200px] flex-col items-start justify-between overflow-y-scroll pb-0 pl-8 pt-6',
        isThemeEnabled ? 'bg-vault_background' : 'bg-black',
      )}
    >
      <View
        className={twMerge(
          'mt-[6px] flex w-full flex-col overflow-hidden',
          hasAudioPlayer && 'mb-[90px]',
        )}
      >
        <View className="flex flex-col gap-1 overflow-auto scrollbar-thin scrollbar-none scrollbar-track-transparent scrollbar-thumb-neutral700 scrollbar-track-rounded-md scrollbar-thumb-rounded-md scrollbar-w-1">
          {isThemeEnabled && !!vaultTheme.logoMediaUrl && (
            <ArtistLogo src={vaultTheme.logoMediaUrl} alt="Artist logo" />
          )}

          <MenuItem
            href={artistNavigationPath(selectedHandleMemo, '/')}
            icon={isHomeSelected ? faHomeSolid : faHome}
            label="Home"
            selected={isHomeSelected}
            showCountIndicator={false}
            event={{
              type: EVENTS.SECONDARY_MENU_NAVIGATE,
              properties: { artistHandle: selectedHandleMemo, path: '/' },
            }}
          />

          <MenuItem
            href={artistNavigationPath(selectedHandleMemo, '/chat')}
            icon={isChatSelected ? faCommentSolid : faComment}
            label="Chat"
            selected={isChatSelected}
            showCountIndicator={!!messageNotificationCount && hasChatReadAccess}
            event={{
              type: EVENTS.SECONDARY_MENU_NAVIGATE,
              properties: { artistHandle: selectedHandleMemo, path: '/chat' },
            }}
          />

          {showMembershipPageIcon && (
            <MenuItem
              href={artistNavigationPath(selectedHandleMemo, '/membership')}
              icon={isMembershipSelected ? faIdCardSolid : faIdCard}
              label="Membership"
              selected={isMembershipSelected}
              showCountIndicator={false}
              event={{
                type: EVENTS.SECONDARY_MENU_NAVIGATE,
                properties: { artistHandle: selectedHandleMemo, path: '/membership' },
              }}
            />
          )}

          {isOwner && <CreateButton artistHandle={selectedHandleMemo} vaultId={vaultId} />}
        </View>
      </View>
    </View>
  );
}

function MenuItem<Event extends EventType>(
  props: {
    icon: IconDefinition;
    label: string;
    selected: boolean;
    showCountIndicator: boolean;
  } & ({ onClick: () => void } | { event: EventObject<Event> | undefined; href: string }),
) {
  const location = useLocation();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  return (
    <View className="relative">
      <Button
        href={'href' in props ? props.href : undefined}
        className={twMerge(
          'flex h-14 max-h-14 min-h-14 w-full flex-row items-center justify-start gap-3 rounded-xl p-3 !font-base !text-[16px] !font-medium outline-none transition-all duration-500 ease-in-out focus:outline-none active:outline-none',
          isThemeEnabled
            ? props.selected
              ? 'text-vault_text'
              : 'text-vault_text/50'
            : props.selected
              ? 'text-white'
              : 'text-white/50',
          isThemeEnabled ? 'hover:bg-vault_text/10' : 'hover:bg-white/10',
        )}
        label={props.label}
        leadingIcon={props.icon}
        leadingIconClassName={twMerge(
          'mr-1 aspect-square w-8 text-[24px]',
          !isThemeEnabled && (props.selected ? 'text-white' : 'text-white/50'),
          !isThemeEnabled && 'hover:bg-white/10',
        )}
        onClick={() => {
          if ('event' in props && !!props.event) {
            trackEvent({ ...props.event, pathname: location.pathname });
          } else if ('onClick' in props) {
            props.onClick();
          }
        }}
      />

      {props.showCountIndicator && (
        <View
          className={twMerge(
            'absolute left-[30px] top-[14px] h-2 w-2 items-center justify-center rounded-full border-2 border-solid',
            isThemeEnabled ? 'border-vault_background bg-vault_text' : 'border-black100 bg-white',
          )}
        />
      )}
    </View>
  );
}

function CreateButton({
  artistHandle,
  vaultId,
}: {
  artistHandle: string | undefined;
  vaultId: string;
}) {
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const location = useLocation();
  const isInChat = location.pathname.includes('/chat');

  const { value: foldersEnabled } = useGate(FEATURE_GATES.FOLDERS);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);
  const { value: isVaultMediaEnabled } = useGate(FEATURE_GATES.VAULT_MEDIA);

  const { createFolder } = useVaultFolder({
    vaultId,
  });

  const onCreateFolderClick = useStableCallback(() => {
    if (artistHandle == null || vaultId == null) return;
    openOverlay(
      <TextInputModal
        title="Create New Folder"
        confirmText="Create"
        placeholder="Untitled"
        minLength={FOLDER_TITLE_MIN_LENGTH}
        maxLength={FOLDER_TITLE_MAX_LENGTH}
        onConfirm={async text => {
          await createFolder({
            title: text,
            folderId,
            onSuccess: () => {
              if (isInChat) {
                navigate(artistNavigationPath(artistHandle, ''));
              }
              closeOverlay();
            },
          });
        }}
      />,
    );
  });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        className={twMerge(
          'flex h-12 max-h-12 min-h-12 w-full cursor-pointer flex-row items-center justify-start gap-3 rounded-full border-none p-3 !font-base !text-[16px] !font-medium outline-none transition-all duration-500 ease-in-out focus:outline-none',
          isThemeEnabled
            ? 'bg-vault_accent text-vault_accent_text hover:bg-vault_accent/80'
            : 'bg-yellow100 text-black hover:bg-yellow100/80',
        )}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-1 aspect-square w-8 text-[24px]" />
        <Text>Create</Text>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          className={twMerge(
            'z-stickyHeader flex min-w-[250px] flex-col gap-0.5 rounded-lg',
            isThemeEnabled ? 'bg-vault_text/10 backdrop-blur-2xl' : 'bg-base900',
          )}
        >
          <DropdownMenu.Item asChild>
            {!!artistHandle && !!vaultId ? (
              <UploadButton
                folderId={folderId ?? null}
                customLabel={isVaultMediaEnabled ? 'Upload media' : 'Upload track'}
                artistLinkValue={artistHandle}
                vaultId={vaultId}
                className={twMerge(
                  'flex w-full cursor-pointer items-center justify-start gap-1.5 self-stretch rounded-b-none rounded-t-lg border-none p-4 font-medium outline-none',
                  isThemeEnabled
                    ? 'bg-vault_text/10 text-vault_text hover:bg-vault_text/20'
                    : 'bg-base800 text-white hover:bg-white/10',
                )}
                showIcon
              />
            ) : null}
          </DropdownMenu.Item>

          <DropdownMenu.Item
            onClick={() => {
              if (!artistHandle) return;
              navigate(artistNavigationPath(artistHandle, '/announcements/create'));
            }}
            className={twMerge(
              'flex cursor-pointer items-center gap-1.5 self-stretch border-none p-4 outline-none',
              foldersEnabled ? '' : 'rounded-b-lg ',
              isThemeEnabled
                ? 'bg-vault_text/10 text-vault_text hover:bg-vault_text/20'
                : 'bg-base800 text-white hover:bg-white/10',
            )}
          >
            <FontAwesomeIcon icon={faMobile} fontSize={16} className="mr-2 aspect-square" />
            <Text className="font-title text-title-s">Text members</Text>
          </DropdownMenu.Item>

          {foldersEnabled && (
            <DropdownMenu.Item
              onClick={onCreateFolderClick}
              className={twMerge(
                'flex cursor-pointer items-center gap-1.5 self-stretch rounded-b-lg border-none p-4 outline-none',
                isThemeEnabled
                  ? 'bg-vault_text/10 text-vault_text hover:bg-vault_text/20'
                  : 'bg-base800 text-white hover:bg-white/10',
              )}
            >
              <FontAwesomeIcon icon={faFolderPlus} fontSize={16} className="mr-2 aspect-square" />
              <Text className="font-title text-title-s">Create folder</Text>
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
