import type { FC, LegacyRef, MouseEventHandler, ReactNode } from 'react';
import React from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  containerRef?: LegacyRef<HTMLDivElement>;
  onClick?: () => void;
  onMouseEnter?: MouseEventHandler<HTMLParagraphElement>;
  onMouseLeave?: MouseEventHandler<HTMLParagraphElement>;
};

export const Text: FC<Props> = ({
  children,
  className,
  containerRef,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <p
      className={className}
      ref={containerRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </p>
  );
};
