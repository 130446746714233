import { type LegacyRef, type RefObject, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faFilm } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPlay } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { type FragmentType, getFragment, VideoItemFragmentDoc } from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { useActiveSubscriptionFeatures } from '../../../hooks/useTierFeatures';
import { useWindow } from '../../../hooks/useWindow';
import { getDurationAsTime } from '../../../utils/dateUtils';
import { formatDateString } from '../../../utils/textUtils';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

gql(/* GraphQL */ `
  fragment VideoItem on VaultVideo {
    id
    title
    linkValue
    createdAt

    isFullVersionAvailable
    blurredMediaUrl
    uploadedMedia {
      id
      mediaType
      url
    }

    vaultId
    duration

    parentVaultContentId

    featureAccess {
      feature {
        __typename
      }
    }

    vault {
      id
      artist: artistProfile {
        id
        profileImage {
          id
          url
        }
      }
      isUserArtistAdmin
      activeSubscription {
        id
        createdAt
        ...ActiveSubscriptionFeatures
      }
    }
  }
`);
export const VideoItem = ({
  video,
  containerRef,
  onPlayClick,
  videoRef,
  hasEllipsis,
}: {
  video: FragmentType<VideoItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  videoRef: RefObject<HTMLVideoElement>;
  onPlayClick?: () => void;
  hasEllipsis?: boolean;
}) => {
  const { isDesktop } = useWindow();
  const {
    title,
    createdAt,
    duration,
    id: videoId,
    vault,
    uploadedMedia,
    blurredMediaUrl,
  } = getFragment(VideoItemFragmentDoc, video);

  useEffect(() => {
    if (videoRef.current != null) {
      videoRef.current.load();
    }
  }, [videoRef]);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: videoId });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const couldBeNew =
    !isOwner && vault.activeSubscription != null && vault.activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || (isVaultCustomizeOpen && isThemeEnabled));
  }, [couldBeNew, isLoading, isThemeEnabled, isVaultCustomizeOpen, isViewed]);

  return (
    <View
      className="group flex w-full flex-grow cursor-pointer select-none flex-col gap-3 pb-3 text-left"
      containerRef={containerRef}
      onClick={onPlayClick}
    >
      <View
        className={twMerge(
          'relative flex min-h-[110px] w-full items-center justify-center rounded-md  transition-all duration-300 ease-in-out',
          isThemeEnabled
            ? 'bg-vault_text/10 group-hover:bg-vault_text/20'
            : isNewMenuEnabled
              ? 'bg-white/10 group-hover:bg-white/20'
              : 'bg-base800/70 group-hover:bg-base800',
        )}
      >
        <AnimatePresence>
          {isNew && (
            <motion.div
              className={twMerge(
                'absolute right-3 top-2 rounded-full font-base !text-base-xs font-semibold text-yellow100',
                isThemeEnabled ? 'text-vault_accent' : 'text-yellow100',
              )}
              exit={{ opacity: 0 }}
            >
              New
            </motion.div>
          )}
        </AnimatePresence>
        <View className="relative flex h-[74px] items-start justify-center">
          {!isLocked ? (
            <video
              ref={videoRef}
              className={twMerge(
                'block h-full w-14 cursor-pointer rounded-lg object-cover',
                isThemeEnabled ? 'bg-vault_text/10' : 'bg-base900',
              )}
              preload="metadata"
              controls={!isDesktop}
              controlsList="nodownload"
              src={uploadedMedia?.url}
              onClick={e => e.preventDefault()}
            />
          ) : (
            <>
              {blurredMediaUrl ? (
                <View className="relative flex h-full w-14 items-center justify-center">
                  <img
                    className={twMerge(
                      'block h-full w-14 cursor-pointer rounded-lg object-cover',
                      isThemeEnabled ? 'bg-vault_text/10' : 'bg-base900',
                    )}
                    src={blurredMediaUrl}
                    onClick={e => e.preventDefault()}
                  />
                  <div
                    className={twMerge(
                      'absolute flex h-6 w-6 items-center justify-center rounded-full',
                      isThemeEnabled ? 'bg-vault_text_opposite/50' : 'bg-black/60',
                    )}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      className={twMerge(
                        'text-[10px]',
                        isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
                      )}
                    />
                  </div>
                </View>
              ) : (
                <>
                  <View className="bg-new-file-background h-[74px] w-[54px] bg-contain bg-no-repeat" />
                  <View className="absolute top-5 flex flex-col items-center justify-center gap-1.5">
                    <FontAwesomeIcon
                      icon={faFilm}
                      className={twMerge(
                        'text-[24px]',
                        isThemeEnabled ? 'text-vault_text' : 'text-white',
                      )}
                    />
                    <FontAwesomeIcon
                      icon={faLock}
                      className={twMerge(
                        'text-[11px]',
                        isThemeEnabled ? 'text-vault_text' : 'text-base300',
                      )}
                    />
                  </View>
                </>
              )}
            </>
          )}
        </View>
      </View>
      <View className="flex flex-col gap-1">
        <View className="flex w-full justify-between gap-1">
          <Text
            className={twMerge(
              'line-clamp-2 text-ellipsis break-words font-base !text-base-m font-medium',
              isThemeEnabled ? 'text-vault_text' : 'text-white',
            )}
          >
            {title ?? 'Untitled'}
          </Text>
          {!!hasEllipsis && <div className="h-5 w-5 flex-shrink-0" />}
        </View>
        <Text
          className={twMerge(
            'text-[12px] font-normal leading-[16px]',
            isThemeEnabled
              ? 'text-vault_text/50'
              : isNewMenuEnabled
                ? 'text-white/50'
                : 'text-base50/50',
          )}
        >
          {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
          &nbsp;&middot;&nbsp;
          {getDurationAsTime(duration)}
        </Text>
      </View>
    </View>
  );
};
