import { enumChecker } from '@soundxyz/utils/const';

const ModalType = {
  IMAGE_CROP: 'IMAGE_CROP',
  LISTEN_NOW: 'LISTEN_NOW',
  CUSTOMIZE_VAULT: 'CUSTOMIZE_VAULT',
} as const;

const isModalType = enumChecker(ModalType);

export { ModalType, isModalType };
