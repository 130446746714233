import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnapshot } from 'valtio';
import { faSquare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faSquareCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  type FiltersSchema,
  filtersState,
  initialFilters,
  useMySubscribers,
} from '../../hooks/useMySubscribers';
import { useStableCallback } from '../../hooks/useStableCallback';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

type InternalState = {
  sort: FiltersSchema['sort'];
  subscribers: boolean | undefined;
  freeTier: boolean | undefined;
  cancelled: boolean | undefined;
};

export function SubscribersFiltersBottomsheet() {
  const { refetch } = useMySubscribers();

  const { closeBottomsheet } = useBottomsheetContainer();

  const { sort, status, tierLevel } = useSnapshot(filtersState);

  const [state, setState] = useState<InternalState>({
    sort,
    cancelled: undefined,
    freeTier: undefined,
    subscribers: undefined,
  });

  const applyFilters = useStableCallback(() => {
    filtersState.sort = state.sort;
    filtersState.status = !!state.cancelled ? 'INACTIVE' : undefined;
    filtersState.tierLevel =
      state.subscribers && !state.freeTier
        ? 'PAID'
        : !state.subscribers && state.freeTier
          ? 'FREE'
          : undefined;

    refetch();
    closeBottomsheet();
  });

  useEffect(() => {
    if (!!sort || !!status || !!tierLevel) {
      setState(prev => ({
        ...prev,
        cancelled: status == null ? undefined : status === 'INACTIVE',
        freeTier: tierLevel == null ? undefined : tierLevel === 'FREE',
        subscribers: tierLevel == null ? undefined : tierLevel === 'PAID',
      }));
    }
  }, [sort, status, tierLevel]);

  return (
    <View className="w-full overflow-x-hidden md2:flex md2:h-full md2:flex-col md2:justify-center">
      <View className="my-[20px] flex w-full flex-col items-center justify-center gap-6 md2:px-6">
        <View className="flex w-full flex-col items-center justify-center gap-3 px-6">
          <Text className="text-center font-title text-title-l font-medium text-white">
            Sort & view
          </Text>

          <Text className="self-start text-center font-base text-base-m font-medium text-base500">
            Date joined
          </Text>
        </View>

        <View className="flex w-full flex-col self-start md:w-[91%]">
          <Button
            label="Recent"
            type="secondary"
            className="mb-[1px]"
            position="top"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Recent</Text>
                <input type="radio" className="custom-radio !m-0" checked={state.sort === 'DESC'} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, sort: 'DESC' }))}
            fullWidthLabel
          />
          <Button
            label="Oldest"
            type="secondary"
            position="bottom"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Oldest</Text>
                <input type="radio" className="custom-radio !m-0" checked={state.sort === 'ASC'} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, sort: 'ASC' }))}
            fullWidthLabel
          />
        </View>

        <View className="flex w-full flex-col items-center justify-center gap-3 px-6">
          <Text className="self-start text-center font-base text-base-m font-medium text-base500">
            Show
          </Text>
        </View>

        <View className="flex w-full flex-col self-start md:w-[91%]">
          <Button
            label="Paid Members"
            type="secondary"
            className="mb-[1px]"
            position="top"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Paid Members</Text>
                <Checkbox selected={!!state.subscribers} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, subscribers: !state.subscribers }))}
            fullWidthLabel
          />
          <Button
            label="Free Tier"
            className="mb-[1px]"
            type="secondary"
            position="middle"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Free Tier</Text>
                <Checkbox selected={!!state.freeTier} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, freeTier: !state.freeTier }))}
            fullWidthLabel
          />
          <Button
            label="Cancelled"
            type="secondary"
            position="bottom"
            labelComponent={
              <View className="flex w-full items-center justify-between">
                <Text>Cancelled</Text>
                <Checkbox selected={!!state.cancelled} />
              </View>
            }
            onClick={() => setState(prev => ({ ...prev, cancelled: !state.cancelled }))}
            fullWidthLabel
          />
        </View>

        <View className="flex w-full flex-col items-center justify-center gap-6 self-start md:w-[91%]">
          <Button
            label="Apply"
            isExternal
            type="primary"
            className="w-full"
            linkClassName="w-full"
            onClick={applyFilters}
          />

          <Button
            label="Reset"
            onClick={() => {
              Object.assign(filtersState, initialFilters);
              setState({
                sort: 'DESC',
                cancelled: undefined,
                freeTier: undefined,
                subscribers: undefined,
              });
            }}
            type="default"
            className="w-full !font-title !text-title-s !font-medium text-white"
          />
        </View>
      </View>
    </View>
  );
}

function Checkbox({ selected }: { selected: boolean }) {
  return (
    <View className="h-5 min-h-5 w-5 min-w-5">
      <FontAwesomeIcon
        icon={selected ? faSquareCheck : faSquare}
        fontSize={20}
        className={selected ? 'text-yellow100' : 'text-white'}
      />
    </View>
  );
}
