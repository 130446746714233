import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faImage } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ACCEPTED_IMAGE_TYPES } from '../../constants/fileConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ImagesAttachment } from '../../contexts/ImagesMessageAttachmentContext';
import { useToast } from '../../contexts/ToastContext';
import { useImageAttachments } from '../../hooks/useImageMessageAttachments';
import { Text } from '../common/Text';

const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB

export const ImageMessageUpload = ({
  artistId,
  onImageSelect,
}: {
  artistId: string;
  onImageSelect: () => void;
}) => {
  const { openToast } = useToast();
  const { closeBottomsheet } = useBottomsheetContainer();
  const { images } = useSnapshot(ImagesAttachment);
  const { saveImageAttachments } = useImageAttachments();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_IMAGE_TYPES,
    multiple: true,
    maxSize: MAX_FILE_SIZE,
    maxFiles: 4,
    onDrop: (acceptedFiles, fileRejections) => {
      const limitExceeded = fileRejections.some(rejection =>
        rejection.errors.some(error => error.code === 'too-many-files'),
      );

      if (limitExceeded) {
        openToast({
          text: `You can only upload up to 4 image files at once. Please select fewer files.`,
          variant: 'error',
        });
        return;
      }

      const sizeError = fileRejections.some(rejection =>
        rejection.errors.some(error => error.code === 'file-too-large'),
      );

      if (sizeError) {
        openToast({
          text: 'One or more files are too large. Please upload files smaller than 2GB.',
          variant: 'error',
        });
        return;
      }

      if (images.length + acceptedFiles.length > 4) {
        openToast({
          text: 'You can only upload a total of 4 image files.',
          variant: 'error',
        });
        return;
      }
      onImageSelect();
      saveImageAttachments(artistId, acceptedFiles);
      closeBottomsheet();
    },
  });

  return (
    <div {...getRootProps()} className="flex cursor-pointer flex-row items-center gap-2">
      <input {...getInputProps()} />
      <FontAwesomeIcon
        icon={faImage}
        size="sm"
        className={isThemeEnabled ? 'text-vault_text' : 'text-white'}
      />
      <Text
        className={twMerge(
          '!text-base-l font-medium',
          isThemeEnabled ? 'text-vault_text' : 'text-white',
        )}
      >
        Upload an image
      </Text>
    </div>
  );
};
