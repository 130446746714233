import { type LegacyRef, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import millify from 'millify';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { gql } from '@soundxyz/gql-string';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { FolderItemFragmentDoc, type FragmentType, getFragment } from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { formatDateString, pluralizeText } from '../../../utils/textUtils';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

gql(/* GraphQL */ `
  fragment FolderItem on VaultFolder {
    id
    title
    createdAt
    parentVaultContentId
    childrenVaultCount
    nestedChildrenVaultContentCount
    linkValue
    vault {
      id
      activeSubscription {
        id
        createdAt
      }
    }
  }
`);

export const FolderItem = ({
  folderInfo,
  isOwner,
  containerRef,
  onClick,
  editingFolder,
  hasEllipsis,
}: {
  folderInfo: FragmentType<FolderItemFragmentDoc>;
  isOwner: boolean;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
  onClick?: () => void;
  editingFolder: { id: string; title: string; isEditing: boolean } | null;
  hasEllipsis?: boolean;
}) => {
  const {
    id: folderId,
    title,
    createdAt,
    childrenVaultCount,
    vault: { activeSubscription },
  } = getFragment(FolderItemFragmentDoc, folderInfo);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);
  const vaultTheme = useSnapshot(VaultThemeStore);

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: folderId });

  const isEditingFolder = editingFolder?.id === folderId;

  const couldBeNew =
    !isOwner && activeSubscription != null && activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || (isVaultCustomizeOpen && isThemeEnabled));
  }, [couldBeNew, isLoading, isThemeEnabled, isVaultCustomizeOpen, isViewed]);

  return (
    <View
      className="flex w-full flex-grow cursor-pointer select-none flex-col gap-3 pb-3 text-left"
      containerRef={containerRef}
      onClick={onClick}
    >
      <View
        className={twMerge(
          'relative flex min-h-[110px] w-full items-center justify-center rounded-md transition-all duration-300 ease-in-out',
          isThemeEnabled
            ? 'bg-vault_text/10 group-hover:bg-vault_text/20'
            : isNewMenuEnabled
              ? 'bg-white/10 group-hover:bg-white/20'
              : 'bg-base800 bg-base800/70 group-hover:bg-base800',
        )}
      >
        <AnimatePresence>
          {isNew && (
            <motion.div
              className={twMerge(
                'absolute right-3 top-2 rounded-full font-base !text-base-xs font-semibold',
                isThemeEnabled ? 'text-vault_accent' : 'text-yellow100',
              )}
              exit={{ opacity: 0 }}
            >
              New
            </motion.div>
          )}
        </AnimatePresence>
        <View
          className={twMerge(
            'h-[74px] w-[76px] bg-contain bg-no-repeat',
            isThemeEnabled
              ? vaultTheme.mode === 'light'
                ? 'bg-folder-background-black'
                : 'bg-folder-background-white'
              : 'bg-folder-background-white',
          )}
        />
      </View>
      <View className="flex flex-col gap-1">
        <View className="flex w-full justify-between gap-1">
          <Text
            className={twMerge(
              'line-clamp-2 text-ellipsis break-words font-base !text-base-m font-medium',
              isThemeEnabled ? 'text-vault_text' : 'text-white',
            )}
          >
            {isEditingFolder ? editingFolder?.title : title ?? 'Untitled'}
          </Text>
          {!!hasEllipsis && <div className="h-5 w-5 flex-shrink-0" />}
        </View>
        <Text
          className={twMerge(
            'text-[12px] font-normal leading-[16px]',
            isThemeEnabled
              ? 'text-vault_text/50'
              : isNewMenuEnabled
                ? 'text-white/50'
                : 'text-base50/50',
          )}
        >
          {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
          &nbsp;&middot;&nbsp;
          {millify(childrenVaultCount, { precision: 0 })}&nbsp;
          {pluralizeText({
            count: childrenVaultCount,
            text: 'file',
          })}
        </Text>
      </View>
    </View>
  );
};
