import { proxy } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../contexts/AuthContext';
import { useMutation, useQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import { SeenMembershipCardDocument, UserArtistMembershipDocument } from '../graphql/generated';
import { LoginStatus } from '../types/authTypes';

gql(/* GraphQL */ `
  query UserArtistMembership($artistHandle: String!) {
    userArtistMembership(artistHandle: $artistHandle) {
      artistId
      serialNumber
      unseenUpdates
      createdAt
      points
      cardFirstTimeSeen
      artist {
        id
        name
        membershipCardImage {
          id
          url
        }
        mainVault {
          id
          isUserArtistAdmin
          activeSubscription {
            id
          }
        }
      }
      user {
        id
        displayName
        username
        avatar {
          id
          url
        }
      }
    }
  }

  mutation SeenMembershipCard($artistHandle: String!) {
    membershipCardSeen(artistHandle: $artistHandle) {
      __typename
    }
  }
`);

RefetchOnComplete({
  trigger: [SeenMembershipCardDocument],
  refetch: [UserArtistMembershipDocument],
  throttled: '500ms',
});

export const VaultToShowBadge = proxy<{ [key: string]: boolean }>({});
export function setBadgeVisibility(artistHandle: string, vaultId: string, isVisible: boolean) {
  VaultToShowBadge[vaultId] = isVisible;
  VaultToShowBadge[artistHandle] = isVisible;
}

export function useMembership({
  artistHandle,
  hasActiveSubscription = true,
}: {
  artistHandle: string | null | undefined;
  hasActiveSubscription?: boolean;
}) {
  const { loggedInUser, loginStatus } = useAuthContext();

  const { data, isLoading } = useQuery(UserArtistMembershipDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      artistHandle,
    },
    enabled: !!artistHandle && loginStatus === LoginStatus.LOGGED_IN && hasActiveSubscription,
    filterQueryKey: { loggedInUserId: loggedInUser?.id },
  });

  const { mutateAsync: seenMembershipCard, isLoading: isLoadingSeenMembershipCard } = useMutation(
    SeenMembershipCardDocument,
    {},
  );
  return {
    membership: data?.data.userArtistMembership,
    isLoading,
    seenMembershipCard,
    isLoadingSeenMembershipCard,
  };
}
