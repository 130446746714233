import { captureException, captureMessage } from '@sentry/react';
import { gql } from '@soundxyz/gql-string';
import { useToast } from '../contexts/ToastContext';
import { useMutation } from '../graphql/client';
import { EditSnippetDocument } from '../graphql/generated';

gql(/* GraphQL */ `
  mutation EditSnippet($input: MutationEditVaultContentSnippetInput!) {
    editVaultContentSnippet(input: $input) {
      __typename
      ... on MutationEditVaultContentSnippetSuccess {
        __typename
      }
      ... on Error {
        message
      }
    }
  }
`);

export function useSnippet() {
  const { openToast } = useToast();

  const { mutateAsync: editSnippet, isLoading: isEditingSnippet } = useMutation(
    EditSnippetDocument,
    {
      retry: 3,
      onSuccess: (data, { input: { startTime, endTime } }) => {
        const minutes = Math.round((((endTime ?? startTime + 25) - startTime) * 2) / 60) + 2;
        if (
          data.data.editVaultContentSnippet.__typename === 'MutationEditVaultContentSnippetSuccess'
        ) {
          openToast({
            text: `Snippet edited successfully. Your snippet video will be available in ~${minutes} minutes.`,
            variant: 'success',
          });
        } else if (data.data.editVaultContentSnippet.__typename === 'NotFoundError') {
          captureMessage('NotFoundError in editVaultContentSnippet', {
            extra: {
              data: data.data.editVaultContentSnippet.message,
            },
            level: 'error',
          });
          openToast({
            text: 'There was an error creating the snippet. Try again.',
            variant: 'error',
          });
        } else {
          captureMessage('Error in editVaultContentSnippet', {
            extra: {
              data: data.data.editVaultContentSnippet.message,
            },
            level: 'error',
          });
          openToast({
            text: 'There was an error creating the snippet. Try again.',
            variant: 'error',
          });
        }
      },
      onError: error => {
        captureException(error, {
          extra: {
            data: error.message,
          },
          level: 'error',
        });
        openToast({
          text: 'There was an error creating the snippet. Try again.',
          variant: 'error',
        });
      },
    },
  );

  return { editSnippet, isEditingSnippet };
}
