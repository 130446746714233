import React from 'react';
import prettyBytes from 'pretty-bytes';
import { TrackUpload, useTrackUpload } from '../../contexts/TrackUploadContext';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function TrackUploadingTracker() {
  const { isUploadingTrack, retryUpload } = useTrackUpload();

  if (!isUploadingTrack && !retryUpload) return null;

  return <TrackUploadingView />;
}

function onCloseClick() {
  TrackUpload.isUploadingTrack = false;
  TrackUpload.retryUpload = null;
}

function TrackUploadingView() {
  const { progressSize, totalSize, retryUpload } = useTrackUpload();

  const percentage = Math.round((progressSize / totalSize) * 100);

  const closeButton = React.useMemo(
    () => (
      <svg
        className="cursor-pointer"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        onClick={onCloseClick}
      >
        <path
          d="M10.7812 2.28125L7.03125 6.03125L10.75 9.75C11.0625 10.0312 11.0625 10.5 10.75 10.7812C10.4688 11.0938 10 11.0938 9.71875 10.7812L5.96875 7.0625L2.25 10.7812C1.96875 11.0938 1.5 11.0938 1.21875 10.7812C0.90625 10.5 0.90625 10.0312 1.21875 9.71875L4.9375 6L1.21875 2.28125C0.90625 2 0.90625 1.53125 1.21875 1.21875C1.5 0.9375 1.96875 0.9375 2.28125 1.21875L6 4.96875L9.71875 1.25C10 0.9375 10.4688 0.9375 10.7812 1.25C11.0625 1.53125 11.0625 2 10.7812 2.28125Z"
          fill="#A3A3A3"
        />
      </svg>
    ),
    [],
  );

  const totalSizePretty = React.useMemo(
    () => prettyBytes(totalSize, { space: false }),
    [totalSize],
  );

  const progressSizePretty = React.useMemo(
    () => prettyBytes(progressSize, { space: false }),
    [progressSize],
  );

  if (retryUpload) {
    return (
      <View className="absolute bottom-[100px] z-stickyFooter flex w-full flex-row justify-center">
        <View className="mx-4 flex w-full flex-col gap-2 rounded-lg bg-destructive700 px-4 py-3 sm:w-[66%] md:w-[50%] lg:w-[33%]">
          <View className="flex flex-1 flex-row items-center justify-between py-3">
            <View className="flex flex-col gap-1 font-base">
              <View className="flex flex-row gap-1">
                <span className="text-base-m font-semibold">Your upload failed.</span>

                <span
                  onClick={retryUpload}
                  className="cursor-pointer text-base-m font-semibold underline"
                >
                  Try again.
                </span>
              </View>
            </View>
            {closeButton}
          </View>
        </View>
      </View>
    );
  }

  return (
    <View className="absolute bottom-[100px] z-stickyFooter flex w-full flex-row justify-center">
      <View className="mx-4 flex w-full flex-col gap-2 rounded-lg bg-base600 px-4 py-3 sm:w-[66%] md:w-[50%] lg:w-[33%]">
        <View className="flex flex-1 flex-row items-center justify-between ">
          <View className="flex flex-col gap-1 font-base">
            <Text className="text-base-m font-semibold">Uploading 1 file...</Text>
            <Text className="text-base-s font-normal">
              <span className="text-yellow100">{progressSizePretty}</span>
              <span className="text-base500"> / {totalSizePretty}</span>
            </Text>
          </View>
          {closeButton}
        </View>
        <View className="h-1 w-full rounded-full bg-white bg-opacity-30">
          <View
            className="h-1 w-1/4 rounded-full bg-yellow100 transition-all"
            style={{
              width: `${percentage}%`,
            }}
          />
        </View>
      </View>
    </View>
  );
}
