import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { type ReplyToMessageFragment } from '../../graphql/generated';
import { useUserDisplayName } from '../../hooks/useUserDisplayName';
import { setReplyToMessage } from '../../hooks/useVaultMessageChannel';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const ReplyToMessage = ({ message }: { message: ReplyToMessageFragment }) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const userDisplayName = useUserDisplayName({
    artistName: message.asArtist?.name,
    userDisplayName: message.user.displayName,
    userId: message.user.id,
    userUsername: message.user.username,
  });

  return (
    <View
      className={twMerge(
        'flex flex-row items-center gap-4 border-0 border-t border-solid px-4 py-3',
        isThemeEnabled ? 'border-vault_text/5' : 'border-base700',
      )}
    >
      <View
        className={twMerge(
          'flex grow flex-col gap-1',
          isThemeEnabled ? 'text-vault_text' : 'text-white',
        )}
      >
        <Text className="font-base !text-base-s font-semibold">{`Replying to ${userDisplayName}`}</Text>
        {message.content ? (
          <Text className="m-0 line-clamp-2 select-none whitespace-pre-line break-all font-base !text-base-m font-normal [overflow-wrap:anywhere]">
            {message.content}
          </Text>
        ) : (
          <Text className="m-0 line-clamp-2 select-none whitespace-pre-line break-all font-base !text-base-s font-normal italic [overflow-wrap:anywhere]">
            On an attachment
          </Text>
        )}
      </View>
      <Button
        label=""
        trailingIcon={faXmark}
        className={twMerge('h-4 w-4', isThemeEnabled && 'text-vault_text')}
        onClick={() => setReplyToMessage(null)}
      />
    </View>
  );
};
