import { useCallback, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { chunk, compact, isArray } from 'lodash-es';
import { Virtuoso } from 'react-virtuoso';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faImage, faMessage } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faThumbtack } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faImage as faImageSolid,
  faMessage as faMessageSolid,
  faThumbtack as faThumbtackSolid,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';

import { gql } from '@soundxyz/gql-string';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useInfiniteQuery, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import type { MediaType } from '../../graphql/generated';
import {
  CreateMessageReactionDocument,
  DeleteMessageDocument,
  DeleteMessageReactionDocument,
  FeatureTypename,
  GetAttachmentsInMessageChannelDocument,
  MessageBubbleFragmentDoc,
  PinMessageDocument,
  RemovePinnedMessageDocument,
  TierTypename,
} from '../../graphql/generated';
import {
  type FragmentType,
  GetArtistMessagesDocument,
  getFragment,
  GetPinnedMessagesDocument,
  SeeDetailsHeaderFragmentDoc,
} from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useWindow } from '../../hooks/useWindow';
import { DetailsTabStore, type SeeDetailsTab } from '../../screens/SeeDetailsMessageChannelPage';
import { SkeletonProfiePicture } from '../../screens/settings/EditArtistPage';
import { compareDates, dateToTime, getMonthAndDate, isSamePeriod } from '../../utils/dateUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { MediaViewer } from '../message/MediaViewer';
import { MessageBubble, SkeletonMessageBubble } from '../message/MessageBubble';
import { EmptyStateView } from './EmptyStateView';

gql(/* GraphQL */ `
  fragment SeeDetailsHeader on MessageChannel {
    id
    vault {
      id
      artistProfile {
        id
        name
        linkValue
        profileImage {
          id
          url
        }
      }
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
  }

  query GetArtistMessages(
    $artistHandle: String!
    $asArtistId: UUID
    $after: String
    $first: Int = 20
  ) {
    artistMessagesInChannel(
      artistHandle: $artistHandle
      includeTrackComments: false
      after: $after
      first: $first
    ) {
      edges {
        node {
          id
          createdAt
          ...messageBubble
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  query GetPinnedMessages($input: QueryMessageChannelByArtistHandleInput!, $asArtistId: UUID) {
    messageChannelByArtistHandle(input: $input) {
      id
      vault {
        id
        artist: artistProfile {
          id
        }
      }
      pinnedMessages {
        id
        createdAt
        ...messageBubble
        ...pinnedMessage
      }
    }
  }

  query GetAttachmentsInMessageChannel($channelId: UUID!) {
    messageChannelAttachments(channelId: $channelId, mediaTypes: [VIDEO, IMAGE]) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          createdAt
          uploadedMedia {
            id
            mediaType
            url
          }
        }
      }
    }
  }
`);

type Props = {
  tab: SeeDetailsTab;
  artistHandle: string;
  isHeaderLoading: boolean;
  messageChannel: FragmentType<SeeDetailsHeaderFragmentDoc> | null;
  isAtTop: boolean;
  setIsAtTop: (isAtTop: boolean) => void;
};

const LIMIT = 20;

RefetchOnComplete({
  trigger: [
    CreateMessageReactionDocument,
    DeleteMessageReactionDocument,
    DeleteMessageDocument,
    PinMessageDocument,
    RemovePinnedMessageDocument,
  ],
  refetch: [GetPinnedMessagesDocument, GetArtistMessagesDocument],
});

export const SeeDetailsMessageChannelView = ({
  messageChannel,
  isHeaderLoading,
  tab,
  artistHandle,
  isAtTop,
  setIsAtTop,
}: Props) => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const { isDesktop } = useWindow();
  const { loggedInUser } = useAuthContext();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const [hasScrolled, setHasScrolled] = useState(false);

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const onSetTab = useStableCallback((tab: SeeDetailsTab) => {
    setIsAtBottom(false);

    DetailsTabStore.produceExistingState(
      draft => {
        draft[artistHandle] = tab;
      },
      { [artistHandle]: tab },
    );
  });

  const messageChannelFrag = getFragment(SeeDetailsHeaderFragmentDoc, messageChannel);

  const now = useMemo(() => new Date(), []);

  const isAuthor =
    loggedInUser?.adminArtists?.some(admin => admin.artistLinkValue === artistHandle) ?? false;

  const channelId = messageChannelFrag?.id;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: messageChannelFrag?.vault?.activeSubscription,
    isOwner: loggedInUser?.artist?.id === messageChannelFrag?.vault?.artistProfile?.id,
  });
  const hasChatWriteAccess = activeSubscriptionFeatures?.enabledFeatures.ChatWrite === true;

  const {
    orderedList: artistMessages,
    isLoading: isLoadingArtistMessages,
    isError: isErrorArtistMessages,
    hasNextPage: hasNextArtistMessagesPage,
    loadMoreNextPage: loadMoreArtistMessages,
    refetch: refetchArtistMessages,
  } = useInfiniteQuery(GetArtistMessagesDocument, {
    enabled: tab === 'artist',
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.artistMessagesInChannel.pageInfo.hasNextPage && {
          after: data.artistMessagesInChannel.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        artistHandle,
        after: pageParam?.after ?? null,
        first: LIMIT,
        asArtistId: loggedInUser?.artist?.id,
      };
    },
    list: ({ artistMessagesInChannel }) => {
      return artistMessagesInChannel.edges.map(e => ({ ...e.node, tab: 'artist' as const }));
    },
    uniq: ({ id }) => id,
    filterQueryKey: {
      artistHandle,
    },
  });

  const {
    data: pinnedMessagesData,
    isLoading: isLoadingPinnedMessages,
    isError: isErrorPinnedMessages,
    refetch: refetchPinnedMessages,
  } = useQuery(GetPinnedMessagesDocument, {
    staleTime: 0,
    variables: {
      input: { artistHandle: artistHandle.toLowerCase() },
      asArtistId: loggedInUser?.artist?.id,
    },
    enabled: tab === 'pinned',
  });

  const {
    orderedList: attachmentsData,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
    hasNextPage: hasNextAtttachmentsPage,
    isLoadingNewPage: isLoadingNewAttachments,
    loadMoreNextPage: loadMoreAttachments,
    refetch: refetchAttachments,
  } = useInfiniteQuery(GetAttachmentsInMessageChannelDocument, {
    enabled: tab === 'attachments' && !!channelId,
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.messageChannelAttachments.pageInfo.hasNextPage && {
          after: data.messageChannelAttachments.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!channelId &&
      (({ pageParam }) => {
        return {
          channelId,
          after: pageParam?.after ?? null,
          first: LIMIT,
        };
      }),
    list: ({ messageChannelAttachments }) => {
      return compact(
        messageChannelAttachments.edges.map(
          e =>
            e.node.uploadedMedia != null &&
            (e.node.uploadedMedia.mediaType === 'VIDEO' ||
              e.node.uploadedMedia.mediaType === 'IMAGE') && {
              createdAt: e.node.createdAt,
              ...e.node.uploadedMedia,
              tab: 'attachments' as const,
            },
        ),
      );
    },
    uniq: ({ id }) => id,
    filterQueryKey: {
      artistHandle,
    },
  });

  const onEndReached = useCallback(() => {
    switch (tab) {
      case 'artist':
        if (hasNextArtistMessagesPage) {
          loadMoreArtistMessages();
        }
        break;
      case 'pinned':
        break;
      case 'attachments':
        if (hasNextAtttachmentsPage) {
          loadMoreAttachments();
        }
        break;
    }
  }, [
    hasNextArtistMessagesPage,
    hasNextAtttachmentsPage,
    loadMoreArtistMessages,
    loadMoreAttachments,
    tab,
  ]);

  const onRetryClick = useCallback(() => {
    switch (tab) {
      case 'artist':
        return refetchArtistMessages();
      case 'pinned':
        return refetchPinnedMessages();
      case 'attachments':
        return refetchAttachments();
    }
  }, [refetchArtistMessages, refetchAttachments, refetchPinnedMessages, tab]);

  const pinnedMessages = useMemo(
    () => pinnedMessagesData?.data.messageChannelByArtistHandle?.pinnedMessages ?? [],
    [pinnedMessagesData?.data.messageChannelByArtistHandle?.pinnedMessages],
  );

  const data = useMemo(() => {
    switch (tab) {
      case 'artist':
        return artistMessages;
      case 'pinned':
        return pinnedMessages.map(m => ({ ...m, tab: 'pinned' as const }));
      case 'attachments':
        return chunk(attachmentsData, isDesktop ? 4 : 3);
    }
  }, [artistMessages, attachmentsData, isDesktop, pinnedMessages, tab]);

  const onView = useCallback(
    (index: number) => {
      openOverlay(
        <MediaViewer
          medias={attachmentsData.map(m => ({
            id: m.id,
            url: m.url,
            type: m.mediaType,
          }))}
          titles={attachmentsData.map(m => getMonthAndDate(new Date(m.createdAt)))}
          subtitles={attachmentsData.map(m => dateToTime(m.createdAt))}
          startAt={index}
          onClose={closeOverlay}
          title=""
          isLoadingNextPage={isLoadingNewAttachments}
        />,
      );
    },
    [attachmentsData, closeOverlay, isLoadingNewAttachments, openOverlay],
  );

  const renderItems = useCallback(
    (
      index: number,
      message:
        | ({ tab: 'artist' | 'pinned' } & FragmentType<MessageBubbleFragmentDoc>)
        | { id: string; url: string; mediaType: MediaType; tab: 'attachments' }[],
    ) => {
      if (isArray(message)) {
        const medias = message;
        return (
          <View className="grid w-full grid-cols-3 md2:grid-cols-4">
            {medias.map((media, i) => {
              return (
                <Media
                  key={media.id}
                  {...media}
                  index={index * (isDesktop ? 4 : 3) + i}
                  onView={onView}
                />
              );
            })}
          </View>
        );
      }

      const { user, asArtist, createdAt } = getFragment(MessageBubbleFragmentDoc, message);
      const isAuthor = asArtist
        ? asArtist.id === loggedInUser?.artist?.id
        : user.id === loggedInUser?.id;

      const isVaultArtist =
        asArtist?.id != null && messageChannelFrag?.vault?.artistProfile?.id === asArtist.id;
      const areSubscriptionTierBadgesVisible = messageChannelFrag?.vault?.tiers
        ?.find(tier => tier.__typename === TierTypename.FreeTier)
        ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatWrite);

      const nextMessage = data[index - 1];

      const nextMessageIsInDifferentGroup =
        nextMessage == null ||
        (!isArray(nextMessage) &&
          (nextMessage?.tab === 'artist' || nextMessage?.tab === 'pinned') &&
          !isSamePeriod(new Date(createdAt), new Date(nextMessage.createdAt), 2));

      const dateMemo = nextMessageIsInDifferentGroup ? (
        <p
          className={twMerge(
            'pb-2 text-center !text-base-s',
            isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
          )}
        >
          <b>{compareDates(new Date(createdAt), now)}</b> {dateToTime(createdAt)}
        </p>
      ) : null;

      return (
        <>
          {dateMemo}
          <MessageBubble
            message={message}
            artistProfileImageUrl={asArtist?.profileImage?.url}
            artistName={asArtist?.name}
            isElevated={false}
            areSubscriptionTierBadgesVisible={!!areSubscriptionTierBadgesVisible}
            isOwner={isAuthor}
            isVaultArtist={isVaultArtist}
            vaultArtistId={messageChannelFrag?.vault?.artistProfile?.id}
            containerMarginRight="-60px"
            type="see_details"
            hasChatWriteAccess={hasChatWriteAccess}
            vaultId={messageChannelFrag?.vault?.id}
          />
        </>
      );
    },
    [
      data,
      hasChatWriteAccess,
      isDesktop,
      isThemeEnabled,
      loggedInUser?.artist?.id,
      loggedInUser?.id,
      messageChannelFrag?.vault?.artistProfile?.id,
      messageChannelFrag?.vault?.id,
      messageChannelFrag?.vault?.tiers,
      now,
      onView,
    ],
  );

  const EmptyFooter = useCallback(() => <View className="h-20" />, []);
  const EmptyHeader = useCallback(() => tab !== 'attachments' && <View className="h-4" />, [tab]);

  const isLoading =
    isHeaderLoading || isLoadingArtistMessages || isLoadingPinnedMessages || isLoadingAttachments;

  const isError = isErrorArtistMessages || isErrorPinnedMessages || isErrorAttachments;

  return (
    <>
      <Header
        messageChannel={messageChannel}
        tab={tab}
        onSetTab={onSetTab}
        isHeaderLoading={isHeaderLoading}
        hasScrolled={hasScrolled}
        isMinimzed={!isDesktop && !isAtTop}
      />
      <View className="w-full flex-1">
        {isError ? (
          <ErrorView
            className="flex-1"
            onRetryClick={onRetryClick}
            withVaultTheme={isThemeEnabled}
          />
        ) : isLoading ? (
          <LoadingList tab={tab} isVaultArtist={isAuthor} />
        ) : data.length === 0 ? (
          <EmptyState tab={tab} />
        ) : (
          <Virtuoso
            onScroll={() => {
              setHasScrolled(true);
            }}
            data={data}
            endReached={onEndReached}
            overscan={{ reverse: 100, main: 100 }}
            itemContent={renderItems}
            className={twMerge(
              'w-full flex-1',
              isThemeEnabled ? 'scrollbar-theme' : 'scrollbar-dark',
            )}
            atTopStateChange={state => !isAtBottom && setIsAtTop(state)}
            atBottomStateChange={setIsAtBottom}
            components={{ Footer: EmptyFooter, Header: EmptyHeader }}
          />
        )}
      </View>
    </>
  );
};

const Header = ({
  messageChannel,
  isHeaderLoading,
  tab,
  onSetTab,
  isMinimzed = false,
  hasScrolled,
}: {
  isHeaderLoading: boolean;
  messageChannel: FragmentType<SeeDetailsHeaderFragmentDoc> | null;
  tab: SeeDetailsTab;
  onSetTab: (tab: SeeDetailsTab) => void;
  isMinimzed?: boolean;
  hasScrolled: boolean;
}) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const messageChannelFrag = getFragment(SeeDetailsHeaderFragmentDoc, messageChannel);

  const { artistProfile } = messageChannelFrag?.vault ?? {};

  if (isHeaderLoading || messageChannelFrag == null || artistProfile == null) {
    return (
      <View className="flex w-full flex-col items-center">
        <SkeletonProfiePicture
          className={twMerge(
            'mb-4 h-[86px] w-[86px] rounded-full',
            isThemeEnabled && 'bg-vault_text/10',
          )}
        />
        <LoadingSkeleton
          className={twMerge('mb-2 h-[34px] w-[250px]', isThemeEnabled && 'bg-vault_text/10')}
        />
        <LoadingSkeleton
          className={twMerge('h-[14px] w-[200px]', isThemeEnabled && 'bg-vault_text/10')}
        />
        <View className="flex w-full flex-row pt-2">
          <Button
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              isThemeEnabled
                ? 'border-vault_background text-vault_text'
                : 'border-base700 text-white',
              tab === 'artist' && (isThemeEnabled ? 'border-vault_text' : 'border-white'),
            )}
            label=""
            iconOnly
            leadingIcon={faMessageSolid}
          />
          <Button
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              isThemeEnabled
                ? 'border-vault_background text-vault_text'
                : 'border-base700 text-white',
              tab === 'pinned' && (isThemeEnabled ? 'border-vault_text' : 'border-white'),
            )}
            label=""
            iconOnly
            leadingIcon={faThumbtackSolid}
          />
          <Button
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              isThemeEnabled
                ? 'border-vault_background text-vault_text'
                : 'border-base700 text-white',
              tab === 'attachments' && (isThemeEnabled ? 'border-vault_text' : 'border-white'),
            )}
            label=""
            iconOnly
            leadingIcon={faImageSolid}
          />
        </View>
      </View>
    );
  }

  const { profileImage, name } = artistProfile;

  return (
    <View className="flex w-full flex-col items-center">
      <AnimatePresence>
        {!isMinimzed && (
          <motion.div
            className="flex w-full flex-col items-center"
            initial={hasScrolled && { opacity: 0, height: 0 }}
            animate={hasScrolled && { opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <ArtistProfileImage
              profileImageUrl={profileImage?.url}
              className="mb-4 h-[86px] w-[86px] rounded-full"
            />
            <Text
              className={twMerge(
                'mb-1 line-clamp-1 font-title !text-title-xl font-medium',
                isThemeEnabled ? 'text-vault_text' : 'text-white',
              )}
            >
              {name}
            </Text>
            <Text
              className={twMerge(
                'line-clamp-1 font-base !text-base-m font-medium',
                isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
              )}
            >
              Artist messages and media
            </Text>
          </motion.div>
        )}
      </AnimatePresence>

      <View className={twMerge('flex w-full flex-row', !isMinimzed && 'pt-2')}>
        <Button
          onClick={() => onSetTab('artist')}
          className={twMerge(
            'flex-1 border-0 border-b border-solid py-5 text-[20px]',
            isThemeEnabled
              ? 'border-vault_background text-vault_text'
              : 'border-base700 text-white',
            tab === 'artist' && (isThemeEnabled ? 'border-vault_text' : 'border-white'),
          )}
          label=""
          iconOnly
          leadingIcon={faMessageSolid}
        />
        <Button
          onClick={() => onSetTab('pinned')}
          className={twMerge(
            'flex-1 border-0 border-b border-solid py-5 text-[20px]',
            isThemeEnabled
              ? 'border-vault_background text-vault_text'
              : 'border-base700 text-white',
            tab === 'pinned' && (isThemeEnabled ? 'border-vault_text' : 'border-white'),
          )}
          label=""
          iconOnly
          leadingIcon={faThumbtackSolid}
        />
        <Button
          onClick={() => onSetTab('attachments')}
          className={twMerge(
            'flex-1 border-0 border-b border-solid py-5 text-[20px]',
            isThemeEnabled
              ? 'border-vault_background text-vault_text'
              : 'border-base700 text-white',
            tab === 'attachments' && (isThemeEnabled ? 'border-vault_text' : 'border-white'),
          )}
          label=""
          iconOnly
          leadingIcon={faImageSolid}
        />
      </View>
    </View>
  );
};

const Media = ({
  url,
  mediaType,
  index,
  onView,
}: {
  url: string;
  mediaType: MediaType;
  index: number;
  onView: (index: number) => void;
}) => {
  const onClick = () => onView(index);

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { isDesktop } = useWindow();

  if (mediaType === 'VIDEO') {
    return (
      <View
        className={twMerge(
          'box-border flex aspect-1 w-full border border-solid',
          isThemeEnabled ? 'border-vault_background' : 'border-black',
        )}
      >
        <video
          preload="metadata"
          src={url}
          className={twMerge(
            'block h-full w-full cursor-pointer',
            isThemeEnabled ? 'bg-vault_text/80' : 'bg-base700',
          )}
          onClick={e => {
            // on mobile web, the video automatically goes full screen
            if (isDesktop) {
              e.preventDefault();
              onClick();
            }
          }}
          controls
          controlsList="nodownload"
        />
      </View>
    );
  }

  return (
    <Image
      alt="Attachment"
      src={url}
      className={twMerge(
        'box-border aspect-1 w-full cursor-pointer border border-solid object-cover',
        isThemeEnabled ? 'border-vault_background bg-vault_text/80' : 'border-black bg-base700',
      )}
      onClick={onClick}
    />
  );
};

const LoadingList = ({ tab, isVaultArtist }: { tab: SeeDetailsTab; isVaultArtist: boolean }) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  if (tab === 'attachments') {
    return (
      <View
        className={twMerge(
          'grid w-full grid-cols-3 gap-[1px] md2:grid-cols-4',
          isThemeEnabled ? 'scrollbar-theme' : 'scrollbar-dark',
        )}
      >
        <LoadingSkeleton
          className={twMerge('aspect-1 w-full rounded-none', isThemeEnabled && 'bg-vault_text/10')}
        />
        <LoadingSkeleton
          className={twMerge('aspect-1 w-full rounded-none', isThemeEnabled && 'bg-vault_text/10')}
        />
        <LoadingSkeleton
          className={twMerge('aspect-1 w-full rounded-none', isThemeEnabled && 'bg-vault_text/10')}
        />
        <LoadingSkeleton
          className={twMerge('aspect-1 w-full rounded-none', isThemeEnabled && 'bg-vault_text/10')}
        />
        <LoadingSkeleton
          className={twMerge('aspect-1 w-full rounded-none', isThemeEnabled && 'bg-vault_text/10')}
        />
        <LoadingSkeleton
          className={twMerge('aspect-1 w-full rounded-none', isThemeEnabled && 'bg-vault_text/10')}
        />
      </View>
    );
  }
  return (
    <View
      className={twMerge(
        'mt-4 box-border flex w-full flex-1 flex-col gap-4 px-4',
        isThemeEnabled ? 'scrollbar-theme' : 'scrollbar-dark',
      )}
    >
      <SkeletonMessageBubble isAuthor={isVaultArtist} />
      <SkeletonMessageBubble isAuthor={isVaultArtist} />
      <SkeletonMessageBubble isAuthor={isVaultArtist} />
    </View>
  );
};

const EmptyState = ({ tab }: { tab: SeeDetailsTab }) => {
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  if (tab === 'artist') {
    return (
      <EmptyStateView
        title="No messages"
        subtitle="This artist has not sent any messages yet."
        icon={faMessage}
        className={twMerge('h-full', isThemeEnabled && 'text-vault_text')}
        iconClassName="text-[60px]"
        subtitleClassName={isThemeEnabled ? 'text-vault_text/50' : undefined}
      />
    );
  }
  if (tab === 'pinned') {
    return (
      <EmptyStateView
        title="No pinned messages"
        subtitle="Messages pinned by the artist will appear here."
        icon={faThumbtack}
        className={twMerge('h-full', isThemeEnabled && 'text-vault_text')}
        iconClassName="text-[60px]"
        subtitleClassName={isThemeEnabled ? 'text-vault_text/50' : undefined}
      />
    );
  }

  return (
    <EmptyStateView
      title="No attachments"
      subtitle="Photos and videos shared by the artist will appear here"
      icon={faImage}
      className={twMerge('h-full', isThemeEnabled && 'text-vault_text')}
      iconClassName="text-[60px]"
      subtitleClassName={isThemeEnabled ? 'text-vault_text/50' : undefined}
    />
  );
};
