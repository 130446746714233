import { type FC, type ReactNode, useRef, useState } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Content, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useWindow } from '../../hooks/useWindow';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Button } from '../buttons/Button';
import { useFullScreenAudioPlayerState } from '../main/AudioPlayer';
import { View } from './View';

export type DropdownAlign = 'start' | 'center' | 'end';

type Props = {
  onOpenChange?: () => void;
  trigger?: ReactNode; //Must be a ForwardRef component or wrapped in one
  disabled?: boolean;
  align?: DropdownAlign;
  children: ReactNode;
};

export const Dropdown: FC<Props> = ({ onOpenChange, trigger, disabled, align, children }) => {
  const { isBottomAudioPlayerOpen } = useFullScreenAudioPlayerState();
  const isScrolling = useRef(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onPointerDownCapture={event => {
        // prevent opening dropdown when scrolling on touch devices
        if (event.pointerType === 'touch' && !isOpen) event.preventDefault();
      }}
      onTouchMove={() => {
        isScrolling.current = true;
      }}
      onTouchEnd={() => {
        if (!isScrolling.current && !isOpen) setIsOpen(true);
        isScrolling.current = false;
      }}
      onClick={e => {
        //prevent events on the parent element of the dropdown to get executed instead of the dropdown elements events
        e.stopPropagation();
      }}
    >
      <Root
        open={isOpen}
        onOpenChange={open => {
          setIsOpen(open);
          onOpenChange?.();
        }}
      >
        <Trigger disabled={disabled} asChild>
          {trigger}
        </Trigger>

        <Content
          align={align}
          className="z-dropdown overflow-clip rounded-xl shadow-tertiary shadow-black"
          collisionPadding={{
            bottom: 72 + (isBottomAudioPlayerOpen ? 87 : 0),
            right: 16,
            left: 16,
            top: 16,
          }}
          onClick={() => setIsOpen(false)}
        >
          {children}
        </Content>
      </Root>
    </div>
  );
};

export const ActionDropdown: FC<ActionBottomsheetProps> = ({ buttons, withVaultTheme }) => {
  const { openBottomsheet } = useBottomsheetContainer();
  const { pathname } = useLocation();

  return (
    <View
      className={twMerge(
        'flex w-full flex-col gap-y-[2px] overflow-hidden rounded-xl',
        withVaultTheme ? 'bg-vault_background backdrop-blur-2xl' : 'bg-black',
      )}
    >
      {buttons.map((button, index) => (
        <Button
          {...button}
          className={twMerge('rounded-none', button.className)}
          key={button.key ?? index}
          onClick={
            button.onClick != null && button.requireConfirmation
              ? () =>
                  openBottomsheet({
                    type: 'CONFIRMATION',
                    confirmationBottomsheetProps: {
                      onConfirm:
                        button.onClick != null
                          ? e => {
                              button.event != null &&
                                trackEvent({
                                  type: button.event.type,
                                  properties: button.event.properties,
                                  pathname,
                                });

                              button.onClick?.(e);
                            }
                          : () => null,
                      subText: button.confirmationSubText,
                    },
                  })
              : button.onClick
          }
          event={
            button.onClick != null && button.requireConfirmation
              ? {
                  type: EVENTS.OPEN_BOTTOMSHEET,
                  properties: {
                    bottomsheetType: BOTTOMSHEET_TYPES.CONFIRMATION,
                    event: button.event?.type,
                  },
                }
              : button.event
          }
        />
      ))}
    </View>
  );
};

export const DropdownEllipsis: FC<{
  desktopClassname?: string;
  onClick: () => void; // NOTE: Only applies on mobile view
  buttons: ActionBottomsheetProps['buttons'];
  dropdownType: string;
  sharedClassname?: string;
  withVaultTheme?: boolean;
}> = ({ onClick, buttons, dropdownType, desktopClassname, sharedClassname, withVaultTheme }) => {
  return (
    <ResponsiveDropdown
      desktopClassName={clsx(sharedClassname, desktopClassname)}
      onClick={onClick}
      buttons={buttons}
      dropdownType={dropdownType}
      label=""
      trailingIcon={faEllipsis}
      desktopIconOnly
      mobileIconOnly
      mobileClassName={sharedClassname}
      withVaultTheme={withVaultTheme}
    />
  );
};

export const ResponsiveDropdown: FC<{
  onClick: () => void;
  buttons: ActionBottomsheetProps['buttons'];
  dropdownType: string;
  align?: DropdownAlign;

  withVaultTheme?: boolean;

  label: string;
  trailingIcon?: IconProp;
  leadingIcon?: IconProp;
  buttonPosition?: 'individual' | 'top' | 'middle' | 'bottom';

  desktopButtonType?: 'primary' | 'secondary' | 'default';
  mobileButtonType?: 'primary' | 'secondary' | 'default';
  desktopIconOnly?: boolean;
  mobileIconOnly?: boolean;
  desktopClassName?: string;
  mobileClassName?: string;
}> = ({
  onClick,
  buttons,
  dropdownType,
  align,

  withVaultTheme,

  label,
  trailingIcon,
  leadingIcon,
  buttonPosition,

  desktopButtonType,
  mobileButtonType,
  desktopIconOnly,
  mobileIconOnly,
  desktopClassName,
  mobileClassName,
}) => {
  const { isDesktop } = useWindow();

  if (!isDesktop) {
    return (
      <Button
        iconOnly={mobileIconOnly}
        type={mobileButtonType}
        label={label}
        leadingIcon={leadingIcon}
        trailingIcon={trailingIcon}
        position={buttonPosition}
        className={twMerge('text-[16px] text-white', mobileClassName)}
        onClick={onClick}
        event={{
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: BOTTOMSHEET_TYPES.ACTION,
            buttons: buttons.length,
            type: dropdownType,
          },
        }}
      />
    );
  }

  return (
    <Dropdown
      align={align}
      trigger={
        <div>
          <Button
            iconOnly={desktopIconOnly}
            type={desktopButtonType}
            label={label}
            leadingIcon={leadingIcon}
            trailingIcon={trailingIcon}
            position={buttonPosition}
            className={twMerge('text-[16px] text-white', desktopClassName)}
            event={{
              type: EVENTS.OPEN_BOTTOMSHEET,
              properties: {
                bottomsheetType: BOTTOMSHEET_TYPES.ACTION,
                buttons: buttons.length,
                type: dropdownType,
              },
            }}
          />
        </div>
      }
    >
      <ActionDropdown buttons={buttons} withVaultTheme={!!withVaultTheme} />
    </Dropdown>
  );
};
