import type { ChangeEventHandler, FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { FormErrorIndicator } from '../../components/forms/FormErrorIndicator';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { Logo } from '../../components/svg/Logo';
import { useAttachPayee } from '../../components/views/hooks/useAttachPayee';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useLinkSpotifyAccount } from '../../hooks/useSpotifyAuth';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useTimer } from '../../hooks/useTimer';
import { Sentry } from '../../sentry';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { ONE_MINUTE_IN_MS, SignInStore } from './store';

export const VerifyPage: FC = () => {
  const navigate = useNavigate();

  const { loginStatus, loggedInUser, authError } = useAuthContext();
  const {
    sendCode,
    loginWithCode,
    state: { status },
  } = useLoginWithSms();
  const { openBottomsheet } = useBottomsheetContainer();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const artistHandle = searchParams.get('artistHandle');
  const payeeSecretId = searchParams.get('psid');
  const redirect = searchParams.get('redirect');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const backToVault = searchParams.get('backToVault');
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const linkSpotifyParam = searchParams.get('linkSpotify');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const [verificationCode, setVerificationCode] = useState<string>('');
  const [errorText, setErrorText] = useState<string | null>(null);

  const { openToast } = useToast();

  const { attachPayeeIfPresent } = useAttachPayee();

  const linkSpotify = useLinkSpotifyAccount();

  const navigationPath = useStableCallback(
    ({
      artistHandle,
      bottomSheetType,
      isInOnboarding,
      payeeSecretId,
      redirect,
      loggedInUser,
      code,
      trackId,
    }: {
      artistHandle?: string | null;
      bottomSheetType?: string | null;
      isInOnboarding?: boolean;
      payeeSecretId?: string | null;
      redirect?: string | null;
      loggedInUser?: {
        highestSubscriptionLevel?: string | null;
        artist?: { linkValue: string } | null;
      } | null;
      code?: string | null;
      trackId?: string | null;
    }): string => {
      if (isInOnboarding) {
        const queryParams = constructQueryParams({
          psid: payeeSecretId,
          openBottomSheet: bottomSheetType,
          artistHandle,
          trackId,
          invite,
          c: smsCampaignResponseShortcode,
        });
        return `${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`;
      }

      if (artistHandle != null) {
        const queryParams = constructQueryParams({
          openBottomSheet: bottomSheetType,
          redirect: isInOnboarding ? 'onboarding' : undefined,
          code,
          trackId,
          invite,
          c: smsCampaignResponseShortcode,
        });
        if (bottomSheetType) {
          return artistNavigationPath(artistHandle, '/', queryParams);
        }
        return artistNavigationPath(artistHandle, '/subscribe', queryParams);
      }

      if (redirect != null) {
        return redirect;
      }

      if (loggedInUser?.artist != null) {
        return artistNavigationPath(loggedInUser.artist.linkValue, '/');
      }

      return ROUTES.VAULTS;
    },
  );

  useEffect(() => {
    if (authError != null) {
      setErrorText('We encountered an error creating your profile');
    } else if (loginStatus === LoginStatus.LOGGED_IN && loggedInUser != null) {
      const isInOnboarding = loggedInUser.username == null && !code;

      function navigateAuth() {
        const navigateTo = navigationPath({
          artistHandle,
          bottomSheetType,
          isInOnboarding,
          payeeSecretId,
          redirect,
          loggedInUser,
          trackId,
          code,
        });

        // Payee collaborator onboarding flow
        if (!isInOnboarding) {
          attachPayeeIfPresent({
            onError() {
              navigate(navigateTo);
            },
            onWithoutPayee() {
              navigate(navigateTo);
            },
          });
        } else {
          navigate(navigateTo);
        }
      }

      if (linkSpotifyParam) {
        linkSpotify().finally(navigateAuth);
      } else {
        navigateAuth();
      }
    }
  }, [
    loginStatus,
    navigate,
    artistHandle,
    loggedInUser,
    authError,
    redirect,
    payeeSecretId,
    openBottomsheet,
    openToast,
    attachPayeeIfPresent,
    bottomSheetType,
    code,
    trackId,
    navigationPath,
    linkSpotify,
    linkSpotifyParam,
  ]);

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const newVal = e.target.value.replace(/\D/g, '').replace(/\s/g, ''); // Remove non-digit characters

    if (newVal.length === 6) {
      void tryLogin(newVal);
    }

    setErrorText(null);
    setVerificationCode(e.target.value.trim().slice(0, 6));
  };

  const signInState = SignInStore.useStore().value;

  const phoneNumber = signInState?.lastActivePhoneNumber;

  const codeRenabled = signInState?.codeRenabled[phoneNumber ?? '_'] ?? 1;

  const { seconds: codeSentDisabledSecondsRemaining, isRunning: isInCooldown } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const onResendCodeClick = async () => {
    if (!phoneNumber || codeSentDisabledSecondsRemaining > 0) return;

    try {
      await sendCode({ phoneNumber: `+${phoneNumber}` });

      const codeRenabled = Date.now() + ONE_MINUTE_IN_MS;
      SignInStore.produceExistingState(
        draft => {
          draft.codeRenabled[phoneNumber] = codeRenabled;
          draft.lastActivePhoneNumber = phoneNumber;
        },
        {
          codeRenabled: { [phoneNumber]: codeRenabled },
          lastActivePhoneNumber: phoneNumber,
        },
      );
    } catch (e) {
      setErrorText('We encountered an error sending your verification code');

      return;
    }
  };

  const tryLogin = async (code: string): Promise<void> => {
    try {
      await loginWithCode({ code });
      // Still loading, need to wait for AuthProvider loginStatus to update
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          phoneNumber,
        },
      });
      setErrorText('We encountered an error verifying your code');

      return;
    }
  };

  const onBackClick = useStableCallback(async () => {
    await SignInStore.produceExistingState(
      draft => {
        draft.lastActivePhoneNumber = null;
      },
      {
        codeRenabled: {},
        lastActivePhoneNumber: null,
      },
    );

    if (backToVault && artistHandle) {
      navigate(artistNavigationPath(artistHandle, '/'));
    } else if (window.history.state.idx != null && window.history.state.idx > 0) {
      navigate(ROUTES.SIGN_IN);
    } else {
      navigate('/');
    }
  });

  if (!phoneNumber || status === 'initial') {
    return <Navigate to={ROUTES.SIGN_IN} />;
  }

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      shouldShowFullScreenOnDesktop
      headerLeft={<BackButton onClick={onBackClick} />}
      headerCenter={
        <View className="flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full bg-white text-black">
          <Logo variant="default" />
        </View>
      }
    >
      <Text className="mb-[28px] font-title text-title-xl font-normal text-white">Verify</Text>
      <View className="w-full">
        <View className="mx-6 my-3 box-content flex flex-col">
          <View className="mx-5 flex select-none flex-row gap-4">
            <input
              type="tel"
              autoFocus
              value={verificationCode}
              placeholder="Enter verification code"
              autoComplete="one-time-code"
              onChange={onChange}
              className="flex-1 border-none bg-transparent text-center font-base text-base-l font-normal text-white selection:bg-transparent focus:w-[unset] focus:border-none focus:outline-none"
            />
            {errorText != null && <FormErrorIndicator />}
          </View>
          <View
            className={twMerge(
              'mt-3 h-[1px] w-full bg-base700',
              errorText != null && 'bg-destructive300',
            )}
          />
        </View>
        {errorText != null && (
          <Text className="mb-5 mt-3 text-center font-base text-base-m font-normal text-destructive300">
            {errorText}
          </Text>
        )}
      </View>
      <div className="mb-[20px] w-full">
        <div className="mx-6">
          <Button
            label={
              codeSentDisabledSecondsRemaining > 0
                ? `Resend code in ${codeSentDisabledSecondsRemaining}s`
                : 'Resend code'
            }
            type="primary"
            className="w-full"
            onClick={onResendCodeClick}
            disabled={
              codeSentDisabledSecondsRemaining > 0 ||
              status === 'sending-code' ||
              status === 'submitting-code' ||
              isInCooldown ||
              loginStatus === LoginStatus.LOADING
            }
            disabledClassName="opacity-30"
            loading={
              status === 'sending-code' ||
              status === 'submitting-code' ||
              loginStatus === LoginStatus.LOADING
            }
            event={{ type: EVENTS.RESEND_CODE, properties: null }}
          />
        </div>
      </div>
      <Text className="w-full max-w-[350px] text-center font-base text-base-m font-normal text-white">
        We've sent you a text with your verification code
      </Text>
    </DefaultLayout>
  );
};
