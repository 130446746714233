import { useEffect, useRef, useState } from 'react';
import { captureException } from '@sentry/react';
import { Mp3MediaRecorder } from 'mp3-mediarecorder';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faStop } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faMicrophone } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { AudioAttachment } from '../../contexts/AudioRecordContext';
import { useToast } from '../../contexts/ToastContext';
import { MediaType } from '../../graphql/generated';
import { uploadMultipartFile } from '../../utils/s3Utils';
import { getWorker, terminateWorker } from '../../utils/workerUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';

export const RecordButton = ({
  className,
  artistId,
  onRecordComplete,
}: {
  className?: string;
  artistId: string;
  onRecordComplete: () => void;
}) => {
  const [isRecording, setIsRecording] = useState(AudioAttachment.isRecording);
  const mediaRecorderRef = useRef<Mp3MediaRecorder | null>(null);
  const worker = useRef<Worker | null>(null);
  const { setRecording, updateRecordingWithUploadResult, clearRecording } =
    useSnapshot(AudioAttachment);
  const { openToast } = useToast();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const startRecording = async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && worker.current) {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

        const recorder = new Mp3MediaRecorder(stream, { worker: worker.current });
        mediaRecorderRef.current = recorder;
        mediaRecorderRef.current.start();

        AudioAttachment.isRecording = true;
        setIsRecording(true);
      } else {
        throw new Error('Audio recording is not supported by this browser.');
      }
    } catch (error) {
      AudioAttachment.isRecording = false;
      setIsRecording(false);
      handleRecordingError(error as Error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.ondataavailable = e => {
        const fileType = 'audio/mp3';
        const fileName = 'recording.mp3';
        const audioBlob = new Blob([e.data], { type: fileType });
        const audioFile = new File([audioBlob], fileName, { type: fileType });

        setRecording(audioFile);
        uploadRecording(audioFile);
        onRecordComplete();
      };

      AudioAttachment.isRecording = false;
      setIsRecording(false);
    }
  };

  const handleRecordingError = (error: Error) => {
    let errorMessage = 'An unexpected error occurred while trying to record audio.';
    if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
      errorMessage = 'Audio recording permission was denied.';
    } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
      errorMessage = 'No microphone devices found.';
    } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
      errorMessage = 'Your microphone is currently in use by another application.';
    }

    openToast({
      text: errorMessage,
      variant: 'error',
    });
  };

  const uploadRecording = async (file: File) => {
    AudioAttachment.isUploading = true;
    try {
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: MediaType.Recording,
        artistId,
      });
      updateRecordingWithUploadResult(mediaId, cdnUrl);
    } catch (error) {
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'RecordButton',
        },
      });
      openToast({
        text: `There was an error uploading your audio note. ${error}`,
        variant: 'error',
      });
      clearRecording();
    } finally {
      AudioAttachment.isUploading = false;
    }
  };

  useEffect(() => {
    worker.current = getWorker();
    return () => terminateWorker();
  }, []);

  return (
    <View>
      <Button
        label=""
        iconOnly
        iconSize="lg"
        leadingIcon={isRecording ? faStop : faMicrophone}
        className={twMerge(
          className,
          isRecording ? 'text-delete' : isThemeEnabled ? 'text-vault_text' : 'text-white',
        )}
        onClick={() => (isRecording ? stopRecording() : startRecording())}
      />
    </View>
  );
};
