import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureException, captureMessage } from '@sentry/react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import * as z from 'zod';
import { mixpanelClient } from '../../clients/mixpanelClient';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { FormErrorIndicator } from '../../components/forms/FormErrorIndicator';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { Logo } from '../../components/svg/Logo';
import { useAttachPayee } from '../../components/views/hooks/useAttachPayee';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { invalidateOperations, useMutation } from '../../graphql/client';
import { AuthUserDocument, UpdateUserOnboardingDocument } from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

const validationSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }).nullish().or(z.literal('')),
  zipcode: z.string().nullish(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

export function OnboardingCompleteProfilePage() {
  const { loginStatus, loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const { mutateAsync } = useMutation(UpdateUserOnboardingDocument, {});
  const [isLoading, setIsLoading] = useState(false);

  const { openToast } = useToast();

  const [searchParams] = useSearchParams();
  const artistHandle = searchParams.get('artistHandle');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const { attachPayeeIfPresent } = useAttachPayee();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<ValidationSchema>({
    defaultValues: {
      email: undefined,
      zipcode: undefined,
    },
    resolver: zodResolver(validationSchema),
  });

  useEffect(() => {
    if (loggedInUser?.email != null) {
      setValue('email', loggedInUser.email);
    }

    if (loggedInUser?.zipCode != null) {
      setValue('zipcode', loggedInUser.zipCode);
    }
  }, [loggedInUser?.email, loggedInUser?.zipCode, setValue]);

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const navigationPath = useStableCallback(
    ({
      artistHandle,
      bottomSheetType,
    }: {
      artistHandle?: string | null;
      bottomSheetType?: string | null;
    }): string => {
      if (artistHandle != null) {
        const queryParams = constructQueryParams({
          openBottomSheet: bottomSheetType,
          trackId,
          invite,
          c: smsCampaignResponseShortcode,
        });

        return artistNavigationPath(artistHandle, '/', queryParams);
      }

      return ROUTES.VAULTS;
    },
  );

  const hasErrors = Object.keys(errors).length > 0;

  const isSubmitDisabled = hasErrors || isSubmitting;

  const onSubmit: SubmitHandler<ValidationSchema> = async (data, e) => {
    e?.preventDefault();

    const newEmail = data.email?.trim().toLowerCase() || null;
    const newZipCode = data.zipcode?.trim().toLowerCase() || null;

    try {
      setIsLoading(true);
      const result = await mutateAsync(
        { input: { newEmail, newZipCode } },
        {
          onError: e => {
            openToast({
              text: e.message,
              variant: 'error',
            });
          },
        },
      );

      if (result.data.updateUser.__typename === 'MutationUpdateUserSuccess') {
        mixpanelClient.people.set({ email: newEmail });

        await invalidateOperations({ operations: [AuthUserDocument] });
        setIsLoading(false);

        const navigateTo = navigationPath({
          artistHandle,
          bottomSheetType,
        });

        attachPayeeIfPresent({
          onError() {
            navigate(navigateTo);
          },
          onWithoutPayee() {
            navigate(navigateTo);
          },
        });
      } else if (result.data.updateUser.__typename === 'ValidationError') {
        captureMessage('ValidationError in OnboardingCompleteProfile', {
          extra: {
            data: result.data,
            loggedInUser,
          },
          level: 'warning',
        });
        openToast({
          text: result.data.updateUser.message,
          variant: 'error',
        });
      } else {
        captureMessage('NotFoundError in OnboardingCompleteProfile', {
          extra: {
            data: result.data,
            loggedInUser,
          },
          level: 'error',
        });
        openToast({
          text: 'Internal error, please try again later',
          variant: 'error',
        });
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      captureException(e, {
        extra: {
          newEmail,
          newZipCode,
        },
      });
      throw e;
    }
  };

  const onSkip = () => {
    const navigateTo = navigationPath({
      artistHandle,
      bottomSheetType,
    });

    attachPayeeIfPresent({
      onError() {
        navigate(navigateTo);
      },
      onWithoutPayee() {
        navigate(navigateTo);
      },
    });
  };

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop={false}
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      showBorder
      headerLeft={<BackButton />}
      headerCenter={
        <View className="flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full bg-white text-black">
          <Logo variant="default" />
        </View>
      }
      shouldShowFullScreenOnDesktop
    >
      <Text className="mb-5 font-title text-title-xl font-normal text-white">
        Complete your profile
      </Text>
      <div className="mb-5 flex h-1 w-[168px] flex-row gap-2">
        <div className="flex h-1 w-20 rounded-full bg-yellow100" />
        <div className="relative h-1 w-20 rounded-full bg-yellow100" />
      </div>
      <Text className="mb-8 text-center font-base text-base-l font-normal text-base200">
        Get invites to local events &
        <br />
        messages from the artist
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-col gap-3">
        <View className="mx-6 my-3 box-content flex flex-col">
          <View className="mx-5 flex flex-row items-center gap-4">
            <input
              type="text"
              {...register('email')}
              placeholder="Enter email"
              className="flex-1 border-none bg-transparent font-base text-base-l font-normal text-white focus:border-none focus:outline-none"
            />
            {errors.email?.message != null && <FormErrorIndicator />}
          </View>
          <View
            className={twMerge(
              'mt-3 h-[1px] w-full bg-base700',
              errors.email?.message != null && 'bg-destructive300',
            )}
          />
          {errors.email?.message != null && (
            <Text className="mt-3 text-center font-base text-base-m font-normal text-destructive300">
              {errors.email?.message}
            </Text>
          )}
        </View>

        <View className="mx-6 my-3 box-content flex flex-col">
          <View className="mx-5 flex flex-row items-center gap-4">
            <input
              type="text"
              {...register('zipcode')}
              placeholder="Enter zip/post code"
              className="flex-1 border-none bg-transparent font-base text-base-l font-normal text-white focus:border-none focus:outline-none"
            />
            {errors.zipcode?.message != null && <FormErrorIndicator />}
          </View>
          <View
            className={twMerge(
              'mt-3 h-[1px] w-full bg-base700',
              errors.zipcode?.message != null && 'bg-destructive300',
            )}
          />
          {errors.zipcode?.message != null && (
            <Text className="mt-3 text-center font-base text-base-m font-normal text-destructive300">
              {errors.zipcode?.message}
            </Text>
          )}
        </View>

        <div className="mb-5 mt-10 flex w-full flex-col items-center justify-center gap-5">
          <div className="mx-6">
            <Button
              label={!isLoading ? 'Next' : null}
              type="primary"
              buttonType="submit"
              loading={isLoading}
              iconOnly={isLoading}
              disabled={isSubmitDisabled}
              disabledClassName="opacity-50 cursor-not-allowed"
              event={{ type: EVENTS.NEXT, properties: { type: 'Onboarding Complete Profile' } }}
            />
          </div>

          <div className="mx-6">
            <Button
              label="Skip"
              className="font-base !text-base-l font-semibold text-yellow100"
              disabled={isLoading}
              event={{ type: EVENTS.SKIP, properties: { type: 'Onboarding Complete Profile' } }}
              onClick={onSkip}
            />
          </div>
        </div>
      </form>
    </DefaultLayout>
  );
}
