import { twMerge } from 'tailwind-merge';
import { Image, type ImageProps } from '../../../common/Image';

export function ArtistLogo({ className, ...rest }: { className?: string } & ImageProps) {
  return (
    <Image
      className={twMerge(
        'aspect-[30/7] h-auto max-h-[70px] w-full max-w-[300px] object-cover',
        className,
      )}
      {...rest}
    />
  );
}
