import React, { useEffect, useState } from 'react';
import type { FC, SyntheticEvent } from 'react';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { exhaustiveGuard } from '@soundxyz/utils';
import { seek, useAudioController } from '../../audio/AudioController';
import { useAudioPosition } from '../../audio/AudioPosition';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { formatTime } from '../../utils/textUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';

type Props = {
  type: 'bottom' | 'fullscreen' | 'normal';
  timestampPosition?: 'top' | 'bottom';
  className?: string;
  timestampClassName?: string;
  withVaultTheme: boolean;
};

export const Timeline: FC<Props> = ({
  type,
  className,
  timestampPosition = 'bottom',
  timestampClassName,
  withVaultTheme,
}) => {
  const { pathname } = useLocation();
  const { duration, playing: isPlaying, track } = useAudioController();
  const { position } = useAudioPosition();
  const [scrubPosition, setScrubPosition] = useState<number>(position);

  // To create a smooth user experience, detach from the live position when user is seeking
  // Then return to updating in real time after user is done seeking or when unpausing
  const [uncontrolled, setUncontrolled] = useState(false);

  const startSeek = (event: SyntheticEvent) => {
    event.stopPropagation();
    setUncontrolled(true);
  };

  const endSeek = (event: SyntheticEvent) => {
    event.stopPropagation();

    track != null &&
      trackEvent({
        type: EVENTS.SEEK_TRACK,
        properties: {
          trackId: track.id,
          vaultId: track.vault.id,
          artistId: track.vault.artist?.id ?? null,
          type: 'timeline',
        },
        pathname,
      });

    if (isPlaying) setUncontrolled(false);
    const target = event.target as HTMLInputElement;
    seek(target.valueAsNumber);
  };

  useEffect(() => {
    if (isPlaying) setUncontrolled(false);
  }, [isPlaying]);

  let typeClassName: string;

  switch (type) {
    case 'bottom':
      typeClassName = twMerge(
        '-mt-[10px] overflow-hidden',
        withVaultTheme
          ? 'input-range-bottom-theme bg-vault_text/30'
          : 'input-range-bottom-default bg-white/30',
      );
      break;
    case 'fullscreen':
      typeClassName = 'input-range-full player-slider player-slider-large-thumb';
      break;
    case 'normal':
      typeClassName = twMerge(
        'input-range-normal player-slider',
        withVaultTheme ? 'bg-vault_text/30' : 'bg-white/30',
      );
      break;
    default: {
      exhaustiveGuard(type);
    }
  }

  const showTimestamps = type === 'fullscreen' || type === 'normal';
  const showTimestampsTop = timestampPosition === 'top' && showTimestamps;
  const showTimestampsBottom = timestampPosition === 'bottom' && showTimestamps;

  const value = uncontrolled ? scrubPosition : position;

  return (
    <div
      className={twMerge('flex w-full flex-col items-center justify-center py-[10px]', className)}
    >
      {showTimestampsTop && (
        <Timestamps
          uncontrolled={uncontrolled}
          scrubPosition={scrubPosition}
          position={position}
          duration={duration}
          className={twMerge('mt-0', timestampClassName)}
        />
      )}
      <input
        className={twMerge(
          'timeline-slider my-2 inline-block w-full cursor-pointer appearance-none rounded-[2px] text-[0.2rem] outline-none',
          typeClassName,
        )}
        type="range"
        step={1}
        min={0}
        value={value}
        max={duration}
        onMouseDown={startSeek}
        onTouchStart={startSeek}
        onKeyDown={startSeek}
        onMouseUp={endSeek}
        onTouchEnd={endSeek}
        onKeyUp={endSeek}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          setScrubPosition(e.target.valueAsNumber);
        }}
        style={
          type === 'normal' || type === 'fullscreen'
            ? {
                background: withVaultTheme
                  ? `linear-gradient(to right, rgb(var(--vault-accent-color, #E3F41D)) 0%, rgb(var(--vault-accent-color, #E3F41D)) ${(value / duration) * 100}%, rgba(var(--vault-text-color, #fff),0.3) ${(value / duration) * 100}%, rgba(var(--vault-text-color, #fff),0.3) 100%)`
                  : `linear-gradient(to right, #E3F41D 0%, #E3F41D ${(value / duration) * 100}%, rgba(255,255,255,0.3) ${(value / duration) * 100}%, rgba(255,255,255,0.3) 100%)`,
              }
            : undefined
        }
      />
      {showTimestampsBottom && (
        <Timestamps
          uncontrolled={uncontrolled}
          scrubPosition={scrubPosition}
          position={position}
          duration={duration}
          className={timestampClassName}
        />
      )}
    </div>
  );
};

function Timestamps({
  uncontrolled,
  scrubPosition,
  position,
  duration,
  className,
}: {
  uncontrolled: boolean;
  scrubPosition: number;
  position: number;
  duration: number;
  className?: string;
}) {
  // scrubbing the input does not update the position of the song until song is played so this makes sure that duration still updates on scrub
  return (
    <View
      className={twMerge(
        'mt-[4px] flex w-full select-none flex-row justify-between text-white',
        className,
      )}
    >
      <Text className="font-base text-[10px] text-base-xs font-semibold">
        {formatTime(uncontrolled ? scrubPosition : position)}
      </Text>

      <Text className="font-base text-[10px] text-base-xs font-semibold">
        -{formatTime(uncontrolled ? duration - scrubPosition : duration - position)}
      </Text>
    </View>
  );
}
