import { useCallback, useState } from 'react';
import { useGate } from 'statsig-react';
import { gql } from '@soundxyz/gql-string';
import { setHideAudioPlayer } from '../audio/AudioMeta';
import { Menu } from '../components/menu/Menu';
import { CustomizeVaultMenu } from '../components/menu/customize-vault/CustomizeVaultMenu';
import { FEATURE_GATES } from '../constants/flagConstants';
import { ModalType } from '../constants/modalConstants';
import { useQuery } from '../graphql/client';
import { VaultThemeByVaultIdDocument } from '../graphql/generated';
import { setVaultTheme } from '../hooks/useVaultTheme';
import { createContainer } from '../utils/unstated';
import { useBottomsheetContainer } from './BottomsheetContext';
import { useModal } from './ModalContext';

gql(/* GraphQL */ `
  query VaultThemeByVaultId($vaultId: UUID!) {
    vaultFromId(vaultId: $vaultId) {
      id
      type
      accentColor
      backgroundColor
      logoImage {
        id
        url
      }
      artistProfile {
        id
        profileImage {
          id
          url
        }
      }
    }
  }
`);

const MenuContext = createContainer(function MenuContext() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosingMenu, setIsClosingMenu] = useState(false);
  const [isClosingCustomizeMenu, setIsClosingCustomizeMenu] = useState(false);
  const [selectedHandle, setSelectedHandle] = useState<string | null>();
  const [isAccountOpen, setIsAccountOpen] = useState(false);

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const [isVaultCustomizeOpen, setIsVaultCustomizeOpen] = useState(false);
  const [vaultId, setVaultId] = useState<string | null>(null);
  const { closeModalByType } = useModal();
  const { openBottomsheet } = useBottomsheetContainer();

  const { data } = useQuery(VaultThemeByVaultIdDocument, {
    staleTime: 10000,
    variables: !!vaultId && { vaultId },
    filterQueryKey: { vaultId },
    enabled: !!vaultId && isThemeEnabled,
  });

  const openMenu = useCallback((selectedArtistHandle?: string) => {
    setIsOpen(true);
    setSelectedHandle(selectedArtistHandle);
  }, []);

  const closeMenu = useCallback(() => {
    isOpen && setIsClosingMenu(true);
    setTimeout(() => {
      setIsOpen(false);
      setSelectedHandle(null);
      setIsClosingMenu(false);
      setIsAccountOpen(false);
      setIsVaultCustomizeOpen(false);
    }, 100);
  }, [isOpen]);

  const openVaultCustomization = useCallback(({ vaultId }: { vaultId: string }) => {
    setVaultId(vaultId);
    setIsVaultCustomizeOpen(true);
    setHideAudioPlayer(true);
  }, []);

  const closeVaultCustomization = useCallback(
    ({ showConfirm }: { showConfirm: boolean }) => {
      isVaultCustomizeOpen && setIsClosingCustomizeMenu(true);

      if (showConfirm) {
        openBottomsheet({
          type: 'CONFIRMATION',
          confirmationBottomsheetProps: {
            subText: "Your changes won't be saved",
            confirmButtonText: 'Close',
            confirmType: 'delete',
            onConfirm: () => {
              setVaultId(null);
              setIsVaultCustomizeOpen(false);
              // Reset vault theme to the original theme
              setVaultTheme({
                newAccentColor: data?.data.vaultFromId?.accentColor,
                newBackgroundColor: data?.data.vaultFromId?.backgroundColor,
                newLogoUrl: data?.data.vaultFromId?.logoImage?.url,
                newProfileImageUrl: data?.data.vaultFromId?.artistProfile?.profileImage?.url,
              });
              closeModalByType(ModalType.CUSTOMIZE_VAULT);
              setIsClosingCustomizeMenu(false);
            },
          },
        });
      } else {
        setTimeout(() => {
          setVaultId(null);
          setIsVaultCustomizeOpen(false);
          // Reset vault theme to the original theme
          setVaultTheme({
            newAccentColor: data?.data.vaultFromId?.accentColor,
            newBackgroundColor: data?.data.vaultFromId?.backgroundColor,
            newLogoUrl: data?.data.vaultFromId?.logoImage?.url,
            newProfileImageUrl: data?.data.vaultFromId?.artistProfile?.profileImage?.url,
          });
          closeModalByType(ModalType.CUSTOMIZE_VAULT);
          setIsClosingCustomizeMenu(false);
        }, 100);
      }
    },
    [
      closeModalByType,
      data?.data.vaultFromId?.accentColor,
      data?.data.vaultFromId?.artistProfile?.profileImage?.url,
      data?.data.vaultFromId?.backgroundColor,
      data?.data.vaultFromId?.logoImage?.url,
      isVaultCustomizeOpen,
      openBottomsheet,
    ],
  );

  return {
    isOpen: isOpen || isClosingMenu,
    openMenu,
    closeMenu,
    selectedHandle,
    isClosingMenu,
    isClosingCustomizeMenu,
    isAccountOpen,
    isVaultCustomizeOpen: isVaultCustomizeOpen || isClosingCustomizeMenu,
    vaultId,
    setIsAccountOpen,
    openVaultCustomization,
    closeVaultCustomization,
  };
});

const useMenuContainer = () => MenuContext.useContainer();

const MenuContainer = () => {
  const { isVaultCustomizeOpen, vaultId } = useMenuContainer();

  if (isVaultCustomizeOpen && vaultId) {
    return <CustomizeVaultMenu vaultId={vaultId} />;
  }

  return <Menu />;
};

export { useMenuContainer, MenuContainer, MenuContext };
