import { forwardRef, type MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { format } from 'date-fns-tz';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faChevronDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCalendar } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import {
  formatSelectedDate,
  getAudienceText,
  getZonedDate,
} from '../../../components/announcement/helpers';
import { ANNOUNCEMENT_MAX_CHARS } from '../../../components/announcement/schema';
import { useAnnouncementForm } from '../../../components/announcement/useAnnouncementForm';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { TrackUpload } from '../../../contexts/TrackUploadContext';
import { ScheduledEventAccessFeatureInput } from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';

const DateInput = forwardRef<
  HTMLButtonElement,
  { value?: string | null; onClick?: MouseEventHandler<HTMLButtonElement>; isThemeEnabled: boolean }
>(({ value, onClick, isThemeEnabled }, ref) => (
  <button
    className={twMerge(
      'flex w-full cursor-pointer appearance-none items-center justify-between border-none bg-transparent py-5 outline-none',
      isThemeEnabled
        ? 'text-vault_text/50 hover:text-vault_text/60'
        : 'text-base500 hover:text-base400',
    )}
    ref={ref}
    onClick={onClick}
  >
    <Text className={twMerge('font-base !text-base-l font-normal')}>{value ? value : 'Now'}</Text>
    <FontAwesomeIcon icon={faCalendar} fontSize={16} />
  </button>
));

export function CreateAnnouncementPage() {
  const navigate = useNavigate();
  const currDate = useMemo(() => new Date(), []);
  const { artistHandle } = useArtistHandle();
  const { pathname } = useLocation();
  const { openBottomsheet } = useBottomsheetContainer();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  useVaultTheme();

  const onBackClick = useStableCallback(() => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: BOTTOMSHEET_TYPES.EXIT_FLOW,
        event: EVENTS.CREATE_ANNOUNCEMENT,
      },
      pathname,
    });

    openBottomsheet({
      type: 'EXIT_FLOW',
      exitFlowBottomsheetProps: {
        onConfirm: () => {
          TrackUpload.isUploadingTrack = false;
          TrackUpload.isPromptOpen = false;
          TrackUpload.fileRef.current = null;
          TrackUpload.title = '';
          TrackUpload.shouldSendSms = false;
          TrackUpload.caption = '';

          navigate(artistNavigationPath(artistHandle, '/'));
          clearFields();
        },
        event: EVENTS.UPLOAD_TRACK,
      },
    });
  });

  useEffect(() => {
    if (!artistHandle) {
      navigate(ROUTES.NOT_FOUND);
      return;
    }
  }, [artistHandle, navigate]);

  const [charCounter, setCharCounter] = useState(0);

  const getMinTime = useStableCallback(() => {
    const isToday = fields.date.toDateString() === currDate.toDateString();
    return isToday ? currDate : new Date(0, 0, 0, 0, 0, 0, 0);
  });

  const getMaxTime = useStableCallback(() => {
    const maxTime = new Date();
    maxTime.setHours(23, 59, 59, 999);
    return maxTime;
  });

  const { errors, fields, setField, validateField, enableSubmit, clearFields } =
    useAnnouncementForm();

  const timeZone = useMemo(() => {
    return format(getZonedDate(currDate), 'z');
  }, [currDate]);

  const formatDate = formatSelectedDate(fields.date);

  const { loggedInUser } = useAuthContext();

  return (
    <DefaultLayout
      withVaultTheme={isThemeEnabled}
      showRoundedTop={isThemeEnabled}
      showBorder={isThemeEnabled}
      headerLeft={
        <BackButton
          onClick={onBackClick}
          className={isThemeEnabled ? 'text-vault_text' : undefined}
        />
      }
      headerCenter={
        <Text
          className={twMerge(
            'font-title !text-title-m font-medium',
            isThemeEnabled ? 'text-vault_text' : 'text-white',
          )}
        >
          Announcement
        </Text>
      }
      headerRight={
        <button
          className="hidden appearance-none border-none bg-transparent outline-none hover:cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 disabled:active:opacity-70 md2:block"
          disabled={!enableSubmit}
          onClick={() => {
            navigate(artistNavigationPath(artistHandle, '/announcements/review'));
          }}
        >
          <Text
            className={twMerge(
              'font-title !text-base-m font-medium active:opacity-70',
              isThemeEnabled ? 'text-vault_accent' : 'text-yellow100',
            )}
          >
            Review
          </Text>
        </button>
      }
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName={twMerge(
        isThemeEnabled &&
          'bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5',
      )}
      contentClassName={isThemeEnabled ? 'md2:bg-vault_text/3' : undefined}
      stretch
    >
      <View className="flex h-full w-full flex-col justify-between pb-6 md2:max-w-[600px]">
        <View className="flex w-full flex-col gap-6 md2:gap-7 ">
          <View className="flex h-auto w-full flex-col">
            <textarea
              value={fields.announcement}
              name="announcement"
              aria-multiline="true"
              placeholder="Write your announcement here..."
              className={twMerge(
                'min-h-[276px] w-full resize-none border-x-0 border-b border-t-0 bg-transparent py-5 font-base !text-base-l outline-none',
                isThemeEnabled
                  ? 'border-vault_text/5 text-vault_text placeholder:text-vault_text/50'
                  : 'border-base700 text-white',
              )}
              onChange={e => {
                setField('announcement', e.target.value);
                setCharCounter(e.target.value.length);
                validateField('announcement');
              }}
              onBlur={() => {
                validateField('announcement');
              }}
            />

            <Text
              className={twMerge(
                'h-6 pt-1 font-base !text-base-s tabular-nums',
                !!errors.announcement
                  ? 'text-destructive400'
                  : isThemeEnabled
                    ? 'text-vault_text/50'
                    : 'text-base500',
              )}
            >
              {charCounter}/{ANNOUNCEMENT_MAX_CHARS}
            </Text>
          </View>

          {loggedInUser?.artist?.mainVaultType === 'FREEMIUM' && (
            <View className="flex w-full flex-col">
              <Text
                className={twMerge(
                  'font-title !text-title-s font-medium',
                  isThemeEnabled ? 'text-vault_text' : 'text-base50',
                )}
              >
                Audience
              </Text>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger className="group appearance-none border-none bg-transparent outline-none">
                  <>
                    <View className="flex w-full cursor-pointer items-center justify-between py-5 outline-none">
                      <Text
                        className={twMerge(
                          'font-base !text-base-l font-normal',
                          isThemeEnabled
                            ? 'text-vault_text/50 group-hover:text-vault_text/60'
                            : 'text-base500 group-hover:text-base400',
                        )}
                      >
                        {!!fields.accessType
                          ? getAudienceText(fields.accessType)
                          : 'Select your audience'}
                      </Text>

                      <FontAwesomeIcon
                        icon={faChevronDown}
                        fontSize={16}
                        className={
                          isThemeEnabled
                            ? 'text-vault_text/50 group-hover:text-vault_text/60'
                            : 'text-base500 group-hover:text-base400'
                        }
                      />
                    </View>

                    <hr
                      className={twMerge(
                        'h-[1px] border-none outline-none',
                        isThemeEnabled ? 'bg-vault_text/5' : 'bg-base700',
                      )}
                    />
                  </>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <DropdownMenu.Content
                    align="start"
                    className={twMerge(
                      'z-stickyHeader -ml-0.5 flex w-full min-w-[calc(100vw-88px)] flex-col gap-1 rounded-md p-1 md2:min-w-[510px]',
                      isThemeEnabled ? 'bg-vault_background' : 'bg-black',
                    )}
                  >
                    {Object.values(ScheduledEventAccessFeatureInput).map(accessType => (
                      <DropdownMenu.Item
                        key={accessType}
                        className={twMerge(
                          'w-full cursor-pointer rounded-md border-none px-5 py-2 outline-none',
                          isThemeEnabled
                            ? 'box-border bg-vault_text/10 text-vault_text hover:bg-vault_text/20'
                            : 'bg-base800 hover:bg-white/10',
                        )}
                        onSelect={() => {
                          setField('accessType', accessType);
                        }}
                      >
                        {getAudienceText(accessType)}
                      </DropdownMenu.Item>
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </View>
          )}
          <View className="flex flex-col">
            <Text
              className={twMerge(
                'font-title !text-title-s font-medium',
                isThemeEnabled ? 'text-vault_text' : 'text-base50',
              )}
            >
              Date and time
            </Text>
            <DatePicker
              value={formatDate}
              selected={fields.date}
              onChange={date => {
                if (!date) {
                  setField('date', currDate);
                  return;
                }
                setField('date', date);
              }}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              className={twMerge(
                'w-full border-none bg-transparent py-5 font-base !text-base-l outline-none',
                isThemeEnabled ? 'text-vault_text' : 'text-base50',
              )}
              placeholderText="Now"
              customInput={<DateInput isThemeEnabled={isThemeEnabled} />}
              minDate={currDate}
              timeIntervals={1}
              minTime={getMinTime()}
              maxTime={getMaxTime()} // Set max time to the end of the day
            />

            <hr
              className={twMerge(
                'h-[1px] border-none outline-none',
                isThemeEnabled ? 'bg-vault_text/5' : 'bg-base700',
              )}
            />
            <Text
              className={twMerge(
                'h-6 pt-1 font-base !text-base-s tabular-nums',
                isThemeEnabled ? 'text-vault_text/50' : 'text-base500',
              )}
            >
              {timeZone}
            </Text>
          </View>
        </View>

        <Button
          type="primary"
          label="Review SMS"
          className="!text-base-3 w-fit self-center md2:hidden"
          disabled={!enableSubmit}
          onClick={() => {
            navigate(artistNavigationPath(artistHandle, '/announcements/review'));
          }}
        />
      </View>
    </DefaultLayout>
  );
}
