import { type ChangeEvent, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import ms from 'ms';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useReactQuery } from '../../graphql/client';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';

const GIPHY_API_KEY = 'WlDZKVCk6g3q1jE0aUbtKYzHqCuy64zX';

interface Gif {
  id: string;
  images: {
    fixed_width: {
      url: string;
      width: string;
      height: string;
    };
  };
  title: string;
}

interface GiphyResponse {
  data: Gif[];
}

const fetchGifs = async (query = ''): Promise<GiphyResponse> => {
  const url = query
    ? `https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_API_KEY}&q=${query}&limit=40`
    : `https://api.giphy.com/v1/gifs/trending?api_key=${GIPHY_API_KEY}&limit=40`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = (await response.json()) as GiphyResponse;
    return data;
  } catch (error) {
    captureException(error, { tags: { feature: 'fetching gif', query, url } });
    throw error;
  }
};

export const GifKeyboard = ({
  onSelect,
  closeKeyboard,
}: {
  onSelect: (gif: Gif) => void;
  closeKeyboard: () => void;
}) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { data, error, isLoading, refetch } = useReactQuery<GiphyResponse, Error>(
    ['gifs', debouncedSearch],
    () => fetchGifs(debouncedSearch),
    {
      staleTime: ms('1 hour'),
      cacheTime: ms('5 hours'),
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelectGif = (gif: Gif) => {
    onSelect(gif);
  };

  if (isLoading) {
    return (
      <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 900: 3 }}>
        <Masonry
          className={twMerge(
            'h-96 overflow-x-clip overflow-y-scroll',
            isThemeEnabled && 'scrollbar-theme',
          )}
        >
          <LoadingSkeleton
            className={twMerge('m-2 aspect-[1/1] w-[90%]', isThemeEnabled && 'bg-vault_text/10')}
          />
          <LoadingSkeleton
            className={twMerge('m-2 aspect-[4/3] w-[90%]', isThemeEnabled && 'bg-vault_text/10')}
          />
          <LoadingSkeleton
            className={twMerge('m-2 aspect-[3/4] w-[90%]', isThemeEnabled && 'bg-vault_text/10')}
          />
          <LoadingSkeleton
            className={twMerge('m-2 aspect-[1/1] w-[90%]', isThemeEnabled && 'bg-vault_text/10')}
          />
          <LoadingSkeleton
            className={twMerge('m-2 aspect-[4/3] w-[90%]', isThemeEnabled && 'bg-vault_text/10')}
          />
          <LoadingSkeleton
            className={twMerge('m-2 aspect-[3/4] w-[90%]', isThemeEnabled && 'bg-vault_text/10')}
          />
        </Masonry>
      </ResponsiveMasonry>
    );
  }

  return (
    <View className="p-4">
      <View className="mb-4 flex flex-row items-center gap-2">
        <Button
          iconOnly
          label=""
          leadingIcon={faClose}
          leadingIconClassName="!text-base-l"
          className={twMerge(
            'h-10 w-10 rounded-full !text-base-l',
            isThemeEnabled
              ? 'bg-vault_text/20 text-vault_text'
              : isNewMenuEnabled
                ? 'bg-base700 text-white md2:bg-white/3'
                : 'bg-base700 text-white',
          )}
          onClick={closeKeyboard}
        />
        <input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search GIFs"
          className={twMerge(
            'min-h-6 flex-grow rounded-full border-none px-[20px] py-[12px] font-base !text-base-l font-normal focus:border-none focus:outline-none',
            isThemeEnabled
              ? 'bg-vault_text/20 text-vault_text placeholder:text-vault_text/50'
              : isNewMenuEnabled
                ? 'bg-base700 text-white md2:bg-white/3'
                : 'bg-base700 text-white',
          )}
        />
      </View>
      {error ? (
        <ErrorView onRetryClick={refetch} withVaultTheme={false} />
      ) : (
        <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 900: 3 }}>
          <Masonry className="h-96 overflow-y-scroll scrollbar-none">
            {data.data.map(gif => (
              <img
                key={gif.id}
                src={gif.images.fixed_width.url}
                alt={gif.title}
                onClick={() => handleSelectGif(gif)}
                className="transition-transform mx-2 mb-4 transform cursor-pointer rounded-lg hover:scale-105"
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </View>
  );
};
