import type { FC } from 'react';
import React from 'react';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useQuery } from '../../graphql/client';
import { VaultMessageAttachmentFragmentDoc } from '../../graphql/generated';
import { type FragmentType, getFragment, VaultTrackByIdDocument } from '../../graphql/generated';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { TrackRow, TrackRowSkeleton } from '../track/TrackRow';
import { PinnedMessageAttachment } from './PinnedMessageAttachment';

type Props = {
  messageContent: FragmentType<VaultMessageAttachmentFragmentDoc>;
  type: 'full' | 'pinned';
  className?: string;
  isAuthor?: boolean;
};

gql(/* GraphQL */ `
  query VaultTrackById($vaultContentId: UUID!) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename
      ... on VaultTrack {
        id
        ...TrackRowInfo
      }
    }
  }

  fragment vaultMessageAttachment on MessageContent {
    id
    vaultContent {
      id
      title
    }
  }
`);

export const MessageAttachment: FC<Props> = ({
  messageContent,
  type = 'full',
  className,
  isAuthor,
}) => {
  const messageContentFrag = getFragment(VaultMessageAttachmentFragmentDoc, messageContent);
  const { data: vaultContent, isLoading } = useQuery(VaultTrackByIdDocument, {
    variables: !!messageContentFrag.vaultContent && {
      vaultContentId: messageContentFrag.vaultContent.id,
    },
    staleTime: 0,
  });

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.MENU);

  if (messageContentFrag.vaultContent != null) {
    if (vaultContent?.data.vaultContentById?.__typename === 'VaultTrack') {
      if (type === 'pinned') {
        return <PinnedMessageAttachment track={vaultContent.data.vaultContentById} />;
      }
      return (
        <TrackRow
          track={vaultContent.data.vaultContentById}
          waveFormClass="w-full"
          className={twMerge('mb-0 mt-[12px] box-border min-h-[110px] w-full', className)}
          lastViewedTime={null}
          isLocked={false}
          isAuthor={isAuthor}
        />
      );
    } else if (isLoading) {
      return type === 'pinned' ? <></> : <TrackRowSkeleton />;
    } else {
      return (
        <View
          className={twMerge(
            'mb-[20px] box-border flex h-[112px] w-full flex-1 flex-col items-center',
            isNewMenuEnabled
              ? isThemeEnabled
                ? 'bg-vault_text/30'
                : 'bg-base800 md2:bg-white/30'
              : 'bg-black',
          )}
        />
      );
    }
  }

  // No message attachment was found
  return (
    <View
      className={twMerge(
        'my-1 box-border flex flex-col rounded-xl bg-[#1f1f1f] p-4',
        isAuthor
          ? isThemeEnabled
            ? 'bg-vault_accent_text/20'
            : 'bg-[rgba(12,92,165,1)]'
          : isNewMenuEnabled
            ? isThemeEnabled
              ? 'bg-vault_accent_text/20'
              : 'md2:white/30 bg-base800'
            : 'bg-base800',
      )}
    >
      <Text
        className={twMerge(
          'text-center !text-base-m italic opacity-50',
          isThemeEnabled && (isAuthor ? 'text-vault_accent_text' : 'text-vault_text'),
        )}
      >
        This content is no longer available
      </Text>
    </View>
  );
};
