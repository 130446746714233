import React from 'react';
import { twMerge } from 'tailwind-merge';
import { View, type ViewProps } from '../common/View';

export function LoadingSkeleton({ className = '', children }: ViewProps) {
  return (
    <View className={twMerge('rounded-md bg-neutral800', className, 'animate-pulse')}>
      {children}
    </View>
  );
}
