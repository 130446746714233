import { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import { captureException } from '@sentry/react';
import { Link } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faCancel, faEye, faEyeSlash, faLock } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsisH } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { ArtistProfileImage } from '../components/artist/ArtistProfileImage';
import { BackButton } from '../components/buttons/BackButton';
import { Button } from '../components/buttons/Button';
import { LinkifyText } from '../components/common/LinkifyText';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { FEATURE_GATES } from '../constants/flagConstants';
import { useBottomsheetContainer } from '../contexts/BottomsheetContext';
import { useToast } from '../contexts/ToastContext';
import { useMutation, useQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  AnnouncementRowFragmentDoc,
  ArtistByHandleDocument,
  getFragment,
  ScheduledEventStatus,
  ShowScheduledAnnouncementDocument,
} from '../graphql/generated';
import {
  CancelScheduledAnnouncementDocument,
  type FragmentType,
  GetPaginatedVaultAnnouncementsDocument,
  HideScheduledAnnouncementDocument,
} from '../graphql/generated';
import { usePaginatedVaultAnnouncements } from '../hooks/announcements/usePaginatedVaultAnnouncements';
import { useVaultAnnouncementEngagement } from '../hooks/announcements/useVaultAnnouncementEngagement';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useStableCallback } from '../hooks/useStableCallback';
import { useTimer } from '../hooks/useTimer';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { futureDateInterval, ONE_DAY_IN_MS, pastDateInterval } from '../utils/dateUtils';

gql(/* GraphQL */ `
  fragment AnnouncementRow on Announcement {
    id
    content
    scheduledAt
    isFullVersionAvailable
    status
    featureAccess {
      feature {
        __typename
      }
    }
    vault {
      id
      artist: artistProfile {
        id
        name
        profileImage {
          id
          url
        }
      }
    }
  }

  mutation CancelScheduledAnnouncement($input: MutationDeleteAnnouncementInput!) {
    deleteAnnouncement(input: $input) {
      __typename
      ... on NotFoundError {
        message
      }
    }
  }

  mutation HideScheduledAnnouncement($input: MutationHideAnnouncementInput!) {
    hideAnnouncement(input: $input) {
      __typename
      ... on NotFoundError {
        message
      }
    }
  }

  mutation ShowScheduledAnnouncement($input: MutationShowAnnouncementInput!) {
    showAnnouncement(input: $input) {
      __typename
      ... on NotFoundError {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [
    CancelScheduledAnnouncementDocument,
    HideScheduledAnnouncementDocument,
    ShowScheduledAnnouncementDocument,
  ],
  refetch: [GetPaginatedVaultAnnouncementsDocument],
});

export function AnnouncementsPage() {
  const { artistHandle } = useArtistHandle();
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  useVaultTheme();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);

  const {
    data,
    isLoading: isLoadingVaultId,
    isError: isErrorVaultId,
  } = useQuery(ArtistByHandleDocument, {
    staleTime: Infinity,
    variables: !!artistHandle && { link: artistHandle },
  });

  const {
    orderedList: announcements,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePaginatedVaultAnnouncements({
    enabled: !!data?.data.artistLink?.artist.linkValue,
    artistHandle:
      !!data?.data.artistLink?.artist.linkValue &&
      data?.data.artistLink.artist.linkValue.toLowerCase(),
  });

  const isOwner = !!data?.data.artistLink?.artist.mainVault?.isUserArtistAdmin;

  useVaultAnnouncementEngagement({
    vaultId: data?.data.artistLink?.artist.mainVaultId,
    triggerQuery:
      !isErrorVaultId && !isLoadingVaultId && data?.data.artistLink?.artist.mainVaultId != null,
  });

  const renderItem = useStableCallback(
    (_index: number, announcement: FragmentType<AnnouncementRowFragmentDoc>) => {
      return (
        !!artistHandle && (
          <AnnouncementRow
            announcement={announcement}
            isOwner={isOwner}
            artistHandle={artistHandle}
          />
        )
      );
    },
  );

  return (
    <DefaultLayout
      withVaultTheme={isThemeEnabled}
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={
        <BackButton
          className={twMerge('md2:hidden', isThemeEnabled ? 'text-vault_text' : 'text-white')}
        />
      }
      stretch
      contentClassName={
        isThemeEnabled ? 'md2:bg-vault_text/3' : isNewMenuEnabled ? 'md2:bg-white/3' : undefined
      }
      headerCenter={
        <Text
          className={twMerge(
            'font-title !text-title-m font-medium',
            isThemeEnabled ? 'text-vault_text' : 'text-base50',
          )}
        >
          Announcements
        </Text>
      }
      childrenWrapperClassName="px-0"
      headerClassName={twMerge(
        isThemeEnabled &&
          'bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5',
      )}
    >
      <Virtuoso
        itemContent={renderItem}
        data={announcements}
        className="no-scrollbar w-full flex-1"
        endReached={hasNextPage && !isFetchingNextPage ? () => fetchNextPage() : undefined}
      />
    </DefaultLayout>
  );
}

function AnnouncementRow({
  announcement,
  isOwner,
  artistHandle,
}: {
  announcement: FragmentType<AnnouncementRowFragmentDoc>;
  isOwner: boolean;
  artistHandle: string;
}) {
  const [isExpired, setIsExpired] = useState(false);
  const { openToast } = useToast();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { mutateAsync: cancelScheduledAnnouncementAsync } = useMutation(
    CancelScheduledAnnouncementDocument,
    {},
  );

  const { mutateAsync: hideScheduledAnnouncementAsync } = useMutation(
    HideScheduledAnnouncementDocument,
    {},
  );

  const { mutateAsync: showScheduledAnnouncementAsync } = useMutation(
    ShowScheduledAnnouncementDocument,
    {},
  );

  const {
    id,
    content,
    scheduledAt,
    vault: { artist },
    isFullVersionAvailable,
    status,
    featureAccess,
  } = getFragment(AnnouncementRowFragmentDoc, announcement);

  const isFree = featureAccess.some(({ feature }) => feature.__typename === 'FreeScheduledEvent');

  const isActive = status === ScheduledEventStatus.Active;
  const isHidden = status === ScheduledEventStatus.Hidden;

  const alreadySent = useMemo(
    () => new Date(scheduledAt) < new Date() || isExpired,
    [isExpired, scheduledAt],
  );

  const needCountdown = useMemo(() => {
    if (alreadySent) {
      return false;
    }
    const now = new Date();
    const scheduled = new Date(scheduledAt);
    return now.getTime() - scheduled.getTime() < ONE_DAY_IN_MS;
  }, [alreadySent, scheduledAt]);

  const dateString = useMemo(
    () =>
      alreadySent
        ? pastDateInterval(new Date(scheduledAt))
        : futureDateInterval(new Date(scheduledAt)),
    [alreadySent, scheduledAt],
  );

  const { hours, minutes, seconds } = useTimer({
    expiryTimestamp: new Date(scheduledAt),
    autoStart: needCountdown,
    onExpire: () => {
      setIsExpired(true);
    },
  });

  const onHideShowPress = useCallback(async () => {
    if (isHidden) {
      await showScheduledAnnouncementAsync({ input: { scheduledEventId: id } }).catch(e => {
        openToast({
          variant: 'error',
          text: 'Announcement could not be shown. Please try again later',
        });

        captureException(e, {
          extra: { scheduledEventId: id },
          tags: { source: 'AnnouncementRow', action: 'showScheduledAnnouncement' },
        });
      });
    } else {
      await hideScheduledAnnouncementAsync({ input: { scheduledEventId: id } }).catch(e => {
        openToast({
          variant: 'error',
          text: 'Announcement could not be hidden. Please try again later',
        });

        captureException(e, {
          extra: { scheduledEventId: id },
          tags: { source: 'AnnouncementRow', action: 'hideScheduledAnnouncement' },
        });
      });
    }

    closeBottomsheet();
  }, [
    closeBottomsheet,
    hideScheduledAnnouncementAsync,
    id,
    isHidden,
    openToast,
    showScheduledAnnouncementAsync,
  ]);

  const onCancelPress = useStableCallback(async () => {
    await cancelScheduledAnnouncementAsync({ input: { scheduledEventId: id } }).catch(e => {
      openToast({
        variant: 'error',
        text: 'Announcement could not be deleted. Please try again later',
      });

      captureException(e, {
        extra: { scheduledEventId: id },
        tags: { source: 'AnnouncementRow', action: 'cancelScheduledAnnouncement' },
      });
    });

    closeBottomsheet();
  });

  return (
    <View
      className={twMerge(
        'mx-4 flex flex-col gap-3 border-0 border-b border-solid py-6',
        isThemeEnabled ? 'border-vault_text/5' : 'border-base700',
      )}
    >
      {artist != null && (
        <View className="flex flex-row">
          <ArtistProfileImage
            profileImageUrl={artist.profileImage?.url}
            className={twMerge(
              'h-8 w-8 rounded-full',
              isThemeEnabled ? 'bg-vault_background' : 'bg-base800',
            )}
          />
          <View className="ml-2">
            <Text
              className={twMerge(
                'line-clamp-1 font-base !text-base-m font-semibold',
                isThemeEnabled ? 'text-vault_text' : 'text-white',
              )}
            >
              {artist.name}
            </Text>
            {isOwner &&
              (isActive ? (
                <Text
                  className={twMerge(
                    'flex font-base !text-base-s font-medium',
                    isThemeEnabled ? 'text-vault_text/50' : 'text-[#3BE98B]',
                  )}
                >
                  Sent to {isFree ? 'all members' : 'paid members'}
                  <Text
                    className={twMerge(
                      'ml-1 font-base !text-base-s font-medium',
                      isThemeEnabled ? 'text-vault_text' : 'text-white',
                    )}
                  >
                    • {dateString} ago
                  </Text>
                </Text>
              ) : isHidden ? (
                <Text
                  className={twMerge(
                    'flex font-base !text-base-s font-medium',
                    isThemeEnabled ? 'text-vault_text/50' : 'text-destructive300',
                  )}
                >
                  Hidden
                  <Text
                    className={twMerge(
                      'ml-1 font-base !text-base-s font-medium',
                      isThemeEnabled ? 'text-vault_text' : 'text-white',
                    )}
                  >
                    {alreadySent
                      ? `• ${dateString} ago`
                      : `• Scheduled ${
                          needCountdown
                            ? `in ${
                                hours > 0 ? `${hours}h ` : ''
                              }${minutes > 0 ? `${minutes}m ` : ''}${seconds > 0 ? `${seconds}s` : ''}`
                            : dateString
                        }`}
                  </Text>
                </Text>
              ) : (
                <Text
                  className={twMerge(
                    'font-base !text-base-s font-medium tabular-nums',
                    isThemeEnabled ? 'text-vault_text/50' : 'text-[#FBBF24]',
                  )}
                >
                  Scheduled{' '}
                  {needCountdown
                    ? `in ${
                        hours > 0 ? `${hours}h ` : ''
                      }${minutes > 0 ? `${minutes}m ` : ''}${seconds > 0 ? `${seconds}s` : ''}`
                    : dateString}
                </Text>
              ))}
          </View>
          {isOwner ? (
            <>
              <View className="flex-1" />
              <Popover.Root>
                <Popover.Trigger
                  className={twMerge(
                    'cursor-pointer appearance-none border-none bg-transparent p-1 outline-none transition-all duration-300',
                    isThemeEnabled
                      ? 'text-vault_text hover:text-vault_text/50'
                      : 'text-base200 hover:text-base400',
                  )}
                >
                  <FontAwesomeIcon icon={faEllipsisH} fontSize={18} />
                </Popover.Trigger>

                <Popover.Portal>
                  <Popover.Content
                    align="end"
                    className={twMerge(
                      'z-stickyHeader flex w-fit flex-col gap-1 rounded-lg',
                      isThemeEnabled ? 'bg-vault_text/10 backdrop-blur-2xl' : 'bg-black',
                    )}
                  >
                    {alreadySent ? (
                      <Button
                        label={isHidden ? 'Show announcement' : 'Hide announcement'}
                        onClick={onHideShowPress}
                        leadingIcon={isHidden ? faEye : faEyeSlash}
                        type="secondary"
                        className={twMerge(
                          'w-[248px] !text-base-m',
                          isThemeEnabled
                            ? 'bg-transparent text-vault_text hover:bg-vault_text/20'
                            : 'text-white',
                        )}
                      />
                    ) : (
                      <Button
                        label="Cancel scheduled message"
                        onClick={() => {
                          openBottomsheet({
                            type: 'CONFIRMATION',
                            confirmationBottomsheetProps: {
                              onConfirm: onCancelPress,
                              subText: 'Are you sure you want to cancel this announcement?',
                            },
                          });
                        }}
                        leadingIcon={faCancel}
                        type="secondary"
                        className={twMerge(
                          'w-auto !text-base-m',
                          isThemeEnabled
                            ? 'bg-transparent text-vault_text hover:bg-vault_text/20'
                            : 'text-destructive300',
                        )}
                      />
                    )}
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </>
          ) : (
            <Text
              className={twMerge(
                'ml-1 !text-base-m font-medium',
                isThemeEnabled ? 'text-vault_text/50' : 'text-white/50',
              )}
            >
              · {dateString}
            </Text>
          )}
        </View>
      )}
      <View className="relative">
        <LinkifyText>
          <Text
            className={twMerge(
              'whitespace-pre-wrap break-words font-base text-[14px]/[20px] font-normal',
              !isFullVersionAvailable && 'blur-[6px]',
              isThemeEnabled ? 'text-vault_text' : 'text-white',
            )}
          >
            {content}
          </Text>
        </LinkifyText>
        {!isFullVersionAvailable && (
          <Link
            className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-1 no-underline"
            to={`/${artistHandle}/subscribe?artistHandle=${artistHandle}`}
          >
            <FontAwesomeIcon
              icon={faLock}
              className={twMerge('text-[16px]', isThemeEnabled ? 'text-vault_text' : 'text-base50')}
            />
            <Text
              className={twMerge(
                'font-base text-[12px]/[18px] font-normal',
                isThemeEnabled ? 'text-vault_text' : 'text-base50',
              )}
            >
              Upgrade to see the announcement
            </Text>
          </Link>
        )}
      </View>
    </View>
  );
}
