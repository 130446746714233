import { forwardRef, type MouseEventHandler, type ReactNode, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faMusic } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import {
  ACCEPTED_AUDIO_MIME_TYPES,
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_VIDEO_TYPES,
  MAX_AUDIO_UPLOAD_SIZE_BYTES,
  MAX_AUDIO_UPLOAD_SIZE_TEXT,
} from '../../constants/fileConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useToast } from '../../contexts/ToastContext';
import { TrackUpload } from '../../contexts/TrackUploadContext';
import {
  ArtistLayoutFragmentDoc,
  FeatureTypename,
  type FragmentType,
  getFragment,
  TierTypename,
  VaultType,
} from '../../graphql/generated';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useUploadVaultContentFile } from '../../hooks/useUploadVaultContentFile';
import { useWindow } from '../../hooks/useWindow';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import type { EventObject, EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { RenewSubscriptionBanner } from '../banner/RenewSubscriptionBanner';
import { Button } from '../buttons/Button';
import { MenuButton } from '../buttons/MenuButton';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { View } from '../common/View';
import { useFullScreenAudioPlayerState } from '../main/AudioPlayer';
import { SelectingVaultNav } from '../vault/SelectingVaultNav';
import { VaultNav } from '../vault/VaultNav';
import { DefaultLayout } from './DefaultLayout';

gql(/* GraphQL */ `
  fragment artistLayout on Artist {
    id
    name
    profileImage {
      id
      url
    }
    mainVault {
      id
      price
      messageChannelId
      activeSubscription {
        id
        status
        currentPeriodEnd
        stripeSubscriptionId
        ...ActiveSubscriptionFeatures
      }
      artist: artistProfile {
        id
        name
      }
      type
      contentCount
      isUserArtistAdmin
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
    linkValue
  }
`);

const ArtistLayout = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    artist: FragmentType<ArtistLayoutFragmentDoc>;
    showProfileImage: boolean;
    rightHeader: ReactNode;
    showBadgeOverlay?: boolean;
  }
>(({ children, artist, showProfileImage, rightHeader, showBadgeOverlay = false }, ref) => {
  const { isBottomAudioPlayerOpen } = useFullScreenAudioPlayerState();
  const { isDesktop } = useWindow();

  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { profileImage, linkValue, mainVault } = getFragment(ArtistLayoutFragmentDoc, artist);
  const isOwner = mainVault.isUserArtistAdmin;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: mainVault?.activeSubscription,
    isOwner,
  });

  const isVaultFreeOnly = mainVault.type === VaultType.FreeOnly;
  const isCurrentUserFreeTier = activeSubscriptionFeatures?.tier === TierTypename.FreeTier;
  const showSubscribeButton = !isVaultFreeOnly && isCurrentUserFreeTier && !showBadgeOverlay;

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const chatAvailableForFreeUsers = mainVault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  const { isSelecting } = useSelectVaultContent();

  const showVaultNav = !isDesktop || !isNewMenuEnabled;

  const bottomNav = useMemo(
    () => (
      <>
        {isSelecting && isOwner ? (
          <SelectingVaultNav vaultId={mainVault.id} />
        ) : (
          showVaultNav && (
            <VaultNav
              vaultId={mainVault.id}
              messageChannelId={mainVault.messageChannelId}
              chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
              hasChatReadAccess={hasChatReadAccess}
              variant="default"
              withVaultTheme={isThemeEnabled}
            />
          )
        )}
      </>
    ),
    [
      chatAvailableForFreeUsers,
      hasChatReadAccess,
      isOwner,
      isSelecting,
      isThemeEnabled,
      mainVault.id,
      mainVault.messageChannelId,
      showVaultNav,
    ],
  );

  return (
    <DefaultLayout
      showBorder={isNewMenuEnabled}
      showRoundedTop={isNewMenuEnabled}
      ref={ref}
      headerLeft={
        !isDesktop && !isSelecting && !showBadgeOverlay ? (
          <MenuButton className={twMerge('h-12', isThemeEnabled && 'text-vault_text')} />
        ) : null
      }
      headerCenter={
        showProfileImage && (
          <ArtistProfileImage
            profileImageUrl={profileImage?.url}
            className="h-12 w-12 md2:hidden"
          />
        )
      }
      headerRight={rightHeader ?? <View className="w-[50px]" />}
      banner={<RenewSubscriptionBanner artist={artist} />}
      isHeaderTransparent={!showProfileImage}
      shouldSkipMargin
      withBottomNavigator={false}
      customBottomNavigator={<View className="w-full md2:hidden">{bottomNav}</View>}
      messageChannelId={mainVault.messageChannelId}
      hasChatReadAccess={hasChatReadAccess}
      chatAvailableForFreeUsers={chatAvailableForFreeUsers}
      vaultId={mainVault.id}
      withVaultTheme={isThemeEnabled}
      headerClassName={
        isThemeEnabled
          ? showProfileImage && !isDesktop
            ? 'border-0 border-b border-solid bg-vault_background border-b-vault_text/5'
            : 'bg-transparent'
          : ''
      }
      hideAudioPlayer={showBadgeOverlay}
      footer={
        <>
          {showSubscribeButton && (
            <View
              className={twMerge(
                'absolute z-above2 w-full md2:w-[600px]',
                isBottomAudioPlayerOpen ? 'bottom-36 md2:bottom-20' : 'bottom-20 md2:bottom-0',
              )}
            >
              <View className="mx-4 flex flex-row justify-center py-4">
                <View>
                  <SubscribeButton
                    label="Upgrade to All-Access"
                    className={twMerge(
                      'w-full !text-base-l',
                      isThemeEnabled ? 'bg-vault_accent text-vault_accent_text' : 'text-base900',
                    )}
                    linkValue={linkValue}
                    artistAvatarUrl={profileImage?.url}
                    price={mainVault.price || DEFAULT_PRICE}
                    vaultId={mainVault.id}
                    showBottomSheet={false}
                    component="vault_page"
                  />
                </View>
              </View>
            </View>
          )}

          <View className="hidden w-full md2:block">{bottomNav}</View>
        </>
      }
    >
      {children}
    </DefaultLayout>
  );
});

const UploadButton = <Event extends EventType>({
  artistLinkValue,
  className,
  event,
  vaultId,
  showIcon,
  customLabel,
  onNavigate,
  folderId,
  onMouseEnter,
  onMouseLeave,
}: {
  artistLinkValue: string;
  className?: string;
  event?: EventObject<Event>;
  vaultId: string;
  showIcon?: boolean;
  customLabel?: string;
  onNavigate?: () => void;
  folderId: string | null;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { value: isVaultMediaEnabled } = useGate(FEATURE_GATES.VAULT_MEDIA);
  const { pathname } = useLocation();
  const { saveVaultContentFile } = useUploadVaultContentFile({
    artistLinkValue,
    vaultId,
    folderId,
  });

  const { openToast } = useToast();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    noDragEventsBubbling: true,
    async onDropAccepted([file]) {
      TrackUpload.isPromptOpen = true;
      onNavigate?.();
      await saveVaultContentFile(file);
    },
    onDropRejected() {
      openToast({
        text: `File could not be uploaded. Make sure it is a WAV or MPEG file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
        variant: 'error',
      });
      TrackUpload.isPromptOpen = false;
    },
    onFileDialogCancel() {
      TrackUpload.isPromptOpen = false;
    },
    accept: !isVaultMediaEnabled
      ? ACCEPTED_AUDIO_MIME_TYPES
      : {
          ...ACCEPTED_AUDIO_MIME_TYPES,
          ...ACCEPTED_IMAGE_TYPES,
          ...ACCEPTED_VIDEO_TYPES,
        },
    maxFiles: 1,
    maxSize: MAX_AUDIO_UPLOAD_SIZE_BYTES,
    disabled: false,
  });

  const rootProps = getRootProps();

  return (
    <div
      className="hover:cursor-pointer"
      {...rootProps}
      onClick={(...args) => {
        event != null && trackEvent({ ...event, pathname });
        TrackUpload.isPromptOpen = true;

        rootProps.onClick?.(...args);
      }}
    >
      <Button
        label={customLabel ?? 'Upload'}
        type="primary"
        className={className}
        leadingIcon={showIcon ? (isVaultMediaEnabled ? faArrowUpFromBracket : faMusic) : undefined}
        leadingIconClassName="aspect-square"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <input {...getInputProps()} />
    </div>
  );
};

export { ArtistLayout, UploadButton };
