import { GraphQLReactWS } from '@soundxyz/graphql-react-ws';
import { getAuthToken } from './authToken';
import { graphqlClientHeaders } from './headers';

const GRAPHQL_API_WS_URL_CONST = import.meta.env.VITE_GRAPHQL_API_WS_URL;

if (!GRAPHQL_API_WS_URL_CONST) {
  throw Error('No VITE_GRAPHQL_API_WS_URL environment variable set!');
}

export const GATEWAY_API_WS_URL = new URL(GRAPHQL_API_WS_URL_CONST);

const maximumWait = 10_000; // 10s

async function randomisedExponentialBackoff(retries: number) {
  let retryDelay = 1000; // start with 1s delay
  for (let i = 0; i < retries; i++) {
    retryDelay *= 2;
  }
  await new Promise(resolve =>
    setTimeout(
      resolve,
      Math.min(retryDelay, maximumWait) +
        // add random timeout from 300ms to 3s
        Math.floor(Math.random() * (3000 - 300) + 300),
    ),
  );
}

export const {
  useSubscription,
  subscriptionStores,
  Effects: SubscriptionEffects,
} = GraphQLReactWS({
  graphqlWsOptions: {
    url: GATEWAY_API_WS_URL.href,
    async connectionParams() {
      return {
        ...(await getAuthToken()),
        ...graphqlClientHeaders,
      };
    },
    shouldRetry() {
      return true;
    },
    retryAttempts: Infinity,
    retryWait: randomisedExponentialBackoff,
  },
});

export type {
  ExecutionResultWithData,
  ExecutionResultWithErrors,
  OnData,
  OnError,
} from '@soundxyz/graphql-react-ws';
