export const FEATURE_GATES = {
  KILLSWITCH_CHAT: 'killswitch_vault_chat',
  KILLSWITCH_SUBSCRIPTION: 'killswitch_vault_subscription',
  FOLDERS: 'vault_folders_web',
  MENU: 'vault_menu_desktop',
  VAULT_SORTING: 'vault_sorting',
  PERSONALIZATION: 'artist_personalization',
  MEMBERSHIP: 'vault_membership',
  VAULT_MEDIA: 'vault_upload_media_web',
  SPOTIFY_CONNECT: 'vault_spotify_connect',
  SPOTIFY_REVIEW_PRESAVE: 'vault_spotify_review_presave',
} as const satisfies Record<string, string>;

export const FEATURE_GATE_OVERRIDES: Record<keyof typeof FEATURE_GATES, boolean | undefined> = {
  KILLSWITCH_CHAT: undefined,
  KILLSWITCH_SUBSCRIPTION: undefined,
  FOLDERS: undefined,
  MENU: undefined,
  VAULT_SORTING: undefined,
  PERSONALIZATION: undefined,
  MEMBERSHIP: undefined,
  VAULT_MEDIA: undefined,
  SPOTIFY_CONNECT: undefined,
  SPOTIFY_REVIEW_PRESAVE: undefined,
};
