import React, { type FC } from 'react';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faAngleLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Button } from './Button';

type Props = {
  className?: string;
  onClick?: () => void;
  onBack?: () => void;
  href?: string;
};

export const BackButton: FC<Props> = ({ className, onClick, onBack, href }) => {
  const { artistHandle } = useArtistHandle();
  const navigate = useNavigate();
  const goBack = () => {
    if (window.history.state.idx != null && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(artistHandle != null ? artistNavigationPath(artistHandle, '') : '/');
    }

    onBack?.();
  };
  return (
    <Button
      iconOnly
      label="Back"
      leadingIcon={faAngleLeft}
      className={twMerge('select-none text-[24px] text-white', className)}
      onClick={href == null ? onClick ?? goBack : undefined}
      href={href}
      event={{ type: EVENTS.BACK, properties: { type: 'default' } }}
    />
  );
};
