export type COLOR = typeof COLOR;

export const COLOR = {
  transparent: 'transparent',
  neutral50: '#FAFAFA',
  neutral100: '#F5F5F5',
  neutral200: '#E5E5E5',
  neutral300: '#D4D4D4',
  neutral400: '#A3A3A3',
  neutral500: '#737373',
  neutral600: '#525252',
  neutral700: '#404040',
  neutral800: '#262626',
  neutral900: '#171717',
  brand50: '#ECFDF5',
  brand100: '#A7F3D0',
  brand200: '#6EE7B7',
  brand300: '#34D399',
  brand500: '#10B981',
  brand600: '#059669',
  brand700: '#047857',
  brand800: '#065F46',
  brand900: '#064E3B',
  brandAccent20: '#E5F3F0',
  brandAccent40: '#D5F0E9',
  brandAccent50: '#D1FAE5',
  brandAccent60: '#C3ECE2',
  brandAccent80: '#B2E9DB',
  brandAccent100: '#A1E6D5',
  accent300: '#F0ABFC',
  accent400: '#FCE4F4',
  accent700: '#D946EF',
  success50: '#F0FDF4',
  success500: '#22C55E',
  success700: '#15803D',
  warning50: '#FFF7ED',
  warning300: '#FBBF24',
  warning400: '#EAB308',
  warning700: '#C2410C',
  destructive50: '#FEF2F2',
  destructive100: '#FF506A',
  destructive200: '#F7A1A1',
  destructive300: '#EF4444',
  destructive400: '#D22F2F',
  destructive700: '#B91C1C',
  education50: '#ECECFF',
  education100: '#8280FF',
  education200: '#605DEC',
  white: '#fff',
  black: '#0E1213',
  blue400: '#4299E1',
  grey100: '#F3F4F6',
  grey200: '#E5E6EB',
  grey300: '#D2D5D9',
  grey400: '#9DA3AE',
  grey500: '#6D7180',
  grey600: '#4D5562',
  grey700: '#394150',
  grey800: '#192024',
  grey900: '#1A1B22',
  danger100: '#e53e3e',
  danger200: '#d32f2f',
  black100: '#262626',
  gray100: '#8F8F8F',
  gray200: '#EEEEEE',
  gray300: '#E7E7E7',
  green100: '#34D399',
  green200: '#A7F3D0',
  green300: '#30AEAD',
  base50: '#FAFAFA',
  base100: '#F7F7F7',
  base200: '#F5F5F5',
  base300: '#E6E6E6',
  base400: '#A3A3A3',
  base500: '#737373',
  base600: '#404040',
  base700: '#333333',
  base800: '#1F1F1F',
  base900: '#0F0F0F',
  fuchsia500: '#EB5CBB',
  fuchsia500_alpha50: 'rgba(235,92,187,0.5)',
  fuchsia500_alpha30: 'rgba(235,92,187,0.3)',
  base300_alpha50: 'rgba(230,230,230,0.5)',
  base800_alpha50: 'rgba(31,31,31,0.5)',
  base900_alpha90: 'rgba(15,15,15,0.9)',
  orange100: '#FF753A',
  yellow100: '#E3F41D',
  spotify: '#63D46D',
  spotify_light: 'rgba(179, 231, 183, 0.60);',
  secondary: '#0085FF',
  delete: '#FF453A',
  // This custom format allow us to use tailwind opacity modifier syntax
  vault_background: 'rgba(var(--vault-background-color, #0E1213), <alpha-value>)',
  vault_text: 'rgba(var(--vault-text-color, #fff), <alpha-value>)',
  vault_text_opposite: 'rgba(var(--vault-text-opposite-color, #0E1213), <alpha-value>)',
  vault_accent: 'rgba(var(--vault-accent-color, #E3F41D), <alpha-value>)',
  vault_accent_text: 'rgba(var(--vault-accent-text-color, #0E1213), <alpha-value>)',
} as const;
