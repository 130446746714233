import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { CD } from '../../constants/imageConstants';
import { AnimateIn } from '../common/AnimateIn';
import { View } from '../common/View';

const HIDE_HEIGHT = 600;
const HIDE_WIDTH = 290;

export function SpinningCD({ isPlaying, trackId }: { isPlaying: boolean; trackId: string }) {
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;

  const [shouldHide, setShouldHide] = useState(
    viewportHeight < HIDE_HEIGHT || viewportHeight < HIDE_WIDTH,
  );

  useEffect(() => {
    const resize = () => {
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      setShouldHide(viewportHeight < HIDE_HEIGHT || viewportWidth < HIDE_WIDTH);
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [viewportWidth]);

  if (shouldHide) {
    return null;
  }

  return (
    <View className="absolute top-[64px] box-border flex aspect-1 max-h-[calc(100vh-24px)] w-[134vw] flex-1 select-none flex-col items-start justify-start overflow-y-hidden">
      <AnimateIn
        key={trackId}
        from="opacity-0 translate-0 translate-x-[-67vw]"
        to="opacity-100 translate-0 translate-x-0"
        duration={800}
      >
        <View
          className={twMerge(
            'absolute left-[-50vw] top-0 mt-[12px] aspect-1 w-[134vw] animate-spinningCD',
            isPlaying ? '[animation-play-state:running]' : '[animation-play-state:paused]',
          )}
        >
          <img src={CD} className="aspect-1 w-full" />
        </View>
      </AnimateIn>
    </View>
  );
}
