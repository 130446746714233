import { type FC, useEffect } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { ActiveSubscriptionFeaturesFragmentDoc } from '../../graphql/generated';
import { type FragmentType, VaultContentType } from '../../graphql/generated';
import { useFreeTier } from '../../hooks/useFreeTier';

import { useVaultContentPagination } from '../../hooks/useVaultContent';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { View } from '../common/View';
import { SkeletonTrackFile, TrackFile } from '../track/TrackFile';
import { VaultUploadPromptView } from '../vault/VaultUploadPromptView';

export const TrackGrid: FC<{
  isOwner: boolean;
  vaultId: string;
  artistLinkValue: string;
  price: number | null;
  invite: string | null;
  smsCampaignResponseShortcode: string | null;
  profileImageUrl: string | null | undefined;
  activeSubscription: FragmentType<ActiveSubscriptionFeaturesFragmentDoc> | null;
  artistName: string;
}> = ({
  isOwner,
  vaultId,
  artistLinkValue,
  activeSubscription,
  invite,
  smsCampaignResponseShortcode,
  price,
  profileImageUrl,
  artistName,
}) => {
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();
  const { subscribeFreeTier } = useFreeTier();
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const { orderedList, isInitialLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useVaultContentPagination({
      vaultId,

      isOwner,
      contentType: VaultContentType.Track,
    });

  const onLockedFileClick = () => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SUBSCRIBE_WELCOME_MESSAGE,
      subscribeWelcomeMessageBottomsheetProps: {
        monthlySubPrice: `$${price}`,
        artistAvatarUrl: profileImageUrl,
        onConfirm: () => {
          if (loginStatus !== LoginStatus.LOGGED_IN) {
            const queryParams = constructQueryParams({
              artistHandle: artistLinkValue,
              openBottomSheet: 'freeTierModal',
              invite,
              c: smsCampaignResponseShortcode,
            });

            navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
          } else {
            subscribeFreeTier({
              input: { vaultId, inviteCode: invite, smsCampaignResponseShortcode },
            });
          }
        },
        artistHandle: artistLinkValue,
        isJoiningFreeTier: true,
        title: 'Join this vault to get started',
        buttonText: 'Join for free',
      },
      shared: {
        hideCloseBottomsheetButton: true,
      },
    });
  };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const LoadingFooter = React.useCallback(
    () =>
      isFetchingNextPage ? (
        <>
          <SkeletonTrackFile />
          <SkeletonTrackFile />
          <SkeletonTrackFile />
        </>
      ) : null,
    [isFetchingNextPage],
  );

  if (isInitialLoading) {
    return <TrackGridSkeleton />;
  }

  return (
    <View className="my-4 w-full">
      {isOwner &&
        loginStatus === LoginStatus.LOGGED_IN &&
        !isInitialLoading &&
        orderedList.length === 0 && (
          <VaultUploadPromptView
            vaultId={vaultId}
            artistLinkValue={artistLinkValue}
            folderId={null}
          />
        )}
      <View className="-mx-3 grid flex-1 grid-cols-3 gap-x-4 gap-y-[18px] overflow-y-scroll scrollbar-none md2:-mx-6">
        {orderedList.map((item, i) => {
          if (item.__typename !== 'VaultTrack') return null;

          return (
            <TrackFile
              key={item.id}
              track={item}
              isSelected={false}
              vaultContentId={item.id}
              artistLinkValue={artistLinkValue}
              type="artist_page"
              containerRef={i === orderedList.length - 1 ? ref : undefined}
              withUpsellInterstitial
              onClick={!activeSubscription && !isOwner ? onLockedFileClick : undefined}
              artistName={artistName}
            />
          );
        })}
        <LoadingFooter />
      </View>
    </View>
  );
};

export const TrackGridSkeleton = () => {
  return (
    <View className="my-4 flex w-full flex-col">
      <View className="-mx-3 grid flex-1 grid-cols-3 gap-x-4 gap-y-[18px] overflow-y-scroll scrollbar-none md2:-mx-6">
        <SkeletonTrackFile />
        <SkeletonTrackFile />
        <SkeletonTrackFile />
        <SkeletonTrackFile />
        <SkeletonTrackFile />
        <SkeletonTrackFile />
      </View>
    </View>
  );
};
