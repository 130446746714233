import { gql } from '@soundxyz/gql-string';
import { isUUID4 } from '@soundxyz/utils/validation';
import { IS_FINAL_DEPLOYMENT } from '../constants/urlConstants';
import { GlobalAuthContext } from '../contexts/AuthContext';
import { fetchQuery } from '../graphql/client';
import { TrackContentByIdDocument } from '../graphql/generated';

gql(/* GraphQL */ `
  query TrackContentById($vaultContentId: UUID!, $asArtistId: UUID) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename

      id

      ... on VaultTrack {
        id

        createdAt

        duration

        title
        linkValue
        caption
        snippetVideo {
          id
          url
        }
        freeTierSnippet {
          id
          startAt
          endAt
        }
        vault {
          id
          type
          messageChannelId
          artist: artistProfile {
            id
            name
            linkValue
            profileImage {
              id
              url
            }
          }
          price
          activeSubscription {
            id
            ...ActiveSubscriptionFeatures
          }
          isUserArtistAdmin
        }
        commentMessageCount
        commentPinnedMessage {
          id
          content
          ...TrackCommentRow
        }
        commentCaptionMessage {
          id
          content
          ...TrackCommentRow
        }
        featureAccess {
          feature {
            __typename
          }
        }
        isFullVersionAvailable
        normalizedPeaks
        parentVaultContentId
      }
    }
    getSignedTrackContent(trackContentId: $vaultContentId) {
      __typename
      ... on QueryGetSignedTrackContentSuccess {
        data
      }
    }
    getSignedTrackSnippetContent(trackContentId: $vaultContentId) {
      __typename
      ... on QueryGetSignedTrackSnippetContentSuccess {
        data
      }
    }
  }
`);

export async function queryAudioFromTrack({ trackId }: { trackId: string }) {
  if (!isUUID4(trackId)) return null;

  const {
    data: { vaultContentById, getSignedTrackContent, getSignedTrackSnippetContent },
  } = await fetchQuery(TrackContentByIdDocument, {
    variables: {
      vaultContentId: trackId,
      asArtistId: GlobalAuthContext.loggedInUser?.artist?.id,
    },
    staleTime: 5000,
  });

  if (!vaultContentById || vaultContentById.__typename !== 'VaultTrack') return null;

  const isFullVersionAvailable = vaultContentById.isFullVersionAvailable;
  const featureAccessList = vaultContentById?.featureAccess || [];
  const hasFreeAccess = featureAccessList.some(
    access => access.feature.__typename === 'FreeVaultContent',
  );

  const trackWithSignature =
    getSignedTrackContent.__typename === 'QueryGetSignedTrackContentSuccess'
      ? getSignedTrackContent.data
      : null;

  const snippetWithSignature =
    getSignedTrackSnippetContent?.__typename === 'QueryGetSignedTrackSnippetContentSuccess'
      ? getSignedTrackSnippetContent.data
      : null;

  const cdnUrl = !isFullVersionAvailable
    ? hasFreeAccess
      ? trackWithSignature
      : snippetWithSignature
    : trackWithSignature;

  if (!cdnUrl) return null;

  const trackUrl = new URL(cdnUrl);

  if (
    IS_FINAL_DEPLOYMENT &&
    (trackUrl.pathname.endsWith('playlist.m3u8') || trackUrl.pathname.endsWith('trimmed.m3u8'))
  ) {
    trackUrl.hostname = window.location.hostname;
  }

  return {
    cdnUrl: trackUrl.href,
    ...vaultContentById,
  };
}
