import type { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { ConfirmationBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

const ConfirmationBottomsheet: FC<ConfirmationBottomsheetProps> = ({
  subText,
  onConfirm,
  event,
  customContent,
  title = 'Are you sure?',
  confirmButtonText = 'Yes',
  confirmType = 'success',
}) => {
  const { closeBottomsheet } = useBottomsheetContainer();
  return (
    <View className="flex flex-col items-center justify-between px-4 md2:h-full md2:py-4">
      <Text className="mb-[16px] text-center font-title font-medium text-white max-md2:text-title-m md2:text-title-xl">
        {title}
      </Text>
      {customContent
        ? customContent
        : subText != null && (
            <Text className="mb-[16px] text-center font-base text-[18px]/[24px] font-normal text-white">
              {subText}
            </Text>
          )}
      <View className="flex w-[calc(100%-20px)] flex-row max-md2:mb-[16px]">
        <Button
          type="secondary"
          label={confirmType === 'success' ? 'Cancel' : 'Keep'}
          onClick={closeBottomsheet}
          className="mr-[8px] w-[unset] flex-1 justify-center rounded-full"
          event={{ type: EVENTS.CONFIRM_CANCEL, properties: { event } }}
        />
        <Button
          type="primary"
          label={confirmButtonText}
          onClick={e => {
            onConfirm(e);
            closeBottomsheet();
          }}
          className={twMerge(
            'ml-[8px] flex-1',
            confirmType === 'delete' && 'bg-destructive300 text-base50',
          )}
          event={{ type: EVENTS.CONFIRM_SUCCES, properties: { event } }}
        />
      </View>
    </View>
  );
};

export { ConfirmationBottomsheet };
