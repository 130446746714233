import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import type { MembershipBadgeBottomsheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { closeFullScreen } from '../main/AudioPlayer';
import { MembershipWelcomeView } from '../views/MembershipView';

export function MembershipBadgeBottomsheet({
  vaultId,
  aspectRatio,
  className,
  isLoading,
  artistHandle,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  points,
}: MembershipBadgeBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { closeExtraBottomsheet } = useExtraBottomsheetContainer();

  return (
    <View className="flex flex-col items-center justify-center">
      <MembershipWelcomeView
        title="You're in the club"
        childrenCotaninerClassName="md:mt-0 mt-2 mb-10"
        vaultId={vaultId}
        aspectRatio={aspectRatio}
        className={className}
        isLoading={isLoading}
        artistHandle={artistHandle}
        artistName={artistName}
        serialNumber={serialNumber}
        imageUrl={imageUrl}
        displayName={displayName}
        createdAt={createdAt}
        points={points}
        onComplete={() => {
          closeExtraBottomsheet();
          closeBottomsheet();
          closeFullScreen();
        }}
      />
    </View>
  );
}
