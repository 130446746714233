import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faComment, faHome, faIdCard } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faComment as faCommentSolid,
  faHome as faHomeSolid,
  faIdCard as faIdCardSolid,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useMembership, VaultToShowBadge } from '../../hooks/useMembership';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { useFullScreenAudioPlayerState } from '../main/AudioPlayer';
import { useBatchedVaultMessageUnreadCount } from '../views/hooks/useVaultMessageUnreadCount';
import { CreateButton } from './CreateButton';

export const VaultNav = memo(function VaultNav({
  messageChannelId,
  vaultId,
  hasChatReadAccess,
  chatAvailableForFreeUsers,
  variant,
  withVaultTheme,
}: {
  messageChannelId: string | undefined;
  vaultId: string | undefined;
  hasChatReadAccess: boolean;
  chatAvailableForFreeUsers: boolean;
  variant: 'default' | 'borderless';
  withVaultTheme: boolean;
}) {
  const { value: membershipEnabled } = useGate(FEATURE_GATES.MEMBERSHIP);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { artistHandle } = useArtistHandle();
  const { isFullscreenAudioOpen } = useFullScreenAudioPlayerState();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  const { loggedInUser, loginStatus } = useAuthContext();

  const isOwner = loggedInUser?.artist?.linkValue === artistHandle;

  const vaultToShowBadge = useSnapshot(VaultToShowBadge);
  const { membership } = useMembership({ artistHandle });

  const { artist, unseenUpdates, cardFirstTimeSeen } = membership || {};
  const hasActiveSubscription = !!artist?.mainVault?.activeSubscription?.id;

  const chatEnabled =
    (isOwner || hasChatReadAccess || chatAvailableForFreeUsers) && !!loggedInUser?.id;
  const showMembershipTab =
    loginStatus === LoginStatus.LOGGED_IN && hasActiveSubscription && membershipEnabled && !isOwner;

  const { unreadCount: messageNotificationCount } = useBatchedVaultMessageUnreadCount({
    messageChannelId,
    vaultId,
    enabled: chatEnabled,
  });

  const isChatCurrentPage = pathname.includes('/chat');
  const isMembershipCurrentPage = pathname.includes('/membership');
  const isFolderPage = pathname.includes('/folder');

  const onHomeClicked = () => {
    if ((!isChatCurrentPage && !isFolderPage && !isMembershipCurrentPage) || !artistHandle) return;

    trackEvent({
      type: EVENTS.TAB_NAVIGATE,
      properties: {
        type: 'vault_page',
      },
    });

    navigate(artistNavigationPath(artistHandle, '/'));
  };

  const onChatClicked = () => {
    if (isChatCurrentPage || !artistHandle) return;

    trackEvent({
      type: EVENTS.TAB_NAVIGATE,
      properties: {
        type: 'message_channel_page',
      },
    });

    navigate(artistNavigationPath(artistHandle, '/chat'));
  };

  const onMembershipClicked = () => {
    if (isMembershipCurrentPage || !artistHandle) return;

    trackEvent({
      type: EVENTS.TAB_NAVIGATE,
      properties: {
        type: 'membership_page',
      },
    });

    navigate(artistNavigationPath(artistHandle, '/membership'));
  };

  // We don't want to show the vault nav if the fullscreen audio player is open or if membership overlay is showing
  if (
    isFullscreenAudioOpen ||
    (vaultId && vaultToShowBadge[vaultId] && cardFirstTimeSeen == false && membershipEnabled)
  ) {
    return null;
  }

  return (
    <View
      className={twMerge(
        'sticky z-mobileNav flex h-[68px] max-h-[68px] w-full items-center justify-center border-0 py-2',
        variant === 'default' &&
          (isThemeEnabled && withVaultTheme
            ? 'border-t border-solid border-t-vault_text/5'
            : 'border-t border-solid border-t-base700'),
        isThemeEnabled && withVaultTheme ? 'bg-vault_background' : 'bg-black',
      )}
    >
      <View
        className={twMerge(
          'flex w-full  items-center justify-around',
          isOwner ? 'max-w-[200px]' : showMembershipTab ? 'max-w-full' : 'max-w-[150px]',
        )}
      >
        <button
          className="flex flex-1 appearance-none flex-col items-center justify-center gap-1 border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
          onClick={onHomeClicked}
        >
          <FontAwesomeIcon
            icon={!isChatCurrentPage && !isFolderPage ? faHomeSolid : faHome}
            fontSize={24}
            className={
              !isChatCurrentPage && !isMembershipCurrentPage && !isFolderPage
                ? isThemeEnabled && withVaultTheme
                  ? 'text-vault_text'
                  : 'text-white'
                : isThemeEnabled && withVaultTheme
                  ? 'text-vault_text/50'
                  : 'text-base50/50'
            }
          />

          <Text
            className={twMerge(
              'mt-0.5 text-center font-base text-[10px] font-semibold leading-[18px]',
              !isChatCurrentPage && !isMembershipCurrentPage && !isFolderPage
                ? isThemeEnabled && withVaultTheme
                  ? 'text-vault_text'
                  : 'text-white'
                : isThemeEnabled && withVaultTheme
                  ? 'text-vault_text/50'
                  : 'text-base50/50',
            )}
          >
            Home
          </Text>
        </button>

        {isOwner && !!vaultId && (
          <View className="mb-2 flex h-12 rounded-full transition-bottom delay-150 duration-300 ease-in-out">
            <CreateButton artistHandle={artistHandle} mainVaultId={vaultId} />
          </View>
        )}

        <button
          className="flex flex-1 appearance-none flex-col items-center justify-center border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
          onClick={onChatClicked}
        >
          <View className="relative">
            <FontAwesomeIcon
              icon={isChatCurrentPage ? faCommentSolid : faComment}
              fontSize={24}
              className={
                isThemeEnabled
                  ? isChatCurrentPage
                    ? 'text-vault_text'
                    : 'text-vault_text/50'
                  : isChatCurrentPage
                    ? 'text-white'
                    : 'text-base50/50'
              }
            />
            {!!messageNotificationCount && !hasChatReadAccess && (
              <View
                className={twMerge(
                  'absolute -right-1 -top-1 flex h-[9px] w-[9px] items-center justify-center rounded-full border-[2px] border-solid font-base text-[10px] font-semibold',
                  isThemeEnabled
                    ? 'border-vault_background bg-vault_text'
                    : 'border-black bg-yellow100 text-black',
                )}
              />
            )}
          </View>

          <Text
            className={twMerge(
              'mt-0.5 text-center font-base text-[10px] font-semibold leading-[18px]',
              isThemeEnabled
                ? isChatCurrentPage
                  ? 'text-vault_text'
                  : 'text-vault_text/50'
                : isChatCurrentPage
                  ? 'text-white'
                  : 'text-base50/50',
            )}
          >
            Chat
          </Text>
        </button>

        {showMembershipTab && (
          <button
            className="flex flex-1 appearance-none flex-col items-center justify-center border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
            onClick={onMembershipClicked}
          >
            <View className="relative">
              <FontAwesomeIcon
                icon={isMembershipCurrentPage ? faIdCardSolid : faIdCard}
                fontSize={24}
                className={
                  isThemeEnabled
                    ? isMembershipCurrentPage
                      ? 'text-vault_text'
                      : 'text-vault_text/50'
                    : isMembershipCurrentPage
                      ? 'text-white'
                      : 'text-base50/50'
                }
              />
              {!!unseenUpdates && (
                <View
                  className={twMerge(
                    'absolute -right-1 -top-1 flex h-[9px] w-[9px] items-center justify-center rounded-full border-[2px] border-solid font-base text-[10px] font-semibold',
                    isThemeEnabled
                      ? 'border-vault_background bg-vault_text'
                      : 'border-black bg-yellow100 text-black',
                  )}
                />
              )}
            </View>

            <Text
              className={twMerge(
                'mt-0.5 text-center font-base text-[10px] font-semibold leading-[18px]',
                isThemeEnabled
                  ? isMembershipCurrentPage
                    ? 'text-vault_text'
                    : 'text-vault_text/50'
                  : isMembershipCurrentPage
                    ? 'text-white'
                    : 'text-base50/50',
              )}
            >
              Membership
            </Text>
          </button>
        )}
      </View>
    </View>
  );
});
