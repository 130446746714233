import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { pause } from '../../audio/AudioEngineHTML5';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { EditTrackSnippetView } from '../../components/views/EditTrackSnippetView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';

import { TrackSnippetDetailsByIdDocument } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useSnippet } from '../../hooks/useSnippet';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  query TrackSnippetDetailsById($vaultContentId: UUID!) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename
      id
      vault {
        id
        artistId
      }
      title
      ... on VaultTrack {
        id
        title
        ...EditTrackSnippet
      }
    }
  }
`);

export function EditSnippetPage() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();

  const { editSnippet } = useSnippet();

  const { loggedInUser, loginStatus } = useAuthContext();

  const { vaultContentId } = useParams();
  const { artistHandle } = useArtistHandle();

  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  useVaultTheme();

  const [trimmed, setTrimmed] = useState<{ start: number; end: number } | null>(null);

  useSetMetaHeaders({
    title: 'Editing track snippet',
  });

  const { data: vaultContent, isLoading } = useQuery(TrackSnippetDetailsByIdDocument, {
    variables: !!vaultContentId && { vaultContentId },
    staleTime: 0,
  });

  useEffect(() => {
    if (!vaultContent?.data.vaultContentById) return;
  }, [vaultContent?.data.vaultContentById]);

  // Stop the audio when the component is unmounted
  useEffect(() => {
    () => pause();
  }, []);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const goBack = useStableCallback(() => {
    if (
      (window.history.state.idx != null && window.history.state.idx > 0) ||
      (window.history?.length && window.history.length > 1)
    ) {
      navigate(-1);
    } else {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  });

  const onBackClick = useStableCallback(() => {
    pause();
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: BOTTOMSHEET_TYPES.EXIT_FLOW,
        event: EVENTS.EDIT_TRACK_SNIPPET,
      },
      pathname,
    });

    openBottomsheet({
      type: 'EXIT_FLOW',
      exitFlowBottomsheetProps: {
        onConfirm: goBack,
        event: EVENTS.EDIT_TRACK_SNIPPET,
      },
    });
  });

  const onSaveClick = useStableCallback(async () => {
    pause();

    if (!vaultContentId || !trimmed) return;

    trackEvent({
      type: EVENTS.SAVE_TRACK_SNIPPET,
      properties: { artistHandle: artistHandle.toLowerCase(), vaultContentId },
      pathname,
    });

    try {
      const result = await editSnippet({
        input: { startTime: trimmed.start, vaultContentId, endTime: trimmed.end },
      });

      if (
        result.data.editVaultContentSnippet.__typename !== 'MutationEditVaultContentSnippetSuccess'
      ) {
        return;
      }
      goBack();
    } catch (error) {
      return;
    }
  });

  if (!vaultContentId || !artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (
    (!isLoading && vaultContent?.data.vaultContentById?.__typename !== 'VaultTrack') ||
    (loginStatus === LoginStatus.LOGGED_IN &&
      vaultContent?.data.vaultContentById &&
      vaultContent.data.vaultContentById.vault.artistId !== loggedInUser?.artist?.id)
  ) {
    return <Navigate to={`/${artistHandle}`} />;
  }

  return (
    <DefaultLayout
      withVaultTheme={isThemeEnabled}
      showRoundedTop={isThemeEnabled}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={
        <BackButton
          onClick={onBackClick}
          className={isThemeEnabled ? 'text-vault_text' : undefined}
        />
      }
      headerClassName={twMerge(
        isThemeEnabled &&
          'bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5',
      )}
      headerCenter={
        <View className="flex flex-col items-center justify-center">
          <Text
            className={twMerge(
              'font-title !text-title-m font-medium',
              isThemeEnabled ? 'text-vault_text' : 'text-white',
            )}
          >
            Edit snippet
          </Text>
          <Text
            className={twMerge(
              'line-clamp-1 font-base !text-base-m font-normal',
              isThemeEnabled ? 'text-vault_text/50' : 'text-base400',
            )}
          >
            {vaultContent?.data.vaultContentById?.title || 'Untitled'}
          </Text>
        </View>
      }
      headerRight={
        <View onClick={onSaveClick}>
          <Text
            className={twMerge(
              'font-title !text-base-m font-medium hover:cursor-pointer active:opacity-70',
              isThemeEnabled ? 'text-vault_accent' : 'text-yellow100',
            )}
          >
            Save
          </Text>
        </View>
      }
    >
      {vaultContent?.data.vaultContentById?.__typename === 'VaultTrack' && (
        <EditTrackSnippetView
          vaultTrackFrag={vaultContent.data.vaultContentById}
          onTrim={setTrimmed}
        />
      )}
    </DefaultLayout>
  );
}
