import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faArrowUpFromBracket, faMegaphone } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faInbox } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrophy } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFolder } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpArrowDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPalette } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { loadTrack } from '../../audio/AudioController';
import { Button } from '../../components/buttons/Button';
import { MenuButton } from '../../components/buttons/MenuButton';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { ArtistLayout } from '../../components/layouts/ArtistLayout';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { openFullscreenPlayer } from '../../components/main/AudioPlayer';
import { MediaViewer } from '../../components/message/MediaViewer';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { useListenNowModal } from '../../components/modals/ListenNowModal';
import { VaultContentSkeleton } from '../../components/vault/VaultContents';
import { MembershipWelcomeView } from '../../components/views/MembershipView';
import { ReferralLinkView } from '../../components/views/ReferralLinkView';
import { SkeletonVaultView, VaultView } from '../../components/views/VaultView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import { fetchQuery, useQuery } from '../../graphql/client';
import {
  ArtistByHandleDocument,
  MediaType,
  VisualVaultContentDocument,
} from '../../graphql/generated';
import { useBatchedVaultUnreadAnnouncementCount } from '../../hooks/announcements/useBatchedVaultUnreadAnnouncementCount';
import { usePaginatedVaultAnnouncements } from '../../hooks/announcements/usePaginatedVaultAnnouncements';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useMembership, VaultToShowBadge } from '../../hooks/useMembership';
import { useReferralLinks } from '../../hooks/useReferralLinks';
import { useShowHighlightedFeature } from '../../hooks/useShowHighlightedFeature';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

gql(/* GraphQL */ `
  query ArtistByHandle($link: String!) {
    artistLink(link: $link) {
      artist {
        id
        name
        linkValue
        mainVaultId
        mainVault {
          id
          messageChannelId
          type
          tiers {
            __typename
            enabledFeatures {
              feature {
                __typename
              }
            }
          }
          activeSubscription {
            id
            status
            ...ActiveSubscriptionFeatures
          }
          isUserArtistAdmin
        }
        profileImage {
          id
          url
        }
        phoneNumbers {
          phoneNumber
        }
        ...artistLayout
        ...artistMainVaultView
      }
    }
  }

  query VisualVaultContent($vaultContentId: UUID!) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename
      id
      title
      linkValue
      ... on VaultImage {
        id
        blurredMediaUrl
        uploadedMedia {
          id
          url
          mediaType
        }
      }
      ... on VaultVideo {
        id
        blurredMediaUrl
        uploadedMedia {
          id
          url
          mediaType
        }
      }
    }
  }
`);

export const VaultPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { artistHandle } = useArtistHandle();
  const { loggedInUser, loginStatus } = useAuthContext();
  const { openToast } = useToast();
  const { openListenNowModal } = useListenNowModal();
  const { value: membershipEnabled } = useGate(FEATURE_GATES.MEMBERSHIP);
  const { value: foldersEnabled } = useGate(FEATURE_GATES.FOLDERS);
  const { value: isNewMenuEnabled } = useGate(FEATURE_GATES.MENU);
  const { value: isThemeEnabled } = useGate(FEATURE_GATES.PERSONALIZATION);

  useVaultTheme();
  const { openVaultCustomization } = useMenuContainer();

  const vaultToShowBadge = useSnapshot(VaultToShowBadge);
  const code = searchParams.get('code'); // referral link code
  const trackId = searchParams.get('trackId');

  const {
    referralCodeData,
    isLoadingReferralCode,
    error: referralCodeError,
    refetch: refetchReferralCode,
  } = useReferralLinks(code);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [showProfileImage, setShowProfileImage] = useState(false);
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { isDesktop } = useWindow();

  const isOwner = loggedInUser?.artist?.linkValue === artistHandle;

  const { isError, isLoading, data, refetch } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  const vaultId = data?.data.artistLink?.artist.mainVaultId;

  useShowHighlightedFeature({ artistHandle, vaultId });

  const hasActiveSubscription = !!data?.data.artistLink?.artist.mainVault.activeSubscription?.id;
  const showInvitePageIcon =
    loginStatus === LoginStatus.LOGGED_IN &&
    (hasActiveSubscription || isOwner) &&
    !membershipEnabled;

  const announcementNotificationCount = useBatchedVaultUnreadAnnouncementCount({
    vaultId: vaultId,
    enabled: !!vaultId,
  });

  const { membership, isLoading: isLoadingMembership } = useMembership({
    artistHandle,
    hasActiveSubscription,
  });
  const { artist, user, serialNumber, createdAt, points, cardFirstTimeSeen } = membership || {};
  // Overlay is shown when new user joins a vault
  const showBadgeOverlay = useMemo(() => {
    return (
      (vaultId ? !!vaultToShowBadge[vaultId] && cardFirstTimeSeen === false : false) &&
      !isOwner &&
      membershipEnabled
    );
  }, [cardFirstTimeSeen, isOwner, membershipEnabled, vaultId, vaultToShowBadge]);

  // Modal is shown to users who are already in the vault
  const showBadgeModal = useMemo(() => {
    return cardFirstTimeSeen === false && !isOwner && membershipEnabled && hasActiveSubscription;
  }, [cardFirstTimeSeen, hasActiveSubscription, isOwner, membershipEnabled]);

  const {
    isLoading: isLoadingAnnouncements,
    isError: isErrorAnnouncements,
    orderedList: announcements,
  } = usePaginatedVaultAnnouncements({ enabled: !!artistHandle, artistHandle });

  const showAnnouncementsButton =
    !isLoadingAnnouncements && !isErrorAnnouncements && announcements.length > 0;

  useEffect(() => {
    if (isLoading) return;

    if (trackId != null) {
      fetchQuery(VisualVaultContentDocument, { variables: { vaultContentId: trackId } }).then(
        data => {
          if (data.data.vaultContentById == null) return;

          const { __typename, title, linkValue } = data.data.vaultContentById;

          if (__typename === 'VaultTrack' && vaultId) {
            if (hasActiveSubscription || isOwner) {
              if (isDesktop) {
                openListenNowModal({
                  trackId,
                  vaultId,
                });
              } else {
                loadTrack({
                  trackId,
                  vaultId,
                  folderId: null,
                  // without user interaction, autoplay is not allowed
                  autoplay: false,
                  component: 'track_landing_page',
                }).then(() => {
                  openFullscreenPlayer();
                });
              }
            } else {
              navigate(
                artistNavigationPath(artistHandle, linkValue ? `/t/${linkValue}` : `/${trackId}`),
              );
            }
          } else if (__typename === 'VaultFolder') {
            navigate(
              artistNavigationPath(
                artistHandle,
                linkValue ? `/f/${linkValue}` : `/folder/${trackId}`,
              ),
            );
          } else if (__typename === 'VaultImage' || __typename === 'VaultVideo') {
            if (!hasActiveSubscription && !isOwner) {
              const typeName = __typename === 'VaultImage' ? 'i' : 'v';

              return navigate(
                artistNavigationPath(
                  artistHandle,
                  linkValue ? `/${typeName}/${linkValue}` : `/${typeName}/${trackId}`,
                ),
              );
            }
            const { uploadedMedia, blurredMediaUrl } = data.data.vaultContentById;

            if (uploadedMedia != null) {
              const { id, url, mediaType } = uploadedMedia;
              openOverlay(
                <MediaViewer
                  title={title ?? ''}
                  medias={[{ id, url, type: mediaType }]}
                  onClose={closeOverlay}
                />,
              );
            } else if (blurredMediaUrl != null) {
              openOverlay(
                <MediaViewer
                  title={title ?? ''}
                  medias={[{ id: trackId, url: blurredMediaUrl, type: MediaType.Image }]}
                  onClose={closeOverlay}
                />,
              );
            }
          }
        },
      );
    }
  }, [
    artistHandle,
    closeOverlay,
    hasActiveSubscription,
    isDesktop,
    isLoading,
    isOwner,
    navigate,
    openListenNowModal,
    openOverlay,
    trackId,
    vaultId,
  ]);

  useEffect(() => {
    if (code && referralCodeData === null) {
      navigate(window.location.pathname, { replace: true });
      openToast({
        text: 'Referral code does not exist',
        variant: 'error',
      });
    }
  }, [code, referralCodeData, navigate, openToast]);

  const onShareClick = useStableCallback(() => {
    const link = generateShareLink({
      artistLinkValue: data?.data.artistLink?.artist.linkValue,
      inviteCode: loggedInUser?.inviteCode,
      path: null,
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SHARE,
      shared: {
        withVaultTheme: isThemeEnabled,
      },
      shareBottomsheetProps: {
        link,
        artistName: data?.data.artistLink?.artist.name ?? 'vault',
        withVaultTheme: isThemeEnabled,
      },
    });
  });

  useSetMetaHeaders({
    title: data?.data.artistLink ? `${data.data.artistLink?.artist.name}'s Vault` : null,
    imageUrl: data?.data.artistLink?.artist.profileImage?.url,
  });

  useEffect(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || !loggedInUser || !!code) return;

    if (loggedInUser?.username == null) {
      const queryParams = constructQueryParams({ artistHandle });
      navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`);
    }
  }, [artistHandle, code, loggedInUser, loggedInUser?.username, loginStatus, navigate]);

  const { setIsSelecting, isSelecting } = useSelectVaultContent();

  useEffect(() => {
    if (!isOwner) {
      setIsSelecting(false);
    }
  }, [isOwner, setIsSelecting]);

  useEffect(() => {
    if (
      showBadgeModal &&
      !showBadgeOverlay &&
      artistHandle &&
      vaultId &&
      vaultToShowBadge[vaultId] == null // don't show modal if overlay was shown
    ) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_BADGE,
        membershipBadgeBottomsheetProps: {
          vaultId,
          aspectRatio: 0.5,
          isLoading: isLoadingMembership,
          artistHandle,
          artistName: data?.data.artistLink?.artist.name || artistHandle,
          serialNumber,
          imageUrl: artist?.membershipCardImage?.url,
          displayName: user?.displayName || user?.username,
          createdAt,
          points,
        },
        shared: {
          hideCloseBottomsheetButton: true,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          preventSwipeToDismiss: true,
          withVaultTheme: true,
        },
      });
    }
  }, [
    artist?.membershipCardImage?.url,
    artistHandle,
    createdAt,
    data?.data.artistLink?.artist.name,
    isLoadingMembership,
    points,
    serialNumber,
    showBadgeModal,
    showBadgeOverlay,
    user?.displayName,
    user?.username,
    vaultId,
    openListenNowModal,
    openBottomsheet,
    vaultToShowBadge,
  ]);

  const buttons = useMemo(() => {
    const options: ActionBottomsheetProps['buttons'] = [];

    if (!data?.data.artistLink?.artist.id) return [];

    if (loggedInUser) {
      options.push({
        label: 'Notifications',
        leadingIcon: faInbox,
        type: 'secondary',
        href: `${ROUTES.SETTINGS}/artist-notification/${data.data.artistLink.artist.id}`,
        onClick: () => {
          closeBottomsheet();
        },
      });
    }

    if (isOwner) {
      options.push({
        label: 'Select Files',
        leadingIcon: faFolder,
        type: 'secondary',
        onClick: () => {
          setIsSelecting(true);
          closeBottomsheet();
        },
      });

      options.push({
        label: 'Sort Files',
        leadingIcon: faArrowUpArrowDown,
        type: 'secondary',
        href: artistNavigationPath(artistHandle, '/rearrange'),
        onClick: () => {
          closeBottomsheet();
        },
      });

      if (vaultId && isThemeEnabled) {
        options.push({
          label: 'Customize',
          leadingIcon: faPalette,
          type: 'secondary',
          onClick: () => {
            openVaultCustomization({ vaultId });
            closeBottomsheet();
          },
        });
      }
    }

    options.push({
      label: 'Share',
      leadingIcon: faArrowUpFromBracket,
      type: 'secondary',
      onClick: e => {
        e.stopPropagation();
        onShareClick();
      },
      event: {
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: {
          bottomsheetType: BOTTOMSHEET_TYPES.SHARE,
          entity: 'vault',
          aristId: data.data.artistLink.artist.id,
        },
      },
    });

    return options;
  }, [
    data?.data.artistLink?.artist.id,
    loggedInUser,
    isOwner,
    closeBottomsheet,
    artistHandle,
    vaultId,
    isThemeEnabled,
    setIsSelecting,
    openVaultCustomization,
    onShareClick,
  ]);

  const onEllipsisClick = useStableCallback(() => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ACTION,
      actionBottomsheetProps: {
        buttons,
        className: 'w-full md2:w-80',
        withVaultTheme: isThemeEnabled,
      },
    });
  });

  const onVaultInvitesClick = useStableCallback(() => {
    navigate(artistNavigationPath(artistHandle, '/invites'));
  });

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError || referralCodeError) {
    return (
      <DefaultLayout
        ref={scrollRef}
        showBorder={isNewMenuEnabled}
        showRoundedTop={isNewMenuEnabled}
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={
          !isDesktop ? (
            <MenuButton className={twMerge('h-12', isThemeEnabled && 'text-vault_text')} />
          ) : null
        }
        isHeaderTransparent={!showProfileImage}
        shouldSkipMargin
        withVaultTheme={isThemeEnabled}
        headerClassName={
          isThemeEnabled
            ? showProfileImage && !isDesktop
              ? 'border-0 border-solid border-b border-vault_text/5 bg-vault_background'
              : 'bg-transparent'
            : undefined
        }
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={isError ? refetch : refetchReferralCode}
            loggingType="vault_page"
            withVaultTheme={isThemeEnabled}
          />
        }
      />
    );
  }

  if (isLoading || data == null || isLoadingReferralCode) {
    return (
      <DefaultLayout
        ref={scrollRef}
        showBorder={isNewMenuEnabled}
        showRoundedTop={isNewMenuEnabled}
        headerLeft={
          !isDesktop ? (
            <MenuButton className={twMerge('h-12', isThemeEnabled && 'text-vault_text')} />
          ) : null
        }
        isHeaderTransparent={!showProfileImage}
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        withVaultTheme={isThemeEnabled}
        shouldSkipMargin
        headerClassName={
          isThemeEnabled
            ? showProfileImage && !isDesktop
              ? 'border-0 border-solid border-b border-vault_text/5 bg-vault_background'
              : 'bg-transparent'
            : undefined
        }
      >
        {foldersEnabled ? <VaultContentSkeleton /> : <SkeletonVaultView />}
      </DefaultLayout>
    );
  }

  if (data.data.artistLink == null) {
    // TODO: Handle artist is null
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (code && referralCodeData) {
    return (
      <ReferralLinkView
        code={code}
        referralCodeInfo={referralCodeData}
        artistHandle={artistHandle}
      />
    );
  }

  return (
    <ArtistLayout
      artist={data.data.artistLink.artist}
      showProfileImage={showProfileImage}
      showBadgeOverlay={showBadgeOverlay}
      ref={scrollRef}
      rightHeader={
        !showBadgeOverlay && (
          <View className="relative flex min-h-12 items-center gap-4 md2:min-h-5">
            {isSelecting && isOwner ? (
              <View className="absolute right-0">
                <Button
                  label="Cancel"
                  type="secondary"
                  onClick={() => {
                    setIsSelecting(false);
                  }}
                  className={twMerge(
                    'rounded-full bg-base800 px-4 py-3 text-[14px]/[18px] font-medium',
                    isNewMenuEnabled ? 'text-white' : 'text-base50',
                  )}
                />
              </View>
            ) : (
              <>
                {showInvitePageIcon && (
                  <Button
                    iconOnly
                    label=""
                    leadingIcon={faTrophy}
                    className={twMerge(
                      'mr-[8px] text-[20px]',
                      isThemeEnabled ? 'text-vault_text' : 'text-white',
                    )}
                    onClick={e => {
                      e.stopPropagation();
                      onVaultInvitesClick();
                    }}
                    event={{
                      type: EVENTS.VAULT_INVITES,
                      properties: {
                        artistHandle,
                      },
                    }}
                  />
                )}
                {showAnnouncementsButton && (
                  <Link
                    className="relative mr-[8px] flex flex-row items-center gap-3 "
                    to={artistNavigationPath(artistHandle, '/announcements')}
                  >
                    <FontAwesomeIcon
                      icon={faMegaphone}
                      className={twMerge(
                        'text-[20px]',
                        isThemeEnabled ? 'text-vault_text' : 'text-white',
                      )}
                      size="lg"
                    />

                    {announcementNotificationCount.unreadCount != null &&
                      announcementNotificationCount.unreadCount > 0 && (
                        <View
                          className={twMerge(
                            'absolute -right-1 top-0 h-2 w-2 items-center justify-center rounded-full',
                            isThemeEnabled ? 'bg-vault_text' : 'bg-yellow100',
                          )}
                        />
                      )}
                  </Link>
                )}
                <Button
                  iconOnly
                  label=""
                  leadingIcon={faEllipsis}
                  className={twMerge(
                    'mr-[8px] text-[20px]',
                    isThemeEnabled ? 'text-vault_text' : 'text-white',
                  )}
                  onClick={e => {
                    e.stopPropagation();
                    onEllipsisClick();
                  }}
                  event={{
                    type: EVENTS.OPEN_BOTTOMSHEET,
                    properties: {
                      bottomsheetType: BOTTOMSHEET_TYPES.SHARE,
                      entity: 'vault',
                      aristId: data.data.artistLink.artist.id,
                    },
                  }}
                />
              </>
            )}
          </View>
        )
      }
    >
      {showBadgeOverlay && vaultId ? (
        <MembershipWelcomeView
          vaultId={vaultId}
          childrenCotaninerClassName="my-10"
          title="Welcome to the Vault"
          aspectRatio={0.8}
          isLoading={isLoadingMembership}
          artistHandle={artistHandle}
          artistName={data.data.artistLink.artist.name || artistHandle}
          serialNumber={serialNumber}
          imageUrl={artist?.membershipCardImage?.url}
          displayName={user?.displayName || user?.username}
          createdAt={createdAt}
          points={points}
        />
      ) : (
        <VaultView
          artist={data.data.artistLink.artist}
          setShowProfileImage={setShowProfileImage}
          scrollRef={scrollRef}
          showRightHeader
        />
      )}
    </ArtistLayout>
  );
};
